import React from "react";
import {Route, Redirect} from "react-router-dom";
import {message} from 'antd';
import 'antd/dist/antd.css';

//AUTH related methods
// import { getFirebaseBackend } from "../helpers/authUtils";
import backend from '../helpers/backend';


const AppRoute = ({
                      component: Component,
                      layout: Layout,
                      isAuthProtected,
                      ...rest
                  }) => (
    <Route
        {...rest}
        render={ props => {
            // const fireBaseBackend = getFirebaseBackend();

            // console.log('路由参数>>>', props)

            // if (isAuthProtected && !fireBaseBackend.getAuthenticatedUser()) {
            if (isAuthProtected && !backend.getAuthenticatedUser()) {
                return (
                    <Redirect
                        to={{pathname: "/login", state: {from: props.location}}}
                    />
                );
            }


            // 新用户必须先填写公司资料，未填写公司资料，company_id=-1
            let companyId = sessionStorage.getItem('companyId');

            // 公司状态为0待审核时不能操作其他模块
            let companyStatus = parseInt(sessionStorage.getItem("companyStatus"));

            // console.log('companyId===', companyId);
            // console.log('路由跳转，公司状态===' + companyStatus)

            if (isAuthProtected && backend.getAuthenticatedUser() && parseInt(companyId) === 0 || companyStatus === 0 || companyStatus === 2) {
                console.log('未填写公司资料,新用户必须先填写公司资料');
                props.location.pathname = "/company-info";
                props.match.path = "/company-info";
                props.match.url = "/company-info";
            }

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            );

        }}
    />
);

export default AppRoute;
