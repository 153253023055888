import { Carousel, Image } from 'antd';
import React, { useState } from 'react';
import { List, Avatar, Button, Skeleton } from 'antd';
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const listTemp = [
    { key: "名称", value: "xxxx", type: 0 },
    { key: "产地", value: "xxxx", type: 1 },
    { key: "商品图片", value: "xxxx", type: 2 },
    { key: "购买连结", value: "www.sample.com", type: 3 }]

const logTemp = [
    { key: "入库", subTitle: "入库", time: "2021-06-01 16:40:10", location: "XX省XX市", link: "aaaa" },
    { key: "打包", subTitle: "打包", time: "2021-06-01 16:40:10", location: "XX省XX市", link: "aaaa" },
    { key: "发货", subTitle: "发货", time: "2021-06-01 16:40:10", location: "XX省XX市", link: "aaaa" }
]


const hasLink = (data) => {
    return data.filter((e, i) => { return e.type === 3 }).length > 0 ? data.filter((e, i) => { return e.type === 3 }) : false
}

const blockChainMsg = (msg) => {
    return (
        <div style={{ margin: "10px", borderRadius: "30px", boxSizing: "border-box", textAlign: 'center', border: "1px #820014 solid", height: "38px", width: "calc(100% - 20px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div>区块链信息: {msg ? msg : "XXXXXXXXXXXXXXXXXXXXXXXXX"}</div>
        </div>
    )
}

const PreviewModal = (props) => {
    const [list, setList] = useState(listTemp)
    const [logList, setLoglist] = useState(logTemp)
    return (
        <div>
            <div>
                <Carousel autoplay>
                    <div>
                        <Image
                            width={"100%"}
                            height={200}
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                    </div>
                    <div>
                        <Image
                            width={"100%"}
                            height={200}
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                    </div>
                    <div>
                        <Image
                            width={"100%"}
                            height={200}
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                    </div>
                </Carousel>
            </div>
            <div>
                <List
                    className="demo-loadmore-list"
                    // loading={initLoading}
                    itemLayout="horizontal"
                    style={{ fontSize: "10px" }}
                    dataSource={list}
                    footer={hasLink(list) ? blockChainMsg(hasLink(list)[0]["value"]) : null}
                    renderItem={item => (
                        <div>
                            <List.Item
                            >
                                <List.Item.Meta
                                    title={<div style={{ paddingLeft: "10px" }}>{item.key}</div>}
                                />
                                <div style={{ paddingRight: "10px" }}>dksaldjsalkjdas</div>
                            </List.Item>
                        </div>
                    )}
                />
            </div>
            <div>
                {logList ? logList.map((e, i) => {
                    return (
                        <div>
                            <div style={{ width: "100%", height: "10px", backgroundColor: "lightgray" }} />
                            <div style={{padding:"10px"}}>
                                <div style={{ textAlign: "center", fontSize:"15px", fontWeight:700,  paddingBottom:"10px" }}>
                                    {e.key}
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", textAlign:"right" }}>
                                    <div>{e.subTitle}</div>
                                    <div>
                                        <div>{e.time}</div>
                                        <div>{e.location}</div>
                                    </div>
                                </div>
                                {e.link?blockChainMsg(e.link):null}
                            </div>
                        </div>
                    )
                }) : null}
            </div>
        </div>);
}

export default PreviewModal;