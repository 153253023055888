import React, {Component} from "react";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {message, Modal, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import SettingMenu from "../Shared/SettingMenu";
import {PlusOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {Input, Row, Col, Card, CardBody, Form, FormGroup, Button, Label} from "reactstrap";
import {AvForm, AvField} from "availity-reactstrap-validation";
import Select from "react-select";

import PicturesGrid from '../../components/PicturesGrid/PicturesGrid'
import ReactSortableHoc from '../../components/ReactSortableHoc'

import PicturesWall from '../../components/PicturesWall'
import moment from 'moment'

import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";

import i18n from '../../i18n';
import {Translation} from 'react-i18next';
import "../../css/FormProduct.css"

// actions
import {
    getProductInfo,
    updateProductInfo,
    createProduct,
    updateInputValue,
    clearProductImageIdProp,
    clearInputError,
    clearAlert,
    clearProductProps
} from "../../store/actions";
import Base64 from "base-64";
import CryptoJS from "crypto-js";
import axios from "axios";

const optionStatus = [
    {label: i18n.t('enabled'), value: 'enabled'},
    {label: i18n.t('disabled'), value: 'disabled'},
];

/**
 * Formats the size
 */
let formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const imgUrl = 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/';
const dir = 'provenance/';
const todayKey = moment().format('YYYYMMDD');
const host = "https://provenance-sz.oss-cn-shenzhen.aliyuncs.com";
const accessKeyId = "LTAI5tM3sbNUsuy9Gfc4vMw3";
const accessSecret = "ZCVR4d9k57prTWyVMsxIFWdCzCwL4Y";
const policyText = {
    "expiration": "2028-01-01T12:00:00.000Z", // 设置该Policy的失效时间，
    "conditions": [
        ["content-length-range", 0, 1048576000] // 设置上传文件的大小限制
    ]
};
const policyBase64 = Base64.encode(JSON.stringify(policyText));
const bytes = CryptoJS.HmacSHA1(policyBase64, accessSecret, {asBytes: true});
const signature = bytes.toString(CryptoJS.enc.Base64);

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


class FormProduct extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.uploadExcelRequest = this.uploadExcelRequest.bind(this);
        this.handleChangePics = this.handleChangePics.bind(this);
        this.uploadFileNames = this.uploadFileNames.bind(this);
        this.delFileNames = this.delFileNames.bind(this);
        this.uploadRequest = this.uploadRequest.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleChangePicturesGrid = this.handleChangePicturesGrid.bind(this);
        this.handleChangePicturesGrid2 = this.handleChangePicturesGrid2.bind(this);

        this.state = {
            productAddType: 1,
            importExcelList: [],
            id: '',
            name: '',
            systemStatus: 'enabled',
            description: '',
            shopUrl: '',
            productionAddress: '',
            selectedFiles: [],
            certificateFilename: '',
            fileLists: [],
            uploadFileLists: [],
            delFileLists: [],
            hasDelFile: false,

            componentNameA: 'banner',
            bannerFileLists: [],
            componentNameB: 'details',
            detailsFileLists: [],
            isChangeBannerFile: false,
            isChangeDetailsFile: false,

            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileName: '',
            fileNum: 100,
            fileList: [],
            // 图片上传推拽排序
            picturesGridFileList: [],
            picturesGridFileList2: [
                {
                    uid: '-2',
                    name: '1626013802314.jpg',
                    status: 'done',
                    url: 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/20210711/1626013802314.jpg'
                },
                {
                    uid: '-3',
                    name: '1626013836528.jpg',
                    status: 'done',
                    url: 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/20210711/1626013836528.jpg'
                }
            ],
            templatePath:'',
        };
    }

    componentDidMount() {
        console.log('componentDidMount');
        this.state.templatePath  = "https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/app/"+i18n.t('product_template')+".xlsx";
        this.props.clearProductProps();
        if (this.props, this.props.match.params.productId !== undefined) {

            setTimeout(() => {
                let detailImages = this.initFileList(this.props.product.detailImages)
                this.setState({
                    fileList: detailImages
                })
            }, 500)

            this.props.getProductInfo(this.props.match.params.productId, this.props.history);
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        document.title = this.props.match.params.productId !== undefined ? i18n.t('edit_product') : i18n.t('add_product');
        // console.log('componentDidUpdate');
        //console.log(this.props.product);
        if (this.state.systemStatus !== this.props.product.systemStatus) {
            console.log('testing update status', this.props.product.systemStatus);
            this.setState({systemStatus: this.props.product.systemStatus});
        }

        

    }

    // 上传图片拖拽排序
    handleChangePicturesGrid(fileList) {
        console.log('===详情图上传拖拽排序组件改变了===');
        console.log(fileList);
        this.setState({
            detailsFileLists: fileList,
            isChangeDetailsFile: true
        })
    }

    handleChangePicturesGrid2(fileList) {
        console.log('===轮播图上传拖拽排序组件改变了===');
        console.log(fileList);
        this.setState({
            bannerFileLists: fileList,
            isChangeBannerFile: true
        })
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.props.clearInputError(name);
    }

    handleStatusChange(event) {
        console.log('handleStatusChange', event);

        this.setState(prevState => ({
            systemStatus: event.value,
        }));
        this.props.updateInputValue('systemStatus', event.value);
        this.props.clearInputError('systemStatus');
    }

    handleAcceptedFiles = files => {
        let totalUploadFiles = 0;

        for (let tmp of this.state.selectedFiles) {
            // if(tmp.uploadable === true || tmp.delete === false) {
            if (tmp.uploadable === true) {
                totalUploadFiles += 1;
            }
        }

        console.log('testing', totalUploadFiles + files.length);
        if (totalUploadFiles + files.length <= 5) {
            files.map(file => {
                console.log('test', file, URL.createObjectURL(file));
                Object.assign(file, {
                    uploadable: true,
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            });

            this.setState(prevState => ({
                selectedFiles: [...prevState.selectedFiles, ...files]
            }));

            this.props.clearInputError('certificateFile');
        }
    };

    handleRemoveImage(index) {
        console.log('handleRemoveImage()', index);

        let tmp = [];

        for (let i = 0; i < this.state.selectedFiles.length; i++) {
            if (index !== i) {
                // not to delete
                tmp.push(this.state.selectedFiles[i]);
            } else {
                // index matched
                if (this.state.selectedFiles[i].uploadable === true) {
                    // delete
                    console.log('testing 1');
                } else {
                    // mark as delete
                    console.log('testing 2');

                    tmp.push({...this.state.selectedFiles[i], delete: !this.state.selectedFiles[i].delete});
                }
            }
        }

        this.setState(prevState => ({
            selectedFiles: tmp
        }));
    }

    uploadFileNames(fileList) {
        console.log('=====上传oss的图片=====');
        console.log(fileList);
        this.setState({
            uploadFileLists: fileList
        })
        // this.setState({
        //   fileLists: [...this.state.fileLists, fileItem]
        // },() =>{
        //     console.log('图片上传成功>>>');
        //     console.log(this.state.fileLists)
        // })
    }

    delFileNames(fileList) {
        console.log('删除图片后>>>');
        console.log(fileList)
        this.setState({
            delFileLists: fileList,
            hasDelFile: true
        })
    }


    handleChangePics(fileList) {
        console.log('=====改变选择图片=====');
        console.log(fileList);
        if (fileList.length > 0) {
            let fileArr = [];
            fileList.map(item => {
                if (item['status'] === 'done') {
                    const {uid, name, status, url} = item;
                    const itemObj = {uid, name, status, url};
                    fileArr.push(itemObj);
                }
            });
            // this.setState({
            //   fileLists: fileArr
            // })
        } else {
            // this.setState({
            //   fileLists: []
            // })
        }
        // console.log(this.state.fileLists)
    }

    // 图片轮播图
    renderPicturesWall(imgArr) {
        if (!imgArr) {
            return
        }
        let fileListsArr = [];
        for (let i = 0; i < imgArr.length; i++) {
            let file = imgArr[i].substring(imgArr[i].lastIndexOf('/') + 1);
            let fileItem = {
                uid: file,
                name: file,
                status: 'done',
                url: imgArr[i],
            };
            fileListsArr.push(fileItem);
        }
        return (
            <PicturesWall uploadFileName={this.uploadFileNames} delFileName={this.delFileNames}
                          handleChangePic={this.handleChangePics} filelist={fileListsArr} filenum={5}/>
        )
    }

    // 初始化商品详情图片
    initFileList(imgArr) {
        if (!imgArr) {
            return
        }
        let fileListsArr = [];
        for (let i = 0; i < imgArr.length; i++) {
            let file = imgArr[i].substring(imgArr[i].lastIndexOf('/') + 1);
            let fileItem = {
                uid: file,
                name: file,
                status: 'done',
                url: imgArr[i],
            };
            fileListsArr.push(fileItem);
        }
        return fileListsArr
    }

    // 图片详情图
    renderPicturesDetails(imgArr) {
        if (!imgArr) {
            return
        }
        let fileListsArr = [];
        for (let i = 0; i < imgArr.length; i++) {
            let file = imgArr[i].substring(imgArr[i].lastIndexOf('/') + 1);
            let fileItem = {
                uid: file,
                name: file,
                status: 'done',
                url: imgArr[i],
            };
            fileListsArr.push(fileItem);
        }
        return (
            <>
                <Upload
                    action='https://provenance-sz.oss-cn-shenzhen.aliyuncs.com'
                    listType="picture-card"
                    fileList={this.state.fileList}
                    customRequest={this.uploadRequest}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    beforeUpload={this.beforeUpload}
                    data={{
                        key: dir + todayKey + '/' + this.state.fileName,
                        policy: policyBase64,
                        OSSAccessKeyId: accessKeyId,
                        success_action_status: 200,
                        signature,
                    }}
                >
                    {this.state.fileList.length >= this.state.fileNum ? null :
                        <div>
                            <PlusOutlined/>
                            <div style={{marginTop: 8}}>上传图片
                            </div>
                        </div>}
                </Upload>
                <Modal
                    visible={this.state.previewVisible}
                    // title={this.state.previewTitle}
                    bodyStyle={{padding: 10}}
                    footer={null}
                    onCancel={this.handleCancel}>
                    <img alt="example" style={{width: '100%'}}
                         src={this.state.previewImage}/>
                </Modal>
            </>
        )
    }

    renderPicturesUpload() {
        return (
            <>
                <Upload
                    action='https://provenance-sz.oss-cn-shenzhen.aliyuncs.com'
                    listType="picture-card"
                    fileList={this.state.fileList}
                    customRequest={this.uploadRequest}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    beforeUpload={this.beforeUpload}
                    data={{
                        key: dir + todayKey + '/' + this.state.fileName,
                        policy: policyBase64,
                        OSSAccessKeyId: accessKeyId,
                        success_action_status: 200,
                        signature,
                    }}
                >
                    {this.state.fileList.length >= this.state.fileNum ? null :
                        <div>
                            <PlusOutlined/>
                            <div style={{marginTop: 8}}>上传图片
                            </div>
                        </div>}
                </Upload>
                <Modal
                    visible={this.state.previewVisible}
                    // title={this.state.previewTitle}
                    bodyStyle={{padding: 10}}
                    footer={null}
                    onCancel={this.handleCancel}>
                    <img alt="example" style={{width: '100%'}}
                         src={this.state.previewImage}/>
                </Modal>
            </>
        )
    }

    // 加载图片拖拽排序组件
    renderReactSortableHocDetail(imgArr, fileNum, changePg) {
        if (!imgArr) {
            return
        }
        let fileListsArr = [];
        for (let i = 0; i < imgArr.length; i++) {
            let file = imgArr[i].substring(imgArr[i].lastIndexOf('/') + 1);
            let fileItem = {
                uid: file,
                name: file,
                status: 'done',
                url: imgArr[i],
            };
            fileListsArr.push(fileItem);
        }
        return (
            <>
                <ReactSortableHoc
                    fileList={fileListsArr}
                    fileNum={fileNum}
                    changePg={changePg}>
                </ReactSortableHoc>
            </>
        )
    }

    renderReactSortableHoc(fileNum, changePg) {
        return (
            <>
                <ReactSortableHoc
                    fileList={this.state.picturesGridFileList}
                    fileNum={fileNum}
                    changePg={changePg}>
                </ReactSortableHoc>
            </>
        )
    }


    handleCancel = () => this.setState({previewVisible: false});

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = (file) => {
        // console.log('删除图片>>>');
        // console.log(file);
        // console.log(this.state.fileList);
        let fileListArr = this.state.fileList;
        fileListArr.map((item, key) => {
            console.log(item)
            if (item.status === 'removed') {
                fileListArr.splice(key, 1)
            }
        });
        this.setState({
            fileList: [...fileListArr]
        }, () => {
            console.log(this.state.fileList)
        })
    };

    beforeUpload = file => {
        console.log('上传oss前的文件===>>>', file);
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        let fileItem = {
            uid: file.uid,
            name: filename,
            status: 'done',
            url: imgUrl + dir + todayKey + '/' + filename,
        };
        this.setState({
            fileName: filename,
        }, () => {
            // console.log(fileItem)
        });
    };

    // 详情图上传图片
    uploadRequest(params) {
        console.log('自定义上传请求>>>');
        console.log(params);
        let that = this;
        let forms = new FormData();
        let configs = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        let key = dir + todayKey + '/' + this.state.fileName;
        forms.append('key', key);
        forms.append('policy', policyBase64);
        forms.append('OSSAccessKeyId', accessKeyId);
        forms.append('success_action_status', 200);
        forms.append('signature', signature);
        forms.append('file', params.file);

        axios.post(host, forms, configs).then(res => {
            if (res.status === 200) {
                let arr = {
                    uid: that.state.fileName,
                    name: this.state.fileName,
                    status: 'done', // removed done
                    url: 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/' + key,
                };
                that.setState({
                    fileList: [...this.state.fileList, arr]
                }, () => {
                    console.log('图片上传成功>>>');
                    console.log(this.state.fileList)
                })
            }
        })
    }

    // 导入excel 自定义上传方法 https://www.cnblogs.com/qianguyihao/p/13093592.html
    uploadExcelRequest(params) {
        let that = this;
        console.log('自定义上传请求>>>');
        console.log(params.file);

        // start：进度条相关
        // 伪装成 handleChange里面的图片上传状态
        const excelItem = {
            uid: '1', // 注意，这个uid一定不能少，否则上传失败
            name: '商品导入模板.xlsx',
            status: 'uploading',
            url: '',
            percent: 98, // 注意不要写100。100表示上传完成
        };

        this.setState({
            importExcelList: [excelItem],
        });
        // end：进度条相关

        let baseURL = process.env.REACT_APP_API_URL_PROD;
        console.log('baseURL==' + baseURL);
        let host = baseURL + 'importExcel';
        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        // let host = 'http://192.168.0.103:5004/api/v1/importExcel';
        // let host = 'https://www.mocky.io/v2/5cc8019d300000980a055e76';
        let forms = new FormData();
        let configs = {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": authToken
            }
        };
        forms.append('file', params.file);

        axios.post(host, forms, configs).then(res => {
            console.log(res);
            message.success(res.data.data);
            const excelItem = {
                uid: '1', // 注意，这个uid一定不能少，否则上传失败
                name: '商品导入模板.xlsx',
                status: 'done',
                url: '商品导入模板.xlsx',
                percent: 100,
            };
            that.setState({
                importExcelList: [excelItem],
            });
            setTimeout(() => {
                this.props.history.push('/view-product');
            }, 1000)
        }).catch((e) => {
            console.log(e);
            message.error('新增的产品名称已经重复');
            const excelItem = {
                uid: '1', // 注意，这个uid一定不能少，否则上传失败
                name: '商品导入模板.xlsx',
                status: 'error',
                url: '商品导入模板.xlsx',
                percent: 100,
            };
            that.setState({
                importExcelList: [excelItem],
            });
        })
    }

    handleFormSubmit(event, values) {
        let that = this;

        // 商品轮播图
        let productImages = [];
        let bannerFileLists = [];
        if (this.props.match.params.productId) {
            // 更新商品
            if (this.state.isChangeBannerFile) {
                bannerFileLists = this.state.bannerFileLists
            } else {
                // 图片没有被修改
                bannerFileLists = this.props.product.productImageId
            }
        } else {
            // 新增商品
            bannerFileLists = this.state.bannerFileLists
        }

        if (bannerFileLists.length > 0) {
            bannerFileLists.map(item => {
                if (item.url) {
                    productImages.push(item.url)
                } else {
                    productImages.push(item)
                }
            });
        }
        console.log('商品轮播图最终上传的图片===>>>', productImages);


        // 商品详情图
        let productDetailImages = [];
        let detailsFileLists = [];
        if (this.props.match.params.productId) {
            // 更新商品
            if (this.state.isChangeDetailsFile) {
                detailsFileLists = this.state.detailsFileLists
            } else {
                // 图片没有被修改
                detailsFileLists = this.props.product.detailImages
            }
        } else {
            // 新增商品
            detailsFileLists = this.state.detailsFileLists
        }

        if (detailsFileLists.length > 0) {
            detailsFileLists.map(item => {
                if (item.url) {
                    productDetailImages.push(item.url)
                } else {
                    productDetailImages.push(item)
                }
            });
        }
        console.log('商品详情图最终上传的图片===>>>', productDetailImages);

        if (this.props.match.params.productId === undefined) {
            let submitData = {
                ...values,
                systemStatus: that.state.systemStatus,
                image: productImages,
                detailImages: productDetailImages,
            };

            console.log('submit data', submitData);

            this.props.createProduct(submitData, this.props.history);
        } else {
            let deleteProductImageId = [];

            for (let tmp of this.state.selectedFiles) {
                if (tmp.delete === true) {
                    deleteProductImageId.push(tmp.productImageId);
                }
            }

            let submitData = {
                ...values,
                productId: this.props.match.params.productId,
                systemStatus: this.state.systemStatus,
                image: productImages,
                detailImages: productDetailImages,
                deleteProductImageId,
            };

            console.log('submit data', submitData);

            this.props.updateProductInfo(submitData, this.props.history);
        }
    }


    handleFormSubmit222(event, values) {

        // console.log('====商品新增/更新====');


        let productImages = [];
        let uploadFileLists = this.state.uploadFileLists || [];
        let productImageArr = this.props.product.productImageId || [];
        let delFileLists = this.state.delFileLists || [];

        if (uploadFileLists.length > 0) {
            uploadFileLists.map(item => {
                if (item.url) {
                    productImages.push(item.url)
                }
            });
        }
        if (uploadFileLists.length === 0) {
            if (this.state.hasDelFile) {
                // 已点击删除图片操作
                if (delFileLists.length > 0) {
                    let arr = [];
                    delFileLists.map((item) => {
                        arr.push(item.url)
                    })
                    productImages = arr
                }
            } else {
                productImages = productImageArr
            }
        }
        console.log('商品轮播图最终上传的图片===>>>', productImages);

        let productDetailImages = [];
        let uploadDetailFileLists = this.state.fileList
        if (uploadDetailFileLists.length > 0) {
            uploadDetailFileLists.map(item => {
                if (item.url) {
                    productDetailImages.push(item.url)
                }
            });
        }

        console.log('商品详情图最终上传的图片===>>>', productDetailImages);


        // for(let tmp of this.state.selectedFiles) {
        //   if(tmp.uploadable === true) {
        //     productImages.push(tmp);
        //   }
        // }

        // productImages = ['http://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/20210409/1617951327397.png','http://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/20210409/1617951392203.png'];

        if (this.props.match.params.productId === undefined) {
            let submitData = {
                ...values,
                systemStatus: this.state.systemStatus,
                image: productImages,
                detailImages: productDetailImages,
            };

            console.log('submit data', submitData);

            this.props.createProduct(submitData, this.props.history);
        } else {
            let deleteProductImageId = [];

            for (let tmp of this.state.selectedFiles) {
                if (tmp.delete === true) {
                    deleteProductImageId.push(tmp.productImageId);
                }
            }

            let submitData = {
                ...values,
                productId: this.props.match.params.productId,
                systemStatus: this.state.systemStatus,
                image: productImages,
                detailImages: productDetailImages,
                deleteProductImageId,
            };

            console.log('submit data', submitData);

            this.props.updateProductInfo(submitData, this.props.history);
        }
    }

    render() {
        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {/*{this.props.loading ? <Loader/> : null}*/}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            {/* <h4 className="navi-heander-size">{this.props.match.params.productId !== undefined ? t('edit_product') : t('add_product')}</h4> */}
                                            <ol className="breadcrumb mb-0">
                                                <li className="breadcrumb-item font-bold-size">{t('view_product')}</li>
                                                <li className="breadcrumb-item sub-tile-color">{this.props.match.params.productId !== undefined ? t('edit_product') : t('add_product')}</li>
                                            </ol>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="InfoContainer">
                                    <Col sm={12} md={12}>
                                        <div className='productadd-type'>
                                            <span
                                                onClick={() => {
                                                    this.setState({
                                                        productAddType: 1
                                                    })
                                                }}
                                                className={this.state.productAddType == 1 ? 'sact' : ''}>{t('add_form_product')}</span>
                                            <span
                                                onClick={() => {
                                                    this.setState({
                                                        productAddType: 2
                                                    })
                                                }}
                                                className={this.state.productAddType == 2 ? 'sact' : ''}>{t('import_products')}</span>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Row style={{'display': this.state.productAddType == 2 ? 'block' : 'none'}}>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <div style={{'padding': '20px 0'}}>
                                                                    <span>1. {t('import_template')}：</span><a
                                                                    href={this.state.templatePath}
                                                                    target='_blank'>{t('product_template')}.xlsx</a></div>
                                                                <div>2. {t('upload_template')}</div>
                                                                <div style={{'width': '30%', 'padding': '20px'}}>
                                                                    <Upload
                                                                        listType="picture"
                                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                        maxCount={1}
                                                                        fileList={this.state.importExcelList}
                                                                        customRequest={this.uploadExcelRequest}
                                                                    >
                                                                        <Button icon={<UploadOutlined/>}>{t('upload_form')}</Button>
                                                                    </Upload>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row style={{'display': this.state.productAddType == 1 ? 'block' : 'none'}}>
                                            <Col sm={12}>
                                                <Card>
                                                    <CardBody>
                                                        <AvForm id="inline-block" onValidSubmit={this.handleFormSubmit}>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <AvField
                                                                            label={'ID'}
                                                                            type="text"
                                                                            name="id"
                                                                            value={this.props.product.productId !== null ? this.props.product.productId : t('')}
                                                                            disabled
                                                                            inputClass="block-inline product_id_width"
                                                                            labelClass="lable-left"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <AvField
                                                                            label={<> <i
                                                                                className="mdi mdi-asterisk label-asterisk"></i>{t('name')}</>}
                                                                            type="text"
                                                                            name="name"
                                                                            value={this.props.product.name}
                                                                            onChange={this.handleInputChange}
                                                                            required
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                    errorMessage: t('required')
                                                                                },
                                                                                custom: () => this.props.inputError.name,
                                                                            }}
                                                                            inputClass="block-inline"
                                                                            labelClass="lable-left"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <FormGroup>
                                                                            <Label className="lable-left"><i
                                                                                className="mdi mdi-asterisk label-asterisk"></i>{t('status')}
                                                                            </Label>
                                                                            <Select
                                                                                name="pageSize"
                                                                                value={optionStatus.find(option => option.value === this.props.product.systemStatus)}
                                                                                onChange={this.handleStatusChange}
                                                                                options={optionStatus}
                                                                                isSearchable={false}
                                                                                menuPortalTarget={document.body}
                                                                                className="block-inline-status"
                                                                            />
                                                                            {this.props.inputError.systemStatus !== true &&
                                                                            <div
                                                                                className="invalid-feedback d-block">{this.props.inputError.systemStatus}</div>}
                                                                        </FormGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <AvField
                                                                            label={t('description')}
                                                                            type="textarea"
                                                                            name="description"
                                                                            value={this.props.product.description}
                                                                            onChange={this.handleInputChange}
                                                                            inputClass="block-inline"
                                                                            labelClass="lable-left"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <AvField
                                                                            label={t('production_address')}
                                                                            type="textarea"
                                                                            name="productionAddress"
                                                                            value={this.props.product.productionAddress}
                                                                            onChange={this.handleInputChange}
                                                                            inputClass="block-inline"
                                                                            labelClass="lable-left"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>


                                                            <Row>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <AvField
                                                                            label={t('buy_address')}
                                                                            type="text"
                                                                            name="shopUrl"
                                                                            value={this.props.product.shopUrl}
                                                                            onChange={this.handleInputChange}
                                                                            inputClass="block-inline"
                                                                            labelClass="lable-left"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>


                                                            {/*图片墙实现拖拽排序 https://www.jianshu.com/p/29cfa830d478 */}
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <div><Label>{t('product_image_5')}</Label></div>
                                                                    {/*<div className='product-pic'>
                                                                        {this.props.match.params.productId ? this.renderPicturesWall(this.props.product.productImageId) :
                                                                            <PicturesWall
                                                                                uploadFileName={this.uploadFileNames}
                                                                                delFileName={this.delFileNames}
                                                                                handleChangePic={this.handleChangePics}
                                                                                filelist={[]} filenum={5}/>}
                                                                    </div>*/}

                                                                    {/*轮播图*/}
                                                                    <div className='product-pic'>
                                                                        {this.props.match.params.productId ? this.renderReactSortableHocDetail(this.props.product.productImageId, 5, this.handleChangePicturesGrid2) : this.renderReactSortableHoc(5, this.handleChangePicturesGrid2)}
                                                                    </div>

                                                                </Col>
                                                            </Row>

                                                            {/*<Row>
                                                                <Col sm={12}>
                                                                    <div><Label>{t('product_image_details')}</Label>
                                                                    </div>
                                                                    <div className='product-pic'>
                                                                        {this.props.match.params.productId ? this.renderPicturesDetails(this.props.product.detailImages) : this.renderPicturesUpload()}
                                                                    </div>
                                                                </Col>
                                                            </Row>*/}

                                                            {/*图片可推拽排序，可多次选择多张 https://www.jianshu.com/p/29cfa830d478 */}
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <div><Label>{t('product_image_details')}</Label>
                                                                    </div>
                                                                    <div className='product-pic'>
                                                                        {this.props.match.params.productId ? this.renderReactSortableHocDetail(this.props.product.detailImages, 100, this.handleChangePicturesGrid) : this.renderReactSortableHoc(100, this.handleChangePicturesGrid)}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row style={{marginTop: '20px'}}>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <Button color="primary"
                                                                                className="mr-1 company-add btn-primary-bg">
                                                                            {this.props.match.params.productId === undefined ? t('add') : t('update')}
                                                                        </Button>
                                                                        {/* <Button color="secondary" onClick={() => console.log('click cancel')}>{t('cancel')}</Button> */}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>

                                                        {this.props.alert.success ? (
                                                            <SweetAlert
                                                                title={this.props.match.params.productId === undefined ? t('product_added') : t('product_updated')}
                                                                success
                                                                confirmBtnBsStyle="success"
                                                                onConfirm={() => {
                                                                    this.props.clearAlert('success');
                                                                    this.props.history.push('/view-product')
                                                                }}
                                                            >
                                                            </SweetAlert>
                                                        ) : null}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </React.Fragment>
                }
            </Translation>
        );
    }
}

// export default FormUpload;
const mapStateToProps = state => {

    const {
        product,
        productImages,
        inputError,
        alert,
        error,
        loading
    } = state.FormProductInfo;

    console.log('testing', product);

    return {
        product,
        productImages,
        inputError,
        alert,
        error,
        loading
    };
};

export default withRouter(connect(mapStateToProps, {
    getProductInfo,
    createProduct,
    updateProductInfo,
    updateInputValue,
    clearProductImageIdProp,
    clearInputError,
    clearAlert,
    clearProductProps
})(FormProduct));
