import {
    CREATE_COMPANY_INFO,
    GET_COMPANY_INFO,
    GET_COMPANY_INFO_SUCCESS,
    GET_COMPANY_CERTIFICATE_SUCCESS,
    CLEAR_COMPANY_CERTIFICATE_PROP,
    COMPANY_WORKER_LIST,
    COMPANY_WORKER_LIST_SUCCESS,
    REGISTER_APP_USER,
    DEL_APP_USER,
    UPDATE_COMPANY_INFO,
    CLEAR_INPUT_ERROR,
    SHOW_ALERT,
    CLEAR_ALERT,
    API_ERROR,
} from './actionTypes';
import {message} from "antd";

const initialState = {
    companyId: null,
    companyName: '',
    companyPhoneNumber: '',
    workers:[],
    status: '',
    companyEmail: '',
    companyAddressLine1: '',
    companyAddressLine2: '',
    companyIntroduction: '',
    certificateFilename: '',
    companyImages: [],
    companyCertificate: null,
    inputError: {
        name: true,
        phoneNumber: true,
        email: true,
        addressLine1: true,
        addressLine2: true,
        introduction: true,
        certificateFile: true,
    },
    alert: {
        success: false,
    },
    error: null,
    loading: false
}

const companyInfo = (state = initialState, action) => {
    let inputError = {...state.inputError};
    let alert = {...state.alert};

    switch (action.type) {
        case GET_COMPANY_INFO:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_COMPANY_INFO_SUCCESS:
            console.log('reducer debug', action.payload.data.data[0]);
            let companyData = action.payload.data.data[0];
            state = {
                ...state,
                companyId: companyData.company_id,
                companyName: companyData.name,
                companyPhoneNumber: companyData.phone_number,
                status: companyData.status,
                companyEmail: companyData.email,
                companyAddressLine1: companyData.address_line_1,
                companyAddressLine2: companyData.address_line_2,
                certificateFilename: companyData.certificate_filename,
                companyImages: companyData.companyImages,
                companyIntroduction: companyData.introduction,
                loading: false
            }
            break;
        case GET_COMPANY_CERTIFICATE_SUCCESS:
            console.log('reducer debug', action.payload, action.payload.data);

            state = {
                ...state,
                companyCertificate: action.payload.data,
                loading: false
            }
            break;
        case CLEAR_COMPANY_CERTIFICATE_PROP:
            state = {
                ...state,
                companyCertificate: null,
            }
            break;
        case UPDATE_COMPANY_INFO:
            state = {
                ...state,
                loading: true
            }
            break;
        case CREATE_COMPANY_INFO:
            state = {
                ...state,
                loading: true
            }
            break;

        case  COMPANY_WORKER_LIST:
            state = {
                ...state,
                loading: true
            }
            break;
        case COMPANY_WORKER_LIST_SUCCESS:
            state = {
                ...state,
                workers: action.payload.data.data.data,
                totalItemCount: action.payload.data.data.total,
                loading: false
            }
            break;
        case REGISTER_APP_USER:
            break;
        case DEL_APP_USER:
            break;
        case CLEAR_INPUT_ERROR:
            // console.log('clear input error', action);

            inputError[action.payload] = true;

            console.log('after clear input error', inputError)

            state = {
                ...state,
                inputError,
            }
            break;
        case SHOW_ALERT:
            console.log('show alert', action.payload);

            alert[action.payload] = true;
            state = {
                ...state,
                alert,
            }
            break;
        case CLEAR_ALERT:
            // console.log('clear alert', action.payload);

            alert[action.payload] = false;
            state = {
                ...state,
                alert,
            }
            break;
        case API_ERROR:
            console.log('API Error', action.payload);

            if (action.payload !== undefined) {
                for (let tmp of action.payload.data.errs) {
               //     console.log('loop error', tmp);
                    message.warning(tmp.error);
                    if (tmp.field !== undefined) {
                        switch (tmp.field) {
                            case 'name':
                                inputError.name = tmp.error;
                                break;
                            case 'phoneNumber':
                                inputError.phoneNumber = tmp.error;
                                break;
                            case 'email':
                                inputError.email = tmp.error;
                                break;
                            case 'addressLine1':
                                inputError.addressLine1 = tmp.error;
                                break;
                            case 'addressLine2':
                                inputError.addressLine2 = tmp.error;
                                break;
                            case 'introduction':
                                inputError.introduction = tmp.error;
                                break;
                            case 'certificateFile':
                                inputError.certificateFile = tmp.error;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }

            state = {
                ...state,
                inputError,
                error: action.payload,
                loading: false
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}

export default companyInfo;
