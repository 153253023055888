import React, { Component } from "react";
import { Link } from "react-router-dom";
import CopyLogo from '../../assets/images/companylogo.png'
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle
} from "reactstrap";

// import images
// import logodarkImg from "../../assets/images/logo-dark.png";
// import logosmImg from "../../assets/images/logo-sm.png";
// import logolightImg from "../../assets/images/logo-light.png";

// Import other Dropdown
import LanguageDropdown from "../../components/LanguageDropdown";
import NotificationDropdown from "../../components/NotificationDropdown";
import ProfileMenu from "../../components/ProfileMenu";

import i18n from '../../i18n';
import { Translation } from 'react-i18next';
import "../../css/TopBar.css"

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchOpen: false
    };

    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this.setState({ isSearchOpen: !this.state.isSearchOpen });
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if(
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if(document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if(document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if(document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if(document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <Translation>
        {
          (t, { i18n }) =>
            <React.Fragment>
              <header id="page-topbar">
                <div className="navbar-header">
                  <div className="d-flex">
                    <div className="navbar-brand-box">
                      {/* <Link to="/dashboard" className="logo logo-dark">
                        <span className="logo-sm">
                          <img src={logosmImg} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                          <img src={logodarkImg} alt="" height="17" />
                        </span>
                      </Link> */}

                      <Link to="/company-info" className="logo logo-light">
                        {/* <span className="logo-sm">
                          <img src={logosmImg} alt="" height="22" />
                        </span> */}
                        {/* <span className="logo-lg"> */}
                        {/* <img src={logolightImg} alt="" height="18" /> */}
                        {/* </span> */}
                        <span className="top-head-system-name color_gold font-size-20">
                          <img className="navbar_img" src={CopyLogo}/>
                        </span>
                      </Link>
                    </div>
                    <button
                      type="button"
                      onClick={this.toggleMenu}
                      className="btn btn-sm px-3 font-size-24 header-item waves-effect "
                      id="vertical-menu-btn"
                    >
                      <i className="mdi mdi-menu color-gold"></i>
                    </button>

                    <div style={{display:"inline-flex", alignItems:"center"}}>
                        <div style={{display:"inline-flex", alignItems:"flex-end"}}>
                          <span className="color-gold font-size-20">{t('top_head')}  </span> 
                          <span className="color-gold font-size-12"> &nbsp; V 2.1.0</span>
                        </div>
                    </div>
                   
                  </div>

                  <div className="d-flex">
                    {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form> */}

                    {/* <div className="dropdown d-inline-block d-lg-none ml-2">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={this.toggleSearch}
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearchOpen
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                  }
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}

                    <LanguageDropdown  refresh/>

                    {/* <div className="dropdown d-none d-lg-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen"></i>
                </button>
              </div> */}

                    {/* <NotificationDropdown /> */}

                    <ProfileMenu />

                    {/* <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="mdi mdi-settings-outline"></i>
                </button>
              </div> */}
                  </div>
                </div>
              </header>
            </React.Fragment>
        }
      </Translation>
    );
  }
}

export default TopBar;
