import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { GET_PRODUCT_PLAN,
    GET_HISTORY_DYNAMIC,
    GET_DYNAMIC_BY_PRODUCT,
    ADD_PRODUCT_PLAN, 
    PUT_PRODUCT_PLAN,
    UPDATE_PRODUCT_INFO, 
    GET_PRODUCT_WITD_PLAN,
} from './actionTypes';
import { getProductPlanSuccess, getHistoryDynamicSuccess, getDynamicByProductIdSuccess,getProductWithPlanSuccess,showAlert, apiError } from './actions';

// import validator from 'validator';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();


function* getProductPlan({ payload: { urlQuery, history } }) {
    console.log('* getProductPlan');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.getProductPlan, {  urlQuery, authToken: backend.getAuthenticatedUser() });

        if (response.status === 200) {
           
            yield put(getProductPlanSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }

        if(backend.handleError(error.response,history)){
            return;
        }
        
        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* getHistoryDynamic({ payload: { urlQuery, history } }) {
    
    try {
        const response = yield call(backend.getHistoryDynamic, { urlQuery, authToken: backend.getAuthenticatedUser() });

        if (response.status === 200) {
            yield put(getHistoryDynamicSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}



function* getDynamicByProductId({ payload: { urlQuery, history } }) {
  
    try {
        const response = yield call(backend.getDynamicByProductId, { urlQuery, authToken: backend.getAuthenticatedUser() });

        if (response.status === 200) {
            yield put(getDynamicByProductIdSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     // console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* addProductPlan({ payload: { data, history } }) {
    console.log('* updateProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.addProductPlan, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}


function* putProductPlan({ payload: { data, history } }) {
    console.log('* updateProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.putProductPlan, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }

        if(backend.handleError(error.response,history)){
            return;
        }
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}




function* getProductWithPlan({ payload: { urlQuery, history } }) {

    try {
        const response = yield call(backend.getProductWithPlan, { urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
            yield put(getProductWithPlanSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}


function* updateProductInfo({ payload: { data, history } }) {
    console.log('* updateProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.updateProductInfo, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}





export function* watchGetProductPlan() {
    yield takeEvery(GET_PRODUCT_PLAN, getProductPlan)
}


export function* watchGetHistoryDynamic() {
    yield takeEvery(GET_HISTORY_DYNAMIC, getHistoryDynamic)
}

export function* watchGetDynamicByProductId() {
    yield takeEvery(GET_DYNAMIC_BY_PRODUCT, getDynamicByProductId)
}

export function* watchAddProductPlan() {
    yield takeEvery(ADD_PRODUCT_PLAN, addProductPlan)
}

export function* watchPutProductPlan() {
    yield takeEvery(PUT_PRODUCT_PLAN, putProductPlan)
}

export function* watchUpdateProductInfo() {
    yield takeEvery(UPDATE_PRODUCT_INFO, updateProductInfo)
}

export function* watchGetProductWithPlan() {
    yield takeEvery(GET_PRODUCT_WITD_PLAN, getProductWithPlan)
}

function* ProductPlanSaga() {
    yield all([
        fork(watchGetProductPlan),
        fork(watchGetHistoryDynamic),
        fork(watchGetDynamicByProductId),
        fork(watchAddProductPlan),
        fork(watchPutProductPlan),
        fork(watchGetProductWithPlan),
    ]);
}

export default ProductPlanSaga;