import React, {Component} from "react";
import {message, Cascader} from 'antd';
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import citys from '../../components/city'

import SettingMenu from "../Shared/SettingMenu";
import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label} from "reactstrap";
import {AvForm, AvField} from "availity-reactstrap-validation";
// import Dropzone from "react-dropzone";
// import AliyunOSSUpload from '../../components/AliyunOSSUpload'
import PicturesWall from '../../components/PicturesWall'
import moment from 'moment'

// import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input } from 'antd';


import i18n from '../../i18n';
import {Translation} from 'react-i18next';



// actions
import {
    getCompanyInfo,
    updateCompanyInfo,
    createCompanyInfo,
    clearCompanyCertificateProp,
    clearInputError,
    clearAlert
} from "../../store/actions";

/**
 * Formats the size
 */
import "../../css/FormCompanyInfo.css"
// import "../../css/AvField.css"

const { TextArea } = Input;



const imgUrl = 'http://provenance-sz.oss-cn-shenzhen.aliyuncs.com/';
const dir = 'provenance/';
const todayKey = moment().format('YYYYMMDD');


let formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

class FormCompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChangePics = this.handleChangePics.bind(this);
        this.uploadFileNames = this.uploadFileNames.bind(this);
        this.delFileNames = this.delFileNames.bind(this);
        this.changeCity = this.changeCity.bind(this);

        this.state = {
            componentDidUpdateNum: 0,
            addr1Err: false,
            uploadImgErr: false,
            name: '',
            phone: '',
            email: '',
            defaultCityValue: [], // ["广西壮族自治区","梧州市","藤县"]
            cityValue: '', // '广西壮族自治区/梧州市/藤县'
            addressLine1: '',
            addressLine2: '',
            introduction: '',
            // certificateFilename: 'provenance/20210408/1617860583500.png',
            certificateFilename: '',
            companyIntroduction:'',
            companyImages: [],
            selectedFiles: [],
            fileLists: [],
            uploadFileLists: [],
            delFileLists: [],
            hasDelFile: false,
            fileListsDemo: [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: 'http://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/20210408/1617860583500.png',
                }
            ],
            inputError: {
                name: true,
                phoneNumber: true,
                email: true,
                addressLine1: true,
                addressLine2: true,
                introduction: true,
                certificate: true,
            },
            success_msg: false,
            creating: false,
        };
    }

    componentDidMount() {
        // console.log('componentDidMount');
        this.props.clearAlert('success');
        this.props.getCompanyInfo(this.props.history);
       
         
        // console.log('testing', this.props);
        // console.log(this.state.componentDidUpdateNum);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        document.title = i18n.t('company_info');
        // console.log('componentDidUpdate');
        // console.log('=============================');
        // console.log(prevProps);
        // console.log(prevState);
        // console.log(snapshot);
        // console.log(this.props);
        sessionStorage.setItem("companyStatus", this.props.status);
        sessionStorage.setItem("companyId", this.props.companyId);

        if (this.state.componentDidUpdateNum === 0 && this.props.companyId === 0) {
            message.warning(i18n.t('new_user_empty_tip'), 2);
        }

        if (this.state.componentDidUpdateNum === 0 && this.props.status === 0) {
            message.warning(i18n.t('new_user_review_tip'), 2);
        }

        if (this.state.componentDidUpdateNum === 0 && this.props.status === 2) {
            message.warning(i18n.t('new_user_declined_tip'), 2);
        }


        this.state.componentDidUpdateNum += 1;

        // if(this.state.selectedFiles === null) {
        // if(this.props.companyCertificate === null) {
        if (this.props.companyCertificate !== null) {
            // console.log('testing', this.props.companyCertificate);

            let certFile = [new Blob(
                [this.props.companyCertificate],
                {type: 'image/png'}
            )];
            // console.log('debug cert file', certFile);

            certFile.map(file => {
                console.log('testing', file, URL.createObjectURL(file));
                Object.assign(file, {
                    name: i18n.t('uploaded_certificate'),
                    preview: URL.createObjectURL(file),
                    // formattedSize: formatBytes(file.size)
                })
            });

            this.props.clearCompanyCertificateProp();

            

            this.setState({
                selectedFiles: certFile,
                companyIntroduction:this.props.companyIntroduction,
            });
        }

        if (this.props.companyIntroduction && !this.state.companyIntroduction) {

            this.setState({
                companyIntroduction:this.props.companyIntroduction,
            });
        }
    }

    renderCascader(addr) {
        if (this.state.componentDidUpdateNum > 0) {
            let defaultCityValue = [];
            if (addr) {
                defaultCityValue = addr.split('/');
            }
            // console.log(defaultCityValue);
            return (
                <Cascader className='antd-cascader' options={citys} defaultValue={defaultCityValue}
                          onChange={this.changeCity} placeholder=""/>
            )
        } else {
            return
        }
    }

    renderPicturesWall222(imgs) {
        // console.log('renderPicturesWall', imgs);
        // console.log('componentDidUpdateNum', this.state.componentDidUpdateNum);
        if (this.state.componentDidUpdateNum > 0) {
            if (imgs) {
                let fileListsArr = [];
                const file = imgs.substring(imgs.lastIndexOf('/') + 1);
                let fileItem = {
                    uid: file,
                    name: file,
                    status: 'done',
                    url: imgs,
                };
                fileListsArr.push(fileItem);
                // console.log(fileListsArr);
                return (
                    <PicturesWall uploadFileName={this.uploadFileNames} delFileName={this.delFileNames}
                                  handleChangePic={this.handleChangePics}
                                  filelist={fileListsArr} filenum={1}/>
                )
            } else {
                return (
                    <PicturesWall uploadFileName={this.uploadFileNames} delFileName={this.delFileNames}
                                  handleChangePic={this.handleChangePics}
                                  filelist={[]} filenum={1}/>
                )
            }
        } else {
            return
        }
    }


    // 公司证据图多张
    renderPicturesWall(imgArr) {
        if (!imgArr) {
            return
        }
        let fileListsArr = [];
        for (let i = 0; i < imgArr.length; i++) {
            let file = imgArr[i].substring(imgArr[i].lastIndexOf('/') + 1);
            let fileItem = {
                uid: file,
                name: file,
                status: 'done',
                url: imgArr[i],
            };
            fileListsArr.push(fileItem);
            console.log(fileListsArr)
        }
        if (fileListsArr.length > 0) {
            return (
                <PicturesWall uploadFileName={this.uploadFileNames} delFileName={this.delFileNames}
                              handleChangePic={this.handleChangePics} filelist={fileListsArr} filenum={5}/>
            )
        } else {
            return (
                <PicturesWall
                    uploadFileName={this.uploadFileNames}
                    delFileName={this.delFileNames}
                    handleChangePic={this.handleChangePics}
                    filelist={[]} filenum={5}/>
            )
        }

    }


    handleInputError() {
        // console.log('handleInputError');
        return 'test';
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.props.clearInputError(name);

        console.log('handleInputChange', value)
        if("addressLine1" === name){
            this.setState({
                cityValue :value
            })
        }
        else if("introduction" === name){
            this.setState({
                companyIntroduction :value
            })
        }

        // this.setState({
        //   [name]: value
        // });
    }

    handleAcceptedFiles = files => {
        // console.log('debug file', files);
        files.map(file => {
                console.log('test', file, URL.createObjectURL(file));
                Object.assign(file, {
                    uploadable: true,
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            }
        );

        this.setState({selectedFiles: files});

        this.props.clearInputError('certificateFile');
    };

    uploadFileNames(fileList) {
        // console.log('fileName==' + fileName)
        // this.setState({
        //   certificateFilename: dir + todayKey + '/' + fileName
        // })
        // console.log('=====上传oss的图片=====');
        // console.log(fileList);
        this.setState({
            uploadFileLists: fileList
        })
    }


    delFileNames(fileList) {
        // console.log('删除图片后>>>');
        // console.log(fileList)
        this.setState({
            delFileLists: fileList,
            hasDelFile: true
        })
    }

    changeCity(value, selectedOptions) {
       
        // console.log(value);
        // console.log(selectedOptions);
        if (selectedOptions.length > 0) {
            let optionArr = [];
            selectedOptions.map((item) => {
                optionArr.push(item.label)
            });
            console.log(optionArr)
            console.log(optionArr.join('/'));
            let val = optionArr.join('/');
            this.setState({
                cityValue: val,
                addr1Err: false
            })
        } else {
            this.setState({
                cityValue: '',
                addr1Err: true
            })
        }
    }


    handleChangePics(fileList) {
        console.log(fileList);
        if (fileList.length > 0) {
            let fileArr = [];
            fileList.map(item => {
                if (item['status'] === 'done') {
                    const {uid, name, status, url} = item;
                    const itemObj = {uid, name, status, url};
                    fileArr.push(itemObj);
                }
            });
            // this.setState({
            //   fileLists: fileArr
            // })
        } else {
            // this.setState({
            //   fileLists: []
            // })
        }
        // console.log(this.state.fileLists)
    }

    handleFormSubmit(event, values) {
        // console.log('on handleFormSubmit()', event, {...values, selectedFiles: this.state.selectedFiles});

        values.status = this.statusFormatToNum(values.status)

        let certificateFile = [];
        for (let tmp of this.state.selectedFiles) {
            if (tmp.uploadable === true) {
                certificateFile = tmp;
            }
        }

        // console.log('====新增/更新====');
        // console.log(this.props.certificateFilename);
        // console.log(this.state.uploadFileLists);
        // console.log(this.state.delFileLists);

        // 单张图片
        let certificateFilename = '';
        //  let uploadFileLists = this.state.uploadFileLists || [];
        //  uploadFileLists.map(item => {
        //      if (item.url) {
        //          certificateFilename = item.url
        //      }
        //  });

        // 多张图片
        let productImages = [];
        let uploadFileLists = this.state.uploadFileLists || [];
        let productImageArr = this.props.companyImages || [];
        let delFileLists = this.state.delFileLists || [];

        if (uploadFileLists.length > 0) {
            uploadFileLists.map(item => {
                if (item.url) {
                    productImages.push(item.url)
                }
            });
        }
        if (uploadFileLists.length === 0) {
            if (this.state.hasDelFile) {
                // 已点击删除图片操作
                if (delFileLists.length > 0) {
                    let arr = [];
                    delFileLists.map((item) => {
                        arr.push(item.url)
                    })
                    productImages = arr
                }
            } else {
                productImages = productImageArr
            }
        }
        console.log('商品轮播图最终上传的图片===>>>', productImages);

        // if (this.state.uploadFileLists.length === 0) {
        //     if (this.state.hasDelFile) {
        //         message.warning('请上传资质证书');
        //         return
        //     }
        //     certificateFilename = this.props.certificateFilename
        // }
        // console.log('certificateFiles', certificateFilename);

        if (productImages.length === 0) {
            message.warning('请上传资质证书');
            return
        }

        // 省市区
        let addressLine1 = '';
        // console.log('已选择的省市区', this.state.cityValue);
        if (!this.props.companyName) {
            if (this.state.cityValue !== '') {
                addressLine1 = this.state.cityValue;
            } else {
                this.setState({
                    addr1Err: true
                })
                return
            }
            this.props.createCompanyInfo({
                ...values,
                addressLine1: addressLine1,
                certificateFilename: productImages[0],
                companyImages: productImages,
                introduction:this.state.companyIntroduction,
                certificateFile: certificateFile
            }, this.props.history);
        } else {
            if (this.state.addr1Err) {
                message.warning('请填写所在地址');
                return
            }
            if (this.state.cityValue !== '') {
                addressLine1 = this.state.cityValue;
            } else {
                addressLine1 = this.props.companyAddressLine1
            }
            this.props.updateCompanyInfo({
                ...values,
                addressLine1: addressLine1,
                introduction:this.state.companyIntroduction,
                certificateFilename: productImages[0],
                companyImages: productImages,
                certificateFile: certificateFile
            }, this.props.history);
        }
    }

    statusFormat(val) {
        if (val === 1) {
            return i18n.t('company_status1');
        } else if (val === 2) {
            return i18n.t('company_status2');
        } else {
            return i18n.t('company_status0');
        }
    }

    statusFormatToNum(val) {
        if (val === i18n.t('company_status1')) {
            return 1
        } else if (val === i18n.t('company_status2')) {
            return 2
        } else {
            return 0
        }
    }

    render() {
        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {/*{this.props.loading ? <Loader/> : null}*/}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            <h4 className="navi-heander-size">{t('company_info')}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="InfoContainer" style={{paddingTop: '24px'}}>
                                    {
                                        this.state.componentDidUpdateNum > 0 ? parseInt(this.props.companyId) > 0 ? null :
                                            <div style={{
                                                padding: '0 1.25rem',
                                                color: '#ec4561'
                                            }}>{t('new_user_empty_ui_tip')}</div> : null
                                    }
                                    {
                                        this.state.componentDidUpdateNum > 0 ? parseInt(this.props.status) === 0 ?
                                            <div style={{
                                                padding: '0 1.25rem',
                                                color: '#ec4561'
                                            }}>{t('new_user_review_ui_tip')}</div> : null
                                            : null
                                    }
                                    {
                                        this.state.componentDidUpdateNum > 0 ? parseInt(this.props.status) === 2 ?
                                            <div style={{
                                                padding: '0 1.25rem',
                                                color: '#ec4561'
                                            }}>{t('new_user_declined_ui_tip')}</div> : null
                                            : null
                                    }
                                    <Row>
                                        <Col sm={12} md={8}>
                                            <Card>
                                                <CardBody>
                                                    <AvForm id="inline-block" onValidSubmit={this.handleFormSubmit}>
                                                        {/* {Buffer.from(this.props.companyCertificate, 'binary').toString('base64')} */}
                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        label={<><i
                                                                            className="mdi mdi-asterisk label-asterisk"></i>{t('company_name')} </>}
                                                                        type="text"
                                                                        name="name"
                                                                        row="true"
                                                                        value={this.props.companyName || ''}
                                                                        onChange={this.handleInputChange}
                                                                        placeholder={t('company_name_placeholder')}
                                                                        required
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: t('company_name_error')
                                                                            },
                                                                            maxLength: {value: 50},
                                                                            custom: () => this.props.inputError.name,
                                                                        }}
                                                                        inputClass="block-inline"
                                                                        labelClass="lable-left"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                      
                                                            <Row>
                                                                <Col sm={12}>
                                                                    {/* <FormGroup>
                                                                        <AvField
                                                                            label={<><i
                                                                                className="mdi mdi-asterisk label-asterisk"></i>{t('introduction')} </>}
                                                                            type="textarea"
                                                                            style={{'height': '120px'}}
                                                                            name="introduction"
                                                                            maxLength={300}
                                                                            value={this.props.companyIntroduction || ''}
                                                                            onChange={this.handleInputChange}
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                    errorMessage: t('company_introduction_error')
                                                                                },
                                                                                maxLength: {value: 255},
                                                                                custom: () => this.props.inputError.introduction
                                                                            }}
                                                                            inputClass="block-inline "
                                                                            labelClass="lable-left"
                                                                        />
                                                                    </FormGroup> */}
                                                                    <FormGroup>
                                                                        <label className="lable-left"><i className="mdi mdi-asterisk label-asterisk "></i>{t('introduction')}</label>
                                                                         {/* {this.renderCascader(this.props.companyAddressLine1)}  */}
                                                                        <TextArea bordered={false} 
                                                                            name="introduction"
                                                                           /// defaultValue={this.props.companyIntroduction || ''}
                                                                            value={this.state.companyIntroduction || ''}
                                                                            className="block-inline form-control" 
                                                                            onChange={this.handleInputChange}
                                                                            showCount maxLength={300} 
                                                                            style={{ height: 160 }}  />
                                                                         <div style={{fontSize: '80%'}}
                                                                            className='addr1'>请填写公司简介
                                                                        </div>
                                                                    </FormGroup>
                                                                          
                                                                  
                                                                    {/* <div className={this.state.addr1Err ? 'addr-text-danger' : ''}>
                                                                        <label className="lable-left"><i className="mdi mdi-asterisk label-asterisk "></i>{t('introduction')}</label>
                                                                        {/* {this.renderCascader(this.props.companyAddressLine1)} */}
                                                                        {/* <TextArea className="introduce-block-inline" showCount maxLength={300} style={{ height: 120 }}  />
                                                                        <div style={{fontSize: '80%'}}
                                                                            className='addr1'>请填写所在地区
                                                                        </div> */}
                                                                    {/* </div> */}
                                                                </Col>
                                                            </Row>
                                                        
                                                         { i18n.language ==="zh" ?
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <div className={this.state.addr1Err ? 'addr-text-danger' : ''}>
                                                                        <label className="lable-left"><i className="mdi mdi-asterisk label-asterisk "></i>{t('address_line_1')}</label>
                                                                        {this.renderCascader(this.props.companyAddressLine1)}
                                                                        <div style={{fontSize: '80%'}}
                                                                            className='addr1'>请填写所在地区
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            :
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <AvField
                                                                            label={<><i className="mdi mdi-asterisk label-asterisk "></i>{t('address_line_1')} </>}
                                                                            type="text"
                                                                            name="addressLine1"
                                                                            value={this.props.companyAddressLine1 || ''}
                                                                            onChange={this.handleInputChange}
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                    errorMessage: t('company_region_error')
                                                                                },
                                                                                maxLength: {value: 50},
                                                                                custom: () => this.props.inputError.addressLine1
                                                                            }}
                                                                            inputClass="block-inline"
                                                                            labelClass="lable-left"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                         }

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        label={<><i
                                                                            className="mdi mdi-asterisk label-asterisk"></i>{t('address_line_2')} </>}
                                                                        type="textarea"
                                                                        name="addressLine2"
                                                                        value={this.props.companyAddressLine2 || ''}
                                                                        onChange={this.handleInputChange}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: t('company_adress_error')
                                                                            },
                                                                            maxLength: {value: 50},
                                                                            custom: () => this.props.inputError.addressLine2
                                                                        }}
                                                                        inputClass="block-inline"
                                                                        labelClass="lable-left"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        label={<>{t('email')} </>}
                                                                        type="email"
                                                                        name="email"
                                                                        value={this.props.companyEmail || ''}
                                                                        onChange={this.handleInputChange}
                                                                        validate={{
                                                                            maxLength: {value: 30},
                                                                            pattern: {
                                                                                value: '/^[0-9a-zA-Z]+(?:[\\.\\!\\#\\$\\%\\^\\&\\*\\\'\\+\\-\\/\\`\\_\\{\\|\\}\\~]{0,1}[a-zA-Z0-9]+)*@[a-zA-Z0-9\\-]+\\.[0-9a-zA-Z\\-]+$/',
                                                                                errorMessage: t('company_email_error')
                                                                            },
                                                                            custom: () => this.props.inputError.email
                                                                        }}
                                                                        inputClass="block-inline"
                                                                        labelClass="lable-left"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        label={<><i
                                                                            className="mdi mdi-asterisk label-asterisk"></i>{t('phone_no')} </>}
                                                                        type="text"
                                                                        name="phoneNumber"
                                                                        row="true"
                                                                        style={{maxWidth: '10.7rem'}}
                                                                        value={this.props.companyPhoneNumber || ''}
                                                                        onChange={this.handleInputChange}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: t('company_phone_error')
                                                                            },
                                                                        //   pattern: {value: '/[+0-9]{14}$/'},
                                                                            maxLength: {value: 14},
                                                                            custom: () => this.props.inputError.phoneNumber
                                                                        }}
                                                                        inputClass="block-inline"
                                                                        labelClass="lable-left"
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>


                                                        <Row>
                                                            <Col sm={12}>
                                                                <div><Label><i
                                                                    className="mdi mdi-asterisk label-asterisk"></i>{t('certificate')}
                                                                </Label></div>
                                                                <div className='company-pic'>
                                                                    {/*{this.renderPicturesWall(this.props.certificateFilename)}*/}
                                                                    <div className='product-pic'
                                                                         style={{'paddingLeft': 0}}>
                                                                        {this.state.componentDidUpdateNum > 0 ? this.renderPicturesWall(this.props.companyImages) : null}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        label={<><i
                                                                            className="mdi mdi-asterisk label-asterisk"></i>{t('company_status')} </>}
                                                                        type="text"
                                                                        name="status"
                                                                        row="true"
                                                                        disabled
                                                                        style={{maxWidth: '10.7rem'}}
                                                                        value={this.statusFormat(this.props.status)}
                                                                        onChange={this.handleInputChange}
                                                                        inputClass="block-inline"
                                                                        labelClass="lable-left"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>


                                                        <Row style={{marginTop: '20px'}}>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Button color="primary"
                                                                            className="mr-1 btn-primary-bg company-add"> {this.props.companyName ? t('update') : t('save')}</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>

                                                    {this.props.alert.success ? (
                                                        <SweetAlert
                                                            title={t('company_info_updated')}
                                                            success
                                                            confirmBtnBsStyle="success"
                                                            onConfirm={() => this.props.clearAlert('success')}
                                                        >
                                                        </SweetAlert>
                                                    ) : null}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {

    const {
        companyId,
        companyName,
        companyPhoneNumber,
        status,
        companyEmail,
        companyAddressLine1,
        companyAddressLine2,
        certificateFilename,
        companyImages,
        companyIntroduction,
        companyCertificate,
        inputError,
        alert,
        error,
        loading
    } = state.FormCompanyInfo;


    return {
        companyId,
        companyName,
        companyPhoneNumber,
        status,
        companyEmail,
        companyAddressLine1,
        companyAddressLine2,
        certificateFilename,
        companyImages,
        companyIntroduction,
        companyCertificate,
        inputError,
        alert,
        error,
        loading
    };
};

// export default FormCompanyInfo;
export default withRouter(connect(mapStateToProps, {
    getCompanyInfo,
    updateCompanyInfo,
    createCompanyInfo,
    clearCompanyCertificateProp,
    clearInputError,
    clearAlert
})(FormCompanyInfo));
