import React, {Component, useState} from "react";
import {CSVLink, CSVDownload} from "react-csv";
import {useSelector} from "react-redux";
import {Button} from "reactstrap";
import { Modal } from 'antd';
import pathSelector from "./CSVPathStateSelector"
import i18n from '../../i18n';

export default function CSV(props) {
    const path = pathSelector[props.path]
    const currentData = useSelector(path)
    const curFileName = props.curfilename;
    const allFileName = props.allfilename;
    console.log('CSV当前导出的数据>>>', currentData)
    const [data, setDataState] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const onePageHandler = props.handler1;
    const allHandler = props.handler2;
    const done = () => {
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false)
        })
    }

    const verifyMobileWeChat = () =>{

        if(isMobileWeChat()){
            Modal.warning({
                title:'警告提示',
                content:'微信浏览器不支持下载功能，请用其它浏览器完成此功能',
              });
           return true;
        }
     
        return false;
    }
  
   const isMobileWeChat = () =>{

        let isWeixin = !!/MicroMessenger/i.test(navigator.userAgent); 
        let winApp = !!/WindowsWechat/i.test(navigator.userAgent);
        if(isWeixin && !winApp){ 
            return true;
        }
        return false;
    }

    const parser = props.parser;
    return (
        <>
            <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap"}}>
                <div style={{marginTop: "5px", marginRight: "5px"}}>

                    {/*<Button color="primary btn-primary-bg" onClick={(e) => {
                        console.log(111111, currentData);
                        e.preventDefault();
                        e.stopPropagation();
                        handler1(currentData, setDataState, parser, done)
                    }}>
                        {i18n.t("current_page_CSV")}
                    </Button>*/}

                    {/*current_page.csv*/}
                    <CSVLink data={data} filename={curFileName} onClick={() => {
                           if(!verifyMobileWeChat()){
                                onePageHandler(currentData, setDataState, parser, done)
                           }
                            
                        }}>
                      <Button color="primary btn-primary-bg">
                          {i18n.t("current_page_CSV")}
                      </Button>
                    </CSVLink>

                </div>
                <div style={{marginTop: "5px", marginRight: "5px"}}>
                    {/*<Button color="danger" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handler2(data, setDataState, parser, done)
                    }}>
                        {i18n.t("all_record_CSV")}
                    </Button>*/}

                    <CSVLink data={data} filename={allFileName} onClick={() => {
                            if(!verifyMobileWeChat()){
                                allHandler(data, setDataState, parser, done)
                            }
                        }}>
                        <Button color="primary btn-primary-bg">
                            {i18n.t("all_record_CSV")}
                        </Button>
                    </CSVLink>

                </div>
            </div>
            {console.log('最终导出的文档》》》', data)}
            {/*{isOpen ? <CSVDownload data={data}/> : null}*/}
            {/*{isOpen ? <CSVLink data={data} filename={"my-file.csv"} /> : null}*/}
        </>
    );
}
