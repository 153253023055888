import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { 

    GET_REAL_DYN_LIST,
    REAL_DYN_LIS_SUCCESS,
    CLEAR_REAL_DYN,

} from './actionTypes';


import { 
         RealDynListSuccess, 
         showAlert, 
         apiError 
} from './actions';

import validator from 'validator';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();


function* getRealDynList({ payload: { urlQuery, history }}) {
    console.log('* getBatchList');
    try {
       
        const response = yield call(backend.getRealDynList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
           
            yield put(RealDynListSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}






export function* watchGetRealDynList() {
    yield takeEvery(GET_REAL_DYN_LIST, getRealDynList)
}



function* BatchSaga() {
    yield all([
        fork(watchGetRealDynList),
      
    ]);
}

export default BatchSaga;