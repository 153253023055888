import { combineReducers } from "redux";


//Persist
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import FormCompanyInfo from "./companyInfo/reducer";
import FormProductInfo from "./productInfo/reducer";
import FormResourceInfo from "./resourceInfo/reducer";
import ProductInstance from "./productInstance/reducer";
import Package from "./package/reducer";
import Batch from "./batch/reducer";
import TraceabilityRole from "./traceabilityrole/reducer";
import Distribution from "./distribute/reducer";
import MenuReducer from "./menu/reducer";
import ProductPlan from "./plan/reducer";
import Node from "./node/reducer";
import customizationReducer from './customizationReducer';


const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  FormCompanyInfo,
  FormProductInfo,
  ProductInstance,
  FormResourceInfo,
  Package,
  Batch,
  TraceabilityRole,
  Distribution,
  Menu:persistReducer(
    persistConfig,
    MenuReducer,
  ),
  ProductPlan,
  Node,
  customization: customizationReducer,
});

export default rootReducer;
