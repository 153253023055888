export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';



export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_LOGINED = 'VERIFY_USER_LOGINED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const Clear_API_ERROR = 'LOGIN_Clear_ERROR';
