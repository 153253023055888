import {
    SHOW_ALERT,
    CLEAR_ALERT,
    GET_PRODUCT_INSTANCE_LIST,
    GET_PRODUCT_INSTANCE_LIST_SUCCESS,
    ADD_PRODUCT_INSTANCE,
    ADD_PRODUCT_INSTANCE_SUCCESS,
    CLEAR_PRODUCT_INSTANCE_CSV,
    PRODUCT_INSTANCE_API_ERROR,
    SHOW_SUCCESS,
    GET_PRODUCT_INSTANCE_GROUPED_LIST,
    GET_PRODUCT_INSTANCE_GROUPED_LIST_SUCCESS,
} from './actionTypes';


export const getProductInstanceList = (urlQuery, history) => {
    return {
        type: GET_PRODUCT_INSTANCE_LIST,
        payload: { urlQuery, history }
    }
}

export const getProductInstanceListSuccess = (data) => {
    return {
        type: GET_PRODUCT_INSTANCE_LIST_SUCCESS,
        payload: data
    }
}

export const getProductInstanceGroupedList = (urlQuery, history) => {
    return {
        type:  GET_PRODUCT_INSTANCE_GROUPED_LIST,
        payload: { urlQuery, history }
    }
}

export const getProductInstanceGroupedListSuccess = (data) => {
    return {
        type:  GET_PRODUCT_INSTANCE_GROUPED_LIST_SUCCESS,
        payload: data
    }
}

export const addProductInstance = (data, history) => {
    return {
        type: ADD_PRODUCT_INSTANCE,
        payload: { data, history }
    }
}

export const clearProductIntanceCSV = (inputName) => {
    return {
        type: CLEAR_PRODUCT_INSTANCE_CSV,
    }
}

export const showAlert = (alertName) => {
    return {
        type: SHOW_ALERT,
        payload: alertName,
    }
}

export const showSuccess = (alertName) => {
    return {
        type: SHOW_SUCCESS,
        payload: alertName
    }
}

export const clearAlert = (alertName) => {
    return {
        type: CLEAR_ALERT,
        payload: alertName,
    }
}

export const productInstanceApiError = (error) => {
    return {
        type: PRODUCT_INSTANCE_API_ERROR,
        payload: error
    }
}
