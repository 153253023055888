import i18n from "i18next";
import en from './translations/en.json';
import zh from './translations/zh.json';
import hk from './translations/hk.json';
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  en: {
    translation: (en)
  },
  zh: {
    translation: (zh)
  },
  hk: {
    translation: (hk)
  },
};

let defaultLang;

if(!sessionStorage.getItem("lang")) {
  defaultLang = 'zh';
}else {
  defaultLang = sessionStorage.getItem("lang");;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLang,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;