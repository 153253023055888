import * as React from 'react';

import { Col, Row, Card,} from 'antd';



import i18n from '../../../i18n';

const { Meta } = Card;
const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: {
    marginBottom: 24,
  },
};

const IntroduceRow = ({ loading, data,callBack }) => (

  <Row gutter={24}>
    <Col {...topColResponsiveProps}>
      <Card
        // bordered={false}
        // title={i18n.t('inventory_3_days')}
        size="small"
        loading={loading}
        onClick={e=>callBack(3)}
      >
        
         <p style={{textAlign:"center",fontSize:"15px"}}>{i18n.t('inventory_3_days')}</p>
         <p></p>
         <p></p>
         <p style={{textAlign:"center",fontSize:"25px"}}>{data.length>0?data[0].number:0}</p>
      </Card>
    </Col>

    <Col {...topColResponsiveProps}>
      <Card
        size="small"
        bordered={false}
        loading={loading}
        contentHeight={46}
        onClick={e=>callBack(7)}
      >
         <p style={{textAlign:"center",fontSize:"15px"}}>{i18n.t('inventory_7_days')}</p>
         <p></p>
         <p></p>
         <p style={{textAlign:"center",fontSize:"25px"}}>{data.length>0?data[1].number:0}</p>
      </Card>
    </Col>
    <Col {...topColResponsiveProps}>
      <Card
        size="small"
        bordered={false}
        loading={loading}
        contentHeight={46}
        onClick={e=>callBack(14)}
      >
       
         <p style={{textAlign:"center",fontSize:"15px"}}>{i18n.t('inventory_14_days')}</p>
         <p></p>
         <p></p>
       <p style={{textAlign:"center",fontSize:"25px"}}>{data.length>0?data[2].number:0}</p>
      </Card>
    </Col>
    <Col {...topColResponsiveProps}>
      <Card
        size="small"
        loading={loading}
        bordered={false}
        contentHeight={46}
        onClick={e=>callBack(30)}
        >
        <p style={{textAlign:"center",fontSize:"15px"}}>{i18n.t('inventory_30_days')}</p>
         <p></p>
         <p></p>
         <p style={{textAlign:"center",fontSize:"25px"}}>{data.length>0?data[3].number:0}</p>
      </Card>
    </Col>
  </Row>
);

export default IntroduceRow;
