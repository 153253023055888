import {
    GET_PRODUCT_DISTRIBUTION_LIST,
    GET_PRODUCT_DISTRIBUTION_LIST_SUCCESS,
    GET_PRODUCT_DISTRIBUTION_DETAIL,
    GET_PRODUCT_DISTRIBUTION_DETAIL_SUCCESS,
    GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL,
    GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL_SUCCESS,


    

    CLEAR_PRODUCT_INSTANCE_CSV,

    CREATE_PRODUCT,
    UPDATE_PRODUCT_INFO,
    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,
    SHOW_ALERT,
    CLEAR_ALERT,
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    API_ERROR,
    CLEAR_PRODUCT_PROPS,
} from './actionTypes';




export const getDistributionList = (urlQuery, history) => {
    console.log('action getDistributionList()');
    return {
        type: GET_PRODUCT_DISTRIBUTION_LIST,
        payload: { urlQuery, history }
    }
}

export const getDistributionListSuccess = (data) => {
    return {
        type: GET_PRODUCT_DISTRIBUTION_LIST_SUCCESS,
        payload: data
    }
}

export const getDistributionDetail = (urlQuery, history) => {
    console.log('action getDistributionDetail()');
    return {
        type: GET_PRODUCT_DISTRIBUTION_DETAIL,
        payload: { urlQuery, history }
    }
}



export const getDistributionDetailSuccess = (data) => {
    return {
        type: GET_PRODUCT_DISTRIBUTION_DETAIL_SUCCESS,
        payload: data
    }
}


export const getDistributionDetailByProduct = (urlQuery, history) => {
    console.log('action getDistributionDetailByProduct()');
    return {
        type: GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL,
        payload: { urlQuery, history }
    }
}

export const getDistributionDetailByProductSuccess = (data) => {
    console.log('action getDistributionDetailByProductSuccess()');
    return {
        type: GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL_SUCCESS,
        payload: data
    }
}


export const clearProductIntanceCSV = (inputName) => {
    return {
        type: CLEAR_PRODUCT_INSTANCE_CSV,
    }
}










export const getProductList = (urlQuery, history) => {
    console.log('action getProductList()');
    return {
        type: GET_PRODUCT_LIST,
        payload: { urlQuery, history }
    }
}

export const getProductListSuccess = (data) => {
    return {
        type: GET_PRODUCT_LIST_SUCCESS,
        payload: data
    }
}

export const createProduct = (data, history) => {
    console.log('action createProduct()', data);
    return {
        type: CREATE_PRODUCT,
        payload: { data, history }
    }
}

export const updateProductInfo = (data, history) => {
    console.log('action updateProductInfo()', data);
    return {
        type: UPDATE_PRODUCT_INFO,
        payload: { data, history }
    }
}

export const updateProductSystemStatus = (data, history) => {
    console.log('action updateProductSystemStatus()', data);
    return {
        // type: UPDATE_PRODUCT_SYSTEM_STATUS,
        payload: { data, history }
    }
}

// export const updateProductSystemStatusSuccess = (data, history) => {
//     console.log('action updateProductSystemStatus()', data);
//     return {
//         type: UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS,
//         payload: { data, history }
//     }
// }

export const updateInputValue = (inputName, value) => {
    console.log('action updateInputValue()', inputName);
    return {
        type: UPDATE_INPUT_VALUE,
        payload: { inputName, value }
    }
}

export const clearInputError = (inputName) => {
    return {
        type: CLEAR_INPUT_ERROR,
        payload: inputName,
    }
}

export const showAlert = (alertName) => {
    return {
        type: SHOW_ALERT,
        payload: alertName,
    }
}

export const clearAlert = (alertName) => {
    return {
        type: CLEAR_ALERT,
        payload: alertName,
    }
}

export const clearProductProps = () => {
    return {
        type: CLEAR_PRODUCT_PROPS
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}
