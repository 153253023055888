// @flow 

import React, {Component} from "react";
// import { useState, useEffect } from 'react';
import { Translation } from 'react-i18next';
import Loader from "../../components/Loader";
import { Row, Col, Card, CardBody } from "reactstrap";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
// import backend from '../../helpers/backend'
import BarChart from "./BarChart"
import { CircularProgressbar, buildStyles, ChangingProgressProvider } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { easeQuadInOut } from "d3-ease";
import axios from "axios";
import IntroduceRow from './components/IntroduceRow';

import Select from "react-select";
import i18n from '../../i18n';

// actions
import {
  getProductList,
  getProductInventory,
} from "../../store/productInfo/actions";
import { ExpandLessSharp } from "@material-ui/icons";
import commonUtils from '../../helpers/commonUtils'
import {format} from "date-fns";





const Axios = axios.create({
  // baseURL: 'http://0.0.0.0:3000/api/v1',
  baseURL: process.env.REACT_APP_API_URL_PROD,
  timeout: 30000,
});

class  DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading:false,
          Disabled:false,
          barChartData:0,
          activatedProduct:0,
          completedPackage:0,
          weeklyPackageDelivered:0,
          productSelect:{
            productNodes:[],
            currentValue:"",
          },
          pulling:false,
        }
        // this.getDashBoardData = this.getDashBoardData.bind(this);
        this.handleNodesSelectChange = this.handleNodesSelectChange.bind(this);
        this.handleNoteChange = this.handleNoteChange.bind(this);
        

        
    }
    componentDidMount() {
        this.getDashBoardData();
        this.init();
       // if(this.props.productList.length==0){
        this.state.pulling=true;
        this.props.getProductList();
      //  }
        
    }
    init(){

      let optionStatus =[];
        optionStatus.unshift({label:i18n.t("empty"),
        value:-1,
       })

       this.setState(prevState =>({
        productSelect:{
        ...prevState.productSelect,
        productNodes:optionStatus,
        currentValue:optionStatus[0],
        }}));
      
    }
    componentDidUpdate(prevProps,prevState) {
       
      //  if(this.props.productList.length >0 && this.state.productSelect.productNodes.length==0){
           
        if ( !this.props.loading &&  this.state.pulling ) {
          //this.renderChart(this.props);
          this.state.pulling =false;
          this.parseProductList(this.props.productList);
       }
 
    }
//   // const [isLoading, setIsLoading] = useState(false)
//   // const [barChartData, setBarChartData] = useState(false)
//   // const [activatedProduct, setActivatedProduct] = useState(false)
//   // const [completedPackage, setCompletedPackage] = useState(false)
//   // const [weeklyPackageDelivered, setWeeklyPackageDelivered] = useState(false)

    getDashBoardData(){
      const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
      const lang = sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : 'zh';
      
      const options =  {
        headers: {
          "Authorization": authToken,
          "Accept-Language": lang,
        },
      }
      Axios.get(`/dashboard`, options).then((res)=>{
        // setBarChartData(res.data.barChartData)
        // setWeeklyPackageDelivered(res.data.weeklyPackageDelivered)
        // setActivatedProduct(res.data.activatedProduct)
        // setCompletedPackage(res.data.completedPackage)
          this.setState(prevState => ({
            barChartData:res.data.barChartData,
            weeklyPackageDelivered:res.data.weeklyPackageDelivered,
            activatedProduct:res.data.activatedProduct,
            completedPackage:res.data.completedPackage,
          }));
      }).catch((err)=>{
        
          if(err.response.status === 408){
              sessionStorage.removeItem("accessToken");
              this.props.history.push({pathname:'/login',state:{
                code:408,
              }});
              return;
          }
          else if(err.response.status === 401 || err.response.status === 403) {
              sessionStorage.removeItem("accessToken");
              this.props.history.push('/login');
              return;
          }

        
      }
      )
    }

  // useEffect(()=>{
  //   getDashBoardData()
  // },[])
     
  handleNodesSelectChange(event) {
      
    if(event.value ==-1)
      return;
    this.setState(prevState =>({
      productSelect:{
        ...prevState.productSelect,
        currentValue:event,
         }
    }));
    this.getProductInventory(event.value);
  }

  handleNoteChange(event) {
      
    // this.setState(prevState =>({
    //   productSelect:{
    //     ...prevState.productSelect,
    //     // currentValue:event,
    //      }
    // }));
    this.jumpToProductInventory(event);
  }

  jumpToProductInventory(id){
    // let urlQuery={
    //   productId:id,
    // }
    // this.props.getProductInventory(urlQuery,this.props.history);
      let to = new Date();
      let from=null;
      if(id==3){
        from= commonUtils.addDays(new Date(),-3);
      }
      else if(id==7){
        from= commonUtils.addDays(new Date(),-7);
      }
      else if(id==14){
        from= commonUtils.addDays(new Date(),-14);
      }
      else if(id==30){

        from= commonUtils.addMonths(new Date(),-1);
      }

      let currentValue =this.state.productSelect.currentValue;
      let url="/inventory/product/"+currentValue.value+"/"+currentValue.label+"/";
      url=url+format(new Date(from), 'yyyy-MM-dd 00:00:00')+"/"+format(new Date(to), 'yyyy-MM-dd 00:00:00');
      this.props.history.push(url);
  }

  getProductInventory(id){
    let urlQuery={
      productId:id,
    }
    this.props.getProductInventory(urlQuery,this.props.history);
  }

  parseProductList(list){

      let optionStatus =[];

      list.forEach(function (element, index, array) {
          optionStatus.push({label:element.name,
              value:element.productId,
          })

      });



      if(optionStatus.length >0){
          optionStatus.unshift({label:i18n.t("inventory_all"),
            value:0,
           })
          
          this.setState(prevState =>({
            productSelect:{
            ...prevState.productSelect,
            productNodes:optionStatus,
            currentValue:optionStatus[0],
            }
        }));
        this.getProductInventory(0);
      }
      // else{
      //     this.setState(prevState =>({
      //         productSelect:{
      //         ...prevState.productSelect,
      //         productNodes:[],
      //         currentValue:null,
      //         }
      //     }));
      // }

  }
  render() {
    return (
      <Translation>
        {
          (t, { i18n }) =>
            <React.Fragment>
              {this.state.isLoading ? <Loader /> : null}
              <div className="container-fluid">
                {/*Page title*/}


                
                <Row className="align-items-center">
                  <Col sm={6}>
                    <div className="page-title-box">
                      <h4 className="navi-heander-size">
                        {/*{t('welcome_back')}{backend.getAuthenticatedUsername()}*/}
                        {t('dashboard')}
                      </h4>
                    </div>
                    {/*<div>
                      <h4 className="navi-sub-heander-size">
                        {t('dashboard')}
                      </h4>
                    </div>*/}
                  </Col>
                </Row>
                

                {/* Dashboard body */}
               
                <Row style={{marginTop: '1.0rem', marginLeft:'0.01rem',marginBottom: '1.0rem'}}>
                      <Col sm={0.8} style={{lineHeight: '34px',  textAlign:'left'}}>
                          <i className="mdi"></i>{t('product_name')}
                          
                      </Col>
                      <Col sm={3}>
                        <Select
                                  name="pageSize"
                                  isDisabled={this.state.Disabled}
                                  value={this.state.productSelect.currentValue}
                                  onChange={this.handleNodesSelectChange}
                                  options={this.state.productSelect.productNodes}
                                  isSearchable={false}
                                  // menuPortalTarget={document.body}
                                  // className="block-inline-status"
                              />
                      </Col>
                </Row>
                <IntroduceRow data={this.props.inventory} callBack={this.handleNoteChange} />
                <Row>
                  <Col>
                    <div>
                      <Card>
                        <CardBody>
                          <div className="dashboard_30days_graph">
                            <h4>
                              {t('previous_30days_shipments')}
                            </h4>
                          </div>
                          <BarChart data={this.state.barChartData}/>
                        </CardBody>
                      </Card>

                      <Row>
                        <Col sm={12} md={4}>
                          <Card>
                            <CardBody>
                              <h4>
                                {t('amount_package_deliver_this_week')}
                              </h4>
                              <div style={{ padding: "15%" }}>
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={this.state.weeklyPackageDelivered ? 100: 0}  //percent 
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {   
                                      value => {
                                      const roundedValue = Math.round(value/100 * this.state.weeklyPackageDelivered);
                                      return (
                                        <CircularProgressbar
                                          value={value}
                                          text={roundedValue ? roundedValue : "0"}
                                          styles={buildStyles({ pathTransition: "none" })}
                                        />
                                      );
                                  }}
                                </AnimatedProgressProvider>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col sm={12} md={4}>
                          <Card>
                            <CardBody>
                              <h4>
                                {t('activated_product')}
                              </h4>
                              <div style={{ padding: "15%" }}>
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={this.state.activatedProduct ? 100: 0}
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {value => {
                                    const roundedValue = Math.round(value/100 * this.state.activatedProduct);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={roundedValue ? roundedValue : "0"}
                                        styles={buildStyles({ pathTransition: "none" })}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col sm={12} md={4}>
                          <Card>
                            <CardBody>
                              <h4>
                                {t('completed_package')}
                              </h4>
                              <div style={{ padding: "15%" }}>
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={this.state.completedPackage ? 100: 0}
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {
                                    value => {
                                    const roundedValue = Math.round(value/100 * this.state.completedPackage);
                                
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={roundedValue ? roundedValue : "0"}
                                        styles={buildStyles({ pathTransition: "none" })}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </React.Fragment >
        }
      </Translation>
    );
  }
}

// export default DashBoard
const mapStateToProps = state => {
  const {
    productList,
    inventory,
    loading,
  } = state.FormProductInfo;

  return {
      productList,
      loading,
      inventory,
  };
};

export default withRouter(connect(mapStateToProps, {
  getProductList,
  getProductInventory,
  
})(DashBoard));
