export const GET_MENU= "GET_MENU";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_ERROR = "GET_MENU_ERROR";

export const REST_MENU_DATA = "REST_MENU_DATA";

export const GET_MENU_LIST= "GET_MENU_LIST";

export const SET_SELETED_KEYS= "SET_SELETED_KEYS";


export const GET_ROLE_LIST= "GET_ROLE_LIST";

export const GET_CUSTOMER_LIST= "GET_CUSTOMER_LIST";




