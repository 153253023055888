import {
    GET_RESOURCE_INFO,
    GET_RESOURCE_INFO_SUCCESS,
    CREATE_RESOURCE,
    UPDATE_RESOURCE_INFO,
    GET_RESOURCE_LIST,
    GET_RESOURCE_LIST_SUCCESS,
    CLEAR_RESOURCE_PROPS,
    GET_RESOURCE_PLENTY_LIST,
    GET_RESOURCE_PLENTY_LIST_SUCCESS,
    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,
    SHOW_ALERT,
    CLEAR_ALERT,
    UPDATE_PRODUCT_SYSTEM_STATUS,
    API_ERROR,
   
} from './actionTypes';

export const getResouceInfo = (resourceId, history) => {
    console.log('action getResouceInfo()');
    return {
        type: GET_RESOURCE_INFO,
        payload: { resourceId, history }
    }
}

export const getResouceInfoSuccess = (data) => {
    return {
        type: GET_RESOURCE_INFO_SUCCESS,
        payload: data
    }
}



export const getResourceList = (urlQuery, history) => {
    console.log('action getResourceList()');
    return {
        type: GET_RESOURCE_LIST,
        payload: { urlQuery, history }
    }
}


export const getResourceListSuccess = (data) => {
    return {
        type: GET_RESOURCE_LIST_SUCCESS,
        payload: data
    }
}

export const getResourcePlentyList = (urlQuery, history) => {
    console.log('action getResourcePlentyList()');
    return {
        type: GET_RESOURCE_PLENTY_LIST,
        payload: { urlQuery, history }
    }
}
export const getResourcePlentyListSuccess = (data) => {
    return {
        type: GET_RESOURCE_PLENTY_LIST_SUCCESS,
        payload: data
    }
}

export const createResouce = (data, history) => {
    console.log('action createResouce()', data);
    return {
        type: CREATE_RESOURCE,
        payload: { data, history }
    }
}

export const updateResouceInfo = (data, history) => {
    console.log('action updateResouceInfo()', data);
    return {
        type: UPDATE_RESOURCE_INFO,
        payload: { data, history }
    }
}

export const clearResourceProps = () => {
    return {
        type: CLEAR_RESOURCE_PROPS
    }
}

export const updateProductSystemStatus = (data, history) => {
    console.log('action updateProductSystemStatus()', data);
    return {
        type: UPDATE_PRODUCT_SYSTEM_STATUS,
        payload: { data, history }
    }
}



// export const updateProductSystemStatusSuccess = (data, history) => {
//     console.log('action updateProductSystemStatus()', data);
//     return {
//         type: UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS,
//         payload: { data, history }
//     }
// }

export const updateInputValue = (inputName, value) => {
    console.log('action updateInputValue()', inputName);
    return {
        type: UPDATE_INPUT_VALUE,
        payload: { inputName, value }
    }
}

export const clearInputError = (inputName) => {
    return {
        type: CLEAR_INPUT_ERROR,
        payload: inputName,
    }
}

export const showAlert = (alertName) => {
    return {
        type: SHOW_ALERT,
        payload: alertName,
    }
}

export const clearAlert = (alertName) => {
    return {
        type: CLEAR_ALERT,
        payload: alertName,
    }
}


export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}
