import React, {Component} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import UploadList from 'antd/es/upload/UploadList';
import {Modal, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import Base64 from "base-64";
import CryptoJS from "crypto-js";
import axios from "axios";
import moment from 'moment'
import i18n from '../i18n';

// 阿里云oos
const host = "https://provenance-sz.oss-cn-shenzhen.aliyuncs.com";
const dir = 'provenance/';
const todayKey = moment().format('YYYYMMDD');
const accessKeyId = "LTAI5tM3sbNUsuy9Gfc4vMw3";
const accessSecret = "ZCVR4d9k57prTWyVMsxIFWdCzCwL4Y";
const policyText = {
    "expiration": "2028-01-01T12:00:00.000Z", // 设置该Policy的失效时间，
    "conditions": [
        ["content-length-range", 0, 1048576000] // 设置上传文件的大小限制
    ]
};
const policyBase64 = Base64.encode(JSON.stringify(policyText));
const bytes = CryptoJS.HmacSHA1(policyBase64, accessSecret, {asBytes: true});
const signature = bytes.toString(CryptoJS.enc.Base64);

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


const itemStyle = {
    width: '10.625rem',
    height: '13.375rem',
    margin: '0 8px 8px 0',
    cursor: 'grab'
};
const SortableItem = SortableElement((params) => (
    <div style={itemStyle} className='sortable-upload'>
        <UploadList
            locale={{previewFile: '预览图片', removeFile: '删除图片'}}
            showDownloadIcon={false}
            listType={'picture-card'}
            onPreview={params.onPreview}
            onRemove={params.onRemove}
            items={[params.item]}
        />
    </div>
));


const listStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
};
const uploadButton = (
    <div>
        <PlusOutlined/>
        <div>{i18n.t('upload_pic')}</div>
    </div>
);
const SortableList = SortableContainer((params) => {
    return (
        <div style={listStyle} className='sortableItem-box'>
            {params.items.map((item, index) => (
                <SortableItem
                    key={`${item.uid}`}
                    index={index}
                    item={item}
                    onPreview={params.onPreview}
                    onRemove={params.onRemove}
                />
            ))}
            <Upload
                action='https://provenance-sz.oss-cn-shenzhen.aliyuncs.com'
                listType="picture-card"
                accept="image/jpeg,image/jpg,image/png"
                customRequest={params.uploadRequest}
                beforeUpload={params.beforeUpload}
                // data={params.data}
                showUploadList={false}
                onChange={params.onChange}
            >
                {params.items.length >= params.num ? null : uploadButton}
            </Upload>
        </div>
    );
});


class ReactSortableHoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewImage: '',
            fileName: '',
            fileNum: props.fileNum || 5,
            fileList: props.fileList || [],
            pdata: {
                key: '', // provenance/20210711/a.jpg
                policy: policyBase64,
                OSSAccessKeyId: accessKeyId,
                success_action_status: 200,
                signature: signature,
            }
        };
        this.onSortEnd = this.onSortEnd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onPreview = this.onPreview.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.uploadRequest = this.uploadRequest.bind(this);
    }

    onSortEnd({oldIndex, newIndex}) {
        const that = this;
        let items = this.state.fileList;
        this.setState({
            fileList: arrayMove(items, oldIndex, newIndex)
        }, () => {
            that.props.changePg(this.state.fileList)
        });
    };


    onChange(fileList) {
        // console.log('上传组件改变了');
        // console.log(fileList)
        // onFileChange({fileList: newFileList});
    }

    onRemove(file) {
        const that = this;
        const fileList = this.state.fileList;
        const newFileList = fileList.filter(
            (item) => item.uid !== file.uid
        );
        this.setState({
            fileList: newFileList
        }, () => {
            console.log('图片fileList>>>');
            console.log(this.state.fileList);
            that.props.changePg(this.state.fileList)
        });
    }

    async onPreview(file) {
        this.setState({
            previewImage: file.url
        })
    }

    beforeUpload(file) {
        console.log('上传oss前的文件===>>>', file);
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        let pdata = this.state.pdata;
        let key = dir + todayKey + '/' + filename;
        pdata['key'] = key;
        this.setState({
            pdata: pdata,
            fileName: filename
        });
    }

    // axios
    uploadRequest(params) {
        // console.log('自定义上传请求>>>');
        // console.log(params);
        let that = this;
        let forms = new FormData();
        let configs = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        let key = dir + todayKey + '/' + this.state.fileName;
        forms.append('key', key);
        forms.append('policy', policyBase64);
        forms.append('OSSAccessKeyId', accessKeyId);
        forms.append('success_action_status', 200);
        forms.append('signature', signature);
        forms.append('file', params.file);

        axios.post(host, forms, configs).then(res => {
            console.log(res);
            if (res.status === 200) {
                let arr = {
                    uid: that.state.fileName,
                    name: this.state.fileName,
                    status: 'done', // removed done error
                    url: 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/' + key,
                };
                that.setState({
                    fileList: [...this.state.fileList, arr]
                }, () => {
                    console.log('图片上传成功>>>');
                    console.log(this.state.fileList);
                    that.props.changePg(this.state.fileList)
                })
            }
        })
    }


    render() {
        return (
            <>
                <SortableList
                    // 当移动 1 之后再触发排序事件，默认是0，会导致无法触发图片的预览和删除事件
                    distance={1}
                    items={this.state.fileList}
                    num={this.state.fileNum}
                    // data={this.state.pdata}
                    onSortEnd={this.onSortEnd}
                    axis="xy"
                    helperClass="SortableHelper"
                    onChange={this.onChange}
                    onRemove={this.onRemove}
                    onPreview={this.onPreview}
                    beforeUpload={this.beforeUpload}
                    uploadRequest={this.uploadRequest}
                />
                <Modal
                    visible={!!this.state.previewImage}
                    footer={null}
                    onCancel={() => this.setState({previewImage: ''})}
                    bodyStyle={{padding: 10}}
                >
                    <img style={{width: '100%'}} alt="" src={this.state.previewImage}/>
                </Modal>
            </>
        );
    }

}

export default ReactSortableHoc;
