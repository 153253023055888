
export const SHOW_ALERT = 'SHOW_ALERT';
export const EXPIRE_TOKEN = 'EXPIRE_TOKEN';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const GET_PACKAGE_LIST = 'GET_PACKAGE_LIST';
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_PACKAGE_LIST_SUCCESS = 'GET_PACKAGE_LIST_SUCCESS';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const UPDATE_COMPANY_STATUS = 'UPDATE_COMPANY_STATUS';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_COMPANY_STATUS_SUCCESS = 'UPDATE_COMPANY_STATUS_SUCCESS';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const ADD_PACKAGE_SUCCESS = 'ADD_PACKAGE_SUCCESS';
export const CLEAR_PACKAGE_CSV = 'CLEAR_PACKAGE_CSV';
export const PACKAGE_INPUT_ERROR = 'PACKAGE_INPUT_ERROR';
export const CLEAR_PACKAGE_INPUT_ERROR = 'CLEAR_PACKAGE_INPUT_ERROR';

export const PACKAGE_API_ERROR = 'PACKAGE_API_ERROR';
