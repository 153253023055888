import { message } from 'antd';
import { init } from 'echarts';
import {

    GET_PRODUCT_DISTRIBUTION_LIST,
    GET_PRODUCT_DISTRIBUTION_LIST_SUCCESS,
    GET_PRODUCT_DISTRIBUTION_DETAIL,
    GET_PRODUCT_DISTRIBUTION_DETAIL_SUCCESS,
    GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL,
    GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL_SUCCESS,
    
    CLEAR_PRODUCT_INSTANCE_CSV,
    SHOW_ALERT,


    CREATE_PRODUCT,
    UPDATE_PRODUCT_INFO,
    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,

    CLEAR_ALERT,
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,

    UPDATE_PRODUCT_SYSTEM_STATUS,

    CLEAR_PRODUCT_PROPS,
    API_ERROR,
} from './actionTypes';

const initialState = {

    DistributionList: [],
    productImages: {},
    detailImages: {},
    filteredCount: 0,
    totalItemCount: 0,
    Locations:[],


    inputError: {
        productId: true,
        name: true,
        systemStatus: true,
        description: true,
        image: true,
    },
    alert: {
        success: false,
        successMessage: '',
    },
    error: null,
    loading: false,
    productInstanceCsv: null,
}

const distribution = (state = initialState, action) => {
    let product = { ...state.product };
    let productImages = { ...state.productImages };
    let detailImages = { ...state.detailImages };
    let inputError = { ...state.inputError };
    let alert = { ...state.alert };

    switch(action.type) {
        case GET_PRODUCT_DISTRIBUTION_LIST:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_DISTRIBUTION_LIST_SUCCESS:
            console.log('reducer debug', action.payload.data.data);
            state = {
                ...state,
                DistributionList: action.payload.data.data.batchInfo,
                filteredCount: action.payload.data.data.filteredCount,
                totalItemCount: action.payload.data.data.total,
                loading: false,
            }
            break;
        case GET_PRODUCT_DISTRIBUTION_DETAIL:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_DISTRIBUTION_DETAIL_SUCCESS:
            state = {
                ...state,
                Locations:action.payload.data.data,
                loading: false
            }
            break;
        case GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL_SUCCESS:
            state = {
                ...state,
                Locations:action.payload.data.data.LocationData,
                filteredCount: action.payload.data.data.filteredCount,
                totalItemCount: action.payload.data.data.total,
                loading: false
            }
            break;


        case CLEAR_PRODUCT_INSTANCE_CSV:
            state = {
                ...state,
                productInstanceCsv: null,
            }
            break;
 

        case CREATE_PRODUCT:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_PRODUCT_INFO:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_PRODUCT_SYSTEM_STATUS:
            state = {
                ...state,
                loading: true
            }
            break;
        // case UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS:
        //     state = {
        //         ...state,
        //         loading: false
        //     }
        //     break;
        case UPDATE_INPUT_VALUE:
            product[action.payload.inputName] = action.payload.value;

            state = {
                ...state,
                product
            }
            break;
        case CLEAR_INPUT_ERROR:
            console.log('clear input error', action);

            inputError[action.payload] = true;

            console.log('after clear input error', inputError)

            state = {
                ...state,
                inputError,
            }
            break;
        case SHOW_ALERT:
            console.log('show alert', action.payload);

            alert[action.payload] = true;
            state = {
                ...state,
                alert,
                loading: false
            }
            break;
        case CLEAR_ALERT:
            // console.log('clear alert', action.payload);

            alert[action.payload] = false;
            state = {
                ...state,
                alert,
            }
            break;
        case CLEAR_PRODUCT_PROPS:
            state = {
                ...state,
                productList: initialState.productList,
                product: initialState.product,
            }
            break;
        case API_ERROR:
            console.log('API Error', action.payload);

            if(action.payload !== undefined) {
                for(let tmp of action.payload.data.errs) {
                    console.log('loop error', tmp);
                    // message.warning({
                    //     duration: 0,
                    //     content: tmp.error
                    // });
                    message.warning(tmp.error);
                    if(tmp.field !== undefined) {
                        switch(tmp.field) {
                            case 'name':
                                inputError.name = tmp.error;
                                break;
                            case 'systemStatus':
                                inputError.systemStatus = tmp.error;
                                break;
                            case 'description':
                                inputError.description = tmp.error;
                                break;
                            case 'productionAddress':
                                inputError.productionAddress = tmp.error;
                                break;
                            case 'shopUrl':
                                inputError.shopUrl = tmp.error;
                                break;
                            case 'image':
                                inputError.image = tmp.error;
                                break;
                        }
                    }
                }
            }

            state = {
                ...state,
                inputError,
                error: action.payload,
                loading: false
            };
            break;
        default:
            // state = { ...state };
            break;
    }
    return state;
}

export default distribution;
