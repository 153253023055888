import React, {Component} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";

// import i18n from '../../i18n';
import {Translation} from 'react-i18next';

import {  Menu  }  from 'antd';
 
import {  MailOutlined, AppstoreOutlined, SettingOutlined,InfoCircleOutlined, ReconciliationOutlined,QrcodeOutlined,
    ScheduleOutlined,
    CodepenOutlined,
    AppstoreAddOutlined,
    BugOutlined,
    BlockOutlined,
    HistoryOutlined,
    SplitCellsOutlined,
    SendOutlined,
    HomeOutlined,
    BarChartOutlined,
    ChromeOutlined,
    GlobalOutlined,
    SecurityScanOutlined,
     BarcodeOutlined} from '@ant-design/icons'


// actions
import {
    getMenu,
    setSelectedKey,
 } from "../../store/menu/actions";

 const { SubMenu } = Menu;


//  const useStyles = makeStyles((theme) => ({

//     antMenuItemGroupTitle:
//     { 
//         fontSize: '10px',
//     },
//     listIcon: {
//         minWidth: '25px',
//     },
//     listItem: {
//         borderRadius: '5px',
//         marginBottom: '5px',
//     },
//     subMenuCaption: {
//         ...theme.typography.subMenuCaption,
//     },
//     listItemNoBack: {
//         backgroundColor: 'transparent !important',
//         paddingTop: '8px',
//         paddingBottom: '8px',
//         borderRadius: '5px',
//     },
//     errorChip: {
//         color: theme.palette.error.main,
//         backgroundColor: '#ffcdd2',
//         marginRight: '20px',
//     },
// }));

// const data=[
 
//   {
//     id:1,
//     link:"/company-info",
//     linkclass:"waves-effect",
//     lable:"公司资料2",
//     prefix_lable:"ti-info-alt",
   
//   },
//   {
//     id:1,
//     link:"/dashboard",
//     linkclass:"waves-effect",
//     lable:"概览2",
//     prefix_lable:"ti-clipboard",
   
//   } ,
//   {
//     id:2,
//     link:"/#",
//     linkclass:"has-arrow waves-effect mm-collapsed",
//     lable:"商品管理2",
//     prefix_lable:"fas fa-boxes",
//     aria_expanded:"false",
//     submenuclass:"sub-menu",
//     children:[{
//       id:3,
//     lable:"增加商品2",
//     link:"/form-product"
//     },
//     {
//       id:4,
//     lable:"查看商品2",
//     link:"/view-product"
//     },
//     ]
//   }];

const gdata=[
    {
        menuId: 1,
        link: "",
        title: "账户概览",
        icon: "NavigationOutlinedIcon",
        display: "",
        menuType: "group",
        children: [
            {
                menuId: 2,
                link: "/dashboard",
                title: "概览",
                icon: "CardGiftcardOutlinedIcon",
                display: "",
                menuType: "item",
                children: []
            },
            {
                menuId: 7,
                link: "/company-info",
                title: "公司资料",
                icon: "CardGiftcardOutlinedIcon",
                display: "",
                menuType: "item",
                children: []
            }
        ]
    },
    {
        menuId: 8,
        link: "",
        title: "溯源流程",
        icon: "NavigationOutlinedIcon",
        display: "",
        menuType: "group",
        children: [
            {
                menuId: 9,
                link: "",
                title: "商品管理",
                icon: "CardGiftcardOutlinedIcon",
                display: "",
                menuType: "collapse",
                children: [
                    {
                        menuId: 10,
                        link: "/form-product",
                        title: "增加商品",
                        icon: "",
                        display: "",
                        menuType: "item",
                        children: []
                    },
                    {
                        menuId: 11,
                        link: "/view-product",
                        title: "查看商品",
                        icon: "",
                        display: "",
                        menuType: "item",
                        children: []
                    }
                ]
            },
            {
                menuId: 12,
                link: "",
                title: "溯源管理",
                icon: "CardGiftcardOutlinedIcon",
                display: "",
                menuType: "collapse",
                children: [
                    {
                        "menuId": 13,
                        "link": "/view-product",
                        "title": "配置信息",
                        "icon": "",
                        "display": "",
                        "menuType": "item",
                        "children": []
                    },
                    {
                        "menuId": 14,
                        "link": "/workers",
                        "title": "环节账户",
                        "icon": "",
                        "display": "",
                        "menuType": "item",
                        "children": []
                    },
                    {
                        "menuId": 15,
                        "link": "/trace-role-managment",
                        "title": "环节管理",
                        "icon": "",
                        "display": "",
                        "menuType": "item",
                        "children": []
                    }
                ]
            }
        ]
    },
	{
            menuId: 22,
            link: "",
            title: "信息管理",
            icon: "NavigationOutlinedIcon",
            display: "",
            menuType: "group",
            children: [
                {
                    menuId: 23,
                    link: "/code-batch-management",
                    title: "物码批次管理",
                    icon: "ion ion-ios-cube",
                    display: "",
                    menuType: "item",
                    children: []
                },
                {
                    menuId: 24,
                    link: "",
                    title: "仓储运输管理",
                    icon: "CardGiftcardOutlinedIcon",
                    display: "",
                    menuType: "collapse",
                    children: [
                        {
                            menuId: 25,
                            link: "/inventory",
                            title: "库存",
                            icon: "mdi mdi-file-table-box-multiple-outline",
                            display: "",
                            menuType: "item",
                            children: []
                        },
                        {
                            menuId: 26,
                            link: "/packed-package",
                            title: "包裹-已打包",
                            icon: "ti-dropbox",
                            display: "",
                            menuType: "item",
                            children: []
                        }
                    ]
                },
                {
                    menuId: 29,
                    link: "",
                    title: "扫码大数据",
                    icon: "CardGiftcardOutlinedIcon",
                    display: "",
                    menuType: "collapse",
                    children: [
                        {
                            menuId: 30,
                            link: "/product-area",
                            title: "扫码监控",
                            icon: "",
                            display: "",
                            menuType: "item",
                            children: []
                        },
                        {
                            menuId: 31,
                            link: "/view-Distribution",
                            title: "扫码分布",
                            icon: "",
                            display: "",
                            menuType: "item",
                            children: []
                        }
                    ]
                },
                {
                    menuId: 32,
                    link: "/help-doc",
                    title: "帮助文档",
                    icon: "mdi mdi-receipt",
                    display: "",
                    menuType: "item",
                    children: []
                }
            ]
    }
];


const generateMenu= (datalist) => {

    if(!datalist)
       return;
    const listItems = datalist.map((data, index) => {
    let displayStatus = '';

    if(data.children && data.children.length > 0){

      return (  <li key={data.menuId}>
                    <Link to={data.link} className={data.linkclass}>
                    { data.prefix_lable ?  <i className={data.prefix_lable}></i> : null}
                    <span>{data.lable}</span>
                    </Link>
                    <ul className={data.submenuclass} aria-expanded={data.aria_expanded}>
                        {generateMenu(data.children)}
                    </ul>
                </li>
             )

    }
    else{
      return  ( <li key={data.menuId}>
                  { data.prefix_lable ? <Link to={data.link}
                      className={data.linkclass}>
                        <i className={data.prefix_lable}></i>
                      <span>{data.lable}</span> 
                  </Link>  :  <Link to={data.link}
                      className={data.linkclass}>
                      <span>{data.lable}</span> 
                  </Link>
                  }
              </li>)
    }
  });
  return listItems;
};

const genIcon = (name) =>{

    if("MailOutlined" === name){
       return ( <MailOutlined />);
    }
    else if("AppstoreOutlined" === name){
        return ( <AppstoreOutlined />);
    }
    else if("InfoCircleOutlined" === name){
        return ( <InfoCircleOutlined  />);
    }
    else if("ReconciliationOutlined" === name){
        return ( <ReconciliationOutlined />);
    }
    else if("QrcodeOutlined" === name){
        return ( <QrcodeOutlined />);
    }
    else if("BarcodeOutlined" === name){
        return ( <BarcodeOutlined />);
    }
    else if("CodepenOutlined" === name){
        return ( <CodepenOutlined />);
    }
    else if("AppstoreAddOutlined" === name){
        return ( <AppstoreAddOutlined />);
    }
    else if("BugOutlined" === name){
        return ( <BugOutlined />);
    }
    else if("ScheduleOutlined" === name){
        return ( <ScheduleOutlined />);
    }
    else if("BlockOutlined" === name){
        return ( <BlockOutlined />);
    }
    else if("HistoryOutlined" === name){
        return ( <HistoryOutlined />);
    }
    else if("SplitCellsOutlined" === name){
        return ( <SplitCellsOutlined />);
    }
    else if("SendOutlined" === name){
        return ( <SendOutlined />);
    }
    else if("HomeOutlined" === name){
        return ( <HomeOutlined />);
    }
    else if("BarChartOutlined" === name){
        return ( <BarChartOutlined />);
    }
    else if("ChromeOutlined" === name){
        return ( <ChromeOutlined />);
    }
    else if("GlobalOutlined" === name){
        return ( <GlobalOutlined />);
    }
    else if("SecurityScanOutlined" === name){
        return ( <SecurityScanOutlined />);
    }
    else {
        return ( <SettingOutlined />);
    }

}



const menuItem = (data)=>{

      if(data.icon){
        return ( 
               <Menu.Item key={data.menuId} icon={genIcon(data.icon)}>
                     <Link to={data.link}
                        className={data.linkclass}>
                        <span>{data.title}</span> 
                      </Link>
                </Menu.Item>)
      }
      else{

        return ( 
             <Menu.Item key={data.menuId} >
                <Link to={data.link}>
                    <span>{data.title}</span> 
                </Link>
             </Menu.Item> )

      }

}

const generateAntMenu= (datalist) => {
  
    if(!datalist)
       return;
    const listItems = datalist.map((item, index) => {

        if(item.children && item.children.length > 0){
            //generateAntMenu(item.children);
            if(item.menuType === "group"){
                return ( <>
                        <Menu.Divider />
                        {/* <Menu.ItemGroup  title={item.title}  className={classes.antMenuItemGroupTitle}></Menu.ItemGroup>
                            {generateAntMenu(item.children)}
                        </> */}
                         <Menu.ItemGroup key={item.menuId}  title={item.title} ></Menu.ItemGroup>
                         {generateAntMenu(item.children)}
                     </>
                    );
            }
            else{
                if(item.menuType === "collapse"){
                    if(item.icon){
                        return ( 
                            <SubMenu key={item.menuId}  
                               icon={genIcon(item.icon)}
                               title={item.title}>
                                {generateAntMenu(item.children)}
                            </SubMenu>
                        );
                    }
                    else{
                        return ( 
                            <SubMenu key={item.menuId}    title={item.title}>
                                {generateAntMenu(item.children)}
                            </SubMenu>
                        );
                    }
                  
                }
            }
            
        }
        else{
            return  menuItem(item);
        }

    })
    return listItems;
}

const SidebarContent2 = props => {

   const  menu =props.menu;
    return (
        <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">

                {/* menu from web */}
                {generateMenu(menu)}



                {/**local ***/}
                {/* <li>
                    <Link to="/dashboard" className="waves-effect">
                        <i className="ti-clipboard"></i>
                        <span>{i18n.t('dashboard')}</span>
                    </Link>
                </li>


                {
                    (sessionStorage.getItem('roleId') === '1') ? <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-boxes"></i>
                            <span>{i18n.t('company_check')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li>
                                <Link to="/company-check">{i18n.t('company_check1')}</Link>
                            </li>
                            <li>
                                <Link to="/company-pass">{i18n.t('company_check2')}</Link>
                            </li>
                            <li>
                                <Link to="/company-nopass">{i18n.t('company_status2')}</Link>
                            </li>
                        </ul>
                    </li> : null
                }


                <li>
                    <Link to="/company-info" className="waves-effect">
                        <i className="ti-info-alt"></i>
                        <span>{i18n.t('company_info')}</span>
                    </Link>
                </li>


                <li>
                    <Link to="/inventory" className="waves-effect">
                        <i className="mdi mdi-file-table-box-multiple-outline"></i>
                        <span>{i18n.t('inventory')}</span>
                    </Link>
                </li>

                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-boxes"></i>
                        <span>{i18n.t('product_management')}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                        <li>
                            <Link to="/form-product">{i18n.t('add_product')}</Link>
                        </li>
                        <li>
                            <Link to="/view-product">{i18n.t('view_product')}</Link>
                        </li>
                        <li>
                            <Link to="/trace-role-managment">{i18n.t('trace_role_managment')}</Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <Link to="/generate-qr-code" className="waves-effect">
                        <i className="ion ion-ios-qr-scanner"></i>
                        <span>{i18n.t('generate_qr_code')}</span>
                    </Link>
                </li>

                <li>
                    <Link to="/generate-barcode" className="waves-effect">
                        <i className="ion ion-md-barcode"></i>
                        <span>{i18n.t('generate_barcode')}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/code-batch-management" className="waves-effect">
                        <i className="ion ion-ios-cube"></i>
                        <span>{i18n.t('code_batch_management')}</span>
                    </Link>
                </li>

                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-boxes"></i>
                        <span>{i18n.t('production_planning')}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                        <li>
                            <Link to="/productplan">{i18n.t('production_planning')}</Link>
                        </li>
                        <li>
                            <Link to="/infonodes">{i18n.t('node_info_records')}</Link>
                        </li>
                       
                    </ul>
                </li>
       

                <li>
                    <Link to="/packed-package" className="waves-effect">
                        <i className="ti-dropbox"></i>
                        <span>{i18n.t('package_packed')}</span>
                    </Link>
                </li>

                <li>
                    <Link to="/shipped-package" className="waves-effect">
                        <i className="mdi mdi-truck-check"></i>
                        <span>{i18n.t('package_shipped')}</span>
                    </Link>
                </li>

                <li>
                    <Link to="/order-history" className="waves-effect">
                        <i className="mdi mdi-receipt"></i>
                        <span>{i18n.t('order_history')}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="mdi mdi-image-area"></i>
                        <span>{i18n.t('scan_record')}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                        <li>
                            <Link to="/product-area">{i18n.t('scan_moniter')}</Link>
                        </li>
                        <li>
                            <Link to="/view-Distribution">{i18n.t('scan_distribution')}</Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <Link to="/help-doc" className="waves-effect">
                        <i className="ion ion-md-cloud-download"></i>
                        <span>{i18n.t('help_doc')}</span>
                    </Link>
                </li>  */}


                {/* <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-boxes"></i>
                        <span>{i18n.t('resource_management')}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                        <li>
                        <Link to="/view-resource">{i18n.t('view_resource')}</Link>
                        </li>
                        <li>
                        <Link to="/form-resource">{i18n.t('add_resource')}</Link>
                        </li>
                    </ul>
                </li> */}

 
            </ul>
        </div>
    );
};


const SidebarContent = props => {

    const {menu, menuKeys,set,type} = props;
    let collapsed =true;
    if(type==="default")
        collapsed =false;
    else
        collapsed =true;
    // const classes = useStyles();
    const handleSelect = (data) =>{
        set({...menuKeys,
            keys:data.selectedKeys,
        });
    }

    const handleOpenChange =(keys) =>{
        set({...menuKeys,
            openKeys:keys,
        });
    }
   
     return (
        <Menu
            // style={{ height:"100%"}}
            defaultSelectedKeys={menuKeys.keys}
            defaultOpenKeys={menuKeys.openKeys}
            selectedKeys={menuKeys.keys}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            onSelect = {handleSelect}
            onOpenChange={handleOpenChange}
        >


          {generateAntMenu(menu)}

         
          
         {/* <SubMenu key="sub1" icon={<MailOutlined />}  title="Navigation One">
          
            <Menu.Item key="1">
                <Link to="/company-info" className="waves-effect">
                        <i className="ti-info-alt"></i>
                        <span>{i18n.t('company_info')}</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                 <Link to="/form-product">{i18n.t('add_product')}</Link>
            </Menu.Item>
          
          <Menu.ItemGroup key="g2" title="Item 2">
            <Menu.Item key="3" >
                <Link to="/company-check">{i18n.t('order_history')}</Link>
            </Menu.Item>
            <Menu.Item key="4">
                <Link to="/view-product">{i18n.t('view_product')}</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Divider />
        <Menu.ItemGroup key="3"  style={{fontSize:"30px", color:"red"}}  title="xxxxxx" ></Menu.ItemGroup>
        
        <SubMenu key="sub2"  icon={<AppstoreOutlined/>} title="Navigation Two">
          <Menu.Item key="5">
                <Link to="/company-nopass">{i18n.t('company_status2')}</Link>
          </Menu.Item>
          <Menu.Item key="6">
                <Link to="/order-history" className="waves-effect">
                        <i className="mdi mdi-receipt"></i>
                        <span>{i18n.t('order_history')}</span>
                </Link>
          </Menu.Item>
          <SubMenu key="sub3" title="Submenu">
            <Menu.Item key="7">
                <Link to="/form-resource">{i18n.t('add_resource')}</Link></Menu.Item>
            <Menu.Item key="8">
                <Link to="/infonodes">{i18n.t('node_info_records')}</Link>
            </Menu.Item>
          </SubMenu>
        </SubMenu>
        <SubMenu key="sub4"  title="Navigation Three">
          <Menu.Item key="9">
              <Link to="/order-history" className="waves-effect">
                <i className="mdi mdi-receipt"></i>
                <span>{i18n.t('order_history')}</span>
              </Link>
          </Menu.Item>
          <Menu.Item key="10">
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-image-area"></i>
                    <span>{i18n.t('scan_record')}</span>
                </Link>
          </Menu.Item>
          <Menu.Item key="11">
                <Link to="/order-history" className="waves-effect">
                    <i className="mdi mdi-receipt"></i>
                    <span>{i18n.t('order_history')}</span>
                </Link>
          </Menu.Item>
          <Menu.Item key="12">
                 <Link to="/productplan">{i18n.t('production_planning')}</Link>
          </Menu.Item>
          <Menu.Item key="12">
                <Link to="/inventory" className="waves-effect">
                    <i className="mdi mdi-file-table-box-multiple-outline"></i>
                    <span>{i18n.t('inventory')}</span>
                </Link>
          </Menu.Item>
        </SubMenu> */}
      </Menu>
     );
 };

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inited:false,
        };
        // if( this.props.MenuList.length==0)
        //      this.props.getMenu("",this.props.history);
    }

    componentDidMount() {
         if( this.props.MenuList.length == 0){
            this.props.getMenu("",this.props.history);
         }
         else{
            this.initMenu();
         }
       
    }

    componentDidUpdate(prevProps,prevState) {
       
       if (this.props.menuReady) {
            this.initMenu();
        }
    }

    initMenu() {

        if(!this.state.inited){
        //     if (this.props.type !== "condensed" || this.props.isMobile) {
        //         new MetisMenu("#side-menu");
    
        //         var matchingMenuItem = null;
                
        //         var ul = document.getElementById("side-menu");
        //         var items = ul.getElementsByTagName("a");

        //         for (var i = 0; i < items.length; ++i) {
        //             if (this.props.location.pathname === items[i].pathname) {
        //                 matchingMenuItem = items[i];
        //                 break;
        //             }
        //         }
        //         if (matchingMenuItem) {
        //             this.activateParentDropdown(matchingMenuItem);
        //         }
        //     }
            if(this.props.menuKeys.keys.length==0){
                this.setDefaultKey();
            }
            this.state.inited =true;

        }
       
    }

    setDefaultKey(){

        let key=this.searchMenu(this.props.MenuList,this.props.location.pathname);
        if(key !=null){
            let keys=[];
            keys.push(key+"");
            this.props.setSelectedKey({...this.props.menuKeys,
                keys:keys,
            });
        }
      
    }

    searchMenu(list,path){
  
        if(!list)
           return null;
        let len=list.length;
        let index =0, linkid=null,item=null;
        while(index < len){
 
            item=list[index];
            if(item.menuType === "group" || item.menuType === "collapse"){
                linkid =this.searchMenu(item.children,path);
                if(linkid){
                    break;
                }
            }
            else{
                if(item.link === path){
                    linkid =item.menuId;
                   return linkid;
                }
                else{
                    return null;
                }
                
            }

            index++;
        }

        return linkid;
    }
    

    activateParentDropdown = item => {

        // if(item.classList.indexOf("mm-active") >=0)
        //    return false;
        // if(item.classList.value.indexOf("mm-active")>=0)
        //     return false;
        item.classList.add("mm-active");
       
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("mm-active"); // li
            const parent2 = parent.parentElement;

            if (parent2) {

                parent2.classList.add("mm-show");
                const parent3 = parent2.parentElement;
                
                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return true;
        }
        return false;
    };

    render() {
        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {this.props.type !== "condensed" ? (
                                  <SimpleBar  style={{maxHeight:"100%"}}>
                                    <SidebarContent menu={this.props.MenuList} 
                                    menuKeys={this.props.menuKeys}
                                    type="default"
                                    set={this.props.setSelectedKey}/>
                                 </SimpleBar>
                            ) 
                            : (
                                <SidebarContent style={{maxHeight:"100%"}} menu={this.props.MenuList} 
                                menuKeys={this.props.menuKeys}
                                type="condensed"
                                set={this.props.setSelectedKey}/>
                            )}
                             {/* <SimpleBar style={{maxHeight: "100%"}}>
                                <SidebarContent menu={this.props.MenuList} />
                             </SimpleBar> */}

                            {/* <SidebarContent menu={this.props.MenuList}/> */}
                            {/* <PerfectScrollbar className={classes.ScrollHeight}>
                                <MenuList />
                            </PerfectScrollbar> */}
                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
//   console.log('mapStateToProps', state.Menu)
  const {
    MenuList,
    menuReady,
    menuKeys,
  } = state.Menu;
  return {
    MenuList,
    menuReady,
    menuKeys,
  };
};
export default withRouter(connect(mapStateToProps, {
    getMenu,setSelectedKey,
})(Sidebar));
