import React, {Component} from "react";
import SettingMenu from "../Shared/SettingMenu";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {AvForm, AvField, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table, InputGroup} from "reactstrap";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import axios from "axios";
import {parseISO} from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import {MDBDataTable} from "mdbreact";
import Select from "react-select";
import {Modal, Collapse} from "reactstrap";

// import {format} from "date-fns";
// import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";

// import ReactPaginate from 'react-paginate';
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import {Tooltip} from "antd";

// import lianbg from '../../assets/images/lian/lian3.png'
// import lianlogo from '../../assets/images/lian/lian4.png'

import i18n from '../../i18n';
import {Translation} from 'react-i18next';

// actions
import {
    getRealDynList,
    clearInputError,
    clearAlert,
    apiError,
} from "../../store/traceabilityrole/actions";
import CSV from "../../components/CSVDownloader/CSVDownloader"
import CSVHandler from "../../components/CSVDownloader/CSVHandler"
import {LogisticStatusBtn} from "../../components/LogisticStatusBtn/LogisticStatusBtn";

import AntdPagination from '../../components/AntdPagination'
import {ConfigProvider, Pagination} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import zhHK from "antd/lib/locale/zh_HK"

import commonUtils from '../../helpers/commonUtils'

import "../../css/Datepicker.css"

const nowDate = commonUtils.getNowFormatDate();
// console.log('当天时间==' + getNowFormatDate());

const currentStatus = 'completed';

const optionStatus = [
    {label: i18n.t('all'), value: ''},
    {label: i18n.t('packed'), value: 'packed'},
    {label: i18n.t('shipped'), value: 'shipped'},
    {label: i18n.t('completed'), value: 'completed'},
];

const optionPageSize = [
    {label: 10, value: 10},
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
];

const getSortingComponent = (value, sorting) => {
    if (value == sorting.column) {
        switch (sorting.order) {
            case '':
                return <i className="typcn typcn-media-record"/>;
                break;

            case 'asc':
                return <i className="ion ion-ios-arrow-up"/>;
                break;

            case 'desc':
                return <i className="ion ion-ios-arrow-down"/>;
                break;
        }
    } else {
        return <i className="typcn typcn-media-record"/>;
    }
}

const PackageTableRow = (props) => {
    const dataList = props.data;
    const toggleModalShipPackage = props.toggleModalShipPackage;
    const toggleModalShippingInfo = props.toggleModalShippingInfo;
    const toggleModalStatusChangeLog = props.toggleModalStatusChangeLog;
    const currentTime = props.time;

    let startRowNo = props.pageNumber * props.pageSize + 1;

    const toggleModalBlockchain = props.toggleModalBlockchain;

    const listItems = dataList.map((data, index) => {
        let displayStatus = '';



        return (<tr key={index}>
                <td><span className="float-right">{startRowNo++}</span></td>

                <td><span>{data.productId}</span></td>
                <td><span className="nowrap">{data.productName}</span></td>
                {currentStatus === 'packed' ?
                    <td className="nowrap">
                        <Button
                            color="info"
                            onClick={() => toggleModalShipPackage(data.packageId)}
                        >
                            {i18n.t('ship_package')}
                        </Button>
                    </td> : null}


                <td className="nowrap">
                    <div className="nowrap table_td_space_around">
                        <Link to={`/role-setting/${data.productId}/${data.productName}`}>
                            <span color="warning" className="mr-1 text-blue">{i18n.t('common_setting')}</span>
                        </Link>
                    </div>
                </td>

            </tr>
        )
    });

    return (<tbody>{listItems}</tbody>);

};

const entryStr = (pageNumber, pageSize, filteredCount, totalCount) => {
    let to = (pageNumber + 1) * pageSize;
    if (to > filteredCount) {
        to = filteredCount;
    }

    let from = pageNumber * pageSize + 1;
    if (from > to) {
        from = to;
    }
    return <>{i18n.t('table_count', {from: from, to: to, filter: filteredCount, total: totalCount})}</>;
}


const userStr = (adminUser, workerUser) => {
    // if(
    //   (adminUser === null || adminUser === undefined) &&
    //   (workerUser === null || workerUser === undefined)
    // ) {
    //   return '';
    // }
    // return adminUser !== null ? `${adminUser} ` + i18n.t('admin_bracket') : `${workerUser} ` + i18n.t('worker_bracket');
    //

    if (adminUser) {
        return i18n.t('admin_bracket')
    } else {
        return i18n.t('worker_bracket')
    }

};

const ShippingInfoRow = (props) => {
    console.log('testing ShippingInfoRow()', props.data);
    const dataList = props.data;

    let step = 1;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td>{index + 1}</td>
            {currentStatus === 'shipped' ?
                <td className="nowrap">
                    <Button
                        color={data.delete === true ? 'warning' : 'danger'}
                        onClick={() => props.handleRemoveStep(index)}
                    >
                        {data.delete === true ? i18n.t('cancel_remove') : i18n.t('remove')}
                    </Button>
                </td> : null}
            <td>{props.packageId}</td>

            <td>
                {/* {data.step} */}
                {data.delete === true ? null : step++}
            </td>
            <td>
                {data.uploadable === true ?
                    <AvField
                        type="textarea"
                        className="w-200"
                        name={`time[${index}]`}
                        value=""
                    />
                    : data.time}
            </td>
            <td>
                {data.uploadable === true ?
                    <AvField
                        type="textarea"
                        className="w-200"
                        name={`note[${index}]`}
                        value=""
                    />
                    : data.note}
            </td>
            <td className="nowrap">
                {userStr(data.updatedByAdminUserId, undefined)}
            </td>
        </tr>
    );

    if (currentStatus === 'shipped') {
        listItems.push(
            <tr key={listItems.length}>
                <td colSpan="7">
                    <Button
                        color="success"
                        onClick={props.handleAddStep}
                    >
                        {i18n.t('add_shipping_info')}
                    </Button>
                </td>
            </tr>
        );
    }

    return (<tbody>{listItems}</tbody>);
}

const StatusChangeLogRow = (props) => {
    console.log('testing StatusChangeLogRow()', props.data);
    const dataList = props.data;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td><span className="nowrap">{data.status}</span></td>
            <td>{data.datetime}</td>
            <td><span className="nowrap">{data.userId}</span></td>
            <td>{data.location}</td>
        </tr>
    );

    return (<tbody>{listItems}</tbody>);
};


class Roles extends Component {
    constructor(props) {
        super(props);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleFilterVisibility = this.handleFilterVisibility.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleAddStep = this.handleAddStep.bind(this);
        this.handleRemoveStep = this.handleRemoveStep.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.toggleModalShipPackage = this.toggleModalShipPackage.bind(this);
        this.toggleModalShippingInfo = this.toggleModalShippingInfo.bind(this);
        this.toggleModalStatusChangeLog = this.toggleModalStatusChangeLog.bind(this);
        this.handler2 = this.handler2.bind(this);
        this.getCurrentTime = this.getCurrentTime.bind(this);

        this.handleAntdPageChange = this.handleAntdPageChange.bind(this);
        this.handleAntdPageSizeChange = this.handleAntdPageSizeChange.bind(this);
        this.toggleModalBlockchain = this.toggleModalBlockchain.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

        

        this.state = {
            currentTime: 0,
            filter: {
                productId: undefined,
                productName: undefined,
            },
            pageNumber: 0,
            pageSize: 10,
            filteredCount: 0,
            pageCount: 0,
            totals: 0,
            sorting: {
                column: 'packageId',
                order: 'desc',
            },
            modalPackageId: undefined,
            modalShipPackage: {
                toggle: false,
                // packageId: [],
            },
            modalShippingInfo: {
                toggle: false,
                // updateMode: '',
                shipmentCourierRefNo: '',
                shippingInfo: [],
                // packageId: [],
            },
            modalStatusChangeLog: {
                toggle: false,
                statusChangeLog: [],
            },
            blockchainData: {
                toggle: false,
                info: {}
            },
            lang:{
                lang:zhCN,
                locale:"zh",
            }
        };
    }

    componentDidMount() {
        console.log('componentDidMount');
        this.handleFormSubmit();
        this.getCurrentTime();
        this.props.clearAlert('success');
    }

    componentDidUpdate() {
        if (currentStatus === 'packed') {
            document.title = i18n.t('package_packed');
        } else if (currentStatus === 'shipped') {
            document.title = i18n.t('package_shipped');
        } else if (currentStatus === 'completed') {
            document.title = i18n.t('order_history');
        }

        console.log('componentDidUpdate', this.props.filteredCount);


        let pageCount = Math.ceil(this.props.filteredCount / this.state.pageSize);

        console.log('testing update page count', this.state.pageCount, pageCount);

        if (this.state.pageCount !== pageCount) {
            console.log('订单历史 componentDidUpdate');
            if (pageCount <= this.state.pageNumber) {
                this.setState({pageCount: pageCount, pageNumber: 0}, () => this.handleFormSubmit());
            } else {
                this.setState({pageCount: pageCount});
            }
        }
        let lang= sessionStorage.getItem("lang");
        if(this.state.lang.locale !==lang){
            if(lang ==="en"){
                this.setState({lang:{lang:enUS,
                    locale:lang
                }});
            }
            else if(lang ==="zh"){
                this.setState({lang:{
                    lang:zhCN,
                    locale:lang}});
            }
            else{
                this.setState({lang:{
                    lang:zhHK,
                    locale:lang}});
            }
        }

    }

    handler2 = CSVHandler.orderHistory.allDownloadHandler

    getCurrentTime() {
        axios.get("https://worldtimeapi.org/api/ip")
            .then((res) => {
                let currentTime = new Date();
                if (res.data) {
                    currentTime = res.data.datetime ? parseISO(res.data.datetime) : currentTime;
                }
                this.setState({currentTime: currentTime})
                console.log("update current time")
            })
            .catch((err) => {
                let currentTime = new Date();
                this.setState({currentTime: currentTime})
            })
    }

    handleFilterInputChange(event) {
        console.log('handleFilterInputChange', event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));

        console.log('after', this.state);
    }

    handleFilterStatusChange(event) {
        console.log('handleFilterStatusChange', event);

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                status: event.value,
            },
        }));
    }

    handleFilterDateChange(dateInput, value) {
        console.log('handleFilterDateChange', dateInput, value);

        let dateInputValue = value === null ? undefined : value;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [dateInput]: dateInputValue,
            },
        }));
    }

    handlePageSizeChange(event) {
        console.log('handlePageSizeChange', event);

        this.setState({
                pageSize: event.value,
                pageNumber: 0,
            },
            () => this.handleFormSubmit());
    }

    handleSorting(value) {
        console.log('handleSorting', value, this.state.sorting.column);

        let newOrder;
        let newComponent;

        if (value == this.state.sorting.column) {
            switch (this.state.sorting.order) {
                case '':
                    newOrder = 'asc';
                    break;

                case 'asc':
                    newOrder = 'desc';
                    break;

                case 'desc':
                    newOrder = '';
                    break;
            }
        } else {
            newOrder = 'asc';
        }

        this.setState(prevState => ({
                sorting: {
                    column: value,
                    order: newOrder,
                }
            }),
            () => this.handleFormSubmit());
    }

    handlePageClick(event) {
        console.log('handlePageClick', event);

        this.setState(
            prevState => ({
                pageNumber: event.selected,
            }),
            () => this.handleFormSubmit()
        );

        return false;
    }


    // 改变页码
    handleAntdPageChange(page, pageSize) {
        this.setState({
                pageSize: pageSize,
                pageNumber: page - 1,
            },
            () => this.handleFormSubmit()
        );
    }

    // 改变每页显示条数
    handleAntdPageSizeChange(current, size) {
        this.setState({
                pageSize: size,
                pageNumber: 0,
            },
            () => this.handleFormSubmit()
        );
    }


    handleClearFilter() {
        console.log('handleClearFilter()');

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                productId: '',
                productName: '',
            },
        }), () => {
            this.handleFormSubmit()
        });
    }

    handleFilterVisibility() {
        console.log('handleFilterVisibility()');

        this.setState(prevState => ({
            filterVisibility: !prevState.filterVisibility
        }));
    }

    handleAddStep() {
        console.log('handleAddStep');

        this.setState(prevState => ({
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                shippingInfo: [
                    ...prevState.modalShippingInfo.shippingInfo,
                    {
                        uploadable: true,
                    }
                ].map((data, index) => {
                    data.index = index;
                    return data;
                }),
            },
        }));
    }

    handleRemoveStep(index) {
        console.log('handleRemoveStep()', index);

        let tmp = [];

        for (let i = 0; i < this.state.modalShippingInfo.shippingInfo.length; i++) {
            if (index !== i) {
                // not to delete
                tmp.push(this.state.modalShippingInfo.shippingInfo[i]);
            } else {
                // index matched
                if (this.state.modalShippingInfo.shippingInfo[i].uploadable === true) {
                    // delete
                    console.log('testing 1');
                } else {
                    // mark as delete
                    console.log('testing 2');
                    tmp.push({
                        ...this.state.modalShippingInfo.shippingInfo[i],
                        delete: !this.state.modalShippingInfo.shippingInfo[i].delete
                    });
                }
            }
        }

        this.setState(prevState => ({
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                shippingInfo: tmp.map((data, index) => {
                    data.index = index;
                    return data;
                }),
            }
        }));
    }

    handleFormSubmit(event, values) {
        let pageNumber = this.state.pageNumber;

        let submitData = {
            productId: this.state.filter.productId,
            productName: this.state.filter.productName,
            pageNumber: pageNumber + 1,
            pageSize: this.state.pageSize,
            sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
        };

        console.log('on handleFormSubmit()', event, values, submitData);
        this.props.getRealDynList(submitData, this.props.history);
    }

    handleModalSubmit(event, values) {
        // console.log('on handleModalSubmit()', event, values, this.state.modalShippingInfo.updateMode);
        console.log('on handleModalSubmit()', event, values, this.state.modalShippingInfo.shippingInfo);
        let newStatus;
        let removeShippingRecord = undefined;
        // let newOrderShippingRecord = undefined;
        let shippingRecord = undefined;

        if (currentStatus === 'packed') {
            newStatus = 'shipped';
        } else if (currentStatus === 'shipped') {
            // if(event === 'save') {
            //   newStatus = 'shipped';
            // } else {
            //   newStatus = 'completed';
            // }
            newStatus = values.status;

            removeShippingRecord = [];
            // newOrderShippingRecord = [];
            shippingRecord = [];

            let step = 1;

            for (let tmp of this.state.modalShippingInfo.shippingInfo) {
                if (tmp.delete === true) {
                    removeShippingRecord.push(tmp.step);
                }
                // else if(tmp.uploadable !== true) {
                //   newOrderShippingRecord.push({
                //     current: tmp.step,
                //   });
                // }
                else if (tmp.uploadable === true) {
                    shippingRecord.push({
                        time: values.time[tmp.index],
                        note: values.note[tmp.index],
                    });
                }
            }
        }

        if (newStatus !== undefined) {
            let submitData = {
                packageId: this.state.modalPackageId,
                status: newStatus,
                shipmentCourierRefNo: values.shipmentCourierRefNo,
                removeShippingRecord,
                shippingRecord,
            };
            console.log('on handleModalSubmit()', submitData);

            // this.props.updatePackage(submitData, this.props.history);
        }
    }

    /**
     * modal
     */
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleModalShipPackage(modalPackageId = undefined) {
        console.log('toggleModalShipPackage', modalPackageId);

        this.setState(prevState => ({
            modalPackageId: modalPackageId,
            modalShipPackage: {
                ...prevState.modalShipPackage,
                toggle: !prevState.modalShipPackage.toggle,
            },
        }));
        this.removeBodyCss();
    }

    toggleModalShippingInfo(modalPackageId = undefined, shipmentCourierRefNo = '', shippingRecords = []) {
        console.log('toggleModalShippingInfo', modalPackageId, shipmentCourierRefNo, shippingRecords);


        this.setState(prevState => ({
            modalPackageId: modalPackageId,
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                toggle: !prevState.modalShippingInfo.toggle,
                shipmentCourierRefNo: shipmentCourierRefNo,
                shippingInfo: shippingRecords.map((data, index) => {
                    data.index = index;
                    return data;
                }),
            },
        }));
        this.removeBodyCss();
    }

    toggleModalStatusChangeLog(data = []) {
        console.log('toggleModalStatusChangeLog', data);

        let statusChangeLog = [];

        if (data.packedDate) {
            statusChangeLog.push({
                status: i18n.t('packed'),
                datetime: data.packedDate,
                // userId: userStr(data.packedByAdminUserId, data.packedByWorkerUserId),
                userId: data.packageUserName,
                location: data.packedUpdatedInLocation,
            });
        }

        if (data.shippedDate) {
            statusChangeLog.push({
                status: i18n.t('shipped'),
                datetime: data.shippedDate,
                // userId: userStr(data.shippedByAdminUserId, data.shippedByWorkerUserId),
                userId: data.shippedUserName,
                location: data.shippedUpdatedInLocation,
            });
        }


        if (data.completedDate) {
            statusChangeLog.push({
                status: i18n.t('completed'),
                datetime: data.completedDate,
                // userId: userStr(data.completedByAdminUserId, data.completedByWorkerUserId),
                userId: data.completeUserName,
                location: data.completedUpdatedInLocation,
            });
        }


        this.setState(prevState => ({
            modalStatusChangeLog: {
                ...prevState.modalStatusChangeLog,
                toggle: !prevState.modalStatusChangeLog.toggle,
                statusChangeLog: statusChangeLog,
            },
        }));
        this.removeBodyCss();
    }

    toggleModalBlockchain(data = {}) {
        let info = {
            currentBlockHeight: data.currentBlockHeight,
            blockDataHash: data.blockDataHash
        }
        this.setState(prevState => ({
            blockchainData: {
                ...prevState.blockchainData,
                toggle: !prevState.blockchainData.toggle,
                info: info,
            },
        }));
        this.removeBodyCss();
    }

    handleAdd(){
        this.props.history.push('/workers');
    }

    render() {
        const {photoIndex} = this.state;

        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {/*{this.props.loading ? <Loader /> : null}*/}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                             {/* <ol className="breadcrumb mb-0">
                                                <li className="breadcrumb-item font-bold-size">{t('trace_role_managment')}</li>
                                                <Tooltip title={t('code_batch_tips')}><i className="ti-help-alt help-margin"></i></Tooltip>
                                            </ol>  */}
                                            <h4 className="navi-heander-size">
                                                {t('trace_role_managment')}
                                                 <Tooltip title={t('process_user_tips')}><i className="ti-help-alt help-margin"></i></Tooltip>
                                            </h4>

                                        </div>
                                    </Col>
                                </Row>

                                <Row className='InfoContainer'>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                        <button
                                                            onClick={this.handleFilterVisibility}
                                                            className="btn btn-primary mo-mb-2 btn-primary-bg"
                                                            type="button"
                                                        >
                                                            {this.state.filterVisibility === true ? t('hide_filter') : t('show_filter')}
                                                        </button>
                                                        <button
                                                            onClick={this.handleAdd}
                                                            className="btn btn-primary mo-mb-2 btn-primary-bg button-margin-left"
                                                            type="button"
                                                        >
                                                            {t('worker')}
                                                        </button>
                                                       {/* <CSV path="orderHistory"
                                                             curfilename={'商品管理人员第' + (parseInt(this.state.pageNumber + 1)) + '页记录' + nowDate + '.csv'}
                                                             allfilename={'商品管理人员全部记录' + nowDate + '.csv'}
                                                             handler1={CSVHandler.orderHistory.pageDownloadHandler}
                                                             parser={CSVHandler.orderHistory.parser}
                                                             handler2={this.handler2}/>*/}
                                                    </Col>
                                                </Row>

                                                <Collapse isOpen={this.state.filterVisibility}>
                                                    <AvForm className="mb-3" onValidSubmit={this.handleFormSubmit}>
                                                        <Row>
                                                            {/* <Col sm={2}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="productId"
                                                                        label={t('product_id')}
                                                                        value={this.state.filter.productId}
                                                                        onChange={this.handleFilterInputChange}
                                                                        validate={{
                                                                            pattern: {value: '^[1-9]\\d*$', errorMessage: '请输入数字'},
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col> */}
                                                            <Col sm={2}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="productName"
                                                                        label={t('product_name')}
                                                                        value={this.state.filter.productName}
                                                                        onChange={this.handleFilterInputChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {/*<Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('completed_date_from')}</Label>
                                                                    <InputGroup>
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            dateFormat="yyyy-MM-dd"
                                                                            selected={this.state.filter.completedDateTo}
                                                                            onChange={(value) => this.handleFilterDateChange('completedDateTo', value)}
                                                                            isClearable
                                                                            placeholderText={t('click_open_date_picker')}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>*/}
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Button color="primary btn-primary-bg"
                                                                            className="mr-1">{t('apply')}</Button>
                                                                    <Button color="secondary"
                                                                            onClick={this.handleClearFilter}>{t('clear')}</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                </Collapse>

                                                <Row>
                                                    <Col sm={12}>
                                                        <p className="text-muted m-b-30">
                                                            {entryStr(this.state.pageNumber, this.state.pageSize, this.props.filteredCount, this.props.filteredCount)}
                                                        </p>
                                                    </Col>
                                                </Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table className="table thead-nowrap">
                                                            <thead className="thead-header">
                                                            <tr>
                                                                <th style={{width:'50px'}}><span className="float-right">#</span></th>

                                                                <th
                                                                    onClick={() => this.handleSorting('batchId')}>
                                                                    <span>{t('product_id')} {getSortingComponent('batchId', this.state.sorting)}</span>
                                                                </th>
                                                                <th>{t('product_name')}</th>

                                                                {/* <th>{t('product_name')}</th>
                                                                <th>{t('code_end_index')}</th>
                                                                <th>{t('active_start_index')}</th>
                                                                <th>{t('active_end_index')}</th>
                                                                <th>{t('code_status')}</th>  */}

                                                                <th style={{textAlign:'center'}}>{t('action')}</th>

                                                            </tr>
                                                            </thead>
                                                            <PackageTableRow
                                                                toggleModalBlockchain={this.toggleModalBlockchain}
                                                                time={this.state.currentTime}
                                                                data={this.props.RealDynList}
                                                                toggleModalShipPackage={this.toggleModalShipPackage}
                                                                toggleModalShippingInfo={this.toggleModalShippingInfo}
                                                                toggleModalStatusChangeLog={this.toggleModalStatusChangeLog}
                                                                pageNumber={this.state.pageNumber}
                                                                pageSize={this.state.pageSize}/>
                                                        </Table>
                                                    </div>
                                                </div>

                                                <Row className='antd-pagination'>
                                                    {
                                                        this.props.filteredCount > 0 ? <Col>
                                                            <ConfigProvider locale={this.state.lang.lang}>
                                                                <Pagination
                                                                    total={this.props.filteredCount}
                                                                    showSizeChanger
                                                                    showQuickJumper
                                                                    onChange={this.handleAntdPageChange}
                                                                    onShowSizeChange={this.handleAntdPageSizeChange}
                                                                    showTotal={total => t('table_nav_count', {total: total})}
                                                                />
                                                            </ConfigProvider>
                                                        </Col> : null
                                                    }
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                            {this.props.alert.success ? (
                                <SweetAlert
                                    title={t('package_updated')}
                                    success
                                    confirmBtnBsStyle="success"
                                    onConfirm={() => {
                                        this.props.clearAlert('success');
                                        currentStatus === 'packed' ? this.toggleModalShipPackage() : this.toggleModalShippingInfo();
                                        this.handleFormSubmit();
                                    }}
                                >
                                </SweetAlert>
                            ) : null}

                            {this.props.alert.error ? (
                                <SweetAlert
                                    title={this.props.alert.errorMessage}
                                    error
                                    confirmBtnBsStyle="error"
                                    onConfirm={() => {
                                        this.props.clearAlert('error');
                                    }}
                                >
                                    {this.props.alert.errorDescription}
                                </SweetAlert>
                            ) : null}


                            {/* modal */}

                            {/* modal ship package */}
                            <Modal
                                isOpen={this.state.modalShipPackage.toggle}
                            >
                                <AvForm className="mb-3" onValidSubmit={this.handleModalSubmit}>
                                    <ModalHeader
                                        toggle={() => this.toggleModalShipPackage()}
                                    >
                                        {t('ship_package')}
                                    </ModalHeader>
                                    <ModalBody>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {/* <AvField
                                                        name="shipmentCourierRefNo"
                                                        label={t('shipment_ref_optional')}
                                                        value={this.props.packageInfo.shipmentCourierRefNo}
                                                        onClick={() => this.props.clearInputError('shipmentCourierRefNo')}
                                                        validate={{
                                                            custom: () => this.props.inputError.shipmentCourierRefNo,
                                                        }}
                                                    /> */}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="secondary"
                                            onClick={() => this.toggleModalShipPackage()}
                                        >
                                            {t('close')}
                                        </Button>
                                        <Button
                                            color="primary"
                                        >
                                            {t('update_status_shipped')}
                                        </Button>
                                    </ModalFooter>
                                </AvForm>
                            </Modal>

                            {/* modal shipping info */}
                            <Modal
                                className="modal-xl"
                                isOpen={this.state.modalShippingInfo.toggle}
                            >
                                <AvForm className="mb-3 modal-shipping-info" onValidSubmit={this.handleModalSubmit}>
                                    <ModalHeader
                                        toggle={() => this.toggleModalShippingInfo()}
                                    >
                                        {currentStatus === 'shipped' ? t('update_shipping_info') : null}
                                        {currentStatus === 'completed' ? t('shipping_info') : null}
                                    </ModalHeader>
                                    <ModalBody style={{padding: '0 1rem', marginBottom: '10px'}}>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AvField style={{borderRadius: '0'}}
                                                             name="shipmentCourierRefNo"
                                                             label={currentStatus !== 'shipped' ? t('shipment_ref') : t('shipment_ref_optional')}
                                                             value={this.state.modalShippingInfo.shipmentCourierRefNo}
                                                             onClick={() => this.props.clearInputError('shipmentCourierRefNo')}
                                                             disabled={currentStatus !== 'shipped'}
                                                             validate={{
                                                                 custom: () => this.props.inputError.shipmentCourierRefNo,
                                                             }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Label>{t('shipping_info')}</Label>
                                        <div className="table-rep-plugin">
                                            <div
                                                className="table-responsive mb-0"
                                                data-pattern="priority-columns"
                                            >
                                                <Table
                                                    className="table  thead-nowrap"
                                                >
                                                    <thead className="thead-header">
                                                    <tr>
                                                        <th className="w-1p"><span className="float-right">#</span></th>
                                                        {currentStatus === 'shipped' ?
                                                            <th className="w-1p">{t('action')}</th> : null}
                                                        <th className="w-1p"><span
                                                            className="float-right">{t('package_id')}</span></th>
                                                        <th className="w-1p"><span
                                                            className="float-right">{t('step')}</span></th>
                                                        <th>{t('time')}</th>
                                                        <th>{t('note')}</th>
                                                        <th className="w-1p">{t('operator_account_id')}</th>
                                                    </tr>
                                                    </thead>
                                                    <ShippingInfoRow packageId={this.state.modalPackageId}
                                                                     handleRemoveStep={this.handleRemoveStep}
                                                                     handleAddStep={this.handleAddStep}
                                                                     data={this.state.modalShippingInfo.shippingInfo}/>
                                                </Table>
                                            </div>
                                        </div>
                                        {currentStatus === 'shipped' ?
                                            <AvGroup className="avgroup-right mt-3" check>
                                                <Label check>
                                                    <AvInput type="checkbox" name="status" trueValue="completed"
                                                             falseValue="shipped"/> {t('update_status_completed')}
                                                </Label>
                                            </AvGroup>
                                            : null}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="secondary"
                                            onClick={() => this.toggleModalShippingInfo()}
                                        >
                                            {t('close')}
                                        </Button>
                                        {currentStatus === 'shipped' ?
                                            <>
                                                <Button
                                                    color="primary"
                                                    // onClick={() => {
                                                    //   this.setState(prevState => ({
                                                    //     modalShippingInfo: {
                                                    //       ...prevState.modalShippingInfo,
                                                    //       updateMode: 'complete',
                                                    //     },
                                                    //   }),
                                                    //     document.getElementsByClassName('modal-shipping-info')[0].requestSubmit()
                                                    //   );
                                                    // }}
                                                >
                                                    {/* Save & Update Status to "Completed" */}
                                                    {t('update')}
                                                </Button>
                                            </>
                                            : null}
                                    </ModalFooter>
                                </AvForm>
                            </Modal>

                            {/* modal system log */}
                            <Modal
                                className="modal-lg"
                                isOpen={this.state.modalStatusChangeLog.toggle}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleModalStatusChangeLog()}
                                >
                                    {t('status_change_log')}
                                </ModalHeader>
                                <ModalBody>
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                className="table  thead-nowrap"
                                            >
                                                <thead className="thead-header">
                                                <tr>
                                                    <th>{t('status')}</th>
                                                    <th>{t('status_updated_at')}</th>
                                                    <th className="w-1p">{t('operator_account_id')}</th>
                                                    <th>{t('location')}</th>
                                                </tr>
                                                </thead>
                                                <StatusChangeLogRow
                                                    data={this.state.modalStatusChangeLog.statusChangeLog}/>
                                            </Table>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModalStatusChangeLog()}
                                    >
                                        {t('close')}
                                    </Button>
                                </ModalFooter>
                            </Modal>

                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    // console.log('mapStateToProps', state.TraceabilityRole)
    const {
        RealDynList,
        packageList,
        filteredCount,
        totalPackageCount,
        inputError,
        alert,
        error,
        loading
    } = state.TraceabilityRole;

    return {
        RealDynList,
        packageList,
        filteredCount,
        totalPackageCount,
        inputError,
        alert,
        error,
        loading
    };
};

export default withRouter(connect(mapStateToProps, {
    getRealDynList,
    clearInputError,
    clearAlert,
    apiError,
})(Roles));
