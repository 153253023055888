import React, {Component, useState} from "react";

import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {AvForm, AvField} from "availity-reactstrap-validation";
import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table, InputGroup} from "reactstrap";

import {Modal, Collapse} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import {format} from "date-fns";

import AntdPagination from '../../components/AntdPagination'

import {Pagination, ConfigProvider,DatePicker,Tooltip} from 'antd';

// actions
import {
    getProductInfo,
    getProductList,
    getProductImage,
    updateProductInfo,
    updateProductSystemStatus,
    createProduct,
    updateInputValue,
    clearProductImageIdProp,
    clearInputError,
    clearAlert,
    clearProductProps
} from "../../store/actions";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";

import i18n from '../../i18n';
import {Translation} from 'react-i18next';


import CSVHandler from "../../components/CSVDownloader/CSVHandler"
import zhCN from "antd/lib/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import zhHK from "antd/lib/locale/zh_HK"


import commonUtils from '../../helpers/commonUtils'
const nowDate = commonUtils.getNowFormatDate();

const optionStatus = [
    {label: i18n.t('all'), value: ''},
    {label: i18n.t('enabled'), value: 'enabled'},
    {label: i18n.t('disabled'), value: 'disabled'},
];

const optionPageSize = [
    {label: 10, value: 10},
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
];

const entryStr = (pageNumber, pageSize, filteredCount, totalCount) => {
    let to = (pageNumber + 1) * pageSize;
    if (to > filteredCount) {
        to = filteredCount;
    }

    let from = pageNumber * pageSize + 1;
    if (from > to) {
        from = to;
    }

    return <>{i18n.t('table_count', {from: from, to: to, filter: filteredCount, total: totalCount})}</>;
}

const getSortingComponent = (value, sorting) => {
    console.log('getSortingComponent', value, sorting.column);

    let newComponent;

    if (value == sorting.column) {
        switch (sorting.order) {
            case '':
                return <i className="typcn typcn-media-record"/>;
                break;

            case 'asc':
                return <i className="ion ion-ios-arrow-up"/>;
                break;

            case 'desc':
                return <i className="ion ion-ios-arrow-down"/>;
                break;
        }
    } else {
        return <i className="typcn typcn-media-record"/>;
    }
}

const ProductTableRow = (props) => {
    const dataList = props.data;
    // const dataList = [];
    const toggleModalUpdateStatus = props.toggleModalUpdateStatus;
    const toggleModalImage = props.toggleModalImage;

    let startRowNo = props.pageNumber * props.pageSize + 1;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td><span className="float-right">{startRowNo++}</span></td>
            <td>{data.name}</td>
            <td><span className="float-right">{data.productId}</span></td>
            <td>{data.productionAddress}</td>
            <td>{data.createdAt}</td>
            
            <td><span className="float-right">{data.totalUnit}</span></td>
            <td>
                <div className="nowrap table_td_space_around">
                    
                    <Link to={`/distribution/product/${data.productId}`}>
                        <span color="success" className='text-blue'
                                style={{width: '100px', textAlign: 'center', display: 'inline-block'}}>
                            {i18n.t('check')}
                        </span>
                    </Link>
               </div>

            </td>

        </tr>
    );

    return (<tbody>{listItems}</tbody>);
};

class ViewProduct extends Component {
    constructor(props) {
        super(props);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleFilterVisibility = this.handleFilterVisibility.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.toggleModalUpdateStatus = this.toggleModalUpdateStatus.bind(this);
        this.toggleModalImage = this.toggleModalImage.bind(this);
        this.handler2 = this.handler2.bind(this);
        this.handleAntdPageChange = this.handleAntdPageChange.bind(this);
        this.handleAntdPageSizeChange = this.handleAntdPageSizeChange.bind(this);
        this.openPreviewModal = this.openPreviewModal.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
        this.state = {
            isModalOpen: false,
            filterVisibility: false,
            filter: {
                productId: '',
                name: '',
                systemStatus: '',
                startTime: undefined,
                endTime: undefined,
            },
            // productTable: [
            //   {
            //     id: 1,
            //     name: 'Product 1',
            //     description: 'Product 1',
            //     name: 'Product 1 Description',
            //     productionAddress: 'Product 1 Production Address',
            //     totalUnit: 50,
            //     systemStatus: 'enabled',
            //     productImages: []
            //   },
            //   {
            //     id: 1,
            //     name: 'Product 2',
            //     description: 'Product 2',
            //     name: 'Product 2 Description',
            //     productionAddress: 'Product 2 Production Address',
            //     totalUnit: 99,
            //     systemStatus: 'disabled',
            //     productImages: [
            //       'test link'
            //     ]
            //   }
            // ],
            componentDidUpdateNum: 0,
            pageNumber: 0,
            pageSize: 10,
            filteredCount: 0,
            pageCount: 0,
            sorting: {
                column: 'id',
                order: 'desc',
            },
            modalProductId: undefined,
            modalUpdateStatus: {
                toggle: false,
                status: undefined,
            },
            modalImage: {
                toggle: false,
            },
            lightboxImages: [],
            photoIndex: 0,
            isGalleryZoom: false,
            lang:{
                lang:zhCN,
                locale:"zh",
            },
            roleId:""
            
        };
    }

    handler2 = CSVHandler.viewProduct.allDownloadHandler

    componentDidMount() {
        console.log('componentDidMount');

        this.props.clearProductProps();
        this.props.clearAlert('success');

        let roleId= sessionStorage.getItem('roleId');
        this.setState({
             roleId:roleId
        });

        this.handleFormSubmit();

        
    }

    componentDidUpdate() {
        document.title = i18n.t('view_product');
        console.log('componentDidUpdate', this.props.filteredCount);

        this.state.componentDidUpdateNum += 1;

        let pageCount = Math.ceil(this.props.filteredCount / this.state.pageSize);

        console.log('testing update page count', this.state.pageCount, pageCount);

        if (this.state.pageCount !== pageCount) {
            if (pageCount <= this.state.pageNumber) {
                this.setState({pageCount: pageCount, pageNumber: 0}, () => this.handleFormSubmit());
            } else {
                this.setState({pageCount: pageCount});
            }
        }

        let lang= sessionStorage.getItem("lang");
        if(this.state.lang.locale !==lang){
            if(lang ==="en"){
                this.setState({lang:{lang:enUS,
                    locale:lang
                }});
            }
            else if(lang ==="zh"){
                this.setState({lang:{
                    lang:zhCN,
                    locale:lang}});
            }
            else{
                this.setState({lang:{
                    lang:zhHK,
                    locale:lang}});
            }
        }
       
    }


    renderAntdPagination(totalProductCount) {
        console.log('totalProductCount=====>>>>', totalProductCount);
        if (totalProductCount > 0) {
            return (<AntdPagination total={totalProductCount} antdPageChange={this.handleAntdPageChange}
                                    antdPageSizeChange={this.handleAntdPageSizeChange}/>)
        } else {
            return null
        }
    }


    handleFilterDateChange(dateInput, value) {
        console.log('handleFilterDateChange', dateInput, value);

        let dateInputValue = value === null ? undefined : value;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [dateInput]: dateInputValue,
            },
        }));
    }


    

    handleFilterInputChange(event) {
        console.log('handleFilterInputChange', event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));

        console.log('after', this.state);
    }

    handleFilterStatusChange(event) {
        console.log('handleFilterStatusChange', event);

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                systemStatus: event.value,
            },
        }));
    }

    handlePageSizeChange(event) {
        console.log('handlePageSizeChange', event);

        this.setState({
                pageSize: event.value,
                pageNumber: 0,
            },
            () => this.handleFormSubmit());
    }

    handleSorting(value) {
        console.log('handleSorting', value, this.state.sorting.column);

        let newOrder;

        if (value == this.state.sorting.column) {
            switch (this.state.sorting.order) {
                case '':
                    newOrder = 'asc';
                    break;

                case 'asc':
                    newOrder = 'desc';
                    break;

                case 'desc':
                    newOrder = '';
                    break;
            }
        } else {
            newOrder = 'asc';
        }

        this.setState(prevState => ({
                sorting: {
                    column: value,
                    order: newOrder,
                }
            }),
            () => this.handleFormSubmit());
    }

    handlePageClick(event) {
        console.log('handlePageClick', event);

        this.setState(
            prevState => ({
                pageNumber: event.selected,
            }),
            () => this.handleFormSubmit()
        );

        return false;
    }

    openPreviewModal(b, data) {
        this.setState({
            isModalOpen: b
        })
    }

    // 改变页码
    handleAntdPageChange(page, pageSize) {
        this.setState({
                pageSize: pageSize,
                pageNumber: page - 1,
            },
            () => this.handleFormSubmit()
        );
    }

    // 改变每页显示条数
    handleAntdPageSizeChange(current, size) {
        this.setState({
                pageSize: size,
                pageNumber: 0,
            },
            () => this.handleFormSubmit()
        );
    }


    handleClearFilter() {
        console.log('handleClearFilter()');

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                productId: '',
                name: '',
                systemStatus: '',
                startTime: undefined,
                endTime: undefined,
            },
        }),()=>{
            this.handleFormSubmit()
        });
    }

    handleFilterVisibility() {
        console.log('handleFilterVisibility()');

        this.setState(prevState => ({
            filterVisibility: !prevState.filterVisibility
        }));
    }

    handleFormSubmit(event, values) {
        let pageNumber = this.state.pageNumber;
        // if(event !== undefined) {
        //   pageNumber = this.state.pageNumber
        // } else {
        //   this.setState({ pageNumber: 0 });
        //   pageNumber = 0;
        // }

        let submitData = {
            // ...values,
            productId: this.state.filter.productId,
            name: this.state.filter.name,
            systemStatus: this.state.filter.systemStatus,
            startTime: this.state.filter.startTime !== undefined ? format(new Date(this.state.filter.startTime), 'yyyy-MM-dd 00:00:00') : undefined,
            endTime: this.state.filter.endTime !== undefined ? format(new Date(this.state.filter.endTime), 'yyyy-MM-dd 23:59:59') : undefined,
            pageNumber: pageNumber + 1,
            pageSize: this.state.pageSize,
            sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
        };
        console.log('on handleFormSubmit()', event, submitData);

        this.props.getProductList(submitData, this.props.history);
    }


    /**
     * modal
     */
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleModalUpdateStatus(productId = undefined, newStatus = undefined) {
        console.log('toggleModalUpdateStatus()', productId, newStatus);
        this.setState(prevState => ({
            modalProductId: productId,
            modalUpdateStatus: {
                ...prevState.modalUpdateStatus,
                toggle: !prevState.modalUpdateStatus.toggle,
                status: newStatus,
            },
        }));
        this.removeBodyCss();
    }

    toggleModalImage(productId = undefined, productImages = []) {
        console.log('toggleModalImage()', productId, productImages);
        this.setState(prevState => ({
            modalProductId: productId,
            modalImage: {
                ...prevState.modalImage,
                toggle: !prevState.modalImage.toggle,
            },
        }));
        this.removeBodyCss();

        // if (productId !== undefined && productImages.length !== 0 && this.props.productImages[productId] === undefined) {
        //   this.props.getProductImage(productId, productImages, this.props.history);
        // }
    }


    render() {
        const {lightboxImages} = this.state;

        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {/*{this.props.loading ? <Loader/> : null}*/}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            
                                                {
                                                   
                                                   <ol className="breadcrumb mb-0">
                                                    <li className="breadcrumb-item font-bold-size">{t('scan_record')}</li>
                                                    <li className="breadcrumb-item sub-tile-color"><Link
                                                        >{t('scan_distribution')}</Link>
                                                    <Tooltip title={t('code_distribution_tips')}><i className="ti-help-alt help-margin"></i></Tooltip></li>
                                                    </ol>
                                                }

                                                {/* {
                                                   this.state.roleId ==='3' &&
                                                   <ol className="breadcrumb mb-0">
                                                    <li className="breadcrumb-item font-bold-size">{t('scan_distribution')}</li>
                                                    </ol>
                                                } */}
                                            
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                        <button
                                                            onClick={this.handleFilterVisibility}
                                                            className="btn btn-primary mo-mb-2 btn-primary-bg"
                                                            type="button"
                                                        >
                                                            {this.state.filterVisibility === true ? t('hide_filter') : t('show_filter')}
                                                        </button>
                                                        {/* <CSV path={"viewProduct"}
                                                             curfilename={'商品第'+ (parseInt(this.state.pageNumber+1)) +'页记录'+ nowDate + '.csv'}
                                                             allfilename={'商品全部记录'+ nowDate + '.csv'}
                                                             handler1={CSVHandler.viewProduct.pageDownloadHandler}
                                                             parser={CSVHandler.viewProduct.parser}
                                                             handler2={this.handler2}/> */}
                                                        {/* <CSV pageTitle="ViewProduct" pageDownloadHandler={CSVHandler.viewProduct.pageDownloadHandler} allDownloadHandler={CSVHandler.viewProduct.allDownloadHandler}></CSV> */}
                                                    </Col>
                                                </Row>

                                                <Collapse isOpen={this.state.filterVisibility}>
                                                    <AvForm className="mb-3" onValidSubmit={this.handleFormSubmit}>
                                                        <Row className='InfoContainer'>
                                                            {/* <Col sm={2}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="productId"
                                                                        label="ID"
                                                                        value={this.state.filter.productId}
                                                                        onChange={this.handleFilterInputChange}
                                                                        validate={{
                                                                            pattern: {value: '^[1-9]\\d*$', errorMessage: '请输入数字'},
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col> */}

                                                            <Col sm={2}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="name"
                                                                        label={t('name')}
                                                                        value={this.state.filter.name}
                                                                        onChange={this.handleFilterInputChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* <Col sm={2}>
                                                                <FormGroup>
                                                                    <Label>{t('status')}</Label>
                                                                    <Select
                                                                        name="status"
                                                                        value={optionStatus.find(option => option.value === this.state.filter.systemStatus)}
                                                                        onChange={this.handleFilterStatusChange}
                                                                        options={optionStatus}
                                                                        isSearchable={false}
                                                                        menuPortalTarget={document.body}
                                                                    />
                                                                </FormGroup>
                                                            </Col> */}

                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('product_date_from')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                    value={this.state.filter.startTime}
                                                                                    onChange={(moment,string) => this.handleFilterDateChange('startTime', moment)}
                                                                                />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('product_date_to')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                    value={this.state.filter.endTime}
                                                                                    onChange={(moment,string) => this.handleFilterDateChange('endTime', moment)}
                                                                                />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Button color="primary"
                                                                            className="mr-1 btn-primary-bg">{t('apply')}</Button>
                                                                    <Button color="secondary"
                                                                            onClick={this.handleClearFilter}>{t('clear')}</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                </Collapse>

                                                <Row>
                                                    <Col sm={12}>
                                                        <p className="text-muted m-b-30">
                                                            {entryStr(this.state.pageNumber, this.state.pageSize, this.props.filteredCount, this.props.totalProductCount)}
                                                        </p>
                                                    </Col>
                                                </Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table className="table  thead-nowrap">
                                                            <thead className="thead-header">
                                                            <tr>
                                                                <th className="w-1p"><span
                                                                    className="float-right">#</span></th>

                                                                <th onClick={() => this.handleSorting('name')}>{t('name')} {getSortingComponent('name', this.state.sorting)}</th>
                                                                <th className="w-1p"
                                                                    onClick={() => this.handleSorting('id')}><span
                                                                    className="float-right">{i18n.t("product_id")} {getSortingComponent('id', this.state.sorting)}</span>
                                                                </th>
                                                                {/* <th>{t('description')}</th> */}
                                                                <th>{t('production_address')}</th>
                                                                <th>{t('production_created')}</th>
                                                                
                                                                
                                                                <th><span
                                                                    className="float-right">{t('total_unit')}</span>
                                                                </th>
                                                                
                                                                <th className='w-2p'>{t('action')}</th>
                                                               
                                                            </tr>
                                                            </thead>
                                                            <ProductTableRow openPreviewModal={this.openPreviewModal}
                                                                             data={this.props.productList}
                                                                             toggleModalUpdateStatus={this.toggleModalUpdateStatus}
                                                                             toggleModalImage={this.toggleModalImage}
                                                                             pageNumber={this.state.pageNumber}
                                                                             pageSize={this.state.pageSize}/>
                                                            {/* </tbody> */}
                                                        </Table>
                                                    </div>
                                                </div>

                                                <Row className='antd-pagination'>
                                                    {
                                                        this.props.filteredCount > 0 ? <Col>
                                                            <ConfigProvider locale={this.state.lang.lang}>
                                                                <Pagination
                                                                    total={this.props.filteredCount}
                                                                    showSizeChanger
                                                                    showQuickJumper
                                                                    onChange={this.handleAntdPageChange}
                                                                    onShowSizeChange={this.handleAntdPageSizeChange}
                                                                    showTotal={total => t('table_nav_count', {total: total})}
                                                                />
                                                            </ConfigProvider>
                                                        </Col> : null
                                                    }

                                                </Row>

                                                {this.props.alert.success ? (
                                                    <SweetAlert
                                                        title={t('product_status_updated')}
                                                        success
                                                        confirmBtnBsStyle="success"
                                                        onConfirm={() => {
                                                            this.props.clearAlert('success');
                                                            this.toggleModalUpdateStatus();
                                                            this.handleFormSubmit();
                                                        }}
                                                    >
                                                    </SweetAlert>
                                                ) : null}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                       
                            {/* modal */}

                            {/* modal update status */}
                            <Modal
                                className="modal-sm"
                                isOpen={this.state.modalUpdateStatus.toggle}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleModalUpdateStatus()}
                                >
                                    {t('update_status')}
                                </ModalHeader>
                                <ModalBody>
                                    {(this.state.modalUpdateStatus.status === 'enabled') && <>
                                        <del className="text-danger">{t('disabled')}</del>
                                        <i className="ti-arrow-right ml-2 mr-2"></i><span
                                        className="text-success">{t('enabled')}</span></>}
                                    {(this.state.modalUpdateStatus.status === 'disabled') && <>
                                        <del className="text-success">{t('enabled')}</del>
                                        <i className="ti-arrow-right ml-2 mr-2"></i><span
                                        className="text-danger">{t('disabled')}</span></>}
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModalUpdateStatus()}
                                    >
                                        {t('close')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={() => this.props.updateProductSystemStatus({
                                            productId: this.state.modalProductId,
                                            systemStatus: this.state.modalUpdateStatus.status
                                        }, this.props.history)}
                                    >
                                        {t('confirm')}
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            {/* modal image */}
                            <Modal
                                className="modal-lg"
                                isOpen={this.state.modalImage.toggle}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleModalImage()}
                                >
                                    {t('images')}
                                </ModalHeader>
                                <ModalBody>
                                    <div className="zoom-gallery">
                                        {this.props.productImages[this.state.modalProductId] === undefined ? '' :
                                            this.props.productImages[this.state.modalProductId].map((f, i) => {
                                                let src = URL.createObjectURL(f);
                                                return (
                                                    <img
                                                        key={i}
                                                        src={src}
                                                        className="float-left"
                                                        onClick={() => {
                                                            // this.state.lightboxImagesthis.props.productImages[this.state.modalProductId][i]
                                                            this.setState({isGalleryZoom: true, lightboxImages: src})
                                                        }}
                                                        alt=""
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModalImage()}>
                                        {t('close')}
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            {this.state.isGalleryZoom ? (
                                <Lightbox
                                    mainSrc={lightboxImages}
                                    // nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
                                    // prevSrc={
                                    //   lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length]
                                    // }
                                    onCloseRequest={() =>
                                        this.setState({isGalleryZoom: false})
                                    }
                                    // onMovePrevRequest={() =>
                                    //   this.setState({
                                    //     photoIndex:
                                    //       (photoIndex + lightboxImages.length - 1) % lightboxImages.length
                                    //   })
                                    // }
                                    // onMoveNextRequest={() =>
                                    //   this.setState({
                                    //     photoIndex: (photoIndex + 1) % lightboxImages.length
                                    //   })
                                    // }
                                />
                            ) : null}
                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {

    return {
        productList:state.FormProductInfo.productList,
        productImages:state.FormProductInfo.productImages,
        filteredCount:state.FormProductInfo.filteredCount,
        totalProductCount:state.FormProductInfo.totalProductCount,
        alert:state.FormProductInfo.alert,
        error:state.FormProductInfo.error,
        loading:state.FormProductInfo.loading,
        roleId:state.Login.roleId,
    };
};

export default withRouter(connect(mapStateToProps, {
    getProductInfo,
    getProductList,
    getProductImage,
    createProduct,
    updateProductInfo,
    updateProductSystemStatus,
    updateInputValue,
    clearProductImageIdProp,
    clearInputError,
    clearAlert,
    clearProductProps
})(ViewProduct));
