export const GET_REAL_DYN_LIST = 'GET_REAL_DYN_LIST';
export const REAL_DYN_LIS_SUCCESS = 'REAL_DYN_LIS_SUCCESS';
export const CLEAR_REAL_DYN = 'CLEAR_REAL_DYN';


export const GET_PRODUCT_IMAGE = 'GET_PRODUCT_IMAGE';
export const GET_PRODUCT_IMAGE_SUCCESS = 'GET_PRODUCT_IMAGE_SUCCESS';
export const CLEAR_PRODUCT_IMAGE_ID_PROP = 'CLEAR_PRODUCT_IMAGE_ID_PROP';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const UPDATE_PRODUCT_SYSTEM_STATUS = 'UPDATE_PRODUCT_SYSTEM_STATUS';
export const UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS = 'UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS';

export const CLEAR_PRODUCT_PROPS = 'CLEAR_PRODUCT_PROPS';

export const API_ERROR = 'PRODUCT_API_ERROR';
