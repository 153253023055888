import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// falgs
// import usFlag from "../assets/images/flags/us_flag.jpg";
// import chinaFlag from "../assets/images/flags/china_flag.png";
// import germanyFlag from "../assets/images/flags/germany_flag.jpg";
// import italyFlag from "../assets/images/flags/italy_flag.jpg";
// import frenchFlag from "../assets/images/flags/french_flag.jpg";
// import spainFlag from "../assets/images/flags/spain_flag.jpg";
// import russiaFlag from "../assets/images/flags/russia_flag.jpg";

import i18n from '../i18n';
import { Translation } from 'react-i18next';

import { setDefaultLocale } from "react-datepicker";

const usFlag = 'http://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/app/us_flag.jpg';
const chinaFlag = 'http://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/app/china_flag.png';


class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false
    };
    this.toggle = this.toggle.bind(this);
    this.needRefresh = this.needRefresh.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menuOpened: !prevState.menuOpened
    }));
  }

  componentDidMount() {
  
   let lang= sessionStorage.getItem("lang");
   if(lang ==null){
      lang ="zh"
      sessionStorage.setItem("lang", lang);
   }
   else if (lang === 'zh_CN'){
      lang ="zh"
      sessionStorage.setItem("lang", lang);
   }
   i18n.changeLanguage(lang)
   setDefaultLocale(lang);

  }

  needRefresh(){
    if(this.props.refresh){
      window.location.reload()
    }
  }
  
  render() {
    return (
      <Translation>
        {
          (t, { i18n }) =>
            <React.Fragment>
              <Dropdown
                isOpen={this.state.menuOpened}
                toggle={this.toggle}
                className="d-md-block ml-2 font-size-19-5"
              >
                {i18n.language === 'en' &&
                  <>
                    <DropdownToggle
                      className="btn header-item waves-effect font-size-19-5"
                      tag="button"
                    >
                      <img
                        className="mr-2"
                        src={usFlag}
                        alt="Header Language"
                        height="12"
                      />{" "}
                      English
                      <span className="mdi mdi-chevron-down"></span>
                    </DropdownToggle>
                    <DropdownMenu
                      className="language-switch font-size-19-5"
                      right
                     >
                        <DropdownItem tag="a" href="#" className="notify-item font-size-19-5"  onClick={(e) => {
                          i18n.changeLanguage('zh')
                          setDefaultLocale('zh');
                          sessionStorage.setItem("lang", 'zh');
                          this.needRefresh();
                         }} >
                          <img
                            src={chinaFlag}
                            alt="veltrix"
                            className="mr-1"
                            height="14"
                          />{" "}
                          <span className="align-middle"> 简体中文 </span>
                        </DropdownItem>
                        <DropdownItem tag="a" href="#" className="notify-item font-size-19-5"  onClick={(e) => {
                          i18n.changeLanguage('hk')
                          setDefaultLocale('hk');
                          sessionStorage.setItem("lang", 'hk');
                          this.needRefresh();
                         }} >
                          <img
                            src={chinaFlag}
                            alt="veltrix"
                            className="mr-1"
                            height="14"
                          />{" "}
                          <span className="align-middle"> 繁体中文 </span>
                        </DropdownItem>
                    </DropdownMenu>
                    
                  </>
                 }
                 {i18n.language === 'zh' &&
                  <>
                    <DropdownToggle
                      className="btn header-item waves-effect font-size-19-5"
                      tag="button"
                    >
                      <img
                        src={chinaFlag}
                        alt="veltrix"
                        className="mr-1"
                        height="14"
                      />{" "}
                      简体中文
                      <span className="mdi mdi-chevron-down"></span>
                    </DropdownToggle>

                    <DropdownMenu
                      className="language-switch font-size-19-5"
                      right
                     
                    >
                        <DropdownItem tag="a" href="#" className="notify-item font-size-19-5"
                         onClick={() => {
                          i18n.changeLanguage('en')
                          setDefaultLocale('en');
                          sessionStorage.setItem("lang", 'en');
                          this.needRefresh();
                        }}
                        >
                          <img
                            className="mr-2"
                            src={usFlag}
                            alt="Header Language"
                            height="12"
                          />{" "}
                          <span className="align-middle"> English </span>
                        </DropdownItem>
                        <DropdownItem tag="a" href="#" className="notify-item font-size-19-5"  onClick={(e) => {
                          i18n.changeLanguage('hk')
                          setDefaultLocale('hk');
                          sessionStorage.setItem("lang", 'hk');
                          this.needRefresh();
                         }} >
                          <img
                            src={chinaFlag}
                            alt="veltrix"
                            className="mr-1"
                            height="14"
                          />{" "}
                          <span className="align-middle"> 繁体中文 </span>
                        </DropdownItem>


                    </DropdownMenu>
                  </>
                 }
                 {i18n.language === 'hk' &&
                  <>
                    <DropdownToggle
                      className="btn header-item waves-effect font-size-19-5"
                      tag="button"
                    >
                      <img
                        src={chinaFlag}
                        alt="veltrix"
                        className="mr-1"
                        height="14"
                      />{" "}
                      繁体中文
                      <span className="mdi mdi-chevron-down"></span>
                    </DropdownToggle>

                    <DropdownMenu
                      className="language-switch font-size-19-5"
                      right
                     
                    >
                        <DropdownItem tag="a" href="#" className="notify-item font-size-19-5"
                         onClick={() => {
                          i18n.changeLanguage('en')
                          setDefaultLocale('en');
                          sessionStorage.setItem("lang", 'en');
                          this.needRefresh();
                        }}
                        >
                          <img
                            className="mr-2"
                            src={usFlag}
                            alt="Header Language"
                            height="12"
                          />{" "}
                          <span className="align-middle"> English </span>
                        </DropdownItem>
                        <DropdownItem tag="a" href="#" className="notify-item font-size-19-5"  onClick={(e) => {
                          i18n.changeLanguage('zh')
                          setDefaultLocale('zh');
                          sessionStorage.setItem("lang", 'zh');
                          this.needRefresh();
                         }} >
                          <img
                            src={chinaFlag}
                            alt="veltrix"
                            className="mr-1"
                            height="14"
                          />{" "}
                          <span className="align-middle"> 简体中文 </span>
                        </DropdownItem>


                    </DropdownMenu>
                  </>
                 }
              

              </Dropdown>
            </React.Fragment>
        }
      </Translation>
    );
  }
}

export default LanguageDropdown;
