import React, {Component} from "react";
import {Tooltip, Cascader} from 'antd';
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";

import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table, InputGroup} from "reactstrap";

import Select from "react-select";

import axios from "axios";
import {parseISO} from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

import {Modal, Collapse} from "reactstrap";

import {format} from "date-fns";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";

import i18n from '../../i18n';
import {Translation} from 'react-i18next';

// actions
import {
    getProductList,
    getDistributionDetailByProduct,
    getDistributionList,
    getDistributionDetail,
    clearInputError,
    clearAlert,
    apiError,
} from "../../store/distribute/actions";

import zhCN from "antd/lib/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import zhHK from "antd/lib/locale/zh_HK";

import commonUtils from '../../helpers/commonUtils'

import { AreaMap} from '@ant-design/maps';

const nowDate = commonUtils.getNowFormatDate();
// console.log('当天时间==' + getNowFormatDate());

const currentStatus = 'completed';


// 0 所有信息（包含已激活，未激活，已过期） 1 已激活未过期 2 未激活 3 已激活已经过期




class DistributeMap extends Component {
    constructor(props) {
        super(props);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
    
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
      
   
        this.getCurrentTime = this.getCurrentTime.bind(this);
        this.handleNodesSelectChange = this.handleNodesSelectChange.bind(this);

        


       
        this.state = {
            currentTime: 0,
            pageNumber: 0,
            pageSize: 10,
            filteredCount: 0,
            pageCount: 0,
            totals: 0,
            sorting: {
                column: 'packageId',
                order: 'desc',
            },
            modalPackageId: undefined,
            modalShipPackage: {
                toggle: false,
                // packageId: [],
            },
            modalShippingInfo: {
                toggle: false,
                // updateMode: '',
                shipmentCourierRefNo: '',
                shippingInfo: [],
                // packageId: [],
            },
            modalStatusChangeLog: {
                toggle: false,
                statusChangeLog: [],
            },
            productName :'',
            address:{},
            lang:{
                lang:zhCN,
                locale:"zh",
            },
            mapData:{
                type: 'FeatureCollection', 
                features: [] ,
            },
            config:{
                map: {
                  type: 'mapbox',
                  style: 'blank',
                  center: [120.19382669582967, 30.258134],
                  zoom: 3,
                  pitch: 0,
                },
                source: {
                  data:{
                    type: 'FeatureCollection', 
                    features: [] ,
                  } ,
                  parser: {
                    type: 'geojson',
                  },
                },
                autoFit: true,
                color: {
                  field: 'code',
                  value:this.colorSegmentation,// ['#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15'],
                // value: ['#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15'],
                  scale: {
                    type: 'power',
                  },
                },
                style: {
                  opacity: 1,
                  stroke: '#fff',
                  lineWidth: 0.6,
                  lineOpacity: 1,
                },
                state: {
                  active: true,
                },
                label: {
                  visible: true,
                  field: 'name',
                  style: {
                    fill: '#000',
                    opacity: 0.8,
                    fontSize: 10,
                    stroke: '#fff',
                    strokeWidth: 1.5,
                    textAllowOverlap: false,
                    padding: [8, 8],
                  },
                },
                tooltip: {
                  items: ['城市','数量'],
                },
                zoom: {
                  position: 'bottomright',
                },
                legend: {
                  position: 'bottomleft',
                  items:[{ id:"1",
                  color:"#fee5d9",
                  value:" 0"
                      
                  },
                    {
                        id:"2",
                        color:"#fcae91",
                        value:"1-4"
                        
                    },
                    {
                        id:"2",
                        color:"#fb6a4a",
                        value:"5-8"
                        
                    },
                    {
                        id:"3",
                        color:"#de2d26",
                        value:" >8"
                        
                    }]
                },
            },
            productSelect:{
                productNodes:[],
                currentValue:"",
            },
            productSelectRead:false,
            Disabled:false,
            Locations:[],
            pulling:false,
            
            
        };
    }

    componentDidMount() {
        console.log('componentDidMount');
        // this.handleFormSubmit();
        this.state.config.source.data=this.state.mapData;
      
        if(this.props.productList.length==0){
            this.props.getProductList();
        }
        this.init();
        this.getDistributionDetailByProduct(0);
        
    }

    init(){

        let optionStatus =[];
          optionStatus.unshift({label:i18n.t("empty"),
          value:-1,
         })
  
         this.setState(prevState =>({
          productSelect:{
          ...prevState.productSelect,
            productNodes:optionStatus,
            currentValue:optionStatus[0],
          }}));
        
      }
    
    componentDidUpdate(prevProps,prevState) {
       
            //const { Locations } = this.props.Locations;
            // if (prevProps && JSON.stringify(prevProps.Locations) !== JSON.stringify(this.props.Locations)) {
            //    //this.renderChart(this.props);
            //    this.freshDdata();
            // }
            //this.props.loading
           // if ( this.state.Locations.length==0 && this.props.Locations.length > 0) {
            if ( !this.props.loading &&  this.state.pulling ) {
                //this.renderChart(this.props);
                this.state.pulling =false;
                this.storeLocations();
             }
           // if(this.props.productList.length >0 && this.state.productSelect.productNodes.length==0){
            if(this.props.productList.length >0 && !this.state.productSelectRead){
           
                this.parseProductList(this.props.productList);
            }
    }

    asyncFetch(){

        fetch('https://gw.alipayobjects.com/os/bmw-prod/707cd4be-8ffe-4778-b863-3335eefd5fd5.json')
        .then((response) => response.json())
        .then((json) => {
           // setData(json)
           let config={...this.state.config};
           config.source.data =json;
           this.freshDdata();
        //    this.clearLocations(config.source.data);
        //    this.setState({
        //     config:config,
        //    });
         
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });

    }

    storeLocations(){

        this.state.Locations=  this.props.Locations;
        this.asyncFetch();
    }
    freshLocations(citys,name,val){

        let features=citys.features;
        for (let index=0; index < features.length; index++) {
            const elem = features[index];
            //console.log(index, elem);
            if(elem.properties){
                //elem.properties.code="";
                if(elem.properties.name===name){
                     elem.properties.code=val;
                     elem.properties.数量=val;
                    break;
                }
            }
           }

    }

    freshDdata(){

        let config={...this.state.config};
        let leng=this.state.Locations.length;
        this.clearLocations(config.source.data);
        for (let index=0; index < leng; index++) {
            const elem = this.state.Locations[index];
            //console.log(index, elem);
              if(elem.city){
                  this.freshLocations(config.source.data,elem.city,elem.number);
              }
           }

        // if(config.tooltip.items.indexOf("数量")==-1){
        //     config.tooltip.items.push("数量");
        //     // config.color.field="数量";
        // }
          
       
        // config.source.data =json;
        // this.clearLocations2(config.source.data);
        this.setState({
          config:config,
        } );


      
    }

   
    
    clearLocations2(citys){
       let features=citys.features;
   
        for (let index=0; index < features.length; index++) {
            const elem = features[index];
            //console.log(index, elem);
            if(elem.properties){
                 elem.properties.code=10000*index;
            }
        }
    //    return citys;
    }

    clearLocations(citys){
       let features=citys.features;
   
        for (let index=0; index < features.length; index++) {
            const elem = features[index];
            //console.log(index, elem);
            if(elem.properties){
                 elem.properties.code="0";
                 elem.properties.城市=elem.properties.name
            }
        }
    //    return citys;
    }




    parseProductList(list){

        let optionStatus =[];
  
        list.forEach(function (element, index, array) {
            optionStatus.push({label:element.name,
                value:element.productId,
            })
  
        });
  
  
  
        if(optionStatus.length >0){
 
           optionStatus.unshift({label:i18n.t("inventory_all"),
              value:0,
             })
            
            this.setState(prevState =>({
              productSelect:{
              ...prevState.productSelect,
              productNodes:optionStatus,
              currentValue:optionStatus[0],
              },
              productSelectRead:true,
          }));
        //   this.getProductInventory(0);
        }
        else{
            optionStatus.unshift({label:i18n.t("empty"),
              value:-1,
             })
            this.setState(prevState =>({
                productSelect:{
                ...prevState.productSelect,
                productNodes:optionStatus,
                currentValue:null,
                }
            }));
        }
  
    }

    handleNodesSelectChange(event) {
      
        if(event.value ==-1)
           return;
        this.setState(prevState =>({
          productSelect:{
            ...prevState.productSelect,
            currentValue:event,
             }
        }));
       this.getDistributionDetailByProduct(event.value);
      }
    getCurrentTime() {
        axios.get("https://worldtimeapi.org/api/ip")
            .then((res) => {
                let currentTime = new Date();
                if (res.data) {
                    currentTime = res.data.datetime ? parseISO(res.data.datetime) : currentTime;
                }
                this.setState({currentTime: currentTime})
                console.log("update current time")
            })
            .catch((err) => {
                let currentTime = new Date();
                this.setState({currentTime: currentTime})
            })

    }



    handleFilterStatusChange(event) {
        // console.log('handleFilterStatusChange', event);

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                status: event.value,
            },
        }));
    }


    handleFormSubmit(event, values) {
        let pageNumber = this.state.pageNumber;
        let submitData = {
          //  status: this.state.filter.status,
         //   startTime: this.state.filter.startTime !== undefined ? format(new Date(this.state.filter.startTime), 'yyyy-MM-dd HH:mm:ss') : undefined,
         //   expiredTime: this.state.filter.expiredTime !== undefined ? format(new Date(this.state.filter.expiredTime), 'yyyy-MM-dd HH:mm:ss') : undefined,
            pageNumber: pageNumber + 1,
            pageSize: this.state.pageSize,
         //   sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
        };

        console.log('on handleFormSubmit()', event, values, submitData);
        this.props.getDistributionList(submitData, this.props.history);
    }

    getDistributionDetailByProduct(productId) {
        let start=commonUtils.addMonths(new Date(), -1);
        let end = new Date();
        this.state.Locations=[];
        let submitData = {
            productId:productId,
            startTime: end  ? format(new Date(start), 'yyyy-MM-dd 00:00:00') : undefined,
            expiredTime: end  ? format(new Date(end), 'yyyy-MM-dd 00:00:00') : undefined,
        };
        this.state.pulling=true;
        this.props.getDistributionDetailByProduct(submitData, this.props.history);
    }
   
    /**
     * modal
     */
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    colorSegmentation( col ) {

        let val =parseInt(col.code);
        if(val == 0 ){
           return '#fee5d9';
        }
        else if(val >= 1 && val<4){

            return '#fcae91';
        }
        else if(val >=4  && val<8){
            return '#fb6a4a';
        }
        else if(val >=8 ){
            return '#de2d26';
        }
        
    }

    onReady(chart){
        console.log(chart)
        let op= chart.getLegendOptions();
        console.log(op)
    }
    render() {
        const {photoIndex} = this.state;

        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {this.props.loading ? <Loader /> : null}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            <ol className="breadcrumb mb-0">
                                                <li className="breadcrumb-item font-bold-size">{t('scan_record')}</li>
                                                <li className="breadcrumb-item sub-tile-color"><Link
                                                     to="#">{t('code_dis_map')}</Link>
                                                    {/* <Tooltip title={t('code_moniter_tips')}><i className="ti-help-alt help-margin"></i></Tooltip> */}
                                                    </li>
                                            </ol>
                                        </div>
                                    </Col>
                                </Row>

                                {/* <div  */}
                                <Row style={{marginTop: '1.0rem', marginLeft:'0.01rem',marginBottom: '1.0rem'}}>
                                    <Col sm={0.8} style={{lineHeight: '34px',  textAlign:'left'}}>
                                        <i className="mdi"></i>{t('product_name')}
                                        
                                    </Col>
                                    <Col sm={3}>
                                        <Select
                                                name="pageSize"
                                                isDisabled={this.state.Disabled}
                                                value={this.state.productSelect.currentValue}
                                                onChange={this.handleNodesSelectChange}
                                                options={this.state.productSelect.productNodes}
                                                isSearchable={false}
                                                // menuPortalTarget={document.body}
                                                // className="block-inline-status"
                                            />
                                    </Col>
                                </Row>
                                <div>
                                       <Card className="mapzone" style={{zIndex:"0"}}>
                                             {/* <CardBody style={{height:"500px", width:"500px"}}> */}
                                                <AreaMap {...this.state.config }  onReady={
                                                    
                                                    this.onReady}  />
                                            {/* </CardBody> */}
                                        </Card>
                                     
                                </div>
                            </div> 

                            {this.props.alert.success ? (
                                <SweetAlert
                                    title={t('code_setting_title_success')}
                                    success
                                    confirmBtnBsStyle="success"
                                    onConfirm={() => {
                                        this.props.clearAlert('success');
                                        this.handleFormSubmit();
                                    }}
                                >
                                </SweetAlert>
                            ) : null}

                            {this.props.alert.error ? (
                                <SweetAlert
                                    title={this.props.alert.errorMessage}
                                    error
                                    confirmBtnBsStyle="error"
                                    onConfirm={() => {
                                        this.props.clearAlert('error');
                                    }}
                                >
                                    {this.props.alert.errorDescription}
                                </SweetAlert>
                            ) : null}


                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    console.log('mapStateToProps', state.Distribution)
    const {
        DistributionList,
        inputError,
        alert,
        error,
        loading,
        Locations,
        productInstanceCsv,
        
    } = state.Distribution;

    const {
        productList,
      } = state.FormProductInfo;
    return {
        DistributionList,
        inputError,
        alert,
        error,
        loading,
        Locations,
        productList,
        productInstanceCsv
    };
};

export default withRouter(connect(mapStateToProps, {
    getDistributionDetailByProduct,
    getDistributionList,
    getDistributionDetail,
    clearInputError,
    clearAlert,
    apiError,
    getProductList,
})(DistributeMap));
