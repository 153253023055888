import axios from 'axios';
import jwt from 'jwt-decode';

const Axios = axios.create({
  // baseURL: 'http://0.0.0.0:3000/api/v1',
  baseURL: process.env.REACT_APP_API_URL_PROD,

  timeout: 30000,
});

const getQuery = (data) => {
  // console.log('getQuery', data);
  if(data === undefined || data.length === 0) {
    return '';
  }

  const ret = [];

  for(let d in data) {
    if(Array.isArray(data[d])) {
      for(let tmp of data[d]) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(tmp));
      }
    } else if(data[d] !== undefined && data[d] !== '') {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
  }
  return '?' + ret.join('&');
};

const getData = (payload = {}, extraData = {}) => {
  // console.log('getData', payload);
  let formData = new FormData();

  const data = {
    ...payload,
    ...extraData,
  };

  // console.log('data', data);

  for(const [key, value] of Object.entries(data)) {
    if(key !== "onStart" && key !== "onFinish" && key !== "onSuccess" && key !== "onFailed" && key !== "authToken") {
      // console.log('form data', key, value);
      if(key === 'deleteProductImageId') {
        if(Array.isArray(value)) {
          for(let index in value) {
            formData.append(`${key}[${index}]`, value[index]);
          }
        }
      }else if(Array.isArray(value)) {
        for(let index in value) {
          // console.log('testing value index', index, value, value[index]);
          if(value[index] instanceof Object && !(typeof value[index].name == 'string')) {
            // console.log('testing index', index, value[index]);
            for(const [k, v] of Object.entries(value[index])) {
              // console.log('kv', k, v);
              formData.append(`${key}[${index}][${k}]`, v);
            }
          } else {
            formData.append(key, value[index]);
          }
        }
      } else {
        formData.append(key, value);
      }
    }
  }

  // console.log(formData);

  return formData;
}

const getConfig = (payload, extraConfig = {}) => {
    // console.log('getConfig', payload);

    const authToken = payload.authToken ? payload.authToken : null;
    // const lang = payload.lang ? payload.lang : ();

    let lang;

    if(payload.lang) {
      lang = payload.lang;
    } else {
      if(!sessionStorage.getItem("lang")) {
        lang = 'zh';
      } else {
        lang = sessionStorage.getItem("lang");
        //Compatible with Wechat 
        if(lang === 'zh_CN'){
          lang ='zh';
          sessionStorage.setItem("lang","zh");
        }
      }
    }


    return {
      headers: {
        "Authorization": authToken,
        "Accept-Language": lang,
        // "Content-Type":"application/x-www-form-urlencoded",
        ...payload ? payload.headers : {},
      },
      params: {
        ...payload.params || {}
      },
      ...extraConfig
    }
}

function getAuthenticatedUser() {
  // console.log('getAuthenticatedUser()', sessionStorage.getItem("accessToken"));
  if(!sessionStorage.getItem("accessToken")) return null;

  return sessionStorage.getItem("accessToken");
}

function getAuthenticatedUsername() {
  if(!sessionStorage.getItem("accessToken")) return '';

  let token = sessionStorage.getItem("accessToken");
  token = token.replace('Bearer ', '');
  token = jwt(token);
  // console.log('testing jwt', token);

  return token.data.username;
}


function verifyLogin(payload) {
  console.log('API verify login', payload);
  //return Axios.post('/admin-user/access-token', getData(payload), getConfig(payload));
  return Axios.post('/admin-user/verifyLoginByUsername',getData(payload), getConfig(payload));
}


function login(payload) {
  // console.log('API login user', payload);
  //return Axios.post('/admin-user/access-token', getData(payload), getConfig(payload));
  return Axios.post('/admin-user/access-token',getData(payload), getConfig(payload));
}

function logout() {
  // console.log('API logout user');

  let token = sessionStorage.getItem("accessToken");
  token = token.replace('Bearer ', '');

  console.log(token);

  return Axios.delete('/admin-user/access-token/' + token);
}

function register(payload) {
  // console.log('API login user', payload);
  // return Axios.post('/admin-user', getData(payload), getConfig(payload));
  return Axios.post('/admin-user', getData(payload));
}


function getCompanyInfo(payload) {
  // console.log('API get company info', getConfig(payload));
  return Axios.get('/company', getConfig(payload));
}

function getCompanyCertificate(payload) {
  // console.log('API get company certificate', getConfig(payload, { responseType: 'blob' }));
  return Axios.get('/company/certificate', getConfig(payload, { responseType: 'blob' }));
}

function updateCompanyInfo(payload) {
  // console.log('API update company info', getData(payload), getConfig(payload));
  return Axios.put('/company', getData(payload), getConfig(payload));
}

function createCompanyInfo(payload) {
  console.log('API create company info', getData(payload), getConfig(payload));
  //return Axios.post('/company', getData(payload), getConfig(payload, { responseType: 'blob' }));
  return Axios.post('/company',getData(payload), getConfig(payload));
}


/**
 * product
 */
function getProductInfo(payload) {
  // console.log('API get product info', payload, getConfig(payload));
  return Axios.get(`/product/${payload.productId}`, getConfig(payload));
}



function getProductList(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product list', payload, urlQuery, getConfig(payload));

  return Axios.get(`/product${urlQuery}`, getConfig(payload));
}


function getProductInventory(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product list', payload, urlQuery, getConfig(payload));

  return Axios.get(`/getDashboard/${urlQuery}`, getConfig(payload));
}
function getProductImage(payload) {
  console.log('API get product image', payload.productId, payload.productImageId, getConfig(payload, { responseType: 'blob' }));
  return Axios.get(`/product/${payload.productId}/image/${payload.productImageId}`, getConfig(payload, { responseType: 'blob' }));
}

function createProduct(payload) {
  // console.log('API create product', getData(payload), getConfig(payload));
  return Axios.post('/product', getData(payload), getConfig(payload));
}

function updateProductInfo(payload) {
  // console.log('API update product info', getData(payload), getConfig(payload));
  return Axios.put('/product', getData(payload), getConfig(payload));
}

function updateProductSystemStatus(payload) {
  // console.log('API update product system status', getData(payload), getConfig(payload));
  return Axios.put('/product/system-status', getData(payload), getConfig(payload));
}



/**
 * product instance
 */
function getProductInstanceGroupedList(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product instance grouped list', payload, urlQuery, getConfig(payload));

  return Axios.get(`/product-instance-grouped${urlQuery}`, getConfig(payload));
}


function getProductInstanceList(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product instance list', 123, payload, 456, urlQuery, 789, getConfig(payload));

  return Axios.get(`/product-instance${urlQuery}`, getConfig(payload));
}

function addProductInstance(payload) {
  // console.log('API generate product instance csv', getData(payload), getConfig(payload), { responseType: 'blob' });
 // return Axios.post('/product-instance', getData(payload), getConfig(payload, { responseType: 'blob' }));
 return Axios.post('/product-instance', payload, getConfig(payload, { responseType: 'blob' }));

}



/**
 * package
 */
function getCompanyList(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  console.log('API get company list', payload, urlQuery, getConfig(payload));

  return Axios.get(`/getCompanyList${urlQuery}`, getConfig(payload));
}

function getPackageList(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get package list', payload, urlQuery, getConfig(payload));

  return Axios.get(`/package${urlQuery}`, getConfig(payload));
}

function updatePackage(payload) {
  // console.log('API update package', getData(payload), getConfig(payload));
  return Axios.put('/package', getData(payload), getConfig(payload));
  // return Axios.put('/package', getConfig(payload));
}


function updateComanyStatus(payload) {
  // console.log('API update comany status', getData(payload), getConfig(payload));
  return Axios.put('/verityCompany', getData(payload), getConfig(payload));
  // return Axios.put('/package', getConfig(payload));
}

function addPackage(payload) {
  // console.log('API generate package csv', getData(payload), getConfig(payload));
  return Axios.post('/package', getData(payload), getConfig(payload, { responseType: 'blob' }));
}



/**
 *  resource batch
 */
function createResource(payload) {
  // console.log('API create resource', getData(payload), getConfig(payload));
  return Axios.post('/resource', getData(payload), getConfig(payload));
}

function getResourceList(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get resource list', payload, urlQuery, getConfig(payload));

  return Axios.get(`/resource${urlQuery}`, getConfig(payload));
}
function getResourceInfo(payload) {
  console.log('API get Resource info', payload, getConfig(payload));
  return Axios.get(`/resource/${payload.resourceId}`, getConfig(payload));
}

function updateResourceInfo(payload) {
  console.log('API update product info', getData(payload), getConfig(payload));
  return Axios.put('/resource', getData(payload), getConfig(payload));
}

function getResourcePlentyList(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get resource plenty list', payload, urlQuery, getConfig(payload));
  return Axios.get(`/resource/plenty`, getConfig(payload));
}

/*
 * batch
 */
function getBatchList(payload) {

  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get batch list', payload, urlQuery, getConfig(payload));
  return Axios.get(`/dynamicNode/findBatchCode${urlQuery}`, getConfig(payload));
}


function updateBatchInfo(payload) {
  // console.log('API update product info', getData(payload), getConfig(payload));
  return Axios.put('dynamicNode/updateBatchCode', payload, getConfig(payload));
}

function downloadCodeBatch(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get resource plenty list', payload, urlQuery, getConfig(payload), { responseType: 'blob' });
  return Axios.get(`dynamicNode/getBatchInfoById${urlQuery}`, getConfig(payload, { responseType: 'blob' }));
}




/*
 * role
 */
function getRealDynList(payload) {

  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get batch list', payload, urlQuery, getConfig(payload));
  return Axios.get(`/dynamicNode/findDynamicInfo${urlQuery}`, getConfig(payload));
}


/*
 * distribution
 */
function getDistributionList(payload) {

  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get distribution list', payload, urlQuery, getConfig(payload));
  return Axios.get(`/dynamicNode/findBatchLocationInfo${urlQuery}`, getConfig(payload));
}

function getDistributionDetail(payload) {

  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get distribution detail', payload, urlQuery, getConfig(payload));
  return Axios.get(`/dynamicNode/findBatchDetail${urlQuery}`, getConfig(payload));
}


function getDistributionDetailByProduct(payload) {

  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get distribution detail', payload, urlQuery, getConfig(payload));
  return Axios.get(`/dynamicNode/getProductArea${urlQuery}`, getConfig(payload));
}


function getMenu(payload) {

  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get distribution detail', payload, urlQuery, getConfig(payload));
  return Axios.get(`rbac/Menu${urlQuery}`, getConfig(payload));
}
/**
 * 
 * Product plan
 */

 
function getProductPlan(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product list', payload, urlQuery, getConfig(payload));

  return Axios.get(`productPlan/getProductPlan${urlQuery}`, getConfig(payload));
}


function getHistoryDynamic(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product list', payload, urlQuery, getConfig(payload));

  return Axios.get(`productPlan/getHistoryDynamic${urlQuery}`, getConfig(payload));
}

function getDynamicByProductId(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product list', payload, urlQuery, getConfig(payload));

  return Axios.get(`productPlan/getDynamicByProductId${urlQuery}`, getConfig(payload));
}

function addProductPlan(payload) {
  // console.log('API generate package csv', getData(payload), getConfig(payload));
  return Axios.post('productPlan/addProductPlan',  payload, getConfig(payload));
}

function putProductPlan(payload) {
  // console.log('API generate package csv', getData(payload), getConfig(payload));
  return Axios.put('productPlan/putProductPlan',  payload, getConfig(payload));
}


function getProductWithPlan(payload) {
  // let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product list', payload, urlQuery, getConfig(payload));

  return Axios.get(`productPlan/getProduct`, getConfig(payload));
}



/**
 * 
  node 
 */

function getNodeById(payload) {
  let urlQuery = getQuery(payload.urlQuery);
  // console.log('API get product list', payload, urlQuery, getConfig(payload));

  return Axios.get(`node/${payload.urlQuery.nodeId}`, getConfig(payload));
}

/**
 * workers
 */

function getWorkerList(payload) {
  // console.log('API get Worker List', getConfig(payload));
  return Axios.get('rbac/getAppUser', getConfig(payload));
}


function registerAppUser(payload) {
  // console.log('API register App User', getData(payload), getConfig(payload));
  return Axios.post('rbac/registerAppUser', getData(payload), getConfig(payload));
}

function delAppUser(payload) {
  let urlQuery = payload.data.username;
  // console.log('API del App User', getData(payload), getConfig(payload));
  return Axios.delete(`rbac/${urlQuery}`, getConfig(payload));
}


//others
function handleError(res,history) {

  if(res.status === 408){
      sessionStorage.removeItem("accessToken");
      history.push({pathname:'/login',state:{
        code:408,
      }});
      return true;
  }
  else if(res.status === 401 || res.status === 403) {
      sessionStorage.removeItem("accessToken");
      history.push('/login');
      return true;
   }
   else{
     return false;
   }
}

export default {
  getAuthenticatedUser,
  getAuthenticatedUsername,
  verifyLogin,
  login,
  logout,
  register,
  getCompanyInfo,
  getCompanyCertificate,
  updateCompanyInfo,
  createCompanyInfo,
  getProductInfo,
  getProductList,
  getProductInventory,
  getProductImage,
  createProduct,
  updateProductInfo,
  updateProductSystemStatus,
  getProductInstanceList,
  addProductInstance,
  getPackageList,
  getCompanyList,
  updatePackage,
  updateComanyStatus,
  addPackage,
  createResource,
  getResourceList,
  getResourceInfo,
  updateResourceInfo,
  getResourcePlentyList,
  getProductInstanceGroupedList,
  getBatchList,
  getRealDynList,
  updateBatchInfo,
  downloadCodeBatch,
  getDistributionList,
  getDistributionDetail,
  getDistributionDetailByProduct,
  getMenu,
  getWorkerList,
  registerAppUser,
  delAppUser,
  getProductPlan,
  getHistoryDynamic,
  getDynamicByProductId,
  addProductPlan,
  putProductPlan,
  getNodeById,
  getProductWithPlan,
  handleError,
};
