import i18n from '../../i18n';
import backend from '../../helpers/backend'
import { format } from "date-fns";
const CSVHandler = {
    viewProduct: {
        pageDownloadHandler: (dataState, setDataState, parser, done) => {
            const newArr = parser(dataState); setDataState(newArr); done()
        },
        allDownloadHandler: function handler2(dataState, setDataState, parser, done) {
            let submitData = {
                productId: this.state.filter.productId,
                name: this.state.filter.name,
                systemStatus: this.state.filter.systemStatus,
                //pageNumber: 1,
                //pageSize: 100000000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            }
            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getProductList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data;
                                console.log(currentData);
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                // console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        parser: (currentData) => {
            const arr = []
            const optionStatus = {
                "": i18n.t('all'),
                "enabled": i18n.t('enabled'),
                'disabled': i18n.t('disabled')
            }
            currentData.map((ele) => {
                const obj = {};
                obj["ID"] = ele.productId;
                obj[i18n.t("name")] = ele.name;
                obj[i18n.t("description")] = ele.description;
                obj[i18n.t("production_address")] = ele.productionAddress;
                obj[i18n.t("total_unit")] = ele.totalUnit;
                obj[i18n.t("status")] = `${optionStatus[ele.systemStatus]}`
                obj[i18n.t("images")] = `[${ele.productImages}]`;
                arr.unshift(obj)
            })
            return arr
        }
    },
    viewResource: {
        pageDownloadHandler: (dataState, setDataState, parser, done) => {
            const newArr = parser(dataState); setDataState(newArr); done()
        },
        allDownloadHandler: function handle2(dataState, setDataState, parser, done) {
            let submitData = {
                r_id: this.state.filter.productId,
                name: this.state.filter.name,
                status: this.state.filter.status,
                pageNumber: 1,
                pageSize: 100000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            };
            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getResourceList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data;
                                console.log(currentData);
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        parser: (currentData) => {
            const optionStatus = { "-1": i18n.t('all'), "0": i18n.t('using'), "1": i18n.t('useup') };
            const arr = []
            currentData.map((ele) => {
                const obj = {};
                obj["ID"] = ele["r_id"];
                obj[i18n.t("name")] = ele.name;
                obj[i18n.t("batch_num")] = ele["batch_num"];
                obj[i18n.t("status")] = optionStatus[(ele.useup).toString()];
                obj[i18n.t("time")] = ele.createtime;
                arr.unshift(obj)
            })
            return arr
        }
    },
    inventory: {
        pageDownloadHandler: (dataState, setDataState, parser, done) => {
            const newArr = parser(dataState); setDataState(newArr); done()
        },
        allDownloadHandler: function handler2(dataState, setDataState, parser, done) {
            let submitData = {
                productSerialNumber: this.state.filter.productSerialNumber,
                productId: this.state.filter.productId,
                packageId: this.state.filter.packageId,
                status: this.state.filter.status,
                inboundDateFrom: this.state.filter.inboundDateFrom !== undefined ? format(new Date(this.state.filter.inboundDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                inboundDateTo: this.state.filter.inboundDateTo !== undefined ? format(new Date(this.state.filter.inboundDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                packedDateFrom: this.state.filter.packedDateFrom !== undefined ? format(new Date(this.state.filter.packedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                packedDateTo: this.state.filter.packedDateTo !== undefined ? format(new Date(this.state.filter.packedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                shippedDateFrom: this.state.filter.shippedDateFrom !== undefined ? format(new Date(this.state.filter.shippedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                shippedDateTo: this.state.filter.shippedDateTo !== undefined ? format(new Date(this.state.filter.shippedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                completedDateFrom: this.state.filter.completedDateFrom !== undefined ? format(new Date(this.state.filter.completedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                completedDateTo: this.state.filter.completedDateTo !== undefined ? format(new Date(this.state.filter.completedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                pageNumber: 1,
                pageSize: 100000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            };

            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getProductInstanceList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data;
                                console.log(currentData);
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        allGroupedDownloadHandler: function handler2(dataState, setDataState, parser, done) {
            let submitData = {
                productSerialNumber: this.state.filter.productSerialNumber,
                productId: this.state.filter.productId,
                packageId: this.state.filter.packageId,
                status: this.state.filter.status,
                inboundDateFrom: this.state.filter.inboundDateFrom !== undefined ? format(new Date(this.state.filter.inboundDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                inboundDateTo: this.state.filter.inboundDateTo !== undefined ? format(new Date(this.state.filter.inboundDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                packedDateFrom: this.state.filter.packedDateFrom !== undefined ? format(new Date(this.state.filter.packedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                packedDateTo: this.state.filter.packedDateTo !== undefined ? format(new Date(this.state.filter.packedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                shippedDateFrom: this.state.filter.shippedDateFrom !== undefined ? format(new Date(this.state.filter.shippedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                shippedDateTo: this.state.filter.shippedDateTo !== undefined ? format(new Date(this.state.filter.shippedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                completedDateFrom: this.state.filter.completedDateFrom !== undefined ? format(new Date(this.state.filter.completedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                completedDateTo: this.state.filter.completedDateTo !== undefined ? format(new Date(this.state.filter.completedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                pageNumber: 1,
                pageSize: 100000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            };

            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getProductInstanceGroupedList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data;
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        parser: (currentData) => {
            const optionStatus = {
                inbound: i18n.t('inbound'),
                packed: i18n.t('packed'),
                shipped: i18n.t('shipped'),
                completed: i18n.t('completed'),
                '': i18n.t('all')
            }
            const arr = []
            currentData.map((ele) => {
                const obj = {};
                obj[i18n.t("serial_number")] = ele.productSerialNumber;
                obj[i18n.t("nameproduct_name")] = ele.productName;
                obj[i18n.t("product_id")] = ele.productId;
                obj[i18n.t("status")] = optionStatus[ele.status];
                obj[i18n.t("inbound_date")] = ele.inboundDate;
                obj[i18n.t("packed_date")] = ele.packedDate;
                obj[i18n.t("shipped_date")] = ele.shippedDate;
                obj[i18n.t("completed_date")] = ele.completedDate;
                arr.unshift(obj)
            })
            return arr
        },
        parser_grouped:(currentData) => {
            const optionStatus = {
                inbound: i18n.t('inbound'),
                packed: i18n.t('packed'),
                shipped: i18n.t('shipped'),
                completed: i18n.t('completed'),
                '': i18n.t('all')
            }
            const arr = [];
            currentData.map((ele) => {
                const obj = {};
                // obj[i18n.t("package_id")] = ele.packageId;
                if(ele.packageId)
                  {
                    obj[i18n.t("package_id")] = '""'+ ele.packageId.toString()+'""';
                    obj[i18n.t('product_amount')] = ele.productCount;
                    obj[i18n.t('status')] = optionStatus[ele.status];
                    obj[i18n.t('packed_date')] = ele.packedDate;
                    obj[i18n.t('shipped_date')] = ele.shippedDate;
                    obj[i18n.t('completed_date')] = ele.completedDate;
                    arr.unshift(obj)
                  }
              
                
            })
            return arr
        }
    },
    packedPackage: {
        pageDownloadHandler: (dataState, setDataState, parser, done) => {
            const newArr = parser(dataState); setDataState(newArr); done()
        },
        allDownloadHandler: function handler2(dataState, setDataState, parser, done) {
            let submitData = {
                packageId: this.state.filter.packageId,
                status: this.state.filter.status,
                strictStatus: this.state.filter.strictStatus === 'completed' ? "" : this.state.filter.strictStatus,
                packedDateFrom: this.state.filter.packedDateFrom !== undefined ? format(new Date(this.state.filter.packedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                packedDateTo: this.state.filter.packedDateTo !== undefined ? format(new Date(this.state.filter.packedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                shippedDateFrom: this.state.filter.shippedDateFrom !== undefined ? format(new Date(this.state.filter.shippedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                shippedDateTo: this.state.filter.shippedDateTo !== undefined ? format(new Date(this.state.filter.shippedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                completedDateFrom: this.state.filter.completedDateFrom !== undefined ? format(new Date(this.state.filter.completedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                completedDateTo: this.state.filter.completedDateTo !== undefined ? format(new Date(this.state.filter.completedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                pageNumber: 1,
                pageSize: 100000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            }

            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getPackageList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data;
                                console.log(currentData);
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        parser: (currentData) => {
            const arr = []
            currentData.map((ele) => {
                const obj = {};
                // obj[i18n.t("package_id")] = ele.packageId;
                obj[i18n.t("package_id")] = '""'+ ele.packageId.toString()+'""';
                obj[i18n.t("packed_date")] = ele.packedDate;
                arr.unshift(obj)
            })
            return arr
        }
    },
    shippedPackage: {
        pageDownloadHandler: (dataState, setDataState, parser, done) => {
            const newArr = parser(dataState); setDataState(newArr); done();
        },
        allDownloadHandler: function handler2(dataState, setDataState, parser, done) {
            let submitData = {
                packageId: this.state.filter.packageId,
                status: this.state.filter.status,
                strictStatus: "shipped",
                packedDateFrom: this.state.filter.packedDateFrom !== undefined ? format(new Date(this.state.filter.packedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                packedDateTo: this.state.filter.packedDateTo !== undefined ? format(new Date(this.state.filter.packedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                shippedDateFrom: this.state.filter.shippedDateFrom !== undefined ? format(new Date(this.state.filter.shippedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                shippedDateTo: this.state.filter.shippedDateTo !== undefined ? format(new Date(this.state.filter.shippedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                completedDateFrom: this.state.filter.completedDateFrom !== undefined ? format(new Date(this.state.filter.completedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                completedDateTo: this.state.filter.completedDateTo !== undefined ? format(new Date(this.state.filter.completedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                pageNumber: 1,
                pageSize: 100000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            }

            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getPackageList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data;
                                console.log(currentData);
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        parser: (currentData) => {
            const arr = []
            currentData.map((ele) => {
                const obj = {};
                // obj[i18n.t("package_id")] = ele.packageId;
                obj[i18n.t("package_id")] = '""'+ ele.packageId.toString()+'""';
                obj[i18n.t("shipment_ref")] = ele.shipmentCourierRefNo;
                obj[i18n.t("packed_date")] = ele.packedDate;
                obj[i18n.t("shipped_date")] = ele.shippedDate;
                arr.unshift(obj)
            })
            return arr
        }

    },
    orderHistory: {
        pageDownloadHandler: (dataState, setDataState, parser, done) => {
            const newArr = parser(dataState); setDataState(newArr); done();
        },
        allDownloadHandler: function handler2(dataState, setDataState, parser, done) {
            let submitData = {
                packageId: this.state.filter.packageId,
                status: this.state.filter.status,
                strictStatus: "",
                packedDateFrom: this.state.filter.packedDateFrom !== undefined ? format(new Date(this.state.filter.packedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                packedDateTo: this.state.filter.packedDateTo !== undefined ? format(new Date(this.state.filter.packedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                shippedDateFrom: this.state.filter.shippedDateFrom !== undefined ? format(new Date(this.state.filter.shippedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                shippedDateTo: this.state.filter.shippedDateTo !== undefined ? format(new Date(this.state.filter.shippedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                completedDateFrom: this.state.filter.completedDateFrom !== undefined ? format(new Date(this.state.filter.completedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
                completedDateTo: this.state.filter.completedDateTo !== undefined ? format(new Date(this.state.filter.completedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
                pageNumber: 1,
                pageSize: 100000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            }

            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getPackageList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data;
                                console.log(currentData);
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        parser: (currentData) => {
            const optionStatus = {
                inbound: i18n.t('inbound'),
                packed: i18n.t('packed'),
                shipped: i18n.t('shipped'),
                completed: i18n.t('completed'),
                '': i18n.t('all')
            }
            const arr = []
            currentData.map((ele) => {
                const obj = {};
                obj[i18n.t("package_id")] = '""'+ ele.packageId.toString()+'""';
                obj[i18n.t("shipment_ref")] = ele.shipmentCourierRefNo;
                obj[i18n.t("status")] = optionStatus[ele.status];
                obj[i18n.t("packed_date")] = ele.packedDate;
                obj[i18n.t("shipped_date")] = ele.shippedDate;
                obj[i18n.t("complete_date")] = ele.completedDate;
                arr.unshift(obj)
            })
            return arr
        }

    },
    codeBatch: {
        pageDownloadHandler: (dataState, setDataState, parser, done) => {
            const newArr = parser(dataState); setDataState(newArr); done();
        },
        allDownloadHandler: function handler2(dataState, setDataState, parser, done) {
            let submitData = {
                status: this.state.filter.status,
                startTime: this.state.filter.startTime !== undefined ? format(new Date(this.state.filter.startTime), 'yyyy-MM-dd 00:00:00') : undefined,
                expiredTime: this.state.filter.expiredTime !== undefined ? format(new Date(this.state.filter.expiredTime), 'yyyy-MM-dd 23:59:59') : undefined,
                pageNumber: 1,
                pageSize: 100000000,
                sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
            }

            let urlQuery = submitData
            const payload = { urlQuery, authToken: backend.getAuthenticatedUser() }
            backend.getBatchList(payload)
                .then(
                    (res) => {
                        console.log(res.status)
                        try {
                            if (res.status === 200) {
                                let currentData = res.data.data.batchInfo;
                                console.log(currentData);
                                let newArr = parser(currentData);
                                setDataState(newArr);
                                done();
                            } else {
                                throw res;
                            }
                        } catch (error) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                console.log('testing expire token');
                                sessionStorage.removeItem("accessToken");
                                this.props.history.push('/login');
                                return;
                            }
                            console.log('API Error', error.response);
                        }
                    })
                .catch((err) => {
                    console.log(err)
                })
        },
        parser: (currentData) => {
            // 0 所有信息（包含已激活，未激活，已过期） 1 已激活未过期 2 未激活 3 已激活已经过期
            const optionStatus = {
                0: '所有状态',
                1: '已激活未过期',
                2: '未激活',
                3: '已激活已经过期'
            }
            const arr = []
            // console.log('哈哈哈哈哈')
            // console.log(currentData)
            currentData.map((ele) => {
                const obj = {};
                obj[i18n.t("batch_id")] = ele.id;
                obj[i18n.t("code_start_index")] = ele.startIndex;
                obj[i18n.t("code_end_index")] = ele.endIndex;
                obj[i18n.t("active_start_index")] = ele.startTime;
                obj[i18n.t("active_end_index")] = ele.expiredTime;
                obj[i18n.t("code_status")] = ele.used + '/10';
                // obj[i18n.t("status")] = optionStatus[ele.status];
                arr.unshift(obj)
            })
            return arr
        }

    }
}


export default CSVHandler;
