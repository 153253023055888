import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { GET_RESOURCE_INFO, GET_RESOURCE_LIST,  CREATE_RESOURCE, UPDATE_RESOURCE_INFO, GET_RESOURCE_PLENTY_LIST,UPDATE_PRODUCT_SYSTEM_STATUS } from './actionTypes';
import { getResouceInfoSuccess, getResourceListSuccess, showAlert, apiError,getResourcePlentyListSuccess } from './actions';

import validator from 'validator';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();


function* getResourceInfo({ payload: { resourceId, history } }) {
    console.log('* getResourceInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.getResourceInfo, { resourceId: resourceId, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
            // console.log('test case 1');
            if (
                response.data  &&
                response.data.data  &&
                response.data.data.productImages  &&
                response.data.data.productImages.length > 0
            ) {
                //console.log('test case 2');
               // const response2 = yield put({ type: GET_PRODUCT_IMAGE, payload: {productId, productImageId: response.data.data.productImages, history} });
            }
            
            console.log('test case 3');
            yield put(getResouceInfoSuccess(response));

        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }
        
        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* getResourceList({ payload: { urlQuery, history } }) {
    console.log('* getResourceList');
    try {
        const response = yield call(backend.getResourceList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
            yield put(getResourceListSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* getResourcePlentyList({ payload: { urlQuery, history } }) {
    console.log('* getResourcePlentyList');
    try {
        const response = yield call(backend.getResourcePlentyList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
            yield put(getResourcePlentyListSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* getProductImage({ payload: { productId, productImageId, history } }) {
    console.log('* getProductImage', productId, productImageId, history);
    try {
        let responses = yield all(
            productImageId.map(imageId => {
                console.log('testing image id', imageId);
                return call(backend.getProductImage, { productId, productImageId: imageId, authToken: backend.getAuthenticatedUser() })
            })
        );

        console.log('test responses', responses);

        let validResponse = true;

        for(let tmp of responses) {
            if(tmp.status !== 200) {
                validResponse = false;
            }
        }

        if (validResponse === true) {
            console.log('test case 3');
            // yield put(getProductListSuccess(response));
            yield put({ type: 'GET_PRODUCT_IMAGE_SUCCESS', payload: {productId, responses} });
        }else {
            throw responses;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        console.log('API Error', error.responses);
        yield put(apiError(error.responses));
    }
}

function* createResource({ payload: { data, history } }) {
    console.log('* createResource');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.createResource, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });

            history.push('/view-Resource');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* updateResourcetInfo({ payload: { data, history } }) {
    console.log('* updateResourcetInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.updateResourceInfo, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            //yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* updateProductSystemStatus({ payload: { data, history } }) {
    console.log('* updateProductSystemStatus');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.updateProductSystemStatus, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            yield put({ type: 'UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

export function* watchGetResourceInfo() {
     yield takeEvery(GET_RESOURCE_INFO, getResourceInfo)
}

export function* watchGetResourceList() {
     yield takeEvery(GET_RESOURCE_LIST, getResourceList)
}

export function* watchGetResourcePlentyList() {
    yield takeEvery(GET_RESOURCE_PLENTY_LIST, getResourcePlentyList)
}

export function* watchCreateResource() {
    yield takeEvery(CREATE_RESOURCE, createResource)
}

export function* watchUpdateResourcetInfo() {
     yield takeEvery(UPDATE_RESOURCE_INFO, updateResourcetInfo)
}

export function* watchUpdateProductSystemStatus() {
    // yield takeEvery(UPDATE_PRODUCT_SYSTEM_STATUS, updateProductSystemStatus)
}

function*  ResourceInfoSaga() {
    yield all([
       
        fork(watchGetResourceList),
        fork(watchCreateResource),
        fork(watchGetResourceInfo),
        fork(watchGetResourcePlentyList),

        fork(watchUpdateResourcetInfo),
        fork(watchUpdateProductSystemStatus),
    ]);
}

export default ResourceInfoSaga;