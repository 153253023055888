import {
  GET_MENU,
  GET_MENU_SUCCESS,
  REST_MENU_DATA,
  GET_MENU_ERROR,
  SET_SELETED_KEYS
} from "./actionTypes";

export const getMenu = (urlQuery, history) => {
  return {
    type: GET_MENU,
    payload: { urlQuery, history }
  };
};

export const MenuSuccess = data => {
  return {
    type: GET_MENU_SUCCESS,
    payload: data
  };
};

export const RsetMenu = () => {
  return {
    type: REST_MENU_DATA,
  };
};
export const setSelectedKey = (key) => {
  return {
    type: SET_SELETED_KEYS,
    payload: { key }
  };
};

export const MenuError = data => {
  return {
    type: GET_MENU_ERROR,
    payload: data
  };
};


