import React, {Component} from "react";
import SettingMenu from "../Shared/SettingMenu";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {AvForm, AvField} from "availity-reactstrap-validation";
import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table, InputGroup} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {MDBDataTable} from "mdbreact";
import Select from "react-select";
import {Modal, Collapse} from "reactstrap";
import CSV from "../../components/CSVDownloader/CSVDownloader"
import CSVHandler from "../../components/CSVDownloader/CSVHandler"
import {format} from "date-fns";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import ToggleSwitch from "../../components/ToggleSwitch"
import ReactPaginate from 'react-paginate';
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";

import i18n from '../../i18n';
import {Translation} from 'react-i18next';

import AntdPagination from '../../components/AntdPagination';

import {Pagination, ConfigProvider} from 'antd';

import lianbg from '../../assets/images/lian/lian3.png'
import lianlogo from '../../assets/images/lian/lian4.png'

import {CSVLink, CSVDownload} from "react-csv";


// actions
import {
    getDistributionDetailByProduct,
    clearInputError,
    clearAlert,
    apiError,
    clearProductIntanceCSV,
} from "../../store/distribute/actions";
import zhCN from "antd/lib/locale/zh_CN";

import commonUtils from '../../helpers/commonUtils'
const nowDate = commonUtils.getNowFormatDate();

const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];



const optionPageSize = [
    {label: 10, value: 10},
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
];

const getSortingComponent = (value, sorting) => {
    if (value == sorting.column) {
        switch (sorting.order) {
            case '':
                return <i className="typcn typcn-media-record"/>;
                break;

            case 'asc':
                return <i className="ion ion-ios-arrow-up"/>;
                break;

            case 'desc':
                return <i className="ion ion-ios-arrow-down"/>;
                break;
        }
    } else {
        return <i className="typcn typcn-media-record"/>;
    }
}



const ProductInstanceTableRow = (props) => {
    const dataList = props.data;
    const toggleModalStatusChangeLog = props.toggleModalStatusChangeLog;

    let startRowNo = props.pageNumber * props.pageSize + 1;

    if(!dataList)
       return null;

    const listItems = dataList.map((data, index) => {
        let displayStatus = '';

       


        return (<tr key={index}>
            <td><span className="float-right">{startRowNo++}</span></td>
            <td>{data.city}</td>
            <td>{data.province ? data.province : "--"}</td>
            <td><span>{data.number}</span></td>
            {/* <td className='w-3p'><span className="capitalize nowrap">{displayStatus}</span></td>
         
            
            <td className='td-text-center' style={{color: '#3BA1DB', cursor: 'pointer'}}>
        <span onClick={() => toggleModalStatusChangeLog(data)}>
          {i18n.t('open')}
        </span>
            </td> */}
        </tr>)
    });

    return (<tbody>{listItems}</tbody>);
};

const entryStr = (pageNumber, pageSize, filteredCount, totalCount) => {
    let to = (pageNumber + 1) * pageSize;
    if (to > filteredCount) {
        to = filteredCount;
    }

    let from = pageNumber * pageSize + 1;
    if (from > to) {
        from = to;
    }

    return <>{i18n.t('table_count', {from: from, to: to, filter: filteredCount, total: totalCount})}</>;
}

const userStr = (adminUser, workerUser) => {


    if (adminUser) {
        return i18n.t('admin_bracket')
    } else {
        return i18n.t('worker_bracket')
    }

};

const StatusChangeLogRow = (props) => {
    console.log('testing StatusChangeLogRow()', props.data);
    const dataList = props.data;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td><span className="nowrap">{data.status}</span></td>
            <td>{data.datetime}</td>
            <td><span className="nowrap">{data.userId}</span></td>
            <td>{data.location}</td>
        </tr>
    );
    return (<tbody>{listItems}</tbody>);
};

const BlockLianChangeLogRow = (props) => {
    console.log('testing BlockLianChangeLogRow()', props.data);
    const info = props.data;
    return (
        <div className='lian-info'>
            <img className='bg' src={lianbg}/>
            <div className='lian-data'>
                <div>
                    <p>所在链</p>
                    <p className='p2'>创益溯源链</p>
                </div>
                <div>
                    <p>所在区块</p>
                    <p className='p2'>{info.currentBlockHeight}</p>
                </div>
                <div>
                    <p>唯一编码</p>
                    <p className='p2'>{info.blockDataHash}</p>
                </div>
                <img className='logo' src={lianlogo}/>
            </div>
        </div>
    );
};

const BlockLianChangeLogRow2 = (props) => {
    console.log('testing BlockLianChangeLogRow()', props.data);
    const info = props.data;
    return (
        <tbody>
        <tr>
            <td><span className="nowrap">创益溯源链</span></td>
            <td>{info.currentBlockHeight}</td>
            <td><span className="nowrap">{info.blockDataHash}</span></td>
        </tr>
        </tbody>
    );
};

class Inventory extends Component {
    constructor(props) {
        super(props);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleFilterVisibility = this.handleFilterVisibility.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.toggleModalStatusChangeLog = this.toggleModalStatusChangeLog.bind(this);
        this.toggleModalBlockchain = this.toggleModalBlockchain.bind(this);
        this.handler2 = this.handler2.bind(this);
        this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
        this.handleAntdPageChange = this.handleAntdPageChange.bind(this);
        this.handleAntdPageSizeChange = this.handleAntdPageSizeChange.bind(this);

        this.state = {
            displayInGroups: (this.props.match.params.productId === undefined && this.props.match.params.packageId === undefined) ? true : false,
            displaySwitch: (this.props.match.params.productId === undefined && this.props.match.params.packageId === undefined) ? true : false,
            filterVisibility: true,
            filter: {
                
                productId: this.props.match.params.productId === undefined ? '' : this.props.match.params.productId,
                startTime: undefined,
                expiredTime: undefined,
              
            },
            componentDidUpdateNum: 0,
            pageNumber: 0,
            pageSize: 10,
            filteredCount: 0,
            pageCount: 0,
            groupedPageCount: 0,
            sorting: {
                column: 'sn',
                order: 'desc',
            },
            modalStatusChangeLog: {
                toggle: false,
                statusChangeLog: [],
            },
            blockchainData: {
                toggle: false,
                info: {}
            }
        };
    }

    handler2 = CSVHandler.inventory.allDownloadHandler
    handler3 = CSVHandler.inventory.allGroupedDownloadHandler.bind(this)

    componentDidMount() {
        console.log('componentDidMount');

        if(!this.state.filter.startTime && !this.state.filter.expiredTime){

            this.state.filter.startTime =commonUtils.addMonths(new Date(), -1);
            this.state.filter.expiredTime =commonUtils.addDays(new Date(), 1);

        }
      

        this.handleFormSubmit();
        this.props.clearAlert('success');
    }

    componentDidUpdate() {
        document.title = i18n.t('inventory');
        console.log('componentDidUpdate', this.props.filteredCount);

        this.state.componentDidUpdateNum += 1;

        // let pageCount = Math.ceil(this.props.filteredCount / this.state.pageSize);
        // let groupedPageCount = Math.ceil(this.props.filteredGroupedCount / this.state.pageSize);
        // console.log('testing update page count', this.state.pageCount, pageCount);
        // console.log('testing update grouped page count', this.state.groupedPageCount, groupedPageCount);
        // if (this.state.pageCount !== pageCount) {
        //     if (pageCount <= this.state.pageNumber) {
        //         this.setState({pageCount: pageCount, pageNumber: 0}, () => this.handleFormSubmit());
        //     } else {
        //         this.setState({pageCount: pageCount});
        //     }
        // }
        // if (this.state.groupedPageCount !== groupedPageCount) {
        //     if (pageCount <= this.state.groupedPageCount) {
        //         this.setState({groupedPageCount, pageNumber: 0}, () => this.handleFormSubmit());
        //     } else {
        //         this.setState({groupedPageCount});
        //     }
        // }
    }

    renderAntdPagination(totalProductCount) {
        console.log('totalProductCount=====>>>>', totalProductCount);
        if (totalProductCount > 0) {
            return (<AntdPagination total={totalProductCount} antdPageChange={this.handleAntdPageChange}
                                    antdPageSizeChange={this.handleAntdPageSizeChange}/>)
        } else {
            return null
        }
    }

    handleFilterInputChange(event) {
        console.log('handleFilterInputChange', event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));
    }

    handleFilterStatusChange(event) {
        console.log('handleFilterStatusChange', event);

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                status: event.value,
            },
        }));
    }

    handleFilterDateChange(dateInput, value) {
        console.log('handleFilterDateChange', dateInput, value);

        let dateInputValue = value === null ? undefined : value;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [dateInput]: dateInputValue,
            },
        }));
    }

    handlePageSizeChange(event) {
        console.log('handlePageSizeChange', event);

        this.setState({
                pageSize: event.value,
                pageNumber: 0,
            },
            () => this.handleFormSubmit());
    }

    handleSorting(value) {
        console.log('handleSorting', value, this.state.sorting.column);

        let newOrder;
        let newComponent;

        if (value == this.state.sorting.column) {
            switch (this.state.sorting.order) {
                case '':
                    newOrder = 'asc';
                    break;

                case 'asc':
                    newOrder = 'desc';
                    break;

                case 'desc':
                    newOrder = '';
                    break;
            }
        } else {
            newOrder = 'asc';
        }

        this.setState(prevState => ({
                sorting: {
                    column: value,
                    order: newOrder,
                }
            }),
            () => this.handleFormSubmit());
    }

    handlePageClick(event) {
        console.log('handlePageClick', event);

        this.setState(
            prevState => ({
                pageNumber: event.selected,
            }),
            () => this.handleFormSubmit()
        );

        return false;
    }


    // 改变页码
    handleAntdPageChange(page, pageSize) {
        this.setState({
                pageSize: pageSize,
                pageNumber: page - 1,
            },
            () => this.handleFormSubmit()
        );
    }

    // 改变每页显示条数
    handleAntdPageSizeChange(current, size) {
        this.setState({
                pageSize: size,
                pageNumber: 0,
            },
            () => this.handleFormSubmit()
        );
    }


    handleClearFilter() {
        console.log('handleClearFilter()');

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                productId: '',
                startTime: undefined,
                expiredTime: undefined,
               
            },
        }), ()=>{
            this.handleFormSubmit()
        });
    }

    handleFilterVisibility() {
        console.log('handleFilterVisibility()');

        this.setState(prevState => ({
            filterVisibility: !prevState.filterVisibility
        }));
    }

    handleFormSubmit(event, values) {
        let pageNumber = this.state.pageNumber;
        let submitData = {
            productId:this.state.filter.productId,
            startTime: this.state.filter.startTime  ? format(new Date(this.state.filter.startTime), 'yyyy-MM-dd 00:00:00') : undefined,
            expiredTime: this.state.filter.expiredTime  ? format(new Date(this.state.filter.expiredTime), 'yyyy-MM-dd 00:00:00') : undefined,
            pageNumber: pageNumber + 1,
            pageSize: this.state.pageSize,
            sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
        };
        this.props.getDistributionDetailByProduct(submitData, this.props.history);
    }

    handleToggleSwitch() {
        this.setState({pageNumber: 0})
        this.setState((prev) => {
            return {...prev, displayInGroups: !prev.displayInGroups}
        })
        setTimeout(() => {
            this.handleFormSubmit();
        })
    }

    /**
     * modal
     */
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleModalStatusChangeLog(data = []) {
        console.log('toggleModalStatusChangeLog', data);

        let statusChangeLog = [];

        if (data.inboundDate) {
            statusChangeLog.push({
                status: i18n.t('inbound'),
                datetime: data.inboundDate,
                // userId: userStr(data.inboundByAdminUserId, data.inboundByWorkerUserId),
                // userId: data.inboundByAdminUserId ? data.inboundByAdminUserId : data.inboundByWorkerUserId,
                userId: data.inboundUserName,
                location: data.inboundUpdatedInLocation,
            });
        }


        if (data.packedDate) {
            statusChangeLog.push({
                status: i18n.t('packed'),
                datetime: data.packedDate,
                // userId: userStr(data.packedByAdminUserId, data.packedByWorkerUserId),
                userId: data.packageUserName,
                location: data.packedUpdatedInLocation,
            });
        }


        if (data.shippedDate) {
            statusChangeLog.push({
                status: i18n.t('shipped'),
                datetime: data.shippedDate,
                // userId: userStr(data.shippedByAdminUserId, data.shippedByWorkerUserId),
                userId: data.shippedUserName,
                location: data.shippedUpdatedInLocation,
            });
        }


        if (data.completedDate) {
            statusChangeLog.push({
                status: i18n.t('completed'),
                datetime: data.completedDate,
                // userId: userStr(data.completedByAdminUserId, data.completedByWorkerUserId),
                userId: data.completeUserName,
                location: data.completedUpdatedInLocation,
            });
        }


        this.setState(prevState => ({
            modalStatusChangeLog: {
                ...prevState.modalStatusChangeLog,
                toggle: !prevState.modalStatusChangeLog.toggle,
                statusChangeLog: statusChangeLog,
            },
        }));
        this.removeBodyCss();
    }

    toggleModalBlockchain(data = {}) {
        let info = {
            currentBlockHeight: data.currentBlockHeight,
            blockDataHash: data.blockDataHash
        }
        this.setState(prevState => ({
            blockchainData: {
                ...prevState.blockchainData,
                toggle: !prevState.blockchainData.toggle,
                info: info,
            },
        }));
        this.removeBodyCss();
    }

    // Now() {

    //     let dateTime=new Date()
    //     //dateTime=dateTime.setDate(dateTime.getDate()+1);
    //     //dateTime=dateTime.setDate(dateTime.getMonth()+1);
    //     dateTime=dateTime.setFullYear(dateTime.getFullYear(),dateTime.getMonth(),dateTime.getDate()+1);
    //      return  new Date(dateTime)
    // }
    // BackMonth() {

    //     let dateTime=new Date()
    //     dateTime=dateTime.setFullYear(dateTime.getFullYear(),dateTime.getMonth()-1,dateTime.getDate());
    //     return  new Date(dateTime)
    // };


    render() {
        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {this.props.loading ? <Loader/> : null}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            <h4 className="navi-heander-size">{t('scan_distribution_detail')}</h4>
                                            {/*<CSVLink data={csvData} filename={"my-file.csv"}>Download CVS</CSVLink>*/}
                                            {/*<CSVDownload data={csvData} target="_blank" />*/}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                {/* <Row>
                                                    <Col md="12" className="mb-3">
                                                        <div>
                                                            <Row>
                                                                <Col xs="6">
                                                                    <button
                                                                        onClick={this.handleFilterVisibility}
                                                                        className="btn btn-primary mo-mb-2 btn-primary-bg"
                                                                        type="button"
                                                                    >
                                                                        {this.state.filterVisibility === true ? t('hide_filter') : t('show_filter')}
                                                                    </button>
                                                                </Col>
                                                                <Col xs="6">
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end"
                                                                    }}>
                                                                        {this.state.displaySwitch && <ToggleSwitch
                                                                            checked={this.state.displayInGroups}
                                                                            onChange={this.handleToggleSwitch}/>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {this.state.displayInGroups ? <CSV path="inventory_grouped"
                                                                                           curfilename={'库存第'+ (parseInt(this.state.pageNumber+1)) +'页记录'+ nowDate + '.csv'}
                                                                                           allfilename={'库存全部记录'+ nowDate + '.csv'}
                                                                                           handler1={CSVHandler.inventory.pageDownloadHandler}
                                                                                           parser={CSVHandler.inventory.parser_grouped}
                                                                                           handler2={this.handler3}/> :
                                                            <CSV path="inventory"
                                                                 curfilename={'库存第'+ (parseInt(this.state.pageNumber+1)) +'页记录'+ nowDate + '.csv'}
                                                                 allfilename={'库存全部记录'+ nowDate + '.csv'}
                                                                 handler1={CSVHandler.inventory.pageDownloadHandler}
                                                                 parser={CSVHandler.inventory.parser}
                                                                 handler2={this.handler2}/>}
                                                    </Col>
                                                </Row> */}

                                                <Collapse isOpen={this.state.filterVisibility}>
                                                    <AvForm className="mb-3 InfoContainer"
                                                            onValidSubmit={this.handleFormSubmit}>
                                                        <Row>
                                                            
                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('active_start_index')}</Label>
                                                                    <InputGroup>
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            dateFormat="yyyy-MM-dd"
                                                                            selected={this.state.filter.startTime}
                                                                            onChange={(value) => this.handleFilterDateChange('startTime', value)}
                                                                            isClearable
                                                                            placeholderText={t('click_open_date_picker')}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('active_end_index')}</Label>
                                                                    <InputGroup>
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            dateFormat="yyyy-MM-dd"
                                                                            selected={this.state.filter.expiredTime}
                                                                            onChange={(value) => this.handleFilterDateChange('expiredTime', value)}
                                                                            isClearable
                                                                            placeholderText={t('click_open_date_picker')}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                           
                                                            
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Button color="primary"
                                                                            className="mr-1 btn-primary-bg">{t('apply')}</Button>
                                                                    <Button color="secondary"
                                                                            onClick={this.handleClearFilter}>{t('clear')}</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                </Collapse>

                                                <Row>
                                                    <Col sm={12}>
                                                        <p className="text-muted m-b-30">
                                                            {entryStr(this.state.pageNumber, this.state.pageSize, this.props.filteredCount, this.props.totalItemCount)}
                                                        </p>
                                                    </Col>
                                                </Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            className="table thead-nowrap"
                                                        >
                                                            
                                                                <>
                                                                    <thead className="thead-header">
                                                                    <tr>
                                                                        <th className="w-1p"><span
                                                                            className="float-right">#</span></th>
                                                                        {/* <th className="w-1p"
                                                                            onClick={() => this.handleSorting('sn')}>
                                                                            <span
                                                                                className="float-right">{t('serial_number')} {getSortingComponent('sn', this.state.sorting)}</span>
                                                                        </th>
                                                                        <th className="w-1p"
                                                                            onClick={() => this.handleSorting('productId')}>
                                                                            <span
                                                                                className="float-right">{t('product_id')} {getSortingComponent('productId', this.state.sorting)}</span>
                                                                        </th> */}
                                                                        <th className='w-1pp'>{t('area')} </th>
                                                                        <th>{t('province')} </th>
                                                                        {/* <th className="w-1p" onClick={() => this.handleSorting('packageId')}><span className="float-right">Package ID {getSortingComponent('packageId', this.state.sorting)}</span></th> */}
                                                                        <th className="w-3p-n"><span>{t('unit')}</span>
                                                                        </th>
                                                                        {/* <th className='w-3p'>{t('status')}</th>
                                                                        
                                                                       
                                                                        <th className='w-3p'>{t('status_change_log')}</th> */}
                                                                    </tr>
                                                                    </thead>
                                                                    <ProductInstanceTableRow
                                                                        data={this.props.Locations}
                                                                        toggleModalStatusChangeLog={this.toggleModalStatusChangeLog}
                                                                        pageNumber={this.state.pageNumber}
                                                                        pageSize={this.state.pageSize}/>
                                                                </>
                                                            
                                                        </Table>
                                                    </div>
                                                </div>
                                                <Row className='antd-pagination'>
                                                    {
                                                        this.state.displayInGroups ? this.props.filteredGroupedCount > 0 ?
                                                            <Col>
                                                                <ConfigProvider locale={zhCN}>
                                                                    <Pagination
                                                                        total={this.props.filteredGroupedCount}
                                                                        showSizeChanger
                                                                        showQuickJumper
                                                                        onChange={this.handleAntdPageChange}
                                                                        onShowSizeChange={this.handleAntdPageSizeChange}
                                                                        showTotal={total => t('table_nav_count', {total: total})}
                                                                    />
                                                                </ConfigProvider>
                                                            </Col> : null :
                                                            this.props.filteredCount > 0 ? <Col>
                                                                <ConfigProvider locale={zhCN}>
                                                                    <Pagination
                                                                        total={this.props.filteredCount}
                                                                        showSizeChanger
                                                                        showQuickJumper
                                                                        onChange={this.handleAntdPageChange}
                                                                        onShowSizeChange={this.handleAntdPageSizeChange}
                                                                        showTotal={total => t('table_nav_count', {total: total})}
                                                                    />
                                                                </ConfigProvider>
                                                            </Col> : null
                                                    }

                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                            <Modal
                                className="modal-lg"
                                isOpen={this.state.modalStatusChangeLog.toggle}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleModalStatusChangeLog()}
                                >
                                    {t('status_change_log')}
                                </ModalHeader>
                                <ModalBody>
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                className="table table-striped table-bordered thead-nowrap"
                                            >
                                                <thead>
                                                <tr>
                                                    <th>{t('status')}</th>
                                                    <th>{t('status_updated_at')}</th>
                                                    <th>{t('operator_account_id')}</th>
                                                    <th>{t('location')}</th>
                                                </tr>
                                                </thead>
                                                <StatusChangeLogRow
                                                    data={this.state.modalStatusChangeLog.statusChangeLog}/>
                                            </Table>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModalStatusChangeLog()}
                                    >
                                        {t('close')}
                                    </Button>
                                </ModalFooter>
                            </Modal>



                           

                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    console.log('mapStateToProps', state.Distribution)
    const {
        Locations,
        filteredGroupedCount,
        productInstanceList,
        filteredCount,
        totalItemCount,
        alert,
        error,
        loading,
        productInstanceGroupedList,
    } = state.Distribution;

    return {
        Locations,
        filteredGroupedCount,
        productInstanceList,
        filteredCount,
        totalItemCount,
        alert,
        error,
        loading,
        productInstanceGroupedList,
    };
};

export default withRouter(connect(mapStateToProps, {
    getDistributionDetailByProduct,
    clearAlert,
})(Inventory));
