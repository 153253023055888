import Batch from "../../store/batch/reducer";


export default {
    viewProduct: (state)=> state.FormProductInfo.productList,
    viewResource:(state)=> state.FormResourceInfo.itemList,
    inventory:(state)=> state.ProductInstance.productInstanceList,
    packedPackage:(state)=> state.Package.packageList,
    shippedPackage: (state)=>state.Package.packageList,
    orderHistory:(state)=>state.Package.packageList,
    codeBatch:(state)=>state.Batch.BatchList,
    inventory_grouped: (state)=> state.ProductInstance.productInstanceGroupedList,
}
