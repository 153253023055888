// @flow
import * as React from 'react';
import {useState} from 'react';
import {Chart, Interval, Tooltip, Axis} from 'bizcharts';
import i18n from '../../i18n';
import {Row, Col} from "reactstrap";

const data = [
    // { status: i18n.t('completed'), amount: 38 },
    {status: i18n.t('completed'), '数量': 38},
    {status: i18n.t('exceed_time'), '数量': 52},
    {status: i18n.t('shipped'), '数量': 52},
    {status: i18n.t('packed'), '数量': 61},
];


export const BarChart = (props) => {
    const {delayed, shipped, completed, packed} = props.data;
    const [data, setData] = useState([]);
    const [label, setLabel] = useState({});
    const init = () => {
        setData([]);
        setLabel({
            formatter(text, item, index) {
                return text;
            }
        });
        if (props.data) {
            setData([...data,
                {status: i18n.t('packed'), '数量': packed},
                {status: i18n.t('shipped'), '数量': shipped},
                {status: i18n.t('completed'), '数量': completed},
                {status: i18n.t('exceed_time'), '数量': delayed},
               ])
        }

    }
    React.useEffect(() => {
        init()
    }, [props.data])
    return (
        <Row>
            <Col md={12} lg={8}>
                <div>
                    <Chart height={400} autoFit data={data} interactions={['active-region']}
                           padding={[30, 30, 30, 50]}>
                        <Interval position="status*数量"/>
                        <Tooltip shared/>
                        {/*<Axis name="数量" label={label}/>*/}
                    </Chart>
                </div>
            </Col>
            <Col className="d-none d-sm-none d-md-none d-lg-block d-xl-block" lg={4}>
                <div style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly"
                }}>
                    <h4>{i18n.t('packed')}: {packed}</h4>
                    <h4>{i18n.t('shipped')}: {shipped}</h4>
                    <h4>{i18n.t('completed')}: {completed}</h4>
                    <h4>{i18n.t('exceed_time')}: {delayed}</h4>
                    
                   
                </div>
            </Col>
        </Row>
    );
};

export default BarChart;
