import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { 
     GET_COMPANY_INFO,
     COMPANY_WORKER_LIST,
     COMPANY_WORKER_LIST_SUCCESS,
     REGISTER_APP_USER,
     DEL_APP_USER,
     UPDATE_COMPANY_INFO,
     CREATE_COMPANY_INFO 
} from './actionTypes';
import { getCompanyInfoSuccess, getWorkerListSuccess, showAlert, apiError } from './actions';

import validator from 'validator';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();


function* getCompanyInfo({ payload: { history } }) {
    // console.log('* getCompanyInfo');
    try {
        // const response = yield call(fireBaseBackend.getCompanyInfo, user.email, user.password);
        const response = yield call(backend.getCompanyInfo, { authToken: backend.getAuthenticatedUser() });

        // console.log('test response', response, response.status);

        if (response.status === 200) {
    
            yield put(getCompanyInfoSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     // console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* updateCompanyInfo({ payload: { data, history } }) {
    console.log('* updateCompanyInfo');
    try {
 
        const response = yield call(backend.updateCompanyInfo, { ...data, authToken: backend.getAuthenticatedUser() });

        // console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            yield put({ type: 'GET_COMPANY_INFO', payload: {history} });
            // yield call(getCompanyInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* createCompanyInfo({ payload: { data, history } }) {
    console.log('* createCompanyInfo');
    try {

        const response = yield call(backend.createCompanyInfo, { ...data, authToken: backend.getAuthenticatedUser() });

        // console.log('response code', response, response.status);

        if (response.status === 200) {
            sessionStorage.removeItem("accessToken");
            sessionStorage.setItem("accessToken", 'Bearer ' + response.data.accessToken);
            yield put(showAlert('success'));
            yield put({ type: 'GET_COMPANY_INFO', payload: {history} });
            // yield call(getCompanyInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}


function* getWorkerList({ payload: { history } }) {
    console.log('* getWorkerList');
    try {
        const response = yield call(backend.getWorkerList, { authToken: backend.getAuthenticatedUser() });

        if (response.status === 200) {
           
          
            
           
            yield put(getWorkerListSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* registerAppUser({ payload: { data, history } }) {
    console.log('* registerAppUser');
    try {

        const response = yield call(backend.registerAppUser, { ...data, authToken: backend.getAuthenticatedUser() });

        // console.log('response code', response, response.status);

        if (response.status === 200) {
            // yield put(showAlert('success'));
            yield put({ type: 'COMPANY_WORKER_LIST', payload: {history} });
            // yield call(getCompanyInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     // console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* delAppUser({ payload: { data, history } }) {
    console.log('* registerAppUser');
    try {

        const response = yield call(backend.delAppUser, { data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            // yield put(showAlert('success'));
            yield put({ type: 'COMPANY_WORKER_LIST', payload: {history} });
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}
export function* watchGetCompanyInfo() {
    yield takeEvery(GET_COMPANY_INFO, getCompanyInfo)
}

export function* watchUpdateCompanyInfo() {
    yield takeEvery(UPDATE_COMPANY_INFO, updateCompanyInfo)
}
export function* watchCreateCompanyInfo() {
    yield takeEvery(CREATE_COMPANY_INFO, createCompanyInfo)
}

export function* watchGetWorkerList() {
    yield takeEvery(COMPANY_WORKER_LIST, getWorkerList)
}

export function* watchRegisterAppUsert() {
    yield takeEvery(REGISTER_APP_USER, registerAppUser)
}


export function* watchDelAppUser() {
    yield takeEvery(DEL_APP_USER, delAppUser)
}

function* CompanyInfoSaga() {
    yield all([
        fork(watchGetCompanyInfo),
        fork(watchUpdateCompanyInfo),
        fork(watchCreateCompanyInfo),
        fork(watchGetWorkerList),
        fork(watchRegisterAppUsert),
        fork(watchDelAppUser),
    ]);
}

export default CompanyInfoSaga;