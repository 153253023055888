import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import i18n from '../../i18n';
import {
    GET_PRODUCT_INSTANCE_LIST,
    SHOW_ALERT,
    ADD_PRODUCT_INSTANCE,
    ADD_PRODUCT_INSTANCE_SUCCESS,
    SHOW_SUCCESS,
    GET_PRODUCT_INSTANCE_GROUPED_LIST,
} from './actionTypes';
import {
    getProductInstanceListSuccess,
    getProductInstanceGroupedListSuccess,
    showAlert,
    productInstanceApiError,
} from './actions';

import validator from 'validator';

import backend from '../../helpers/backend';


function* getProductInstanceGroupedList({ payload: { urlQuery, history } }) {
    console.log('* getProductGroupedInstanceList');
    try {
        const response = yield call(backend.getProductInstanceGroupedList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        // console.log('test response', response, response.status);

        if(response.status === 200) {
            // console.log('test case 1234156456');
            yield put(getProductInstanceGroupedListSuccess(response));
        } else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     // console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        yield put(productInstanceApiError(error.response));
    }
}

function* getProductInstanceList({ payload: { urlQuery, history } }) {
    console.log('* getProductInstanceList');
    try {
        const response = yield call(backend.getProductInstanceList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        // console.log('test response', response, response.status);

        if(response.status === 200) {
            // console.log('test case 3');
            yield put(getProductInstanceListSuccess(response));
        } else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     // console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        yield put(productInstanceApiError(error.response));
    }
}

function* addProductInstance({ payload: { data, history } }) {
    console.log('* addProductInstance', data);
    try {
        const response = yield call(backend.addProductInstance, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if(response.status === 200) {
            yield put({ type: SHOW_SUCCESS, payload: i18n.t("success") });
            yield put({ type: ADD_PRODUCT_INSTANCE_SUCCESS, payload: { data: response } });
        } else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     // console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        yield put(productInstanceApiError(error.response));
    }
}


export function* watchGetProductInstanceList() {
    yield takeEvery(GET_PRODUCT_INSTANCE_LIST, getProductInstanceList)
}

export function* watchAddProductInstance() {
    yield takeEvery(ADD_PRODUCT_INSTANCE, addProductInstance)
}

export function* watchGetProductInstanceGroupedList() {
    yield takeEvery(GET_PRODUCT_INSTANCE_GROUPED_LIST, getProductInstanceGroupedList)
}


function* ProductInstanceSaga() {
    yield all([
        fork(watchGetProductInstanceList),
        fork(watchAddProductInstance),
        fork(watchGetProductInstanceGroupedList)
    ]);
}

export default ProductInstanceSaga;