import React from "react";
import {Redirect} from "react-router-dom";
import  asyncImportComponent from "../async/asyncComponent"

// Pages Component


import FormCompanyInfo from "../pages/Company/FormCompanyInfo";
import FormCompanyCheck from "../pages/Company/FormCompanyCheck";
import FormCompanyCheckPass from "../pages/Company/FormCompanyCheckPass";
import FormCompanyCheckNoPass from "../pages/Company/FormCompanyCheckNoPass";
import FromProduct from "../pages/Product/FormProduct";
import Inventory from "../pages/Product/Inventory";
import PackedPackage from "../pages/Product/PackedPackage";
import ShippedPackage from "../pages/Product/ShippedPackage";
import TraceabilitySettings from "../pages/Product/TraceInfoSettings";
import TraceabilitySettings_u from "../pages/Extreme/Product/TraceInfoSettings_u";
import OrderHistory from "../pages/Product/OrderHistory";
//import GenerateQRCode from "../pages/Product/GenerateQRCode";
// const  GenerateQRCode =  asyncImportComponent(()=>(import("../pages/Product/GenerateQRCode")));
// // import GenerateBarcode from "../pages/Product/GenerateBarcode";
// const  GenerateBarcode =  asyncImportComponent(()=>(import("../pages/Product/GenerateBarcode")));
// // import HelpDoc from "../pages/Help/HelpDoc";
// const  HelpDoc =  asyncImportComponent(()=>(import("../pages/Help/HelpDoc")));
import DashBoard from "../pages/Dashboard/Dashboard"

// import FormResource from "../pages/Resource/FormResource";
// import ViewResource from "../pages/Resource/ViewResource";
import CodeBatches from "../pages/Batch/CodeBatches";
import TraceabilityRole from "../pages/TraceabilityRole/role";

import RoleSettings from  "../pages/TraceabilityRole/RoleSetting";
import Distribute from "../pages/distribute/Distribute";
import CodeDistribution from "../pages/distribute/CodeDistribution";

import DistributeMap from "../pages/distribute/DistributeMap";

import Details from "../pages/distribute/details";
import WorkerList from "../pages/Worker/WorkerList";

// import ProductPlan from "../pages/plan/ProductPlan";
// import InfoNodes from "../pages/plan/InfoNodes";
// import NodeInfo from "../pages/plan/nodeinfo";
// import NodeInfoEditor from "../pages/plan/nodeinfoeditor";


// Authentication related pages
import Login from "../pages/Authentication/Login";
// import Login from "../pages/Authentication/Loadable"; // 异步加载Login组件
// import DownloadApp from "../pages/Authentication/DownloadApp";
//import LoginAdmin from "../pages/Authentication/LoginAdmin";
//import Logout from "../pages/Authentication/Logout";
//import Register from "../pages/Authentication/Register";

const  Register =  asyncImportComponent(()=>(import("../pages/Authentication/Register")));
const  Logout =  asyncImportComponent(()=>(import("../pages/Authentication/Logout")));
const  LoginAdmin =  asyncImportComponent(()=>(import("../pages/Authentication/LoginAdmin")));
const  DownloadApp =  asyncImportComponent(()=>(import("../pages/Authentication/DownloadApp")));



const  GenerateQRCode =  asyncImportComponent(()=>(import("../pages/Product/GenerateQRCode")));

// import GenerateBarcode from "../pages/Product/GenerateBarcode";
const  GenerateBarcode =  asyncImportComponent(()=>(import("../pages/Product/GenerateBarcode")));
// import HelpDoc from "../pages/Help/HelpDoc";
const  HelpDoc =  asyncImportComponent(()=>(import("../pages/Help/HelpDoc")));

const  NodeInfoEditor =  asyncImportComponent(()=>(import("../pages/plan/nodeinfoeditor")));
const  ProductPlan =  asyncImportComponent(()=>(import("../pages/plan/ProductPlan")));
const  InfoNodes =  asyncImportComponent(()=>(import("../pages/plan/InfoNodes")));
const  NodeInfo =  asyncImportComponent(()=>(import("../pages/plan/nodeinfo")));
const  ViewProduct  =  asyncImportComponent(()=>(import("../pages/Product/ViewProduct")));
const ProductData =  asyncImportComponent(()=>(import("../pages/Product/ProductData")));



//Extreme
const  GenerateQRCode_u =  asyncImportComponent(()=>(import("../pages/Extreme/Product/GenerateQRCode_u")));
const  HelpDoc_u =  asyncImportComponent(()=>(import("../pages/Extreme/Help/HelpDoc_u")));
const  ViewProduct_u =  asyncImportComponent(()=>(import("../pages/Extreme/Product/ViewProduct_u")));
const  ProductData_u =  asyncImportComponent(()=>(import("../pages/Extreme/Product/ProductData_u")));







// import ForgetPwd from "../pages/Authentication/FogetPassword";

// import Dashboard from "../pages/Dashboard";

// import Calendar from "../pages/Apps/Calendar/index";

// import EmailInbox from "../pages/Apps/Email/inbox";
// import EmailRead from "../pages/Apps/Email/read";
// import EmailCompose from "../pages/Apps/Email/compose";



// // Charts
// import ChartistChart from "../pages/Charts/ChartistChart";
// import ChartjsChart from "../pages/Charts/ChartjsChart";
// import ApexChart from "../pages/Charts/ApexChart";
// import SarklineChart from "../pages/Charts/SarklineChart";
// import EchartChart from "../pages/Charts/EChart";



const authProtectedRoutes = [
    // Calendar
    {path: "/dashboard", component: DashBoard},
    {path: "/company-info", component: FormCompanyInfo},
    {path: "/company-check", component: FormCompanyCheck},
    {path: "/company-pass", component: FormCompanyCheckPass},
    {path: "/company-nopass", component: FormCompanyCheckNoPass},
    {exact: true, path: "/view-product/:productId/:productName/:created_at", component: ProductData},
    {exact: true, path: "/view-product-u/:productId/:productName/:created_at", component: ProductData_u},
    {path: "/view-product", component: ViewProduct},
    {path: "/TraceabilitySettings", component: TraceabilitySettings},
    {path: "/TraceabilitySettings_u", component: TraceabilitySettings_u},
    {path: "/view-product-u", component: ViewProduct_u},
    {exact: true, path: "/form-product/:productId", component: FromProduct},
    {path: "/form-product", component: FromProduct},
    {path: "/trace-role-managment", component: TraceabilityRole},
    {exact: true, path: "/role-setting/:productId/:productName", component: RoleSettings},
    // { path: "/view-resource", component: ViewResource },
    // {exact: true, path: "/form-resource/:resourceId", component: FormResource},
    // {path: "/form-resource", component: FormResource},
    {exact: true, path: "/inventory/product/:productId/:productName/:from/:to", component: Inventory},
    {exact: true, path: "/inventory/product/:productId/:productName", component: Inventory},
    {exact: true, path: "/inventory/package/:packageId", component: Inventory},
    {path: "/inventory", component: Inventory},
    {path: "/packed-package", component: PackedPackage},
    {path: "/shipped-package", component: ShippedPackage},
    {path: "/order-history", component: OrderHistory},
    {path: "/generate-qr-code", component: GenerateQRCode},
    {path: "/generate-qr-code-u", component: GenerateQRCode_u},
    
    {path: "/generate-barcode", component: GenerateBarcode},
    {path: "/code-batch-management", component: CodeBatches},
    {path: "/product-area", component: Distribute},
    {path: "/view-Distribution", component: CodeDistribution},
    {path: "/DistributeMap", component: DistributeMap},
    {path: "/workers", component: WorkerList},
    {exact: true, path: "/productplan/edit/:nodeId/:productName/:planId", component: NodeInfoEditor},
    {exact: true, path: "/productplan/:nodeId/:productName", component: NodeInfo},
    {path: "/productplan", component: ProductPlan},
    {path: "/infonodes", component: InfoNodes},
 
    {exact: true, path: "/distribution/product/:productId", component: Details},
    {path: "/help-doc", component: HelpDoc},
    {path: "/help-doc-u", component: HelpDoc_u},

//   // Calendar
//   { path: "/calendar", component: Calendar },
//   { path: "/pages-faq", component: PagesFaq },
//   { path: "/dashboard", component: Dashboard },
    {path: "/", exact: true, component: () => <Redirect to="/login"/>}
];

const publicRoutes = [
    {path: "/logout", component: Logout},
    {path: "/login", component: Login},
    {path: "/downloadapp", component: DownloadApp},
    {path: "/login-admin", component: LoginAdmin},
//   { path: "/", component: Login },
//   { path: "/forget-password", component: ForgetPwd },
    {path: "/pages-register", component: Register},

];

export {authProtectedRoutes, publicRoutes};
