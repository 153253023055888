import { init } from 'echarts';
import {
    GET_RESOURCE_INFO,
    GET_RESOURCE_INFO_SUCCESS,
    CREATE_RESOURCE,
    UPDATE_RESOURCE_INFO,
    GET_RESOURCE_LIST,
    GET_RESOURCE_LIST_SUCCESS,
    CLEAR_RESOURCE_PROPS,
    GET_RESOURCE_PLENTY_LIST,
    GET_RESOURCE_PLENTY_LIST_SUCCESS,

    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,
    SHOW_ALERT,
    CLEAR_ALERT,
    UPDATE_PRODUCT_SYSTEM_STATUS,
    UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS,

   
    API_ERROR,
} from './actionTypes';

const initialState = {
    resource: {
        r_id: null,
        name: '',
        batch_num: null,
        useup: null
    },
    // product: {
    //     productId: null,
    //     name: 'test 1',
    //     systemStatus: 'enabled',
    //     description: 'test 2',
    //     productionAddress: 'test 3',
    //     images: null,
    // },
    itemList: [],
    resourceImages: {},
    filteredCount: 0,
    totalItemCount: 0,
    inputError: {
        resouceId: true,
        name: true,
        batch_num: true,
        useup: true,
       
    },
    // search: {
    //     productId: '',
    //     name: '',
    //     systemStatus: 'enabled',
    // },
    alert: {
        success: false,
        successMessage: '',
    },
    error: null,
    loading: false
}

const resouceInfo = (state = initialState, action) => {
    let resource = { ...state.resource };
    // let productImages = { ...state.productImages };
    let inputError = { ...state.inputError };
    let alert = { ...state.alert };

    switch(action.type) {
        case GET_RESOURCE_INFO:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_RESOURCE_INFO_SUCCESS:
            console.log('reducer debug', action.payload.data.data);
            let data = action.payload.data.data;

            resource.r_id = data.r_id;
            resource.name = data.name;
            resource.batch_num = data.batch_num;
            resource.useup = data.useup;
        

            state = {
                ...state,
                resource: resource,
                loading: false,
            }
            break;
        case GET_RESOURCE_LIST:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_RESOURCE_LIST_SUCCESS:
            console.log('reducer debug', action.payload.data);
            state = {
                ...state,
                itemList: action.payload.data.data,
                filteredCount: action.payload.data.filteredCount,
                totalItemCount: action.payload.data.totalProductCount,
                loading: false,
            }
            break;
        case CREATE_RESOURCE:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_RESOURCE_INFO:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_RESOURCE_PLENTY_LIST:
            state = {
             ...state,
                loading: true
            }
            break;
        case GET_RESOURCE_PLENTY_LIST_SUCCESS:
            console.log('reducer debug', action.payload.data);
            state = {
                ...state,
                itemList: action.payload.data.data,
                loading: false,
            }
            break;
        case UPDATE_PRODUCT_SYSTEM_STATUS:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS:
            state = {
                ...state,
                loading: false
            }
            break;
        case UPDATE_INPUT_VALUE:
            resource[action.payload.inputName] = action.payload.value;

            state = {
                ...state,
                resource
            }
            break;
        case CLEAR_INPUT_ERROR:
            console.log('clear input error', action);

            inputError[action.payload] = true;

            console.log('after clear input error', inputError)

            state = {
                ...state,
                inputError,
            }
            break;
        case SHOW_ALERT:
            console.log('show alert', action.payload);

            alert[action.payload] = true;
            state = {
                ...state,
                alert,
                loading: false
            }
            break;
        case CLEAR_ALERT:
            // console.log('clear alert', action.payload);

            alert[action.payload] = false;
            state = {
                ...state,
                alert,
            }
            break;
        case CLEAR_RESOURCE_PROPS:
            state = {
                ...state,
                resource: initialState.resource,
            }
            break;
        case API_ERROR:
            console.log('API Error', action.payload);

            if(action.payload !== undefined) {
                for(let tmp of action.payload.data.errs) {
                    console.log('loop error', tmp);
                    if(tmp.field !== undefined) {
                        switch(tmp.field) {
                            case 'name':
                                inputError.name = tmp.error;
                                break;
                            case 'systemStatus':
                                inputError.systemStatus = tmp.error;
                                break;
                            case 'description':
                                inputError.description = tmp.error;
                                break;
                            case 'productionAddress':
                                inputError.productionAddress = tmp.error;
                                break;
                            case 'image':
                                inputError.image = tmp.error;
                                break;
                        }
                    }
                }
            }

            state = {
                ...state,
                inputError,
                error: action.payload,
                loading: false
            };
            break;
         // case GET_PRODUCT_IMAGE_SUCCESS:
        //     console.log('reducer debug', action.payload);

        //     productImages[action.payload.productId] = [];

        //     for(let tmp of action.payload.responses) {
        //         productImages[action.payload.productId].push(tmp.data);
        //     }

        //     state = {
        //         ...state,
        //         productImages,
        //         loading: false
        //     }
        //     break;
        // case GET_PRODUCT_LIST:
        //     state = {
        //         ...state,
        //         loading: true
        //     }
        //     break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default resouceInfo;