import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import i18n from 'i18next'
export default function Switches(props) {
    const checked = props.checked

    const handleChange = () => { props.onChange() }

    return (
        <div>
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        value="end"
                        control={
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />}
                        label={i18n.t('display_as_package')}
                        labelPlacement="end"
                    />
                </FormGroup>
            </FormControl>
        </div>
    );
}

