import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { 

    GET_BATCH_LIST,
    UPDATE_BATCH_INFO,
    DOWNLOAD_CODE_BATCH,
    DOWNLOAD_CODE_BATCH_SUCCESS,
    SHOW_BATCH_ALERT,

} from './actionTypes';


import { 
         getBatchListSuccess, 
         showAlert, 
         apiError 
} from './actions';


//AUTH related methods
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();


function* getBatchList({ payload: { urlQuery, history }}) {
    console.log('* getBatchList');
    try {
       
        const response = yield call(backend.getBatchList, { urlQuery, authToken: backend.getAuthenticatedUser() });

      //  console.log('test response', response, response.status);

        if (response.status === 200) {
           
            yield put(getBatchListSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        // console.log('API Error', error.response.data);
        // console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* updateBatchInfo({ payload: { data, history }}) {
    console.log('* getProductList');
    try {
        const response = yield call(backend.updateBatchInfo, { ...data, authToken: backend.getAuthenticatedUser() });

        // console.log('test response', response, response.status);

        if (response.status === 200) {
            yield put({ type: SHOW_BATCH_ALERT, payload: 'success' });
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* downloadCodeBatch({ payload: { urlQuery, history }}) {
    console.log('* downloadCodeBatch');
    try {
        const response = yield call(backend.downloadCodeBatch, {urlQuery:urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
            yield put({ type: DOWNLOAD_CODE_BATCH_SUCCESS, payload: { data: response } });
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}



export function* watchGetBatchList() {
    yield takeEvery(GET_BATCH_LIST, getBatchList)
}

export function* watchUpdateBatchInfo() {
    yield takeEvery(UPDATE_BATCH_INFO, updateBatchInfo)
}

export function* watchDownloadCodeBatch() {
    yield takeEvery(DOWNLOAD_CODE_BATCH, downloadCodeBatch)
}



function* DistributionSaga() {
    yield all([
        fork(watchGetBatchList),
        fork(watchUpdateBatchInfo),
        fork(watchDownloadCodeBatch),

    ]);
}

export default DistributionSaga;