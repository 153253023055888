import { message } from 'antd';
import { init } from 'echarts';
import {

    GET_BATCH_LIST,
    GET_BATCH_LIST_SUCCESS,
    UPDATE_BATCH_INFO,
    DOWNLOAD_CODE_BATCH,
    DOWNLOAD_CODE_BATCH_SUCCESS,
    CLEAR_PRODUCT_INSTANCE_CSV,
    UPDATE_BATCH_INFO_SUCCESS,
    SHOW_BATCH_ALERT,
    CLEAR_BATCH_ALERT,



    GET_PRODUCT_IMAGE,
    GET_PRODUCT_IMAGE_SUCCESS,
    CLEAR_PRODUCT_IMAGE_ID_PROP,
    CREATE_PRODUCT,
    UPDATE_PRODUCT_INFO,
    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,

    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,

    UPDATE_PRODUCT_SYSTEM_STATUS,
    // UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS,

    CLEAR_PRODUCT_PROPS,
    API_ERROR,
} from './actionTypes';

const initialState = {

    BatchList: [],
    productImages: {},
    detailImages: {},
    filteredCount: 0,
    totalItemCount: 0,
    inputError: {
        productId: true,
        name: true,
        systemStatus: true,
        description: true,
        image: true,
    },
    // search: {
    //     productId: '',
    //     name: '',
    //     systemStatus: 'enabled',
    // },
    alert: {
        success: false,
        successMessage: '',
    },
    error: null,
    loading: false,
    productInstanceCsv: null,
}

const batchInfo = (state = initialState, action) => {
    let product = { ...state.product };
    let productImages = { ...state.productImages };
    let detailImages = { ...state.detailImages };
    let inputError = { ...state.inputError };
    let alert = { ...state.alert };

    switch(action.type) {
        case GET_BATCH_LIST:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_BATCH_LIST_SUCCESS:
            console.log('reducer debug', action.payload.data.data);
            state = {
                ...state,
                BatchList: action.payload.data.data.batchInfo,
                filteredCount: action.payload.data.data.filteredCount,
                totalItemCount: action.payload.data.data.total,
                loading: false,
            }
            break;
        case UPDATE_BATCH_INFO:
            state = {
                ...state,
                loading: true
            }
            break;

        case DOWNLOAD_CODE_BATCH_SUCCESS:
                state = {
                    ...state,
                    productInstanceCsv: action.payload.data.data,
                }
            break;
        case CLEAR_PRODUCT_INSTANCE_CSV:
            state = {
                ...state,
                productInstanceCsv: null,
            }
            break;
 

        case GET_PRODUCT_IMAGE:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_IMAGE_SUCCESS:
            console.log('reducer debug', action.payload);

            productImages[action.payload.productId] = [];

            for(let tmp of action.payload.responses) {
                productImages[action.payload.productId].push(tmp.data);
            }

            state = {
                ...state,
                productImages,
                loading: false
            }
            break;
        case CLEAR_PRODUCT_IMAGE_ID_PROP:
            product.productImageId = null;
            productImages = {};

            state = {
                ...state,
                // productCertificate: null,
                product: product,
                productImages: productImages,
            }
            break;
        case GET_PRODUCT_LIST:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_LIST_SUCCESS:
            console.log('reducer debug', action.payload.data);

            state = {
                ...state,
                productList: action.payload.data.data,
                filteredCount: action.payload.data.filteredCount,
                totalProductCount: action.payload.data.totalProductCount,
                loading: false,
            }
            break;
        case CREATE_PRODUCT:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_PRODUCT_INFO:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_PRODUCT_SYSTEM_STATUS:
            state = {
                ...state,
                loading: true
            }
            break;
        // case UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS:
        //     state = {
        //         ...state,
        //         loading: false
        //     }
        //     break;
        case UPDATE_INPUT_VALUE:
            product[action.payload.inputName] = action.payload.value;

            state = {
                ...state,
                product
            }
            break;
        case CLEAR_INPUT_ERROR:
            console.log('clear input error', action);

            inputError[action.payload] = true;

            console.log('after clear input error', inputError)

            state = {
                ...state,
                inputError,
            }
            break;
        case SHOW_BATCH_ALERT:
            console.log('show alert', action.payload);

            alert[action.payload] = true;
            state = {
                ...state,
                alert,
                loading: false
            }
            break;
        case CLEAR_BATCH_ALERT:
            console.log('clear alert', action.payload);

            alert[action.payload] = false;
            state = {
                ...state,
                alert,
            }
            break;
        case CLEAR_PRODUCT_PROPS:
            state = {
                ...state,
                productList: initialState.productList,
                product: initialState.product,
            }
            break;
        case API_ERROR:
            // console.log('API Error', action.payload);

            if(action.payload !== undefined) {
                for(let tmp of action.payload.data.errs) {
                    console.log('loop error', tmp);
                    // message.warning({
                    //     duration: 0,
                    //     content: tmp.error
                    // });
                    message.warning(tmp.error);
                    if(tmp.field !== undefined) {
                        switch(tmp.field) {
                            case 'name':
                                inputError.name = tmp.error;
                                break;
                            case 'systemStatus':
                                inputError.systemStatus = tmp.error;
                                break;
                            case 'description':
                                inputError.description = tmp.error;
                                break;
                            case 'productionAddress':
                                inputError.productionAddress = tmp.error;
                                break;
                            case 'shopUrl':
                                inputError.shopUrl = tmp.error;
                                break;
                            case 'image':
                                inputError.image = tmp.error;
                                break;
                        }
                    }
                }
            }

            state = {
                ...state,
                inputError,
                error: action.payload,
                loading: false
            };
            break;
        default:
            // state = { ...state };
            break;
    }
    return state;
}

export default batchInfo;
