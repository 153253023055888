import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';

import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';


import { registerLocale, setDefaultLocale } from "react-datepicker";
import zh from 'date-fns/locale/zh-CN';
import en from 'date-fns/locale/en-US';
registerLocale('zh', zh);
registerLocale('en', en)

if(!sessionStorage.getItem("lang")) {
    setDefaultLocale('zh');
} else {
    setDefaultLocale(sessionStorage.getItem("lang"));
}

const app = (
    <Provider store={store}>
        
            <BrowserRouter>
                <PersistGate loading={null} persistor={persistStore(store)}>
                     <App />
                </PersistGate>
            </BrowserRouter>
        
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
