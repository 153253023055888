import {
    SHOW_ALERT,
    CLEAR_ALERT,
    GET_PACKAGE_LIST,
    GET_COMPANY_LIST,
    UPDATE_PACKAGE,
    UPDATE_COMPANY_STATUS,
    ADD_PACKAGE,
    PACKAGE_API_ERROR,
    CLEAR_PACKAGE_CSV,
    CLEAR_PACKAGE_INPUT_ERROR,
} from './actionTypes';


export const getCompanyList = (urlQuery, history) => {
    return {
        type: GET_COMPANY_LIST,
        payload: { urlQuery, history }
    }
}

export const getPackageList = (urlQuery, history) => {
    return {
        type: GET_PACKAGE_LIST,
        payload: { urlQuery, history }
    }
}

export const updatePackage = (data, history) => {
    console.log('action updatePackage()', data);
    return {
        type: UPDATE_PACKAGE,
        payload: { data, history }
    }
}

export const updateComanyStatus = (data, history) => {
    console.log('action updateComanyStatus()', data);
    return {
        type: UPDATE_COMPANY_STATUS,
        payload: { data, history }
    }
}

export const addPackage = (data, history) => {
    console.log('action addPackage()', data);
    return {
        type: ADD_PACKAGE,
        payload: { data, history }
    }
}

export const clearPackageCSV = (inputName) => {
    return {
        type: CLEAR_PACKAGE_CSV,
    }
}

export const clearInputError = (inputName) => {
    return {
        type: CLEAR_PACKAGE_INPUT_ERROR,
        payload: inputName,
    }
}

export const showAlert = (alertName) => {
    return {
        type: SHOW_ALERT,
        payload: alertName,
    }
}

export const clearAlert = (alertName) => {
    return {
        type: CLEAR_ALERT,
        payload: alertName,
    }
}

export const packageApiError = (error) => {
    return {
        type: PACKAGE_API_ERROR,
        payload: error
    }
}
