import React from 'react';
import {Pagination, ConfigProvider} from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';

moment.locale('zh-cn');

class AntdPagination extends React.Component {
    constructor(props) {
        super(props);
        console.log('分页组件构造器====>>>', props);
        this.state = {
            total: this.props.total,
            componentDidUpdateNum: 0
        };
        this.changePageHandle = this.changePageHandle.bind(this);
        this.changePageSizeHandle = this.changePageHandle.bind(this);
    }

    componentDidMount() {
        console.log('===分页组件渲染完成===')
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('===分页组件更新完成===');
        console.log(prevProps);
        console.log(prevState);
        this.state.componentDidUpdateNum += 1;
        console.log('分页组件===>>>', this.state.componentDidUpdateNum);
        const total = this.props.total;
        console.log('数据总条数===>>>', total);
        if (this.state.componentDidUpdateNum > 1 && prevProps.total !== prevState.total) {
            console.log('分页组件更新total啦===>>>', prevProps.total);
            this.setState({
                total: total
            })
        }
    }

    changePageHandle(page, pageSize) {
        console.log('===页码改变===');
        console.log(page, pageSize);
        this.props.antdPageChange(page, pageSize)
    }

    changePageSizeHandle(current, size) {
        console.log('===显示条数改变===');
        console.log(current, size);
        this.props.antdPageSizeChange(current, size)
    }

    render() {
        return (
            <>
                <ConfigProvider locale={zhCN}>
                    <Pagination
                        total={this.state.total}
                        showSizeChanger
                        showQuickJumper
                        onChange={this.changePageHandle}
                        onShowSizeChange={this.changePageSizeHandle}
                        showTotal={total => `共 ${total} 条`}
                    />
                </ConfigProvider>
            </>
        )
    }
}

export default AntdPagination;
