import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { 
    GET_NODE_BY_ID,
    UPDATE_PRODUCT_INFO, 
} from './actionTypes';
import { getNodebyIDSuccess,showAlert, apiError } from './actions';

import validator from 'validator';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();


function* getNodebyID({ payload: { urlQuery, history } }) {
    console.log('* getNodebyID');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.getNodeById, {  urlQuery, authToken: backend.getAuthenticatedUser() });

        if (response.status === 200) {
           
            yield put(getNodebyIDSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}







function* addProductPlan({ payload: { data, history } }) {
    console.log('* updateProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.addProductPlan, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}


function* putProductPlan({ payload: { data, history } }) {
    console.log('* updateProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.putProductPlan, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}



function* updateProductInfo({ payload: { data, history } }) {
    console.log('* updateProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.updateProductInfo, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* updateProductSystemStatus({ payload: { data, history } }) {
    console.log('* updateProductSystemStatus');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.updateProductSystemStatus, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            yield put({ type: 'UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

export function* watchGetNodebyID() {
    yield takeEvery(GET_NODE_BY_ID, getNodebyID)
}




function* NodeSaga() {
    yield all([
        fork(watchGetNodebyID),
        
    ]);
}

export default NodeSaga;