export const GET_PRODUCT_PLAN = 'GET_PRODUCT_PLAN';
export const GET_PRODUCT_PLAN_SUCCESS = 'GET_PRODUCT_PLAN_SUCCESS';

export const GET_HISTORY_DYNAMIC = 'GET_HISTORY_DYNAMIC';
export const GET_HISTORY_DYNAMIC_SUCCESS = 'GET_HISTORY_DYNAMIC_SUCCESS';

export const GET_DYNAMIC_BY_PRODUCT = 'GET_DYNAMIC_BY_PRODUCT';

export const GET_DYNAMIC_BY_PRODUCT_SUCCESS = 'GET_DYNAMIC_BY_PRODUCT_SUCCESS';

export const PUT_PRODUCT_PLAN = 'PUT_PRODUCT_PLAN';
export const PUT_PRODUCT_PLAN_SUCCESS = 'PUT_PRODUCT_PLAN_SUCCESS';

export const GET_PRODUCT_WITD_PLAN = 'GET_PRODUCT_WITD_PLAN';

export const GET_PRODUCT_WITD_PLAN_SUCCESS = 'GET_PRODUCT_WITD_PLAN_SUCCESS';






export const ADD_PRODUCT_PLAN = 'ADD_PRODUCT_PLAN';
export const ADD_PRODUCT_PLAN_SUCCESS = 'ADD_PRODUCT_PLAN_SUCCESS';


export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const UPDATE_PRODUCT_SYSTEM_STATUS = 'UPDATE_PRODUCT_SYSTEM_STATUS';
export const UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS = 'UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS';

export const CLEAR_PRODUCT_PROPS = 'CLEAR_PRODUCT_PROPS';

export const API_ERROR = 'PRODUCT_API_ERROR';
