import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import CompanyInfoSaga from './companyInfo/saga';
import ProductInfoSaga from './productInfo/saga';
import productInstanceSaga from './productInstance/saga';
import packageSaga from './package/saga';
import ResourceInfoSaga from  './resourceInfo/saga';
import BatchSaga from  './batch/saga';
import TraceabilityTole from  './traceabilityrole/saga';
import DistributionSaga from  './distribute/saga';
import MenuSaga from  './menu/saga';
import ProductPlanSaga from  './plan/saga';
import NodeSaga from  './node/saga';




export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        LayoutSaga(),
        CompanyInfoSaga(),
        ProductInfoSaga(),
        productInstanceSaga(),
        packageSaga(),
        ResourceInfoSaga(),
        BatchSaga(),
        TraceabilityTole(),
        DistributionSaga(),
        MenuSaga(),
        ProductPlanSaga(),
        NodeSaga(),
    ])
}