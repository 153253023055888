export const GET_RESOURCE_INFO = 'GET_RESOURCE_INFO';
export const GET_RESOURCE_INFO_SUCCESS = 'GET_RESOURCE_INFO_SUCCESS';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const UPDATE_RESOURCE_INFO = 'UPDATE_RESOURCE_INFO';
export const GET_RESOURCE_LIST = 'GET_RESOURCE_LIST';
export const GET_RESOURCE_LIST_SUCCESS = 'GET_RESOURCE_LIST_SUCCESS';
export const CLEAR_RESOURCE_PROPS = 'CLEAR_RESOURCE_PROPS';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const GET_RESOURCE_PLENTY_LIST = 'GET_RESOURCE_PLENTY_LIST';
export const GET_RESOURCE_PLENTY_LIST_SUCCESS = 'GET_RESOURCE_PLENTY_LIST_SUCCESS';




export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const UPDATE_PRODUCT_SYSTEM_STATUS = 'UPDATE_PRODUCT_SYSTEM_STATUS';
export const UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS = 'UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS';



export const API_ERROR = 'RESOURCE_API_ERROR';
