import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { 

    GET_PRODUCT_DISTRIBUTION_LIST,
    GET_PRODUCT_DISTRIBUTION_DETAIL,
    GET_PRODUCT_DISTRIBUTION_DETAIL_SUCCESS,
    GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL,

} from './actionTypes';


import { 
         getDistributionListSuccess, 
         getDistributionDetailSuccess,
         getDistributionDetailByProductSuccess,

         apiError 
} from './actions';

import validator from 'validator';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();


function* getDistributionList({ payload: { urlQuery, history }}) {
    console.log('* getDistributionList');
    try {
       
        const response = yield call(backend.getDistributionList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
           
            yield put(getDistributionListSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        // console.log('API Error', error.response.data);
        // console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* getDistributionDetail({ payload: { urlQuery, history }}) {
    console.log('* getDistributionDetail');
    try {
        const response = yield call(backend.getDistributionDetail, {urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
           // yield put({ type: SHOW_ALERT, payload: 'success' });
           yield put(getDistributionDetailSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* getDistributionDetailByProduct({ payload: { urlQuery, history }}) {
    console.log('* getDistributionDetailByProduct');
    try {
        const response = yield call(backend.getDistributionDetailByProduct, {urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
           // yield put({ type: SHOW_ALERT, payload: 'success' });
           yield put(getDistributionDetailByProductSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}










export function* watchDistributionList() {
    yield takeEvery(GET_PRODUCT_DISTRIBUTION_LIST, getDistributionList)
}

export function* watchDistributionDetail() {
    yield takeEvery(GET_PRODUCT_DISTRIBUTION_DETAIL, getDistributionDetail)
}


export function* watchDistributionDetailByProduct() {
    yield takeEvery(GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL, getDistributionDetailByProduct)
}


function* BatchSaga() {
    yield all([
        fork(watchDistributionList),
        fork(watchDistributionDetail),
        fork(watchDistributionDetailByProduct),

    ]);
}

export default BatchSaga;