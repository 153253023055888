
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const SHOW_SUCCESS = "SHOW_SUCCESS"; // temp changes to quick fix bug, should not be used as this way
export const GET_PRODUCT_INSTANCE_LIST = 'GET_PRODUCT_INSTANCE_LIST';
export const GET_PRODUCT_INSTANCE_LIST_SUCCESS = 'GET_PRODUCT_INSTANCE_LIST_SUCCESS';
export const ADD_PRODUCT_INSTANCE = 'ADD_PRODUCT_INSTANCE';
export const ADD_PRODUCT_INSTANCE_SUCCESS = 'ADD_PRODUCT_INSTANCE_SUCCESS';
export const CLEAR_PRODUCT_INSTANCE_CSV = 'CLEAR_PRODUCT_INSTANCE_CSV';

export const PRODUCT_INSTANCE_API_ERROR = 'PRODUCT_INSTANCE_API_ERROR';

export const GET_PRODUCT_INSTANCE_GROUPED_LIST = 'GET_PRODUCT_INSTANCE_GROUPED_LIST';
export const GET_PRODUCT_INSTANCE_GROUPED_LIST_SUCCESS = 'GET_PRODUCT_INSTANCE_GROUPED_LIST_SUCCESS';