
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import i18n from '../i18n';
import { Translation } from 'react-i18next';

// users
import user4 from '../assets/images/users/user-4.jpg';
import backend from '../helpers/backend';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }


    render() {

        return (
            <Translation>
                {
                    (t, { i18n }) =>
                        <React.Fragment>
                            <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block font-size-19-5" >
                                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown font-size-19-5" tag="button">
                                    {/* <img className="rounded-circle header-profile-user" src={user4} alt="Header Avatar" /> */}
                                    <i className="far fa-user font-size-19-5"></i>
                                    <span className="ml-2 font-size-19-5">{backend.getAuthenticatedUsername()}</span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {/* <DropdownItem tag="a" href="#"><i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>Profile</DropdownItem>
                        <DropdownItem tag="a" href="#"><i className="mdi mdi-wallet font-size-17 align-middle mr-1"></i>My Wallet</DropdownItem>
                        <DropdownItem tag="a" href="#"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>Settings</DropdownItem>
                        <DropdownItem tag="a" href="#"><i className="mdi mdi-lock-open-outline font-size-17 align-middle mr-1"></i>Lock screen</DropdownItem> */}
                                    {/* <div className="dropdown-divider"></div> */}
                                    <Link
                                        to='/logout'
                                        className="dropdown-item">
                                        <i className="mdi mdi-logout font-size-19-5 align-middle mr-1"></i>
                                        <span className="font-size-19-5">{t('logout')}</span>
                                    </Link>
                                    {/* <DropdownItem tag="a" href="#">V 2.0.1</DropdownItem> */}
                                </DropdownMenu>
                            </Dropdown>
                        </React.Fragment>
                }
            </Translation>
        );
    }
}

export default withRouter(ProfileMenu);


