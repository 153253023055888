// @flow
import * as React from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import WarningIcon from '@material-ui/icons/Warning';
import { green, blue, red } from "@material-ui/core/colors";
import StoreIcon from '@material-ui/icons/Store';
import i18n from "i18next"
import { useEffect } from 'react';
import { parse, parseISO, differenceInCalendarDays } from "date-fns";
import {useState} from "react";
import RefreshIcon from '@material-ui/icons/Refresh';

export const LogisticStatusBtn = (props) => {
    const dataObject = props.data;
    const status = props.data.status;
    const currentTime = props.time;
    const shippedDate = props.data.shippedDate;
    const [diffInDays, setDiffInDays] = useState()

    useEffect(() => {
        let time = parse(shippedDate, "yyyy-MM-dd HH:mm:ss", new Date())
        const diff = differenceInCalendarDays(currentTime, time)
        setDiffInDays(diff)
    }, [currentTime])

    if (status === "completed") {
        return (
            <div title={i18n.t("signed")} style={{ cursor:"help",display: "inline-block" }}>
                <AssignmentTurnedInIcon title="arrived" style={{ color: green[500] }} />
            </div>
        )
    }

    if (status === "inbound" || status === "packed") {
        return (
            <div title={i18n.t("at_store")}>
                <StoreIcon style={{ cursor:"help"}}/>
            </div>
        )
    }

    return (
        <>
            {console.log('是否重新渲染')}
            {console.log('diffInDays====='+ diffInDays)}
            {diffInDays > 5 ?
             <div title={i18n.t("exceed_time_tip")}>
                <WarningIcon style={{ cursor:"help", color: red[500]}} />
            </div> :
            <div>
                    <div title={i18n.t("delivering")} style={{display:"inline-block"}}> <LocalShippingIcon style={{cursor:"help", color: blue[500] }} /> </div>
                    <RefreshIcon style={{display:"none", cursor:"pointer"}} onClick={()=>{console.log("recheck status clicked")}}/>
                </div>
            }
        </>
    );
};
