import {
    GET_NODE_BY_ID,
    GET_NODE_BY_ID_SUCCESS,


    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,
    SHOW_ALERT,
    CLEAR_ALERT,
    API_ERROR,
} from './actionTypes';




export const getNodebyID= (urlQuery, history) => {
    return {
        type: GET_NODE_BY_ID,
        payload: { urlQuery, history }
    }
}

export const getNodebyIDSuccess= (data) => {
    return {
        type: GET_NODE_BY_ID_SUCCESS,
        payload: { data}
    }
}






export const updateInputValue = (inputName, value) => {
    console.log('action updateInputValue()', inputName);
    return {
        type: UPDATE_INPUT_VALUE,
        payload: { inputName, value }
    }
}

export const clearInputError = (inputName) => {
    return {
        type: CLEAR_INPUT_ERROR,
        payload: inputName,
    }
}

export const showAlert = (alertName) => {
    return {
        type: SHOW_ALERT,
        payload: alertName,
    }
}

export const clearAlert = (alertName) => {
    return {
        type: CLEAR_ALERT,
        payload: alertName,
    }
}


export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}
