import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import { GET_PRODUCT_INFO, GET_PRODUCT_LIST, 
    GET_PRODUCT_IMAGE, CREATE_PRODUCT, 
    UPDATE_PRODUCT_INFO, 
    GET_PRODUCT_INVENTORY,
    UPDATE_PRODUCT_SYSTEM_STATUS,
    GET_PRODUCT_INVENTORY_SUCCESS,
} from './actionTypes';
import { getProductInfoSuccess, getProductImageSuccess, getProductListSuccess, showAlert, apiError } from './actions';

import validator from 'validator';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();

function* getProductInfo({ payload: { productId, history } }) {
    console.log('* getProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.getProductInfo, { productId: productId, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if (response.status === 200) {
            yield put(getProductInfoSuccess(response));

            // history.push('/dashboard');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        
        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* getProductInventory({ payload: { urlQuery, history } }) {
   
    try {


        
        const response = yield call(backend.getProductInventory, { urlQuery, authToken: backend.getAuthenticatedUser() });

        // console.log('test response', response, response.status);

        if (response.status === 200) {
            //yield put(getProductListSuccess(response));
            yield put({ type: 'GET_PRODUCT_INVENTORY_SUCCESS', payload: {data:response.data.data} });
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* getProductList({ payload: { urlQuery, history } }) {

    try {
        const response = yield call(backend.getProductList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        if (response.status === 200) {
            yield put(getProductListSuccess(response));
        }else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }
        console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* getProductImage({ payload: { productId, productImageId, history } }) {
    console.log('* getProductImage', productId, productImageId, history);
    try {
        let responses = yield all(
            productImageId.map(imageId => {
                console.log('testing image id', imageId);
                return call(backend.getProductImage, { productId, productImageId: imageId, authToken: backend.getAuthenticatedUser() })
            })
        );

        console.log('test responses', responses);

        let validResponse = true;

        for(let tmp of responses) {
            if(tmp.status !== 200) {
                validResponse = false;
            }
        }

        if (validResponse === true) {
            // yield put(getProductListSuccess(response));
            yield put({ type: 'GET_PRODUCT_IMAGE_SUCCESS', payload: {productId, responses} });
        }else {
            throw responses;
        }
    } catch(error) {

        if(backend.handleError(error.response,history)){
            return;
        }

        console.log('API Error', error.responses);
        yield put(apiError(error.responses));
    }
}

function* createProduct({ payload: { data, history } }) {
    console.log('* createProduct');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.createProduct, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });

            // history.push('/view-product');
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {

        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

function* updateProductInfo({ payload: { data, history } }) {
    // console.log('* updateProductInfo');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.updateProductInfo, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            // yield put({ type: 'GET_PRODUCT_INFO', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {

        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* updateProductSystemStatus({ payload: { data, history } }) {
    console.log('* updateProductSystemStatus');
    try {
        // const response = yield call(fireBaseBackend.getProductInfo, user.email, user.password);
        const response = yield call(backend.updateProductSystemStatus, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if (response.status === 200) {
            yield put(showAlert('success'));
            yield put({ type: 'UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS', payload: {history} });
            // yield call(getProductInfo);
        }else {
            console.log('throw 2');
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        // console.log('API Error', error.response);
        yield put(apiError(error.response));
    }
}

export function* watchGetProductInfo() {
    yield takeEvery(GET_PRODUCT_INFO, getProductInfo)
}

export function* watchGetProductList() {
    yield takeEvery(GET_PRODUCT_LIST, getProductList)
}

export function* watchGetProductImage() {
    yield takeEvery(GET_PRODUCT_IMAGE, getProductImage)
}

export function* watchCreateProduct() {
    yield takeEvery(CREATE_PRODUCT, createProduct)
}

export function* watchUpdateProductInfo() {
    yield takeEvery(UPDATE_PRODUCT_INFO, updateProductInfo)
}

export function* watchGetProductInventory() {
    yield takeEvery(GET_PRODUCT_INVENTORY, getProductInventory)
}



export function* watchUpdateProductSystemStatus() {
    yield takeEvery(UPDATE_PRODUCT_SYSTEM_STATUS, updateProductSystemStatus)
}

function* ProductInfoSaga() {
    yield all([
        fork(watchGetProductInfo),
        fork(watchGetProductList),
        fork(watchGetProductImage),
        fork(watchCreateProduct),
        fork(watchUpdateProductInfo),
        fork(watchUpdateProductSystemStatus),
        fork(watchGetProductInventory),
    ]);
}

export default ProductInfoSaga;