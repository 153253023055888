import { init } from 'echarts';
import {
    SHOW_ALERT,
    SHOW_SUCCESS,
    CLEAR_ALERT,
    GET_PRODUCT_INSTANCE_LIST,
    GET_PRODUCT_INSTANCE_LIST_SUCCESS,
    ADD_PRODUCT_INSTANCE,
    ADD_PRODUCT_INSTANCE_SUCCESS,
    CLEAR_PRODUCT_INSTANCE_CSV,
    PRODUCT_INSTANCE_API_ERROR,
    GET_PRODUCT_INSTANCE_GROUPED_LIST,
    GET_PRODUCT_INSTANCE_GROUPED_LIST_SUCCESS
} from './actionTypes';

const initialState = {
    productInstanceCsv: null,
    //
    productInstanceList: [],
    filteredCount: 0,
    totalProductInstanceCount: 0,
    //
    productInstanceGroupedList:[],
    filteredGroupedCount: 0,
    totalGroupedProductInstanceCount: 0,
    //
    alert: {
        success: false,
        successMessage: '',
        error: false,
        errorMessage: '',
        errorDescription: '',
    },
    error: null,
    loading: false
}

const productInstance = (state = initialState, action) => {
    let alert = { ...state.alert };

    // console.log('reducer debug', action.type, action.payload);

    switch(action.type) {
        case GET_PRODUCT_INSTANCE_GROUPED_LIST:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_INSTANCE_GROUPED_LIST_SUCCESS:
            state = {
                ...state,
                productInstanceGroupedList: action.payload.data.data,
                filteredGroupedCount: action.payload.data.filteredGroupedCount,
                totalGroupedProductInstanceCount: action.payload.data.totalGroupedProductInstanceCount,
                loading: false,
            }
            break;
        case GET_PRODUCT_INSTANCE_LIST:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_INSTANCE_LIST_SUCCESS:
            state = {
                ...state,
                productInstanceList: action.payload.data.data,
                filteredCount: action.payload.data.filteredCount,
                totalProductInstanceCount: action.payload.data.totalProductInstanceCount,
                loading: false,
            }
            break;
        case ADD_PRODUCT_INSTANCE:
            state = {
                ...state,
                loading: true
            }
            break;
        case ADD_PRODUCT_INSTANCE_SUCCESS:
            state = {
                ...state,
                productInstanceCsv: action.payload.data.data,
                loading: true
            }
            break;
        case CLEAR_PRODUCT_INSTANCE_CSV:
            state = {
                ...state,
                productInstanceCsv: null,
                loading: true
            }
            break;
        case SHOW_ALERT:
            alert.error = true;
            alert.errorMessage =action.payload;
            state = {
                ...state,
                alert,
                loading: false
            }
            break;
        
        case SHOW_SUCCESS:
            alert.success = true;
            alert.successMessage =action.payload;
            state = {
                ...state,
                alert,
                loading: false
            }
            break;

        case CLEAR_ALERT:
            // alert[action.payload] = false;
            alert.success=false;
            alert.error= false;
            state = {
                ...state,
                alert,
            }
            break;

        case PRODUCT_INSTANCE_API_ERROR:
            for(let tmp of action.payload.data.errs) {
                if(tmp.field === undefined) {
                  alert.errorMessage = tmp.error;
                  alert.error = true;
                } else {
                  switch(tmp.field) {
                    case 'unit':
                      alert.errorMessage = 'Please check the unit entered'
                      alert.errorDescription = tmp.error;
                      alert.error = true;
                      break;
                  }
                }
              }

            state = {
                ...state,
                alert,
                error: action.payload,
                loading: false
            };
            break;
        default:
            // state = { ...state };
            break;
    }
    return state;
}

export default productInstance;