export const GET_PRODUCT_DISTRIBUTION_LIST = 'GET_PRODUCT_DISTRIBUTION_LIST';
export const GET_PRODUCT_DISTRIBUTION_LIST_SUCCESS = 'GET_PRODUCT_DISTRIBUTION_LIST_SUCCESS';
export const GET_PRODUCT_DISTRIBUTION_DETAIL = 'GET_PRODUCT_DISTRIBUTION_DETAIL';
export const GET_PRODUCT_DISTRIBUTION_DETAIL_SUCCESS = 'GET_PRODUCT_DISTRIBUTION_DETAIL_SUCCESS';
export const GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL = 'GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL';
export const GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DISTRIBUTION_PRODUCT_DETAIL_SUCCESS';


export const CLEAR_PRODUCT_INSTANCE_CSV = 'CLEAR_PRODUCT_INSTANCE_CSV';




export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const UPDATE_PRODUCT_SYSTEM_STATUS = 'UPDATE_PRODUCT_SYSTEM_STATUS';


export const CLEAR_PRODUCT_PROPS = 'CLEAR_PRODUCT_PROPS';

export const API_ERROR = 'PRODUCT_API_ERROR';

