import {
    GET_PRODUCT_INFO,
    GET_PRODUCT_INFO_SUCCESS,
    GET_PRODUCT_IMAGE,
    GET_PRODUCT_IMAGE_SUCCESS,
    CLEAR_PRODUCT_IMAGE_ID_PROP,
    CREATE_PRODUCT,
    UPDATE_PRODUCT_INFO,
    GET_PRODUCT_INVENTORY,
    
    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,
    SHOW_ALERT,
    CLEAR_ALERT,
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    UPDATE_PRODUCT_SYSTEM_STATUS,
    UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS,
    API_ERROR,
    CLEAR_PRODUCT_PROPS,
   
} from './actionTypes';



export const getProductInventory = (urlQuery, history) => {
    return {
        type: GET_PRODUCT_INVENTORY,
        payload: { urlQuery, history }
    }
}

export const getProductInfo = (productId, history) => {
    console.log('action getProductInfo()');
    return {
        type: GET_PRODUCT_INFO,
        payload: { productId, history }
    }
}

export const getProductInfoSuccess = (data) => {
    return {
        type: GET_PRODUCT_INFO_SUCCESS,
        payload: data
    }
}

export const getProductImage = (productId, productImageId, history) => {
    console.log('action getProductImage()');
    return {
        type: GET_PRODUCT_IMAGE,
        payload: { productId, productImageId, history }
    }
}

export const getProductImageSuccess = (data) => {
    return {
        type: GET_PRODUCT_IMAGE_SUCCESS,
        payload: data
    }
}

export const clearProductImageIdProp = () => {
    return {
        type: CLEAR_PRODUCT_IMAGE_ID_PROP,
    }
}

export const getProductList = (urlQuery, history) => {
    console.log('action getProductList()');
    return {
        type: GET_PRODUCT_LIST,
        payload: { urlQuery, history }
    }
}

export const getProductListSuccess = (data) => {
    return {
        type: GET_PRODUCT_LIST_SUCCESS,
        payload: data
    }
}

export const createProduct = (data, history) => {
    console.log('action createProduct()', data);
    return {
        type: CREATE_PRODUCT,
        payload: { data, history }
    }
}


export const updateProductInfo = (data, history) => {
    console.log('action updateProductInfo()', data);
    return {
        type: UPDATE_PRODUCT_INFO,
        payload: { data, history }
    }
}

export const updateProductSystemStatus = (data, history) => {
    console.log('action updateProductSystemStatus()', data);
    return {
        type: UPDATE_PRODUCT_SYSTEM_STATUS,
        payload: { data, history }
    }
}

// export const updateProductSystemStatusSuccess = (data, history) => {
//     console.log('action updateProductSystemStatus()', data);
//     return {
//         type: UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS,
//         payload: { data, history }
//     }
// }

export const updateInputValue = (inputName, value) => {
    console.log('action updateInputValue()', inputName);
    return {
        type: UPDATE_INPUT_VALUE,
        payload: { inputName, value }
    }
}

export const clearInputError = (inputName) => {
    return {
        type: CLEAR_INPUT_ERROR,
        payload: inputName,
    }
}

export const showAlert = (alertName) => {
    return {
        type: SHOW_ALERT,
        payload: alertName,
    }
}

export const clearAlert = (alertName) => {
    return {
        type: CLEAR_ALERT,
        payload: alertName,
    }
}

export const clearProductProps = () => {
    return {
        type: CLEAR_PRODUCT_PROPS
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}
