import React, {Component} from "react";
import {useTranslation} from 'react-i18next';
import {Row, Col, Card, CardBody, Alert} from "reactstrap";
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';

// Redux
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import LanguageDropdown from "../../components/LanguageDropdown";
// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";

import Loader from "../../components/Loader";
// actions
import {verifyUser,loginUser,apiError,clearApiError,RsetMenu} from "../../store/actions";

import i18n from '../../i18n';
import {Translation} from 'react-i18next';

// import images
import logoSm from "../../assets/images/logo-sm.png";
import backend from "../../helpers/backend";

import loginimag from '../../assets/images/loginimag3.png'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pwdType: 'password',
            pwdVal: '',
            isShow: false,
            pwdAuth: [],
            pwdAuthTxt: '弱'
        };

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.showPwd = this.showPwd.bind(this);
        this.pwdChange = this.pwdChange.bind(this);
    }

    componentDidMount() {
        if (backend.getAuthenticatedUser() !== null) {
            this.props.history.push('/dashboard');
        }else {
            if(sessionStorage.getItem('roleId')){
                sessionStorage.removeItem('roleId')
                sessionStorage.removeItem('companyStatus')
               // window.location.reload()
            }
        }
        if(this.props.location.state) {
            if( this.props.location.state.code ==408){
                 this.props.apiError(i18n.t("forbidden_by_others"));
            }
        }
        this.props.RsetMenu();
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        // console.log('handleValidSubmit()', values);
        if(this.props.error)
           this.props.clearApiError();
        if(this.props.logined == false){
            this.props.verifyUser(values, this.props.history);
        }
        else{
           
            this.props.loginUser(values, this.props.history);
        }
      
    }

    showPwd() {
        if (this.state.isShow) {
            this.setState({
                isShow: false,
                pwdType: 'password'
            })
        } else {
            this.setState({
                isShow: true,
                pwdType: 'text'
            })
        }
    }

    pwdChange(event, values) {
        let level = this.checkPassWord(values);
        switch (level) {
            case 1:
                this.setState({
                    pwdAuth: ['sp1', 'line', 'line'],
                    pwdAuthTxt: '弱'
                })
                break;
            case 2:
                this.setState({
                    pwdAuth: ['line', 'sp2', 'line'],
                    pwdAuthTxt: '中'
                })
                break;
            case 3:
                this.setState({
                    pwdAuth: ['line', 'line', 'sp3'],
                    pwdAuthTxt: '强'
                })
                break;
            case 5:
                this.setState({
                    pwdAuth: ['line', 'line', 'sp3'],
                    pwdAuthTxt: '强'
                })
                break;
            default:
                this.setState({
                    pwdAuth: [],
                    pwdAuthTxt: ''
                })
        }
    }

    //校验密码强度
    checkPassWord(value) {
        // 0：表示弱 1：表示中 2：表示强
        let modes = 0;
        if (value.length >= 6 && value.length <= 20 && /\d/.test(value) && (/[a-zA-Z]/.test(value))) {
            modes++;
            if (/[a-z]/.test(value) || /[A-Z]/.test(value)) {
                modes++;
            }
            if (/[a-z]/.test(value) && /[A-Z]/.test(value)) {
                modes++;
            }
            if (value.length >= 12 && (/[a-zA-Z]/.test(value))) {
                modes++;
            }
            if (value.length >= 12 && /[a-z]/.test(value) && /[A-Z]/.test(value)) {
                modes++;
            }
        } else {
            return 0;
        }
        return modes;
    }

    render() {
        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            <div className="account-pages login-page">
                                <div className="container">
                                    <Row className="justify-content-center">
                                        <Col md={8} lg={6} xl={5}>
                                            <img className="login-img" src={loginimag}/>
                                            <div className="position-relative top-nagtive">
                                                {/*{this.props.loading ? <Loader/> : null}*/}
                                                <Card className="overflow-hidden" style={{marginBottom: 0}}>
                                                    <div className="bg-primary">
                                                        <div className="text-primary text-center p-4-auth">
                                                            <h5 style={{'marginTop':'10px'}} className="text-white font-size-20">
                                                                {t('welcome_back')}
                                                            </h5>
                                                            <p className="text-white-50">
                                                                {t('sign_in_to_use')}
                                                            </p>
                                                            {/* <Link to="/" className="logo logo-admin">
                                                                 <img src={logoSm} height="24" alt="logo" />
                                                                </Link> */}
                                                        </div>
                                                    </div>

                                                    <CardBody className="p-4">
                                                        <div className="p-3">
                                                            <AvForm
                                                                className="form-horizontal mt-4"
                                                                onValidSubmit={this.handleValidSubmit}
                                                            >
                                                                {this.props.error ? (
                                                                    <Alert color="danger">{this.props.error}</Alert>
                                                                ) : null}

                                                                <div className="form-group">
                                                                    <AvField
                                                                        type="text"
                                                                        name="username"
                                                                        label={t('username')}
                                                                        value=""
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: t('required')
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{position: 'relative'}}>
                                                                    <AvField
                                                                        type={this.state.pwdType}
                                                                        name="password"
                                                                        label={t('password')}
                                                                        required
                                                                        value=""
                                                                        // onChange={this.pwdChange}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: t('required')
                                                                            },
                                                                            // pattern: {value: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$', errorMessage: '密码至少包含：数字和英文字母，长度6-20'},
                                                                        }}
                                                                    />
                                                                    <div className='pwd-eye' onClick={this.showPwd}>
                                                                        {this.state.isShow ? <EyeOutlined/> :
                                                                            <EyeInvisibleOutlined/>}
                                                                    </div>
                                                                    {/*<div className='pwd-auth'>
                                                                        {
                                                                        this.state.pwdAuth.map((item,index) =>{
                                                                            return (<span key={index} className={item}></span>)
                                                                        })
                                                                        }
                                                                        {this.state.pwdAuth.length>0?this.state.pwdAuthTxt:''}
                                                                    </div>*/}
                                                                </div>

                                                                {/* <Row className="form-group">
                                                            <Col sm={6}>
                                                            <label>sdjfsjkdfkjhkdhfks</label>
                                                            </Col>
                                                            <Col sm={6} className="text-right">
                                                                <input
                                                                className=" w-md waves-effect waves-light"

                                                                >
                                                                </input>
                                                            </Col>
                                                            </Row> */}
                                                                {/*<Row className="form-group">*/}
                                                                {/*<Col sm={6}>*/}
                                                                {/*<p>*/}
                                                                {/*<Link*/}
                                                                {/*to="pages-register"*/}
                                                                {/*className="font-weight-medium text-primary"*/}
                                                                {/*>*/}
                                                                {/*{t('signup')}*/}
                                                                {/*</Link>*/}
                                                                {/*</p>*/}
                                                                {/*</Col>*/}
                                                                {/*<Col sm={6} className="text-right">*/}
                                                                {/*<p>*/}
                                                                {/*<Link*/}
                                                                {/*to="/forget-password"*/}
                                                                {/*className="font-weight-medium text-primary"*/}
                                                                {/*>*/}
                                                                {/*{" "}*/}
                                                                {/*Forgot your password?{" "}*/}
                                                                {/*</Link>{" "}*/}
                                                                {/*</p>*/}
                                                                {/*</Col> */}
                                                                {/*</Row>*/}

                                                                <Row className="form-group">
                                                                    <Col sm={12} className="text-right" style={{marginTop: '20px'}}>
                                                                        <button style={{width: '100%'}}
                                                                                className="btn btn-primary w-md waves-effect waves-light"
                                                                                type="submit"
                                                                        >
                                                                            {t('sign_in')}
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                                {/* <Row className="form-group mt-2 mb-0">
                                  <div className="col-12 mt-4">
                                    <Link to="/forget-password">
                                      <i className="mdi mdi-lock"></i> Forgot your
                                      password?
                                    </Link>
                                  </div>
                                </Row>  */}
                                                            </AvForm>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="text-right login-lang">
                                                <LanguageDropdown />
                                                {/* <p className="mb-0">
                          © {new Date().getFullYear()} Veltrix. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                        </p>  */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStatetoProps = state => {
    const {error, loading,logined} = state.Login;
    return {error, loading,logined};
};

export default withRouter(connect(mapStatetoProps, 
    {verifyUser,loginUser,apiError,clearApiError,RsetMenu})(Login));
