import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Redux States
import {
    GET_PACKAGE_LIST,
    GET_COMPANY_LIST,
    GET_PACKAGE_LIST_SUCCESS,
    GET_COMPANY_LIST_SUCCESS,
    UPDATE_PACKAGE,
    UPDATE_COMPANY_STATUS,
    UPDATE_PACKAGE_SUCCESS,
    UPDATE_COMPANY_STATUS_SUCCESS,
    ADD_PACKAGE,
    ADD_PACKAGE_SUCCESS,
    SHOW_ALERT,
    EXPIRE_TOKEN,
} from './actionTypes';
import {
    showAlert,
    packageApiError
} from './actions';

import validator from 'validator';

import backend from '../../helpers/backend';

function* getCompanyList({ payload: { urlQuery, history } }) {
    console.log('* getCompanyList');
    try {
        const response = yield call(backend.getCompanyList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if(response.status === 200) {
            yield put({ type: GET_COMPANY_LIST_SUCCESS, payload: { data: response.data } });
        } else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        yield put(packageApiError(error.response));
    }
}

function* getPackageList({ payload: { urlQuery, history } }) {
    console.log('* getPackageList');
    try {
        const response = yield call(backend.getPackageList, { urlQuery, authToken: backend.getAuthenticatedUser() });

        console.log('test response', response, response.status);

        if(response.status === 200) {
            yield put({ type: GET_PACKAGE_LIST_SUCCESS, payload: { data: response.data } });
        } else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        yield put(packageApiError(error.response));
    }
}

function* updateComanyStatus({ payload: { data, history } }) {
    console.log('* updateComanyStatus');
    try {
        const response = yield call(backend.updateComanyStatus, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if(response.status === 200) {
            yield put({ type: SHOW_ALERT, payload: 'success' });
            yield put({ type: UPDATE_COMPANY_STATUS_SUCCESS, payload: { history } });
        } else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        yield put(packageApiError(error.response));
    }
}

function* updatePackage({ payload: { data, history } }) {
    console.log('* updatePackage');
    try {
        const response = yield call(backend.updatePackage, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if(response.status === 200) {
            yield put({ type: SHOW_ALERT, payload: 'success' });
            yield put({ type: UPDATE_PACKAGE_SUCCESS, payload: { history } });
        } else {
            throw response;
        }
    } catch(error) {
        // if(error.response.status === 401 || error.response.status === 403) {
        //     console.log('testing expire token');
        //     sessionStorage.removeItem("accessToken");
        //     history.push('/login');
        //     return;
        // }
        if(backend.handleError(error.response,history)){
            return;
        }

        yield put(packageApiError(error.response));
    }
}

function* addPackage({ payload: { data, history } }) {
    console.log('* addPackage');
    try {
        const response = yield call(backend.addPackage, { ...data, authToken: backend.getAuthenticatedUser() });

        console.log('response code', response, response.status);

        if(response.status === 200) {
            yield put({ type: SHOW_ALERT, payload: 'success' });
            yield put({ type: ADD_PACKAGE_SUCCESS, payload: { data: response } });
        } else {
            throw response;
        }
    } catch(error) {
        if(error.response.status === 401 || error.response.status === 403) {
            console.log('testing expire token');
            sessionStorage.removeItem("accessToken");
            history.push('/login');
            // 处理token过期后loading设置为false
            yield put({ type: EXPIRE_TOKEN, payload: 'testing expire token' });
            return;
        }

        yield put(packageApiError(error.response));
    }
}

export function* watchGetCompanyList() {
    yield takeEvery(GET_COMPANY_LIST, getCompanyList)
}

export function* watchGetPackageList() {
    yield takeEvery(GET_PACKAGE_LIST, getPackageList)
}

export function* watchUpdatePackage() {
    yield takeEvery(UPDATE_PACKAGE, updatePackage)
}

export function* watchUpdateComanyStatus() {
    yield takeEvery(UPDATE_COMPANY_STATUS, updateComanyStatus)
}



export function* watchAddPackage() {
    yield takeEvery(ADD_PACKAGE, addPackage)
}

function* PackageSaga() {
    yield all([
        fork(watchGetPackageList),
        fork(watchGetCompanyList),
        fork(watchUpdatePackage),
        fork(watchUpdateComanyStatus),
        fork(watchAddPackage),
    ]);
}

export default PackageSaga;
