import {
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  REST_MENU_DATA,
  SET_SELETED_KEYS
} from "./actionTypes";

const initialState = {
  MenuList: [],
  menuReady:false,
  menuKeys:{
    keys:[],
    openKeys:[]
  },
  forgetError: null
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU:
      state = {
        ...state,
        MenuList: [],
        menuReady:false,
        forgetError: null
      };
      break;
    case GET_MENU_SUCCESS:
      state = {
        ...state,
        menuReady:true,
        MenuList: action.payload.data.data,
      };
      break;
    case GET_MENU_ERROR:
      state = { ...state, forgetError: action.payload };
      break;
    case REST_MENU_DATA:
      state = {
        ...state,
        MenuList: [],
        menuReady:false,
        menuKeys:{
          keys:[],
          openKeys:[],
        },
        forgetError: null
      };
      break;
    case SET_SELETED_KEYS:
      state = {
        ...state,
        menuKeys: action.payload.key,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default menu;
