import { init } from 'echarts';
import {
  SHOW_ALERT,
  EXPIRE_TOKEN,
  CLEAR_ALERT,
  GET_PACKAGE_LIST,
  GET_COMPANY_LIST,
  GET_PACKAGE_LIST_SUCCESS,
  GET_COMPANY_LIST_SUCCESS,
  UPDATE_PACKAGE,
  UPDATE_COMPANY_STATUS,
  UPDATE_PACKAGE_SUCCESS,
  ADD_PACKAGE,
  ADD_PACKAGE_SUCCESS,
  PACKAGE_INPUT_ERROR,
  CLEAR_PACKAGE_INPUT_ERROR,
  PACKAGE_API_ERROR,
  CLEAR_PACKAGE_CSV,
} from './actionTypes';

const initialState = {
  packageInfo: {
    shipmentCourierRefNo: '',
  },
  packageCsv: null,
  packageList: [],
  filteredCount: 0,
  totalPackageCount: 0,
  inputError: {
    shipmentCourierRefNo: true,
  },
  alert: {
    success: false,
    successMessage: '',
    error: false,
    errorMessage: '',
    errorDescription: '',
  },
  error: null,
  loading: false
}

const packageReducer = (state = initialState, action) => {
  let packageInfo = { ...state.packageInfo };
  let inputError = { ...state.inputError };
  let alert = { ...state.alert };

  // console.log('reducer debug', action.type, action.payload);

  switch(action.type) {
    case GET_PACKAGE_LIST:
      state = {
        ...state,
        loading: true
      }
      break;
    case GET_COMPANY_LIST:
      state = {
        ...state,
        loading: true
      }
      break;
    case GET_PACKAGE_LIST_SUCCESS:
      console.log('testing ', action.type, action.payload.data);
      state = {
        ...state,
        packageList: action.payload.data.data,
        filteredCount: action.payload.data.filteredCount,
        totalPackageCount: action.payload.data.totalPackageCount,
        loading: false,
      }
      break;
    case GET_COMPANY_LIST_SUCCESS:
      console.log('testing ', action.type, action.payload.data);
      state = {
        ...state,
        packageList: action.payload.data.companies,
        filteredCount: action.payload.data.totalProductCount,
        loading: false,
      }
      break;
    case UPDATE_PACKAGE_SUCCESS:
      state = {
        ...state,
        loading: true
      }
      break;
    case UPDATE_PACKAGE:
      state = {
        ...state,
        loading: true
      }
      break;
    case UPDATE_COMPANY_STATUS:
      state = {
        ...state,
        loading: true
      }
      break;
    case PACKAGE_INPUT_ERROR:
      packageInfo[action.payload.inputName] = action.payload.value;

      state = {
        ...state,
        packageInfo: packageInfo
      }
      break;
    case ADD_PACKAGE:
      state = {
        ...state,
        loading: true
      }
      break;
    case ADD_PACKAGE_SUCCESS:
      state = {
        ...state,
        packageCsv: action.payload.data.data,
        loading: true
      }
      break;
    case CLEAR_PACKAGE_CSV:
      state = {
        ...state,
        packageCsv: null,
        loading: false
      }
      break;
    case CLEAR_PACKAGE_INPUT_ERROR:
      inputError[action.payload] = true;

      state = {
        ...state,
        inputError,
      }
      break;
    case SHOW_ALERT:
      alert[action.payload] = true;
      state = {
        ...state,
        alert,
        loading: false
      }
      break;
    case EXPIRE_TOKEN:
      state = {
        ...state,
        packageCsv: null,
        loading: false
      }
      break;
    case CLEAR_ALERT:
      alert[action.payload] = false;
      alert[action.payload + 'Message'] = '';
      alert[action.payload + 'Description'] = '';
      state = {
        ...state,
        alert,
      }
      break;
    case PACKAGE_API_ERROR:
      for(let tmp of action.payload.data.errs) {
        if(tmp.field === undefined) {
          alert.errorMessage = tmp.error;
          alert.error = true;
        } else {
          switch(tmp.field) {
            case 'shipmentCourierRefNo':
              inputError.shipmentCourierRefNo = tmp.error;
              break;
            case 'time':
              alert.errorMessage = 'Please check the time entered'
              alert.errorDescription = tmp.error;
              alert.error = true;
              break;
            case 'note':
              alert.errorMessage = 'Please check the note entered'
              alert.errorDescription = tmp.error;
              alert.error = true;
              break;
            case 'unit':
              alert.errorMessage = 'Please check the unit entered'
              alert.errorDescription = tmp.error;
              alert.error = true;
              break;
          }
        }
      }

      state = {
        ...state,
        // error: action.payload,
        inputError,
        alert,
        loading: false
      };
      break;
    default:
      // state = { ...state };
      break;
  }
  return state;
}

export default packageReducer;
