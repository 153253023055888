import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_MENU } from "./actionTypes";
import { MenuSuccess} from "./actions";

//AUTH related methods
import backend from '../../helpers/backend';

// const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getMenu({ payload: { urlQuery, history } }) {
  try {
    const response = yield call(backend.getMenu, { urlQuery, authToken: backend.getAuthenticatedUser()});
   
    if (response.status === 200) {
      yield put(MenuSuccess(response));
    }else {
        console.log('throw 2');
        throw response;
    }
  } catch (error) {
   // yield put(userForgetPasswordError(error));
      if(backend.handleError(error.response,history)){
        return;
      }
  }
}

export function* watchGetMenu() {
  yield takeEvery(GET_MENU, getMenu);
}

function* menuSaga() {
  yield all([fork(watchGetMenu)]);
}

export default menuSaga;
