import { LOGIN_USER,VERIFY_USER,VERIFY_USER_LOGINED, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS,
     API_ERROR,Clear_API_ERROR } from './actionTypes';

const initialState = {
    error: null,
    logined:false,
    loading: false,
    roleId:0
}
const login = (state = initialState, action) => {
    switch(action.type) {
        case VERIFY_USER:
            state = {
                ...state,
                loading: true
            }
            break;
        case VERIFY_USER_LOGINED:
            state = { ...state,
                logined:true, 
            };
            break;
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            }
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                error: null,
                loading: false,
                roleId:action.payload.data.roleId,
            }
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state, error: null, };
            break;
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        case Clear_API_ERROR:
            state = {
                ...state,
                error: null,
            }
            break;
        default:
            // state = { ...state };
            break;
    }
    return state;
}

export default login;