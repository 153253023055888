// 获取当天时间 YYYY-MM-DD
import {format} from "date-fns";
const getNowFormatDate = function () {
    let date = new Date();
    let seperator1 = "-";
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    let currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
};


const timeToDay = function (time){
   return format(new Date(time), 'yyyy-MM-dd');
}

const addDays = function (dateTime, numDay){
    let date=dateTime.setFullYear(dateTime.getFullYear(),dateTime.getMonth(),dateTime.getDate()+numDay);
    return  new Date(date)
 }

const addMonths = function (dateTime, numMonth){
    let date=dateTime.setFullYear(dateTime.getFullYear(),dateTime.getMonth()+numMonth,dateTime.getDate());
    return  new Date(date)
  }
 


export default {
    getNowFormatDate,
    timeToDay,
    addDays,
    addMonths,
  }
