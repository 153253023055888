import {takeEvery, fork, put, all, call} from 'redux-saga/effects';

// Login Redux States
import {LOGIN_USER, LOGOUT_USER,VERIFY_USER,VERIFY_USER_LOGINED} from './actionTypes';
import {loginSuccess, logoutUserSuccess, apiError} from './actions';

//AUTH related methods
// import { getFirebaseBackend } from '../../../helpers/authUtils';
import backend from '../../../helpers/backend';
import { isReturnStatement } from 'typescript';

// const fireBaseBackend = getFirebaseBackend();

console.log('back', backend);

function* verifyLogin({payload: {user, history}}) {
    
    
    console.log('Verify user');

    try {
        // const response = yield call(fireBaseBackend.loginUser, user.email, user.password);


         let response = yield call(backend.verifyLogin, {...user}); 


         try {
     
            response = yield call(backend.login, {...user});
         
            // console.log('response code', response, response.status);
    
            if (response.status === 200) {
                sessionStorage.setItem("accessToken", 'Bearer ' + response.data.accessToken);
                let companyId = response.data.companyId;
                let roleId = response.data.roleId;
                sessionStorage.setItem("companyId", companyId);
                // roleId： 1管理员 2普通用户
                sessionStorage.setItem("roleId", roleId);
    
                yield put(loginSuccess(response));
                if (companyId === -1 || roleId == 3) {
                    // 公司未注册
                    history.push('/company-info');
                } else {
                    history.push('/dashboard');
                }
    
            } else {
                throw response;
            }
        } catch (error) {
            // console.log('API Error', error.response.data);
            console.log('API Error', error.response);
            // yield put(apiError(error.message));
            yield put(apiError(error.response.data.errs[0].error));
    
            return;
            // yield put(logoutUser({ payload: { history } }));
        }

           
        //  loginUser({payload: {user, history}});
          
        //  response = yield call(backend.login, {...user});

        // // console.log('response code', response, response.status);

        // if (response.status === 200) {
        //     sessionStorage.setItem("accessToken", 'Bearer ' + response.data.accessToken);
        //     let companyId = response.data.companyId;
        //     let roleId = response.data.roleId;
        //     sessionStorage.setItem("companyId", companyId);
        //     // roleId： 1管理员 2普通用户
        //     sessionStorage.setItem("roleId", roleId);

        //     yield put(loginSuccess(response));
        //     if (companyId === -1) {
        //         // 公司未注册
        //         history.push('/company-info');
        //     } else {
        //         history.push('/dashboard');
        //     }

        // } else {
        //     throw response;
        // }
    } catch (error) {
        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put({ type: VERIFY_USER_LOGINED, payload: 'success' });
        yield put(apiError(error.response.data.errs[0].error));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* loginUser({payload: {user, history}}) {
    console.log('* login user');

    try {
     
        const response = yield call(backend.login, {...user});
     
        // console.log('response code', response, response.status);

        if (response.status === 200) {
            sessionStorage.setItem("accessToken", 'Bearer ' + response.data.accessToken);
            let companyId = response.data.companyId;
            let roleId = response.data.roleId;
            sessionStorage.setItem("companyId", companyId);
            // roleId： 1管理员 2普通用户
            sessionStorage.setItem("roleId", roleId);

            yield put(loginSuccess(response));
            if (companyId === -1 || roleId == 3) {
                // 公司未注册
                history.push('/company-info');
            } else {
                history.push('/dashboard');
            }

        } else {
            throw response;
        }
    } catch (error) {
        // console.log('API Error', error.response.data);
        console.log('API Error', error.response);
        // yield put(apiError(error.message));
        yield put(apiError(error.response.data.errs[0].error));
        // yield put(logoutUser({ payload: { history } }));
    }
}

function* logoutUser({payload: {history}}) {
    // console.log('* logoutUser');
    try {
        // sessionStorage.removeItem("accessToken");

        const response = yield call(backend.logout);

        console.log('response code', response, response.status);

        sessionStorage.removeItem("companyId");

        if (response.status === 200) {
            sessionStorage.removeItem("accessToken");
        } else if (response.status === 401 || response.status === 403) {
            sessionStorage.removeItem("accessToken");
        } else if (response.data.errs[0].error === "无效的Access Token" || response.data.errs[0].error === "Invalid token") {
            sessionStorage.removeItem("accessToken");
        } else {
            throw response;
        }


        history.push('/login');
    } catch (error) {
        sessionStorage.removeItem("companyId");
        if (error.response.status === 401 || error.response.status === 403) {
            sessionStorage.removeItem("accessToken");
            history.push('/login');
        } else if (
            error.response.data.errs[0].error === "无效的Access Token" ||
            error.response.data.errs[0].error === "Invalid token") {
            sessionStorage.removeItem("accessToken");
            history.push('/login');
        } else {
            yield put(apiError(error));
        }
    }
}



export function* watchVerifyLogin() {
    yield takeEvery(VERIFY_USER, verifyLogin)
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchVerifyLogin),
    ]);
}

export default authSaga;
