import React, {Component} from "react";
import SettingMenu from "../Shared/SettingMenu";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {AvForm, AvField, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table, InputGroup} from "reactstrap";
// import DatePicker from "react-datepicker";
import CSV from "../../components/CSVDownloader/CSVDownloader";
import CSVHandler from "../../components/CSVDownloader/CSVHandler";
import {Radio} from 'antd';

import AntdPagination from '../../components/AntdPagination'

import "react-datepicker/dist/react-datepicker.css";
import {MDBDataTable} from "mdbreact";
import Select from "react-select";
import {Modal, Collapse} from "reactstrap";

import {format} from "date-fns";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";

import ReactPaginate from 'react-paginate';
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import SendIcon from '@material-ui/icons/Send';
import i18n from '../../i18n';
import {Translation} from 'react-i18next';

// actions
import {
    getCompanyList,
    updateComanyStatus,
    clearInputError,
    clearAlert,
    packageApiError,
} from "../../store/package/actions";

import lianbg from '../../assets/images/lian/lian3.png'
import lianlogo from '../../assets/images/lian/lian4.png'
import {ConfigProvider, Pagination} from "antd";
import zhCN from "antd/lib/locale/zh_CN";

const currentStatus = 'packed';

const optionStatus = [
    {label: i18n.t('all'), value: ''},
    {label: i18n.t('packed'), value: 'packed'},
    {label: i18n.t('shipped'), value: 'shipped'},
    {label: i18n.t('completed'), value: 'completed'},
];

const optionPageSize = [
    {label: 10, value: 10},
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
];

const getSortingComponent = (value, sorting) => {
    if (value == sorting.column) {
        switch (sorting.order) {
            case '':
                return <i className="typcn typcn-media-record"/>;
                break;

            case 'asc':
                return <i className="ion ion-ios-arrow-up"/>;
                break;

            case 'desc':
                return <i className="ion ion-ios-arrow-down"/>;
                break;
        }
    } else {
        return <i className="typcn typcn-media-record"/>;
    }
}

const PackageTableRow = (props) => {
    const dataList = props.data;
    console.log('**********************************')
    console.log(dataList)
    const toggleModalShipPackage = props.toggleModalShipPackage;

    let startRowNo = props.pageNumber * props.pageSize + 1;

    const listItems = dataList.map((data, index) => {
        let displayStatus = '';

        switch (data.status) {
            case 0:
                displayStatus = i18n.t('company_status0');
                break;
            case 1:
                displayStatus = i18n.t('company_status1');
                break;
            case 2:
                displayStatus = i18n.t('company_status2');
                break;
        }

        return (<tr key={index}>
                <td><span>{startRowNo++}</span></td>
                <td><span className='text-ellipsis'>{data.name}</span></td>
                <td><span className='text-ellipsis'>{data.email}</span></td>
                <td><span className='text-ellipsis'>{data.phoneNumber}</span></td>
                <td><span className='text-ellipsis'>{data.addressLine1 + ' ' + data.addressLine2}</span></td>
                <td><span className="nowrap">{data.createTime}</span></td>
                <td><span className="nowrap">{displayStatus}</span></td>
                <td className="nowrap td-text-center">
                    <span style={{cursor: 'pointer'}} className="text-blue"
                          onClick={() => toggleModalShipPackage(data)}>{i18n.t('company_checkbtn')}</span>
                </td>
            </tr>
        )
    });

    return (<tbody>{listItems}</tbody>);
};

const BlockLianChangeLogRow = (props) => {
    console.log('testing BlockLianChangeLogRow()', props.data);
    const info = props.data;
    return (
        <div className='lian-info'>
            <img className='bg' src={lianbg}/>
            <div className='lian-data'>
                <div>
                    <p>所在链</p>
                    <p className='p2'>创益溯源链</p>
                </div>
                <div>
                    <p>所在区块</p>
                    <p className='p2'>{info.currentBlockHeight}</p>
                </div>
                <div>
                    <p>唯一编码</p>
                    <p className='p2'>{info.blockDataHash}</p>
                </div>
                <img className='logo' src={lianlogo}/>
            </div>
        </div>
    );
};

const entryStr = (pageNumber, pageSize, filteredCount, totalCount) => {
    let to = (pageNumber + 1) * pageSize;
    if (to > filteredCount) {
        to = filteredCount;
    }

    let from = pageNumber * pageSize + 1;
    if (from > to) {
        from = to;
    }

    return <>{i18n.t('table_count', {from: from, to: to, filter: filteredCount, total: totalCount})}</>;
}

const userStr = (adminUser, workerUser) => {
    // if(
    //   (adminUser === null || adminUser === undefined) &&
    //   (workerUser === null || workerUser === undefined)
    // ) {
    //   return '';
    // }
    // return adminUser !== null ? `${adminUser} ` + i18n.t('admin_bracket') : `${workerUser} ` + i18n.t('worker_bracket');

    if (adminUser) {
        return i18n.t('admin_bracket')
    } else {
        return i18n.t('worker_bracket')
    }

};

const ShippingInfoRow = (props) => {
    console.log('testing ShippingInfoRow()', props.data);
    const dataList = props.data;

    let step = 1;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td>{index + 1}</td>
            {currentStatus === 'shipped' ?
                <td className="nowrap">
                    <Button
                        color={data.delete === true ? 'warning' : 'danger'}
                        onClick={() => props.handleRemoveStep(index)}
                    >
                        {data.delete === true ? i18n.t('cancel_remove') : i18n.t('remove')}
                    </Button>
                </td> : null}
            <td>{props.packageId}</td>

            <td>
                {/* {data.step} */}
                {data.delete === true ? null : step++}
            </td>
            <td>
                {data.uploadable === true ?
                    <AvField
                        type="textarea"
                        className="w-200"
                        name={`time[${index}]`}
                        value=""
                    />
                    : data.time}
            </td>
            <td>
                {data.uploadable === true ?
                    <AvField
                        type="textarea"
                        className="w-200"
                        name={`note[${index}]`}
                        value=""
                    />
                    : data.note}
            </td>
            <td className="nowrap">
                {userStr(data.updatedByAdminUserId, undefined)}
            </td>
        </tr>
    );

    if (currentStatus === 'shipped') {
        listItems.push(
            <tr key={listItems.length}>
                <td colSpan="7">
                    <Button
                        color="success"
                        onClick={props.handleAddStep}
                    >
                        {i18n.t('add_shipping_info')}
                    </Button>
                </td>
            </tr>
        );
    }

    return (<tbody>{listItems}</tbody>);
}

const StatusChangeLogRow = (props) => {
    console.log('testing StatusChangeLogRow()', props.data);
    const dataList = props.data;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td><span className="nowrap">{data.status}</span></td>
            <td>{data.datetime}</td>
            <td><span className="nowrap">{data.userId}</span></td>
            <td>{data.location}</td>
        </tr>
    );

    return (<tbody>{listItems}</tbody>);
}

class PackedPackage extends Component {
    constructor(props) {
        super(props);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleFilterVisibility = this.handleFilterVisibility.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleAddStep = this.handleAddStep.bind(this);
        this.handleRemoveStep = this.handleRemoveStep.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.toggleModalShipPackage = this.toggleModalShipPackage.bind(this);
        this.toggleModalShippingInfo = this.toggleModalShippingInfo.bind(this);
        this.toggleModalStatusChangeLog = this.toggleModalStatusChangeLog.bind(this);
        this.handleAntdPageChange = this.handleAntdPageChange.bind(this);
        this.handleAntdPageSizeChange = this.handleAntdPageSizeChange.bind(this);
        this.toggleModalBlockchain = this.toggleModalBlockchain.bind(this);
        this.companyStatusChange = this.companyStatusChange.bind(this);
        this.handler2 = this.handler2.bind(this)

        this.state = {
            filter: {
                packageId: '',
                status: '',
                strictStatus: currentStatus,
                shipmentCourierRefNo: '',
                packedDateFrom: undefined,
                packedDateTo: undefined,
                shippedDateFrom: undefined,
                shippedDateTo: undefined,
                completedDateFrom: undefined,
                completedDateTo: undefined,
            },
            pageNumber: 0,
            companyStatusRadio: 1,
            pageSize: 10,
            filteredCount: 0,
            pageCount: 0,
            componentDidUpdateNum: 0,
            sorting: {
                column: 'packageId',
                order: 'desc',
            },
            modalPackageId: undefined,
            modalShipPackage: {
                toggle: false,
                companyInfo: {
                    addressLine1: "",
                    addressLine2: "",
                    certificateFilename: "",
                    companyImages: [],
                    companyId: '',
                    createTime: "",
                    email: "",
                    introduction: "",
                    name: "",
                    phoneNumber: "",
                    status: '',
                    updateTime: "",
                    userId: ''
                },
                // packageId: [],
            },
            modalShippingInfo: {
                toggle: false,
                // updateMode: '',
                shipmentCourierRefNo: '',
                shippingInfo: [],
                // packageId: [],
            },
            modalStatusChangeLog: {
                toggle: false,
                statusChangeLog: [],
            },
            blockchainData: {
                toggle: false,
                info: {}
            }
        };
    }

    handler2 = CSVHandler.packedPackage.allDownloadHandler

    componentDidMount() {
        console.log('componentDidMount');
        this.handleFormSubmit();
        this.props.clearAlert('success');
    }

    componentDidUpdate() {
        this.state.componentDidUpdateNum += 1;
        console.log('componentDidUpdate', this.props.filteredCount);

        let pageCount = Math.ceil(this.props.filteredCount / this.state.pageSize);

        console.log('testing update page count', this.state.pageCount, pageCount);

        if (this.state.pageCount !== pageCount) {
            if (pageCount <= this.state.pageNumber) {
                this.setState({pageCount: pageCount, pageNumber: 0}, () => this.handleFormSubmit());
            } else {
                this.setState({pageCount: pageCount});
            }
        }
    }


    renderAntdPagination(totalProductCount) {
        console.log('totalProductCount=====>>>>', totalProductCount);
        if (totalProductCount > 0) {
            return (<AntdPagination total={totalProductCount} antdPageChange={this.handleAntdPageChange}
                                    antdPageSizeChange={this.handleAntdPageSizeChange}/>)
        } else {
            return null
        }
    }

    handleFilterInputChange(event) {
        console.log('handleFilterInputChange', event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));

        console.log('after', this.state);
    }

    handleFilterStatusChange(event) {
        console.log('handleFilterStatusChange', event);

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                status: event.value,
            },
        }));
    }

    handleFilterDateChange(dateInput, value) {
        console.log('handleFilterDateChange', dateInput, value);

        let dateInputValue = value === null ? undefined : value;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [dateInput]: dateInputValue,
            },
        }));
    }

    handlePageSizeChange(event) {
        console.log('handlePageSizeChange', event);

        this.setState({
                pageSize: event.value,
                pageNumber: 0,
            },
            () => this.handleFormSubmit());
    }


    // 改变页码
    handleAntdPageChange(page, pageSize) {
        this.setState({
                pageSize: pageSize,
                pageNumber: page - 1,
            },
            () => this.handleFormSubmit()
        );
    }

    // 改变每页显示条数
    handleAntdPageSizeChange(current, size) {
        this.setState({
                pageSize: size,
                pageNumber: 0,
            },
            () => this.handleFormSubmit()
        );
    }


    handleSorting(value) {
        console.log('handleSorting', value, this.state.sorting.column);

        let newOrder;
        let newComponent;

        if (value == this.state.sorting.column) {
            switch (this.state.sorting.order) {
                case '':
                    newOrder = 'asc';
                    break;

                case 'asc':
                    newOrder = 'desc';
                    break;

                case 'desc':
                    newOrder = '';
                    break;
            }
        } else {
            newOrder = 'asc';
        }

        this.setState(prevState => ({
                sorting: {
                    column: value,
                    order: newOrder,
                }
            }),
            () => this.handleFormSubmit());
    }

    handlePageClick(event) {
        console.log('handlePageClick', event);

        this.setState(
            prevState => ({
                pageNumber: event.selected,
            }),
            () => this.handleFormSubmit()
        );

        return false;
    }

    handleClearFilter() {
        console.log('handleClearFilter()');

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                packageId: '',
                status: '',
                packedDateFrom: undefined,
                packedDateTo: undefined,
                shippedDateFrom: undefined,
                shippedDateTo: undefined,
                completedDateFrom: undefined,
                completedDateTo: undefined,
            },
        }));
    }

    handleFilterVisibility() {
        console.log('handleFilterVisibility()');

        this.setState(prevState => ({
            filterVisibility: !prevState.filterVisibility
        }));
    }

    handleAddStep() {
        console.log('handleAddStep');

        this.setState(prevState => ({
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                shippingInfo: [
                    ...prevState.modalShippingInfo.shippingInfo,
                    {
                        uploadable: true,
                    }
                ].map((data, index) => {
                    data.index = index;
                    return data;
                }),
            },
        }));
    }

    handleRemoveStep(index) {
        console.log('handleRemoveStep()', index);

        let tmp = [];

        for (let i = 0; i < this.state.modalShippingInfo.shippingInfo.length; i++) {
            if (index !== i) {
                // not to delete
                tmp.push(this.state.modalShippingInfo.shippingInfo[i]);
            } else {
                // index matched
                if (this.state.modalShippingInfo.shippingInfo[i].uploadable === true) {
                    // delete
                    console.log('testing 1');
                } else {
                    // mark as delete
                    console.log('testing 2');
                    tmp.push({
                        ...this.state.modalShippingInfo.shippingInfo[i],
                        delete: !this.state.modalShippingInfo.shippingInfo[i].delete
                    });
                }
            }
        }

        this.setState(prevState => ({
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                shippingInfo: tmp.map((data, index) => {
                    data.index = index;
                    return data;
                }),
            }
        }));
    }

    handleFormSubmit(event, values) {
        let pageNumber = this.state.pageNumber;
        // if(event !== undefined) {
        //   pageNumber = this.state.pageNumber
        // } else {
        //   this.setState({ pageNumber: 0 });
        //   pageNumber = 0;
        // }
        let submitData = {
            // status: this.state.filter.status,
            status: 0, // 审核状态, 0:未审核 1:审核通过 2:审核不通过
            pageNumber: pageNumber + 1,
            pageSize: this.state.pageSize
        };
        console.log('on handleFormSubmit()', event, values, submitData);

        this.props.getCompanyList(submitData, this.props.history);
    }

    handleModalSubmit(event, values) {
        console.log('on handleModalSubmit()', event, values);
        let submitData = {
            companyId: this.state.modalShipPackage.companyInfo.companyId,
            status: this.state.companyStatusRadio // 0:未审核 1:审核通过 2:审核不通过
        };
        console.log('on handleModalSubmit()', submitData);

        this.props.updateComanyStatus(submitData, this.props.history);
    }

    /**
     * modal
     */
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleModalShipPackage(companyInfo = undefined) {
        console.log('toggleModalShipPackage', companyInfo);

        console.log('==============888888=============')
        console.log(companyInfo)

        this.setState(prevState => ({
            // companyInfo: companyInfo,
            modalShipPackage: {
                ...prevState.modalShipPackage,
                toggle: !prevState.modalShipPackage.toggle,
                companyInfo: {...companyInfo},
            },
        }));
        this.removeBodyCss();
    }

    companyStatusChange(e) {
        console.log('radio checked', e.target.value);
        this.setState({
            companyStatusRadio: e.target.value
        })
    }

    toggleModalShippingInfo(modalPackageId = undefined, shipmentCourierRefNo = '', shippingRecords = []) {
        console.log('toggleModalShippingInfo', modalPackageId, shipmentCourierRefNo, shippingRecords);


        this.setState(prevState => ({
            modalPackageId: modalPackageId,
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                toggle: !prevState.modalShippingInfo.toggle,
                shipmentCourierRefNo: shipmentCourierRefNo,
                shippingInfo: shippingRecords.map((data, index) => {
                    data.index = index;
                    return data;
                }),
            },
        }));
        this.removeBodyCss();
    }

    toggleModalStatusChangeLog(data = []) {
        console.log('toggleModalStatusChangeLog', data);

        let statusChangeLog = [];

        if (data.packedDate) {
            statusChangeLog.push({
                status: i18n.t('packed'),
                datetime: data.packedDate,
                userId: userStr(data.packedByAdminUserId, data.packedByWorkerUserId),
                location: data.packedUpdatedInLocation,
            });
        }


        if (data.shippedDate) {
            statusChangeLog.push({
                status: i18n.t('shipped'),
                datetime: data.shippedDate,
                userId: userStr(data.shippedByAdminUserId, data.shippedByWorkerUserId),
                location: data.shippedUpdatedInLocation,
            });
        }

        if (data.completedDate) {
            statusChangeLog.push({
                status: i18n.t('completed'),
                datetime: data.completedDate,
                userId: userStr(data.completedByAdminUserId, data.completedByWorkerUserId),
                location: data.completedUpdatedInLocation,
            });

        }

        this.setState(prevState => ({
            modalStatusChangeLog: {
                ...prevState.modalStatusChangeLog,
                toggle: !prevState.modalStatusChangeLog.toggle,
                statusChangeLog: statusChangeLog,
            },
        }));
        this.removeBodyCss();
    }

    toggleModalBlockchain(data = {}) {
        let info = {
            currentBlockHeight: data.currentBlockHeight,
            blockDataHash: data.blockDataHash
        }
        this.setState(prevState => ({
            blockchainData: {
                ...prevState.blockchainData,
                toggle: !prevState.blockchainData.toggle,
                info: info,
            },
        }));
        this.removeBodyCss();
    }


    render() {
        const {photoIndex} = this.state;

        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {/*{this.props.loading ? <Loader/> : null}*/}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            <h4 className="navi-heander-size">{t('company_nocheck')}</h4>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>

                                                <Row>
                                                    <Col sm={12}>
                                                        <p className="text-muted m-b-30">
                                                            {entryStr(this.state.pageNumber, this.state.pageSize, this.props.filteredCount, this.props.filteredCount)}
                                                        </p>
                                                    </Col>
                                                </Row>


                                                <div className="table-rep-plugin companycheck-table">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table className="table  thead-nowrap">
                                                            <thead className="thead-header">
                                                            <tr>
                                                                <th className="w-1p"><span>#</span></th>
                                                                <th>{t('company_name')}</th>
                                                                <th>{t('email')}</th>
                                                                <th>{t('phone_no')}</th>
                                                                <th>{t('address_line_2')}</th>
                                                                <th>{t('production_created')}</th>
                                                                <th>{t('company_status')}</th>
                                                                <th className="w-3p">{t('action')}</th>
                                                            </tr>
                                                            </thead>
                                                            <PackageTableRow
                                                                data={this.props.packageList}
                                                                toggleModalShipPackage={this.toggleModalShipPackage}
                                                                pageNumber={this.state.pageNumber}
                                                                pageSize={this.state.pageSize}/>
                                                        </Table>
                                                    </div>
                                                </div>


                                                <Row className='antd-pagination'>
                                                    {
                                                        this.props.filteredCount > 0 ? <Col>
                                                            <ConfigProvider locale={zhCN}>
                                                                <Pagination
                                                                    total={this.props.filteredCount}
                                                                    showSizeChanger
                                                                    showQuickJumper
                                                                    onChange={this.handleAntdPageChange}
                                                                    onShowSizeChange={this.handleAntdPageSizeChange}
                                                                    showTotal={total => `共 ${total} 条`}
                                                                />
                                                            </ConfigProvider>
                                                        </Col> : null
                                                    }

                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                            {this.props.alert.success ? (
                                <SweetAlert
                                    title={t('companystatus_updated')}
                                    success
                                    confirmBtnBsStyle="success"
                                    onConfirm={() => {
                                        this.props.clearAlert('success');
                                        currentStatus === 'packed' ? this.toggleModalShipPackage() : this.toggleModalShippingInfo();
                                        this.handleFormSubmit();
                                    }}
                                >
                                </SweetAlert>
                            ) : null}

                            {this.props.alert.error ? (
                                <SweetAlert
                                    title={this.props.alert.errorMessage}
                                    error
                                    confirmBtnBsStyle="error"
                                    onConfirm={() => {
                                        this.props.clearAlert('error');
                                    }}
                                >
                                    {this.props.alert.errorDescription}
                                </SweetAlert>
                            ) : null}


                            {/* modal */}

                            {/* modal ship package */}
                            <Modal isOpen={this.state.modalShipPackage.toggle} className="modal-lg">
                                <AvForm onValidSubmit={this.handleModalSubmit}>
                                    <ModalHeader toggle={() => this.toggleModalShipPackage()}>
                                        {t('company_info')}
                                    </ModalHeader>
                                    <ModalBody style={{padding: '0 1rem', margin: '10px 0'}}>
                                        <Row className='company_check_row'>
                                            <Col sm={2}>{t('company_name')}：</Col>
                                            <Col sm={10}>{this.state.modalShipPackage.companyInfo.name}</Col>
                                        </Row>
                                        <Row className='company_check_row'>
                                            <Col sm={2}>{t('introduction')}：</Col>
                                            <Col sm={10}>{this.state.modalShipPackage.companyInfo.introduction}</Col>
                                        </Row>
                                        <Row className='company_check_row'>
                                            <Col sm={2}>{t('email')}：</Col>
                                            <Col sm={10}>{this.state.modalShipPackage.companyInfo.email}</Col>
                                        </Row>
                                        <Row className='company_check_row'>
                                            <Col sm={2}>{t('phone_no')}：</Col>
                                            <Col sm={10}>{this.state.modalShipPackage.companyInfo.phoneNumber}</Col>
                                        </Row>
                                        <Row className='company_check_row'>
                                            <Col sm={2}>{t('address_line_2')}：</Col>
                                            <Col
                                                sm={10}>{this.state.modalShipPackage.companyInfo.addressLine1 + this.state.modalShipPackage.companyInfo.addressLine2}</Col>
                                        </Row>
                                        <Row className='company_check_row'>
                                            <Col sm={2}>{t('certificate')}：</Col>
                                            <Col style={{backgroundColor: '#fff'}} sm={10}>
                                                {this.state.modalShipPackage.companyInfo.companyImages ? this.state.modalShipPackage.companyInfo.companyImages.map((item, index) => {
                                                    return (<img src={item} key={index}/>)
                                                }) : null}
                                            </Col>
                                        </Row>
                                        <Row className='company_check_row'>
                                            <Col sm={2}>{t('company_status')}：</Col>
                                            <Col style={{backgroundColor: '#fff'}} sm={10}>
                                                {/*{this.state.modalShipPackage.companyInfo.status}*/}
                                                <Radio.Group onChange={this.companyStatusChange}
                                                             value={this.state.companyStatusRadio}>
                                                    <Radio value={1}>通过</Radio>
                                                    <Radio value={2}>不通过</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="secondary"
                                            onClick={() => this.toggleModalShipPackage()}>
                                            {t('close')}
                                        </Button>
                                        <Button color="primary">
                                            {t('update')}
                                        </Button>
                                    </ModalFooter>
                                </AvForm>
                            </Modal>

                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    console.log('mapStateToProps', state.Package)
    const {
        packageInfo,
        packageList,
        filteredCount,
        totalPackageCount,
        inputError,
        alert,
        error,
        loading
    } = state.Package;

    return {
        packageInfo,
        packageList,
        filteredCount,
        totalPackageCount,
        inputError,
        alert,
        error,
        loading
    };
};

export default withRouter(connect(mapStateToProps, {
    getCompanyList,
    updateComanyStatus,
    clearInputError,
    clearAlert,
    packageApiError,
})(PackedPackage));
