import { 
    CREATE_COMPANY_INFO, 
    GET_COMPANY_INFO, 
    GET_COMPANY_INFO_SUCCESS, 
    GET_COMPANY_CERTIFICATE_SUCCESS, 
    REGISTER_APP_USER,
    DEL_APP_USER,
    CLEAR_COMPANY_CERTIFICATE_PROP,
    UPDATE_COMPANY_INFO,
    CLEAR_INPUT_ERROR,
    COMPANY_WORKER_LIST,
    COMPANY_WORKER_LIST_SUCCESS,
    SHOW_ALERT,
    CLEAR_ALERT,
    API_ERROR ,
} from './actionTypes';

export const getCompanyInfo = (history) => {
    // console.log('action getCompanyInfo()');
    return {
        type: GET_COMPANY_INFO,
        payload: { history }
    }
}

export const getCompanyInfoSuccess = (data) => {
    return {
        type: GET_COMPANY_INFO_SUCCESS,
        payload: data
    }
}

export const getCompanyCertificateSuccess = (data) => {
    return {
        type: GET_COMPANY_CERTIFICATE_SUCCESS,
        payload: data
    }
}

export const clearCompanyCertificateProp = () => {
    return {
        type: CLEAR_COMPANY_CERTIFICATE_PROP,
    }
}

export const updateCompanyInfo = (data, history) => {
    console.log('action updateCompanyInfo()', data);
    return {
        type: UPDATE_COMPANY_INFO,
        payload: { data, history }
    }
}

export const createCompanyInfo = (data, history) => {
    console.log('action createCompanyInfo()', data);
    return {
        type: CREATE_COMPANY_INFO,
        payload: { data, history }
    }
}

export const getWorkerList = (history) => {
    console.log('action getWorkerList()');
    return {
        type: COMPANY_WORKER_LIST,
        payload: { history }
    }
}

export const getWorkerListSuccess = (data) => {
    return {
        type: COMPANY_WORKER_LIST_SUCCESS,
        payload: data
    }
}

export const registerAppUser = (data, history) => {
    return {
        type: REGISTER_APP_USER,
        payload: { data, history }
    }
}

export const delAppUser = (data, history) => {
    return {
        type: DEL_APP_USER,
        payload: { data, history }
    }
}

export const clearInputError = (inputName) => {
    return {
        type: CLEAR_INPUT_ERROR,
        payload: inputName,
    }
}

export const showAlert = (alertName) => {
    return {
        type: SHOW_ALERT,
        payload: alertName,
    }
}

export const clearAlert = (alertName) => {
    return {
        type: CLEAR_ALERT,
        payload: alertName,
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}
