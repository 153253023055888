import React, { useEffect, useState, useRef} from "react";
import {useSelector} from "react-redux";
import {message, Space, Select, Switch} from 'antd';
import {withRouter, Link, useHistory} from "react-router-dom";
import {Input, Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table} from "reactstrap";
import axios from "axios";
import {DownOutlined} from "@ant-design/icons"
import Loader from "../../components/Loader";
import NoData from "../../assets/images/NoData.png"
import i18n from '../../i18n';
import {Translation} from 'react-i18next';
import "../../css/ProductData.css"

import {Modal, Collapse} from "reactstrap";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import { Table as Table2, Button2 } from 'antd';



let nodeSelected =null;

const {Option} = Select;
let dataChecker = (data) => {
    let error = {}
    data.map((e, i) => {
        if (!e.name || e.name === "") {
            error['环节名称不能为空'] = true;
        }
        for (let ele of e.items) {
            if (!ele.name) {
                error['标题不能为空'] = true;
            }
            if (ele.content === "" || ele.content.length === 0 || ele.content[0].length === 0) {
                error['内容不能为空'] = true;
            }
            if (ele.content.length !== 0) {
                if (ele.type === 0 && ele.content[0].length >= 128) {
                    error["文本长度不大于128"] = true;
                }
                if (ele.type === 4 && ele.content[0].length >= 256) {
                    error["长文本长度不大于256"] = true;
                }
            }
            if (ele.show !== 0 && ele.show !== 1) {
                error["数码展示的值错误"] = true;
            }
        }
    })
    if (Object.keys(error).length > 0) {
        message.warn("注意: " + Object.keys(error).reduce((a, c, i) => {
            return a + c + " "
        }, ""))
        return false
    }
    return true
}
const isObjEmpty = (obj) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
const Axios = axios.create({
    // baseURL: 'http://0.0.0.0:3000/api/v1',
    baseURL: process.env.REACT_APP_API_URL_PROD,
    timeout: 30000,
});

class Node {
    constructor(num) {
        this.name = `Node${num}`;
        this.items = [];
    }

    addItem(obj) {
        this.items = [...this.items, obj]
    }
}

class Item {
    constructor(type, name, content, show) {
        this.type = type ? type : 0;
        this.name = name;
        this.content = content ? content : "";
        this.show = show !== undefined ? show : true;
    }
}




const DisplayPad = (props) => {
    const {getProductNodeData, nodeTotalData, data, setData, nodeId, deleteNodeFx, setIsModifying, isModifying, isNewNodeSecOpen, setOperator, toggleModal} = props
    const [isModifyArr, setIsModifyArr] = useState(new Array(data.length).fill(false))
    const [modalSate, setModalSate] = useState({show: false, select: []})
    useEffect(() => {
        if (isModifying) {
            return
        }
        setIsModifyArr(new Array(data.length).fill(false));
        getUserInfo();

    }, [data])
    const editNode = (i) => {
        if (isNewNodeSecOpen) {
            message.warn("请先完成新增环节");
            return;
        }
        if (isModifying) {
            message.warn("正在修改其他溯源环节信息 请先完成修改");
            return;
        }
        //2021-8-13
        toggleModal(i)
    }

    const getUserInfo = (cb) => {
        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        const lang = sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : 'zh';
        const options = {
            headers: {
                "Authorization": authToken,
            },
        }

        Axios.get(`dynamicNode/getUserInfo`, options).then((res) => {
            const returnedData = res.data.data;
            if (res.data.data && res.data.data.length == 0)
                return;

            let data = res.data.data[0]
            // setNodeId(data.productId)
            console.log(res.data, "res.data")
            if (res.data.code && res.data.code !== 200) {
                //  setNodeTotalData({})
                //  setData(undefined)
                throw "code is not 200"
            }
            if (returnedData === "") {
                // setNodeTotalData({})
                // setData(undefined)
                message.success("未有节点资料")
                return
            }
            if (cb) {
                cb();
            }
            // setNodeTotalData(JSON.parse(JSON.stringify(data)))
            //setData(JSON.parse(JSON.stringify(data.dynamicInfo)))
            setOperator(res.data.data);
        }).catch((err) => {
            ;message.warn("未能连接到服务器")
        })
    }


    const saveNode = (i, cb) => {
        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        const options = {
            headers: {
                "Authorization": authToken,
            },
        }
        let userIds = [];

        let items = data[i].userConfig;
        //2021-8-13 accordring demand 0809
        if(!items){
            getProductNodeData(cb);
            return;
        }
           

        for (var ii = 0; ii < items.length; ii++) {
            userIds.push(items[ii].userId);
        }

        let node = {};
        node.dnId = data[i].dnId;
        node.nodeId = data[i].nodeId;
        node.sort = data[i].sort;
        node.sort = data[i].sort;
        node.userIds = userIds;
        //if (!isObjEmpty(nodeTotalData)) {
        Axios.post('dynamicNode/createDynamicConfig', node, options)
            .then((res) => {
                if (res.data.code && res.data.code !== 200) {
                    throw "http status is not 200"
                }
                getProductNodeData(cb)
            })
            .catch((err) => {
                message.warn("新增节点失败")
            })
        return
        // }
    }
    const NodeDataZone = (props) => {
        const fileListMaker = (contentArray) => {
            let fileArray = []
            contentArray.map((e, i) => {
                let fullPath = e;
                let fileName = fullPath.split(/[\\\/]/).pop()
                const data = {
                    uid: `-${i}`,
                    name: fileName,
                    status: 'done',
                    url: fullPath,
                }
                fileArray.push(data)
            })
            return fileArray
        }
        const {data, setData, isModify, nodeId, getWholeNodeData, toggleModal,saveNode} = props;
        const zoneId = props.i;
        const logFx = () => {
        }
        const cbdelete = (i) => {
            return () => setIsModifying(false)
        }
        const cbModify = (i) => {
            return () => {
                setIsModifying(false)
            }
        }
        const DisplayInfoRow = (props) => {
            const {rowData, index, toggleModal} = props;
            
            return (

                <React.Fragment>
                    {rowData ? rowData.map((e, i) => {
                            return (
                                <tr key={`InfoRow-${i}`}>
                                    <td><span className="float-left">{e.userId}</span></td>
                                    <td><span className="float-left">{e.realName}</span></td>
                                    <td>{isModify === false ? <span className="float-left"><a
                                        className="activated-link"
                                        onClick={() => {
                                            let data = getWholeNodeData();
                                            let itemsArr = [...data[index]["userConfig"]];

                                            itemsArr.splice(i, 1);
                                            data[index]["userConfig"] = [...itemsArr]
                                            setData([...data]);
                                            saveNode(index);
                                        }}>{i18n.t('remove')}</a></span>: <span>--</span>}</td>
                                </tr>)
                        }) :
                        ""
                    }

                </React.Fragment>
            )

        }
        return (
            <div>
                <div key={zoneId} style={{marginBottom: "20px"}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "57px",
                        alignItems: "center",
                        backgroundColor: "whitesmoke"
                    }}>
                        <div>
                            <span style={{padding: "5px"}}><DownOutlined/></span>
                            <span>{data.name}</span>
                        </div>
                        <div>
                            {!isModify ? <span className="custBtn" onClick={() => {
                                editNode(zoneId)
                            }}>{i18n.t('common_add')}</span> : null}
                            {isModify ? <span className="custBtn" onClick={() => {
                                saveNode(zoneId, cbModify(zoneId))
                            }}>{i18n.t('save')}</span> : null}
                            {/* {isModify ? <span className="custBtn" onClick={() => { deleteNodeFx(i, cbdelete(i)) }}>删除</span> : null} */}
                        </div>
                    </div>
                    <div>
                        <form onSubmit={(e) => {  e.preventDefault() }}>
                            <div className={(!isModify ? "hidden-field" : null)} 
                                style={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid rgba(187, 187, 187, 100)"
                                }}> 环节名称编辑：
                                <input className="addNodeInput" type="text" name="NodeName"  value={data.name || ""} placeholder="新增环节名称" onChange={(e) => {
                                    let data = getWholeNodeData();
                                    data[zoneId]["name"] = e.target.value;
                                    setData([...data])
                                }}/>
                            </div>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0"
                                    data-pattern="priority-columns">
                                    {isModify || data.userConfig ? 
                                          <Table  className="table thead-nowrap"
                                            style={{borderBottom: "1px solid rgba(187, 187, 187, 100)"}}  >
                                            <thead className="thead-header" style={{backgroundColor: "unset"}}>
                                            <tr>
                                                <th className="w-1p"><span className="float-left">ID</span></th>
                                                <th className="w-1p"><span className="float-left">{i18n.t('username')}</span> </th>
                                                <th className="w-1p" style={{minWidth: "80px"}}> <span className="float-left">{i18n.t('action')}</span>  </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {DisplayInfoRow({
                                                setData: setData, rowData: data.userConfig, setRowData: () => {
                                                }, index: zoneId, toggleModal: toggleModal
                                            })}
                                            </tbody>
                                        </Table>
                                        : <p className="td-text-center"><span>{i18n.t('no_role_tip')}</span></p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>)
    }
    return (
        <>
            {data.map((e, i) => {
                return (
                    <React.Fragment key={i}>
                        {
                            NodeDataZone({
                                nodeId: nodeId,
                                getWholeNodeData: () => data,
                                key: `NodeDataDisplay-${i}`,
                                data: e,
                                i: i,
                                setData: setData,
                                isModify: isModifyArr[i],
                                setOperator: setOperator,
                                toggleModal: toggleModal,
                                saveNode : saveNode,
                            })
                        }
                    </React.Fragment>
                )

            })}
        </>
    )
}
const RoleSettings = (props) => {
    const history = useHistory()
    const productArr = useSelector((state) => state.TraceabilityRole.RealDynList)
    const [productId, setProductId] = useState(props.match.params.productId);
    const [nodeId, setNodeId] = useState();
    // const companyInfo = useSelector((state) => state.FormCompanyInfo)
    const [isModifying, setIsModifying] = useState("")
    const [nodeRefArray, setNodeRefArray] = useState([])
    const [productData, setProductData] = useState();
    const [data, setData] = useState();
    const addNewNodeSecRef = useRef(null)
    const [isNewNodeSecOpen, setIsNewNodeSecOpen] = useState(false)
    const [nodeTotalData, setNodeTotalData] = useState({})
    const [modalSate, setModalSate] = useState({show: false, operators: [],modleData:[]})
    const [nodeVersions, setNodeVersion] = useState([])
    const [versionModalSate, setVersionModalSate] = useState({show: false})
    const [version, setVerson] = useState();
    ///const [nodeSelected, setNodeSelected] = useState()



    let operatorSelected=[];
    const columns = [
        {
          title: 'ID',
          dataIndex: 'userId',
        },
        {
       
          title: i18n.t('role_name'),
          dataIndex: 'realName',
        }
      ];

      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
              operatorSelected =selectedRows;
        },
      };


      const vesioncolumns =[
         {

            filterMultiple:true,
            title: 'ID',
            dataIndex: 'id',
        },
        {
            filterMultiple:true,
          title: i18n.t('node_info_version'),
          dataIndex: 'versionFlag',
        }
      ];

      let operatorSelected2=[];

      const rowSelection2 = {
        onChange: (selectedRowKeys, selectedRows) => {
            operatorSelected2 =selectedRows;
        },
      };
      //table end

    const getProductNodeData = (cb) => {
        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        const lang = sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : 'zh';
        const options = {
            headers: {
                "Authorization": authToken,
            },
        }
        if (!props.match.params.productId) {
            history.goBack()
            return
        }
        let productId = props.match.params.productId // for testing purpose
        let url="";
        if(nodeSelected){
            url =`dynamicNode/findDynamicByProDuctIdAndDnId?productId=${productId}&dnId=${nodeSelected}`;
        }
        else{
            url =`dynamicNode/findDynamicByProductID?productId=${productId}`;
        }
        Axios.get(url, options).then((res) => {
            const returnedData = res.data.data;

            if (res.data.data && res.data.data.length == 0)
                return;

            let data = res.data.data[0]
            setNodeId(data.productId);
            setVerson(data.versionFlag);
            console.log(res.data, "res.data")
            if (res.data.code && res.data.code !== 200) {
                setNodeTotalData({})
                setData(undefined)
                throw "code is not 200"
            }
            if (returnedData === "") {
                setNodeTotalData({})
                setData(undefined)
                message.success("未有节点资料")
                return
            }
            if (cb) {
                cb();
            }
            setNodeTotalData(JSON.parse(JSON.stringify(data)))
            setData(JSON.parse(JSON.stringify(data.dynamicInfo)))
            if(nodeVersions.length==0)
                getNodeVersion();
        }).catch((err) => {
            ;message.warn("未能连接到服务器")
        })
    }

    const saveNode2 = (i, cb) => {
        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        const options = {
            headers: {
                "Authorization": authToken,
            },
        }
        let userIds = [];

        let items = data[i].userConfig;
        //2021-8-13 accordring demand 0809
        if(!items){
            getProductNodeData(cb);
            return;
        }
           

        for (var ii = 0; ii < items.length; ii++) {
            userIds.push(items[ii].userId);
        }

        let node = {};
        node.dnId = data[i].dnId;
        node.nodeId = data[i].nodeId;
        node.sort = data[i].sort;
        node.sort = data[i].sort;
        node.userIds = userIds;
        //if (!isObjEmpty(nodeTotalData)) {
        Axios.post('dynamicNode/createDynamicConfig', node, options)
            .then((res) => {
                if (res.data.code && res.data.code !== 200) {
                    throw "http status is not 200"
                }
                getProductNodeData(cb)
            })
            .catch((err) => {
                message.warn("新增节点失败")
            })
        return
        // }
    }


    const deleteNode = (i, cb) => {
        const productId = props.match.params.productId;
        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        const options = {
            headers: {
                "Authorization": authToken,
            },
        }
        if (!isObjEmpty(nodeTotalData)) {
            ;
            const cloneData = JSON.parse(JSON.stringify(nodeTotalData))
            let newArr = [...cloneData.nodes]
            if (i >= newArr.length) {
                message.warn("错误，请刷新页面")
                return
            }
            newArr.splice(i, 1)
            cloneData.nodes = newArr
            Axios.put('node', cloneData, options)
                .then((res) => {
                    if (res.data.code && res.data.code !== 200) {
                        throw "http status is not 200"
                    }
                    getProductNodeData(cb)
                })
                .catch((err) => {
                    message.warn("失败")
                })
            return
        }
    }
    const uploadingFx = (payload, closingFx) => {
        const productId = props.match.params.productId;
        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        ;
        const data = {productId: productId, nodes: payload}
        const options = {
            headers: {
                "Authorization": authToken,
            },
        }
        if (isObjEmpty(nodeTotalData)) {
            Axios.post('node', data, options)
                .then((res) => {
                    if (res.data.code && res.data.code !== 200) {
                        throw "http status is not 200"
                    }
                    closingFx();
                    getProductNodeData()
                })
                .catch((err) => {
                    message.warn("新增节点失败")
                })
            return
        }
        if (!isObjEmpty(nodeTotalData)) {
            const cloneData = JSON.parse(JSON.stringify(nodeTotalData))
            let newArr = [...cloneData.nodes]
            newArr = newArr.concat(payload)
            cloneData.nodes = newArr
            Axios.put('node', cloneData, options)
                .then((res) => {
                    if (res.data.code && res.data.code !== 200) {
                        throw "http status is not 200"
                    }
                    closingFx();
                    getProductNodeData()
                })
                .catch((err) => {
                    message.warn("新增节点失败")
                })
            return
        }
    }

    //2021-11=23

    const getNodeVersion =()=>{

        const authToken = sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : null;
        const lang = sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : 'zh';
        const options = {
            headers: {
                "Authorization": authToken,
            },
        }
        if (!props.match.params.productId) {
            history.goBack()
            return
        }
        let productId = props.match.params.productId // for testing purpose
        Axios.get(`productPlan/getDynamicByProductId?productId=${productId}`, options).then((res) => {
            const returnedData = res.data.data;

            if (returnedData && returnedData.length  <= 1)
                return;

            let  keyObject=[];
            let  source={};
        
            for (var ii = 0; ii < returnedData.length; ii++) {
               // userIds.push(items[ii].userId);
               source =returnedData[ii];
               source.key ="V"+ii;
               keyObject.push(source);
            }
            setNodeVersion(keyObject);
            setVersionModalSate({
                ...versionModalSate,
                show : true,
            })

            // let data = res.data.data[0]
            // setNodeId(data.productId)
            // console.log(res.data, "res.data")
            // if (res.data.code && res.data.code !== 200) {
            //     setNodeTotalData({})
            //     setData(undefined)
            //     throw "code is not 200"
            // }
            // if (returnedData === "") {
            //     setNodeTotalData({})
            //     setData(undefined)
            //     message.success("未有节点资料")
            //     return
            // }
            // if (cb) {
            //     cb();
            // }
            // setNodeTotalData(JSON.parse(JSON.stringify(data)))
            // setData(JSON.parse(JSON.stringify(data.dynamicInfo)))
        }).catch((err) => {
            ;message.warn("未能连接到服务器")
        })

    }


    const addNewNode = () => {
        if (isModifying) {
            message.warn("请先完成修改溯源环节信息");
            return
        }
        if (!isNewNodeSecOpen) {
            setIsNewNodeSecOpen(true);
            return
        }
        // if (addNewNodeSecRef.current.addNewSecNode) {
        //     addNewNodeSecRef.current.addNewSecNode();
        // }
    }


    const setOperator = (data) => {
        let len=data.length;
        let temp=[];
        if (len > 0) {

          
            temp=[];
            let obj=null;
            for(let i=0; i<len;i++ ){

                obj =data[i];
                obj.key=i;
                temp.push(obj)

            }
            setModalSate({
                ...modalSate,
                operators: data,
                modleData:temp,
            })
        }
       

    }
    const toggleModal = (i) => {

        // setUser(user)   //错误，React 发现接收的对象的地址是同一个，则会认为数据没有改变，不会更新视图

        setModalSate({
            ...modalSate,
            show: !modalSate.show,
            pos: i,
        })
    //    getProductNodeData();
      // setIsModifying(false)
    }

    const conformModal = () => {

        let tep=operatorSelected;

        let length = operatorSelected.length;
        let length2 = modalSate.operators.length;
        let opId;
        var item;
        let items = [];
        for (var i = 0; i < length; i++) {
            opId = operatorSelected[i].userId
            for (var ii = 0; ii < length2; ii++) {
                item = modalSate.operators[ii];
                if (item.userId == opId) {
                    items.push(item);
                }
            }
        }

        let localIndex = modalSate.pos;

        // if(!data[localIndex].items){
        // to do:  items  allways do not exist.
        if (!data[localIndex].userConfig) {
            data[localIndex].userConfig = items;
        } else {
           // data[localIndex].userConfig = data[localIndex].userConfig.concat(items);
           length = items.length;
           let temp=data[localIndex].userConfig;
           let found=false;
           length2 = temp.length;

           for (var i = 0; i < length; i++) {
                opId = items[i].userId;
                found=false;
                for (var ii = 0; ii < length2; ii++) {
                    item = temp[ii];
                    if (item.userId == opId) {
                        found=true;
                        break;
                    }
                }
                if(!found)
                    temp.push(items[i])
            }
        }


        setData( [...data]
        )

        setModalSate({
            ...modalSate,
            show: !modalSate.show,
            pos: -1,
            // select:operatorSelected,
        })

        saveNode2(localIndex);
    }



    const toggleModal2 = (i) => {

        // setUser(user)   //错误，React 发现接收的对象的地址是同一个，则会认为数据没有改变，不会更新视图

        setModalSate({
            ...modalSate,
            show: !modalSate.show,
            pos: i,
        })
       // getProductNodeData();
    //    setIsModifying(true);
    }

    const toggleVersionModal = (i) => {

        // setUser(user)   //错误，React 发现接收的对象的地址是同一个，则会认为数据没有改变，不会更新视图

        setVersionModalSate({
            ...versionModalSate,
            show : !versionModalSate.show,
        })
    //    getProductNodeData();
      // setIsModifying(false)
    }

    const conformVersionModal = () => {

 

        let length = operatorSelected2.length;
        if(length >0){
            //setNodeSelected(operatorSelected[0].id);
            nodeSelected = operatorSelected2[0].id;
            getProductNodeData();
            setVersionModalSate({
                ...versionModalSate,
                show : !versionModalSate.show,
            })
        }
    }

   
    const closingSectionFx = () => {
        setIsNewNodeSecOpen(false)
    }
    useEffect(() => {
        document.title = i18n.t('view_product');
        nodeSelected =null;
        let resultArr = productArr.filter((e, i) => Number(e.productId) === Number(productId))
        if (resultArr.length > 0) {
            setProductData(resultArr[0])
        }
        getProductNodeData()
    }, []);
    return (
        <Translation>
            {
                (t, {i18n}) =>
                    <React.Fragment>
                        {props.loading ? <Loader/> : null}
                        <div className="container-fluid">
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box">
                                        {/* <h4 className="navi-heander-size">{this.props.match.params.productId !== undefined ? t('edit_product') : t('add_product')}</h4> */}
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item font-bold-size">{t('product_management')}</li>
                                            <li className="breadcrumb-item sub-tile-color"><Link  to="/view-product">{t('trace_role_managment')}</Link></li>
                                        </ol>
                                    </div>
                                </Col>
                            </Row>
                            <div className="InfoContainer">
                                <Row>
                                    <Col sm={12} md={12}>
                                        <Card>
                                            <CardBody style={{width: "100%", paddingLeft: "0", paddingRight: "0"}}>
                                                <div className={"padding-add"}>
                                                    <div style={{
                                                        color: "black",
                                                        fontSize: "20px",
                                                        fontWeight: "700",
                                                        marginBottom: "22px"
                                                    }}>{t('product_base_info')}
                                                    </div>
                                                    <div style={{
                                                        maxWidth: "653px",
                                                        width: "100%",
                                                        font: "18px",
                                                        color: "black",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginBottom: "22px"
                                                    }}>
                                                        <div>{t('product_name')}: {props.match.params.productName ? props.match.params.productName : ""}</div>
                                                        <Space>
                                                            <div>{t('node_info_version')}: {version ? version:" "} </div> 
                                                            { nodeVersions.length > 1 &&
                                                                <button onClick= {() => {
                                                                        toggleVersionModal(-1)
                                                                    }}
                                                                    className="btn btn-primary mo-mb-2 btn-primary-bg"
                                                                    type="button">{ t('node_modify_role')}
                                                                </button>
                                                            }
                                                         </Space>
                                                        {/*<div>创建时间：{props.match.params.created_at ? props.match.params.created_at : ""}</div>*/}
                                                    </div>
                                                    <div style={{
                                                        color: "black",
                                                        fontSize: "20px",
                                                        fontWeight: "700",
                                                        marginBottom: "22px"
                                                    }}>{t('trace_node_info')}
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        data ? <DisplayPad getProductNodeData={getProductNodeData}
                                                                         nodeTotalData={nodeTotalData}
                                                                         isModifying={isModifying}
                                                                         setIsModifying={setIsModifying}
                                                                         isNewNodeSecOpen={isNewNodeSecOpen}
                                                                         deleteNodeFx={deleteNode} nodeId={nodeId}
                                                                         data={data} setData={setData}
                                                                         setOperator={setOperator}
                                                                         toggleModal={toggleModal}/>
                                                        : <div className="noResultDiv padding-add">
                                                            {/*<img width={200} src={NoData}></img>*/}
                                                            <img width={200} src='https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/app/NoData.png'></img>
                                                            <div>{t('no_data')}</div>
                                                        </div>
                                                    }
                                                   
                                                   
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>

 
                        <Modal  style={{width: '1000px', height: '600px'}}  
                            className="modal-lg"  
                            isOpen={modalSate.show}>
                            <ModalHeader toggle={() => toggleModal(-1)}>
                                {t('role_manager')}
                            </ModalHeader>
                            <ModalBody>
                                <Table2 rowSelection={{
                                    type: "checkbox ",
                                    ...rowSelection,
                                    }}  columns={columns} dataSource={modalSate.modleData} />

                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary"
                                    onClick={() => toggleModal2(-1)}
                                >  {t('cancel_remove')}  </Button>
                                <Button color="primary"
                                    onClick={() => conformModal()}
                                >  {t('common_add')} </Button>
                            </ModalFooter>
                        </Modal>
                        

                        <Modal  style={{width: '500px', height: '600px'}}  
                            className="modal-lg"  
                            isOpen={versionModalSate.show}>
                            <ModalHeader toggle={() => toggleVersionModal(-1)}>
                                {t('node_info_version')}
                            </ModalHeader>
                            <ModalBody>
                                <Table2 rowSelection={{
                                    type: "radio",
                                    ...rowSelection2,
                                    }}  
                                    columns={vesioncolumns} dataSource={nodeVersions} />

                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary"
                                    onClick={() => toggleVersionModal(-1)}
                                >  {t('cancel_remove')}  </Button>
                                <Button color="primary"
                                    onClick={() => conformVersionModal()}
                                >  {t('confirm')} </Button>
                            </ModalFooter>
                        </Modal>
                    </React.Fragment>}
        </Translation>
    );
}
export default RoleSettings
