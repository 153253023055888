import React, {Component} from "react";
import {Tooltip, Cascader} from 'antd';
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";

import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table, InputGroup} from "reactstrap";



import axios from "axios";
import {parseISO} from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

import {Modal, Collapse} from "reactstrap";

import {format} from "date-fns";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";


import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";

import i18n from '../../i18n';
import {Translation} from 'react-i18next';

// actions
import {
    getDistributionList,
    getDistributionDetail,
    clearInputError,
    clearAlert,
    apiError,
    clearProductIntanceCSV,
} from "../../store/distribute/actions";

import CSVHandler from "../../components/CSVDownloader/CSVHandler"

import {ConfigProvider, Pagination,DatePicker} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import zhHK from "antd/lib/locale/zh_HK";

import commonUtils from '../../helpers/commonUtils'




const nowDate = commonUtils.getNowFormatDate();
// console.log('当天时间==' + getNowFormatDate());

const currentStatus = 'completed';


// 0 所有信息（包含已激活，未激活，已过期） 1 已激活未过期 2 未激活 3 已激活已经过期
const optionStatus = [
    {label: '所有状态', value: '0'},
    {label: '已激活未过期', value: '1'},
    {label: '未激活', value: '2'},
    {label: '已激活已经过期', value: '3'},
];

const optionPageSize = [
    {label: 10, value: 10},
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
];

const getSortingComponent = (value, sorting) => {
    if (value == sorting.column) {
        switch (sorting.order) {
            case '':
                return <i className="typcn typcn-media-record"/>;
                break;

            case 'asc':
                return <i className="ion ion-ios-arrow-up"/>;
                break;

            case 'desc':
                return <i className="ion ion-ios-arrow-down"/>;
                break;
        }
    } else {
        return <i className="typcn typcn-media-record"/>;
    }
}

const PackageTableRow = (props) => {
    const dataList = props.data;
    const toggleModalShipPackage = props.toggleModalShipPackage;
    const toggleModalShippingInfo = props.toggleModalShippingInfo;
    const toggleModalStatusChangeLog = props.toggleModalStatusChangeLog;
    const handleDistributionDetail=props.handleDistributionDetail;
    const currentTime = props.time;

    let startRowNo = props.pageNumber * props.pageSize + 1;

    const toggleModalBlockchain = props.toggleModalBlockchain;

    const greatToday =(date) =>{

         if(new Date(date) < new Date()){
             return false;
         }
         else{
             return true;
         }

    }

    if (!dataList)
        return (<tbody></tbody>);

    const trimDate =(date) =>{
        return format(new Date(date), 'yyyy-MM-dd')
    }

    const listItems = dataList.map((data, index) => {
        let displayStatus = '';

        return (<tr key={index}>
                <td><span className="float-right">{startRowNo++}</span></td>

                {/* <td><span className="float-right">{data.id}</span></td> */}
                <td><span className="nowrap">{data.id}</span></td>
                {/* <td><span className="nowrap">{data.time}</span></td>
                <td><span className="nowrap">{data.number}</span></td>
                <td><span className="nowrap">{data.normal}</span></td> */}
               {data.abnormal > 0 ? <td><span className="nowrap" style={{color: '#ec4561'}}>{data.abnormal}</span></td>:<td><span className="nowrap">{data.abnormal}</span></td>}
                <td><span className="nowrap ">{data.details}</span></td>
               

                {currentStatus === 'packed' ?
                    <td className="nowrap">
                        <Button
                            color="info"
                            onClick={() => toggleModalShipPackage(data.packageId)}
                        >
                            {i18n.t('ship_package')}
                        </Button>
                    </td> : null}


                <td>
                    <div className="nowrap table_td_space_around">
                    
                           {  data.abnormal ?
                                <span style={{cursor: 'pointer'}}
                                    className="text-blue"
                                    onClick={() => handleDistributionDetail(data)}
                                >
                                    {i18n.t('note')}
                                </span>  : <span className="pointer-no-drop">
                                    {i18n.t('note')}
                                </span>
                            }
                    </div> 
                </td>
            </tr>
        )
    });

    return (<tbody>{listItems}</tbody>);

};

const entryStr = (pageNumber, pageSize, filteredCount, totalCount) => {
    let to = (pageNumber + 1) * pageSize;
    if (to > filteredCount) {
        to = filteredCount;
    }

    let from = pageNumber * pageSize + 1;
    if (from > to) {
        from = to;
    }
    return <>{i18n.t('table_count', {from: from, to: to, filter: filteredCount, total: totalCount})}</>;
}


const userStr = (adminUser, workerUser) => {

    if (adminUser) {
        return i18n.t('admin_bracket')
    } else {
        return i18n.t('worker_bracket')
    }

};



const StatusChangeLogRow = (props) => {
    console.log('testing StatusChangeLogRow()', props.data);
    const dataList = props.data;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td><span className="nowrap">{data.productSerialNumber}</span></td>
            <td>{data.location}</td>
            <td>{data.createTime}</td>
        </tr>
    );

    return (<tbody>{listItems}</tbody>);
};



class Distribution extends Component {
    constructor(props) {
        super(props);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleFilterVisibility = this.handleFilterVisibility.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleAddStep = this.handleAddStep.bind(this);
        this.handleRemoveStep = this.handleRemoveStep.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.toggleModalShipPackage = this.toggleModalShipPackage.bind(this);
        this.toggleModalShippingInfo = this.toggleModalShippingInfo.bind(this);
        this.toggleModalStatusChangeLog = this.toggleModalStatusChangeLog.bind(this);
        this.handleDistributionDetail =this.handleDistributionDetail.bind(this);
       
        
        this.handler2 = this.handler2.bind(this);
        this.getCurrentTime = this.getCurrentTime.bind(this);

        this.handleAntdPageChange = this.handleAntdPageChange.bind(this);
        this.handleAntdPageSizeChange = this.handleAntdPageSizeChange.bind(this);
       
        this.state = {
            currentTime: 0,
            filter: {
                status: '0',
                startTime: undefined,
                expiredTime: undefined,
            },
            pageNumber: 0,
            pageSize: 10,
            filteredCount: 0,
            pageCount: 0,
            totals: 0,
            sorting: {
                column: 'packageId',
                order: 'desc',
            },
            modalPackageId: undefined,
            modalShipPackage: {
                toggle: false,
                // packageId: [],
            },
            modalShippingInfo: {
                toggle: false,
                // updateMode: '',
                shipmentCourierRefNo: '',
                shippingInfo: [],
                // packageId: [],
            },
            modalStatusChangeLog: {
                toggle: false,
                statusChangeLog: [],
            },
            productName :'',
            address:{},
            lang:{
                lang:zhCN,
                locale:"zh",
            }
        };
    }

    componentDidMount() {
        console.log('componentDidMount');
        this.handleFormSubmit();
    }

    componentDidUpdate() {
       
        console.log('componentDidUpdate', this.props.filteredCount);


        let pageCount = Math.ceil(this.props.filteredCount / this.state.pageSize);

        // console.log('testing update page count', this.state.pageCount, pageCount);

        if (this.state.pageCount !== pageCount) {
            // console.log('订单历史 componentDidUpdate');
            if (pageCount <= this.state.pageNumber) {
                this.setState({pageCount: pageCount, pageNumber: 0}, () => this.handleFormSubmit());
            } else {
                this.setState({pageCount: pageCount});
            }
        }

        if (this.props.productInstanceCsv !== null) {
            console.log('testing', this.props.productInstanceCsv);
            const downFileType = this.props.productInstanceCsv.type;
            const file = new Blob([this.props.productInstanceCsv]);
            const url = window.URL.createObjectURL(file);
            // console.log('testing download', url);
            let link = document.createElement("a");
            // 导出csv 导出文件名用“商品名+日期
            if (downFileType === 'application/octet-stream') {
                let downloadName = this.state.productName + '.zip';
                link.setAttribute('download', downloadName);
            } else {
                let downloadName = this.state.productName + '.csv';
                link.setAttribute('download', downloadName);
            }
        
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();

            this.props.clearProductIntanceCSV();
        }

        let lang= sessionStorage.getItem("lang");
        if(this.state.lang.locale !==lang){
            if(lang ==="en"){
                this.setState({lang:{lang:enUS,
                    locale:lang
                }});
            }
            else if(lang ==="zh"){
                this.setState({lang:{
                    lang:zhCN,
                    locale:lang}});
            }
            else{
                this.setState({lang:{
                    lang:zhHK,
                    locale:lang}});
            }
        }

    }

    handler2 = CSVHandler.codeBatch.allDownloadHandler

    getCurrentTime() {
        axios.get("https://worldtimeapi.org/api/ip")
            .then((res) => {
                let currentTime = new Date();
                if (res.data) {
                    currentTime = res.data.datetime ? parseISO(res.data.datetime) : currentTime;
                }
                this.setState({currentTime: currentTime})
                console.log("update current time")
            })
            .catch((err) => {
                let currentTime = new Date();
                this.setState({currentTime: currentTime})
            })
    }

    handleFilterInputChange(event) {
        console.log('handleFilterInputChange', event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));

        console.log('after', this.state);
    }

    handleFilterStatusChange(event) {
        console.log('handleFilterStatusChange', event);

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                status: event.value,
            },
        }));
    }

    handleFilterDateChange(dateInput, value) {
        console.log('handleFilterDateChange', dateInput, value);

        let dateInputValue = value === null ? undefined : value;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [dateInput]: dateInputValue,
            },
        }));
    }


    handlePageSizeChange(event) {
        console.log('handlePageSizeChange', event);

        this.setState({
                pageSize: event.value,
                pageNumber: 0,
            },
            () => this.handleFormSubmit());
    }

    handleSorting(value) {
        console.log('handleSorting', value, this.state.sorting.column);

        let newOrder;
        let newComponent;

        if (value == this.state.sorting.column) {
            switch (this.state.sorting.order) {
                case '':
                    newOrder = 'asc';
                    break;

                case 'asc':
                    newOrder = 'desc';
                    break;

                case 'desc':
                    newOrder = '';
                    break;
            }
        } else {
            newOrder = 'asc';
        }

        this.setState(prevState => ({
                sorting: {
                    column: value,
                    order: newOrder,
                }
            }),
            () => this.handleFormSubmit());
    }

    handlePageClick(event) {
        console.log('handlePageClick', event);

        this.setState(
            prevState => ({
                pageNumber: event.selected,
            }),
            () => this.handleFormSubmit()
        );

        return false;
    }


    // 改变页码
    handleAntdPageChange(page, pageSize) {
        this.setState({
                pageSize: pageSize,
                pageNumber: page - 1,
            },
            () => this.handleFormSubmit()
        );
    }

    // 改变每页显示条数
    handleAntdPageSizeChange(current, size) {
        this.setState({
                pageSize: size,
                pageNumber: 0,
            },
            () => this.handleFormSubmit()
        );
    }


    handleClearFilter() {
        console.log('handleClearFilter()');

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                status: '0',
                startTime: undefined,
                expiredTime: undefined,
            },
        }), () => {
            this.handleFormSubmit()
        });
    }

    handleFilterVisibility() {
        console.log('handleFilterVisibility()');

        this.setState(prevState => ({
            filterVisibility: !prevState.filterVisibility
        }));
    }

    handleAddStep() {
        console.log('handleAddStep');

        this.setState(prevState => ({
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                shippingInfo: [
                    ...prevState.modalShippingInfo.shippingInfo,
                    {
                        uploadable: true,
                    }
                ].map((data, index) => {
                    data.index = index;
                    return data;
                }),
            },
        }));
    }

    handleRemoveStep(index) {
        console.log('handleRemoveStep()', index);

        let tmp = [];

        for (let i = 0; i < this.state.modalShippingInfo.shippingInfo.length; i++) {
            if (index !== i) {
                // not to delete
                tmp.push(this.state.modalShippingInfo.shippingInfo[i]);
            } else {
                // index matched
                if (this.state.modalShippingInfo.shippingInfo[i].uploadable === true) {
                    // delete
                    console.log('testing 1');
                } else {
                    // mark as delete
                    console.log('testing 2');
                    tmp.push({
                        ...this.state.modalShippingInfo.shippingInfo[i],
                        delete: !this.state.modalShippingInfo.shippingInfo[i].delete
                    });
                }
            }
        }

        this.setState(prevState => ({
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                shippingInfo: tmp.map((data, index) => {
                    data.index = index;
                    return data;
                }),
            }
        }));
    }

    handleFormSubmit(event, values) {
        let pageNumber = this.state.pageNumber;
        let submitData = {
          //  status: this.state.filter.status,
         //   startTime: this.state.filter.startTime !== undefined ? format(new Date(this.state.filter.startTime), 'yyyy-MM-dd HH:mm:ss') : undefined,
         //   expiredTime: this.state.filter.expiredTime !== undefined ? format(new Date(this.state.filter.expiredTime), 'yyyy-MM-dd HH:mm:ss') : undefined,
            pageNumber: pageNumber + 1,
            pageSize: this.state.pageSize,
         //   sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
        };

        console.log('on handleFormSubmit()', event, values, submitData);
        this.props.getDistributionList(submitData, this.props.history);
    }

    handleModalSubmit(event, values) {
        // console.log('on handleModalSubmit()', event, values, this.state.modalShippingInfo.updateMode);
        console.log('on handleModalSubmit()', event, values, this.state.modalShippingInfo.shippingInfo);
        let newStatus;
        let removeShippingRecord = undefined;
        // let newOrderShippingRecord = undefined;
        let shippingRecord = undefined;

        if (currentStatus === 'packed') {
            newStatus = 'shipped';
        } else if (currentStatus === 'shipped') {
            // if(event === 'save') {
            //   newStatus = 'shipped';
            // } else {
            //   newStatus = 'completed';
            // }
            newStatus = values.status;

            removeShippingRecord = [];
            // newOrderShippingRecord = [];
            shippingRecord = [];

            let step = 1;

            for (let tmp of this.state.modalShippingInfo.shippingInfo) {
                if (tmp.delete === true) {
                    removeShippingRecord.push(tmp.step);
                }
                // else if(tmp.uploadable !== true) {
                //   newOrderShippingRecord.push({
                //     current: tmp.step,
                //   });
                // }
                else if (tmp.uploadable === true) {
                    shippingRecord.push({
                        time: values.time[tmp.index],
                        note: values.note[tmp.index],
                    });
                }
            }
        }

        if (newStatus !== undefined) {
            let submitData = {
                packageId: this.state.modalPackageId,
                status: newStatus,
                shipmentCourierRefNo: values.shipmentCourierRefNo,
                removeShippingRecord,
                shippingRecord,
            };
            console.log('on handleModalSubmit()', submitData);

            // this.props.updatePackage(submitData, this.props.history);
        }
    }

    /**
     * modal
     */
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleModalShipPackage(modalPackageId = undefined) {
        console.log('toggleModalShipPackage', modalPackageId);

        this.setState(prevState => ({
            modalPackageId: modalPackageId,
            modalShipPackage: {
                ...prevState.modalShipPackage,
                toggle: !prevState.modalShipPackage.toggle,
            },
        }));
        this.removeBodyCss();
    }

    toggleModalShippingInfo(modalPackageId = undefined, shipmentCourierRefNo = '', shippingRecords = []) {
        console.log('toggleModalShippingInfo', modalPackageId, shipmentCourierRefNo, shippingRecords);


        this.setState(prevState => ({
            modalPackageId: modalPackageId,
            modalShippingInfo: {
                ...prevState.modalShippingInfo,
                toggle: !prevState.modalShippingInfo.toggle,
                shipmentCourierRefNo: shipmentCourierRefNo,
                shippingInfo: shippingRecords.map((data, index) => {
                    data.index = index;
                    return data;
                }),
            },
        }));
        this.removeBodyCss();
    }

    /**
     *   active date  modal
     */
    toggleModalStatusChangeLog(data = []) {
        // console.log('toggleModalStatusChangeLog', data);

   
        this.setState(prevState => ({
            modalStatusChangeLog: {
                ...prevState.modalStatusChangeLog,
                toggle: !prevState.modalStatusChangeLog.toggle,
            }
        }));
        this.removeBodyCss();
    }

    /**
     * 
     * @returns 
     */
     handleDistributionDetail(data){
        let param = {  packageId: data.id }
        // let time=format(new Date(e.createTime), 'yyyyMMdd');
        // this.state.productName=e.productName+time+e.startIndex;
        this.setState( prevState => ({
            modalStatusChangeLog: {
            ...prevState.modalStatusChangeLog,
            toggle: !prevState.modalStatusChangeLog.toggle,
        },
        }));
        this.props.getDistributionDetail(param, this.props.history);
    }







    render() {
        const {photoIndex} = this.state;

        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {this.props.loading ? <Loader /> : null}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            {/* <h4 className="navi-heander-size">
                                                {t('scan_moniter')}
                                            </h4> */}
                                            <ol className="breadcrumb mb-0">
                                                <li className="breadcrumb-item font-bold-size">{t('scan_record')}</li>
                                                <li className="breadcrumb-item sub-tile-color"><Link
                                                     >{t('scan_moniter')}</Link>
                                                    <Tooltip title={t('code_moniter_tips')}><i className="ti-help-alt help-margin"></i></Tooltip>
                                                    </li>
                                            </ol>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='InfoContainer'>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                {/* <Row>
                                                    <Col md="12" className="mb-3">
                                                        <button
                                                            onClick={this.handleFilterVisibility}
                                                            className="btn btn-primary mo-mb-2 btn-primary-bg"
                                                            type="button"
                                                        >
                                                            {this.state.filterVisibility === true ? t('hide_filter') : t('show_filter')}
                                                        </button>
                                                        <CSV path="codeBatch"
                                                             curfilename={'物码批次管理第' + (parseInt(this.state.pageNumber + 1)) + '页记录' + nowDate + '.csv'}
                                                             allfilename={'物码批次管理全部记录' + nowDate + '.csv'}
                                                             handler1={CSVHandler.codeBatch.pageDownloadHandler}
                                                             parser={CSVHandler.codeBatch.parser}
                                                             handler2={this.handler2}/>
                                                    </Col>
                                                </Row> */}

                                                {/* <Collapse isOpen={this.state.filterVisibility}>
                                                    <AvForm className="mb-3" onValidSubmit={this.handleFormSubmit}>
                                                        <Row>
                                                          
                                                             <Col sm={2}>
                                                                <FormGroup>
                                                                    <Label>{t('status')}</Label>
                                                                    <Select
                                                                        name="status"
                                                                        value={optionStatus.find(option => option.value === this.state.filter.status)}
                                                                        onChange={this.handleFilterStatusChange}
                                                                        options={optionStatus}
                                                                        isSearchable={false}
                                                                        menuPortalTarget={document.body}
                                                                    />
                                                                </FormGroup>
                                                            </Col> 

                                                            <Col sm={4}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('active_start_index')}</Label>
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        dateFormat="yyyy-MM-dd hh:mm:ss"
                                                                        showTimeSelect="true"
                                                                        selected={this.state.filter.startTime}
                                                                        onChange={(value) => this.handleQueryDateChange('startTime', value)}
                                                                        isClearable
                                                                        timeCaption="时间"
                                                                        placeholderText={t('active_setting_tip')}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('active_end_index')}</Label>
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        dateFormat="yyyy-MM-dd hh:mm:ss"
                                                                        showTimeSelect="true"
                                                                        selected={this.state.filter.expiredTime}
                                                                        onChange={(value) => this.handleQueryDateChange('expiredTime', value)}
                                                                        isClearable
                                                                        timeCaption="时间"
                                                                        placeholderText={t('active_setting_tip')}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Button color="primary btn-primary-bg"
                                                                            className="mr-1">{t('apply')}</Button>
                                                                    <Button color="secondary"
                                                                            onClick={this.handleClearFilter}>{t('clear')}</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                </Collapse> */}

                                                <Row>
                                                    <Col sm={12}>
                                                        <p className="text-muted m-b-30">
                                                            {entryStr(this.state.pageNumber, this.state.pageSize, this.props.filteredCount, this.props.totalItemCount)}
                                                        </p>
                                                    </Col>
                                                </Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            className="table   thead-nowrap"
                                                        >
                                                            <thead className="thead-header">
                                                            <tr>
                                                                <th className="w-1p"><span
                                                                    className="float-right">#</span></th>

                                                                {/* <th className="w-1p"
                                                                    onClick={() => this.handleSorting('batchId')}>
                                                                    <span
                                                                        className="float-right">{t('batch_id')} {getSortingComponent('batchId', this.state.sorting)}</span>
                                                                </th> */}
                                                                <th>{t('package_id')}</th>
                                                                {/* <th>{t('scan_span')}</th>
                                                                <th>{t('total_unit')}</th>
                                                                <th>{t('noraml')}</th> */}
                                                                <th>{t('abnormal')}</th>
                                                                <th>{t('destination_address')}</th>
                                                                <th className='w-3p'>{t('action')}</th>
                                                            </tr>
                                                            </thead>
                                                            <PackageTableRow
                                                                toggleModalBlockchain={this.toggleModalBlockchain}
                                                                time={this.state.currentTime}
                                                                data={this.props.DistributionList}
                                                                toggleModalShipPackage={this.toggleModalShipPackage}
                                                                toggleModalShippingInfo={this.toggleModalShippingInfo}
                                                                toggleModalStatusChangeLog={this.toggleModalStatusChangeLog}
                                                                handleDistributionDetail={this.handleDistributionDetail}
                                                                pageNumber={this.state.pageNumber}
                                                                pageSize={this.state.pageSize}/>
                                                        </Table>
                                                    </div>
                                                </div>

                                                <Row className='antd-pagination'>
                                                    {
                                                        this.props.filteredCount > 0 ? <Col>
                                                            <ConfigProvider locale={this.state.lang.lang}>
                                                                <Pagination
                                                                    total={this.props.filteredCount}
                                                                    showSizeChanger
                                                                    showQuickJumper
                                                                    onChange={this.handleAntdPageChange}
                                                                    onShowSizeChange={this.handleAntdPageSizeChange}
                                                                    showTotal={total => t('table_nav_count', {total: total})}
                                                                />
                                                            </ConfigProvider>
                                                        </Col> : null
                                                    }
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                            {this.props.alert.success ? (
                                <SweetAlert
                                    title={t('code_setting_title_success')}
                                    success
                                    confirmBtnBsStyle="success"
                                    onConfirm={() => {
                                        this.props.clearAlert('success');
                                        // currentStatus === 'packed' ? this.toggleModalShipPackage() : this.toggleModalShippingInfo();
                                        this.handleFormSubmit();
                                    }}
                                >
                                </SweetAlert>
                            ) : null}

                            {this.props.alert.error ? (
                                <SweetAlert
                                    title={this.props.alert.errorMessage}
                                    error
                                    confirmBtnBsStyle="error"
                                    onConfirm={() => {
                                        this.props.clearAlert('error');
                                    }}
                                >
                                    {this.props.alert.errorDescription}
                                </SweetAlert>
                            ) : null}


                            

                            {/* time setting  mpdal */}
                            <Modal
                                className="modal-lg"
                                isOpen={this.state.modalStatusChangeLog.toggle}
                            >
                                 {/*<AvForm onValidSubmit={this.toggleModalActiveDate}>*/}
                                <ModalHeader
                                    toggle={() => this.toggleModalStatusChangeLog()}
                                >
                                    {t('scan_record')}
                                </ModalHeader>
                                <ModalBody style={{padding: '0 1rem', marginBottom: '10px'}}>
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                className="table  thead-nowrap"
                                            >
                                                <thead className="thead-header">
                                                <tr>
                                                    <th>{t('package_id')}</th>
                                                    <th>{t('location')}</th>
                                                    <th>{t('time')}</th>
                                                </tr>
                                                </thead>
                                                <StatusChangeLogRow
                                                    data={this.props.Locations}/>
                                            </Table>
                                        </div>
                                    </div>
                                   
                                </ModalBody>
                                <ModalFooter>
                                    {/* <Button
                                        color="secondary"
                                        onClick={() => this.toggleModalStatusChangeLog()}
                                    >
                                        {t('close')}
                                    </Button> */}
                                    <Button
                                        color="primary"
                                        onClick={() => this.toggleModalStatusChangeLog()}
                                    >
                                        {t('confirm')}
                                    </Button>
                                </ModalFooter>
                                 {/*</AvForm>*/}
                            </Modal>

                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    console.log('mapStateToProps', state.Distribution)
    const {
        DistributionList,
        filteredCount,
        totalItemCount,
        inputError,
        alert,
        error,
        loading,
        Locations,
        productInstanceCsv
    } = state.Distribution;

    return {
        DistributionList,
        filteredCount,
        totalItemCount,
        inputError,
        alert,
        error,
        loading,
        Locations,
        productInstanceCsv
    };
};

export default withRouter(connect(mapStateToProps, {
    getDistributionList,
    getDistributionDetail,
    clearInputError,
    clearAlert,
    apiError,
    clearProductIntanceCSV,
})(Distribution));
