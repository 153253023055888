export const GET_NODE_BY_ID = 'GET_NODE_BY_ID';
export const GET_NODE_BY_ID_SUCCESS = 'GET_NODE_BY_ID_SUCCESS';

export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';


export const API_ERROR = 'PRODUCT_API_ERROR';
