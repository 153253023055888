import React, {Component} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {AvForm, AvField} from "availity-reactstrap-validation";
import {Row, Col, Card, CardBody, Form, FormGroup, Button, Label, Table, InputGroup} from "reactstrap";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {Modal, Collapse} from "reactstrap";
import CSV from "../../components/CSVDownloader/CSVDownloader"
import CSVHandler from "../../components/CSVDownloader/CSVHandler"
import {format} from "date-fns";
import ToggleSwitch from "../../components/ToggleSwitch"
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";

import i18n from '../../i18n';
import {Translation} from 'react-i18next';

import AntdPagination from '../../components/AntdPagination';

import {Pagination, ConfigProvider, DatePicker} from 'antd';

import lianbg from '../../assets/images/lian/lian3.png'
import lianlogo from '../../assets/images/lian/lian4.png'
import moment from 'moment';


// actions
import {
    getProductInstanceList,
    clearAlert,
    productInstanceApiError,
    getProductInstanceGroupedList,
} from "../../store/productInstance/actions";
import zhCN from "antd/lib/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import zhHK from "antd/lib/locale/zh_HK"

// import locale2 from 'antd/es/date-picker/locale/zh_CN';

import commonUtils from '../../helpers/commonUtils'
const nowDate = commonUtils.getNowFormatDate();

const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

const optionStatus = [
    {label: i18n.t('all'), value: ''},
    {label: i18n.t('inbound'), value: 'inbound'},
    {label: i18n.t('packed'), value: 'packed'},
    {label: i18n.t('shipped'), value: 'shipped'},
    {label: i18n.t('completed'), value: 'completed'},
];

const optionPageSize = [
    {label: 10, value: 10},
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
];

const getSortingComponent = (value, sorting) => {
    if (value == sorting.column) {
        switch (sorting.order) {
            case '':
                return <i className="typcn typcn-media-record"/>;
                break;

            case 'asc':
                return <i className="ion ion-ios-arrow-up"/>;
                break;

            case 'desc':
                return <i className="ion ion-ios-arrow-down"/>;
                break;
        }
    } else {
        return <i className="typcn typcn-media-record"/>;
    }
}

const GroupedProductInstanceTableRow = (props) => {
    const dataList = props.data;
    let startRowNo = props.pageNumber * props.pageSize + 1;

    const toggleModalBlockchain = props.toggleModalBlockchain;

    const listItems = dataList.map((data, index) => {
        let displayStatus = '';

        switch (data.status) {
            case 'inbound':
                displayStatus = i18n.t('inbound');
                break;
            case 'packed':
                displayStatus = i18n.t('packed');
                break;
            case 'shipped':
                displayStatus = i18n.t('shipped');
                break;
            case 'completed':
                displayStatus = i18n.t('completed');
                break;
        }


        return (<tr key={index}>
            <td><span className="float-right">{startRowNo++}</span></td>
            <td><span className="float-right">{data.packageId}</span></td>
            <td><span className="float-right">{data.productCount}</span></td>
            <td className='w-3p'><span className="capitalize nowrap">{displayStatus}</span></td>
            <td><span className="nowrap">{data.packedDate}</span></td>
            <td><span className="nowrap">{data.shippedDate}</span></td>
            <td><span className="nowrap">{data.completedDate}</span></td>
            <td className='td-text-center'>
                <Link to={`/inventory/package/${data.packageId}`}>
          <span style={{color: '#3BA1DB'}}>
            {i18n.t('open')}
          </span>
                </Link>
            </td>
        </tr>)
    });

    return (<tbody>{listItems}</tbody>);
};

const ProductInstanceTableRow = (props) => {
    const dataList = props.data;
    const toggleModalStatusChangeLog = props.toggleModalStatusChangeLog;
    const productName =props.productName;

    let startRowNo = props.pageNumber * props.pageSize + 1;

    const listItems = dataList.map((data, index) => {
        let displayStatus = '';

        switch (data.status) {
            case 'inbound':
                displayStatus = i18n.t('inbound');
                break;
            case 'packed':
                displayStatus = i18n.t('packed');
                break;
            case 'shipped':
                displayStatus = i18n.t('shipped');
                break;
            case 'completed':
                displayStatus = i18n.t('completed');
                break;
        }


        return (<tr key={index}>
             <td><span className="float-right">{startRowNo++}</span></td>
             { 
                !productName  && <>
                <td><span className="float-right">{data.productSerialNumber}</span></td>
                <td><span className="float-right">{data.productId}</span></td>
                <td>{data.productName}</td>
                </>
             }
           
           
            <td><span className="float-right">{data.packageId}</span></td>
            <td className='w-3p'><span className="capitalize nowrap">{displayStatus}</span></td>
            <td><span className="nowrap">{data.inboundDate}</span></td>
            <td><span className="nowrap">{data.packedDate}</span></td>
            <td><span className="nowrap">{data.shippedDate}</span></td>
            <td><span className="nowrap">{data.completedDate}</span></td>
            <td className='td-text-center' style={{color: '#3BA1DB', cursor: 'pointer'}}>
        <span onClick={() => toggleModalStatusChangeLog(data)}>
          {i18n.t('open')}
        </span>
            </td>
        </tr>)
    });

    return (<tbody>{listItems}</tbody>);
};

const entryStr = (pageNumber, pageSize, filteredCount, totalCount) => {
    let to = (pageNumber + 1) * pageSize;
    if (to > filteredCount) {
        to = filteredCount;
    }

    let from = pageNumber * pageSize + 1;
    if (from > to) {
        from = to;
    }

    return <>{i18n.t('table_count', {from: from, to: to, filter: filteredCount, total: totalCount})}</>;
}

const userStr = (adminUser, workerUser) => {
    // if (
    //   (adminUser === null || adminUser === undefined) &&
    //   (workerUser === null || workerUser === undefined)
    // ) {
    //   return '';
    // }
    // return adminUser !== null ? `${adminUser} ` + i18n.t('admin_bracket') : `${workerUser} ` + i18n.t('worker_bracket');
    //

    if (adminUser) {
        return i18n.t('admin_bracket')
    } else {
        return i18n.t('worker_bracket')
    }

};

const StatusChangeLogRow = (props) => {
    console.log('testing StatusChangeLogRow()', props.data);
    const dataList = props.data;

    const listItems = dataList.map((data, index) =>
        <tr key={index}>
            <td><span className="nowrap">{data.status}</span></td>
            <td>{data.datetime}</td>
            <td><span className="nowrap">{data.userId}</span></td>
            <td>{data.location}</td>
        </tr>
    );
    return (<tbody>{listItems}</tbody>);
};

const BlockLianChangeLogRow = (props) => {
    console.log('testing BlockLianChangeLogRow()', props.data);
    const info = props.data;
    return (
        <div className='lian-info'>
            <img className='bg' src={lianbg}/>
            <div className='lian-data'>
                <div>
                    <p>所在链</p>
                    <p className='p2'>创益溯源链</p>
                </div>
                <div>
                    <p>所在区块</p>
                    <p className='p2'>{info.currentBlockHeight}</p>
                </div>
                <div>
                    <p>唯一编码</p>
                    <p className='p2'>{info.blockDataHash}</p>
                </div>
                <img className='logo' src={lianlogo}/>
            </div>
        </div>
    );
};

const BlockLianChangeLogRow2 = (props) => {
    console.log('testing BlockLianChangeLogRow()', props.data);
    const info = props.data;
    return (
        <tbody>
        <tr>
            <td><span className="nowrap">创益溯源链</span></td>
            <td>{info.currentBlockHeight}</td>
            <td><span className="nowrap">{info.blockDataHash}</span></td>
        </tr>
        </tbody>
    );
};

class Inventory extends Component {
    constructor(props) {
        super(props);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleFilterVisibility = this.handleFilterVisibility.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleSorting = this.handleSorting.bind(this);
        this.toggleModalStatusChangeLog = this.toggleModalStatusChangeLog.bind(this);
        this.toggleModalBlockchain = this.toggleModalBlockchain.bind(this);
        this.handler2 = this.handler2.bind(this);
        this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
        this.handleAntdPageChange = this.handleAntdPageChange.bind(this);
        this.handleAntdPageSizeChange = this.handleAntdPageSizeChange.bind(this);

       // console.log(typeof (this.props.match.params.productId));
       // console.log(typeof (this.props.match.params.productName));
        let productId =this.props.match.params.productId === undefined? -1 : parseInt(this.props.match.params.productId);
       

        this.state = {
            displayInGroups: (productId ==-1  && 
                this.props.match.params.packageId === undefined) ? true : false,
            displaySwitch: (productId ==-1 && 
                this.props.match.params.packageId === undefined) ? true : false,
            filterVisibility: false,
            filter: {
                productSerialNumber: '',
                productId: productId ? productId 
                :'',
                packageId: this.props.match.params.packageId === undefined ? '' : 
                this.props.match.params.packageId,
                status: '',
                inboundDateFrom: this.props.match.params.from ?moment(this.props.match.params.from) :undefined,
                inboundDateTo: this.props.match.params.to ?moment(this.props.match.params.to) :undefined,
                packedDateFrom: undefined,
                packedDateTo: undefined,
                shippedDateFrom: undefined,
                shippedDateTo: undefined,
                completedDateFrom: undefined,
                completedDateTo: undefined,
            },
            componentDidUpdateNum: 0,
            pageNumber: 0,
            pageSize: 10,
            filteredCount: 0,
            pageCount: 0,
            groupedPageCount: 0,
            sorting: {
                column: 'sn',
                order: 'desc',
            },
            modalStatusChangeLog: {
                toggle: false,
                statusChangeLog: [],
            },
            blockchainData: {
                toggle: false,
                info: {}
            },
            lang:{
                lang:zhCN,
                locale:"zh",
            },
            productName:this.props.match.params.productName && productId > 0 ? this.props.match.params.productName:"",
        };
    }

    handler2 = CSVHandler.inventory.allDownloadHandler
    handler3 = CSVHandler.inventory.allGroupedDownloadHandler.bind(this)

    componentDidMount() {
        // console.log('componentDidMount');
        this.handleFormSubmit();
        this.props.clearAlert('success');
    }

    componentDidUpdate() {
        document.title = i18n.t('inventory');
        // console.log('componentDidUpdate', this.props.filteredCount);

        this.state.componentDidUpdateNum += 1;

        let pageCount = Math.ceil(this.props.filteredCount / this.state.pageSize);
        let groupedPageCount = Math.ceil(this.props.filteredGroupedCount / this.state.pageSize);
        if (this.state.pageCount !== pageCount) {
            if (pageCount <= this.state.pageNumber) {
                this.setState({pageCount: pageCount, pageNumber: 0}, () => this.handleFormSubmit());
            } else {
                this.setState({pageCount: pageCount});
            }
        }
        if (this.state.groupedPageCount !== groupedPageCount) {
            if (pageCount <= this.state.groupedPageCount) {
                this.setState({groupedPageCount, pageNumber: 0}, () => this.handleFormSubmit());
            } else {
                this.setState({groupedPageCount});
            }
        }

        let lang= sessionStorage.getItem("lang");
        if(this.state.lang.locale !==lang){
            if(lang ==="en"){
                this.setState({lang:{lang:enUS,
                    locale:lang
                }});
            }
            else if(lang ==="zh"){
                this.setState({lang:{
                    lang:zhCN,
                    locale:lang}});
            }
            else{
                this.setState({lang:{
                    lang:zhHK,
                    locale:lang}});
            }
        }
        //From a given procduct 
        // if(this.props.match.params.productId && !this.state.productName && this.props.productInstanceList.length >0){

        //      this.setState({
        //         productName:this.props.productInstanceList[0].productName,
        //      })
        // }

    }

    renderAntdPagination(totalProductCount) {
        // console.log('totalProductCount=====>>>>', totalProductCount);
        if (totalProductCount > 0) {
            return (<AntdPagination total={totalProductCount} antdPageChange={this.handleAntdPageChange}
                                    antdPageSizeChange={this.handleAntdPageSizeChange}/>)
        } else {
            return null
        }
    }

    handleFilterInputChange(event) {
        console.log('handleFilterInputChange', event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));
    }

    handleFilterStatusChange(event) {
        console.log('handleFilterStatusChange', event);

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                status: event.value,
            },
        }));
    }

    handleFilterDateChange(dateInput, value) {
        // console.log('handleFilterDateChange', dateInput, value);

        let dateInputValue = value === null ? undefined : value;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [dateInput]: dateInputValue,
            },
        }));
    }

    handlePageSizeChange(event) {
        console.log('handlePageSizeChange', event);

        this.setState({
                pageSize: event.value,
                pageNumber: 0,
            },
            () => this.handleFormSubmit());
    }

    handleSorting(value) {
        console.log('handleSorting', value, this.state.sorting.column);

        let newOrder;
        let newComponent;

        if (value == this.state.sorting.column) {
            switch (this.state.sorting.order) {
                case '':
                    newOrder = 'asc';
                    break;

                case 'asc':
                    newOrder = 'desc';
                    break;

                case 'desc':
                    newOrder = '';
                    break;
            }
        } else {
            newOrder = 'asc';
        }

        this.setState(prevState => ({
                sorting: {
                    column: value,
                    order: newOrder,
                }
            }),
            () => this.handleFormSubmit());
    }

    handlePageClick(event) {
        console.log('handlePageClick', event);

        this.setState(
            prevState => ({
                pageNumber: event.selected,
            }),
            () => this.handleFormSubmit()
        );

        return false;
    }


    // 改变页码
    handleAntdPageChange(page, pageSize) {
        this.setState({
                pageSize: pageSize,
                pageNumber: page - 1,
            },
            () => this.handleFormSubmit()
        );
    }

    // 改变每页显示条数
    handleAntdPageSizeChange(current, size) {
        this.setState({
                pageSize: size,
                pageNumber: 0,
            },
            () => this.handleFormSubmit()
        );
    }


    handleClearFilter() {
        console.log('handleClearFilter()');

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                productSerialNumber: '',
                productId: '',
                packageId: '',
                status: '',
                inboundDateFrom: undefined,
                inboundDateTo: undefined,
                packedDateFrom: undefined,
                packedDateTo: undefined,
                shippedDateFrom: undefined,
                shippedDateTo: undefined,
                completedDateFrom: undefined,
                completedDateTo: undefined,
            },
        }), ()=>{
            this.handleFormSubmit()
        });
    }

    handleFilterVisibility() {
        console.log('handleFilterVisibility()');

        this.setState(prevState => ({
            filterVisibility: !prevState.filterVisibility
        }));
    }

    handleFormSubmit(event, values) {
        let pageNumber = this.state.pageNumber;
       
        let submitData = {
            productSerialNumber: this.state.filter.productSerialNumber,
            productId: this.state.filter.productId > 0 ?this.state.filter.productId : '',
            packageId: this.state.filter.packageId,
            status: this.state.filter.status,
            inboundDateFrom: this.state.filter.inboundDateFrom !== undefined ? format(new Date(this.state.filter.inboundDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
            inboundDateTo: this.state.filter.inboundDateTo !== undefined ? format(new Date(this.state.filter.inboundDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
            packedDateFrom: this.state.filter.packedDateFrom !== undefined ? format(new Date(this.state.filter.packedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
            packedDateTo: this.state.filter.packedDateTo !== undefined ? format(new Date(this.state.filter.packedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
            shippedDateFrom: this.state.filter.shippedDateFrom !== undefined ? format(new Date(this.state.filter.shippedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
            shippedDateTo: this.state.filter.shippedDateTo !== undefined ? format(new Date(this.state.filter.shippedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
            completedDateFrom: this.state.filter.completedDateFrom !== undefined ? format(new Date(this.state.filter.completedDateFrom), 'yyyy-MM-dd 00:00:00') : undefined,
            completedDateTo: this.state.filter.completedDateTo !== undefined ? format(new Date(this.state.filter.completedDateTo), 'yyyy-MM-dd 23:59:59') : undefined,
            pageNumber: pageNumber + 1,
            pageSize: this.state.pageSize,
            sortBy: `${this.state.sorting.column}${this.state.sorting.order}`
        };
        console.log('on handleFormSubmit()', event, values, submitData);

        this.props.getProductInstanceList(submitData, this.props.history);
        this.props.getProductInstanceGroupedList(submitData, this.props.history);
    }

    handleToggleSwitch() {
        this.setState({pageNumber: 0})
        this.setState((prev) => {
            return {...prev, displayInGroups: !prev.displayInGroups}
        })
        setTimeout(() => {
            this.handleFormSubmit();
        })
    }

    /**
     * modal
     */
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleModalStatusChangeLog(data = []) {
        console.log('toggleModalStatusChangeLog', data);

        let statusChangeLog = [];

        if (data.inboundDate) {
            statusChangeLog.push({
                status: i18n.t('inbound'),
                datetime: data.inboundDate,
                // userId: userStr(data.inboundByAdminUserId, data.inboundByWorkerUserId),
                // userId: data.inboundByAdminUserId ? data.inboundByAdminUserId : data.inboundByWorkerUserId,
                userId: data.inboundUserName,
                location: data.inboundUpdatedInLocation,
            });
        }


        if (data.packedDate) {
            statusChangeLog.push({
                status: i18n.t('packed'),
                datetime: data.packedDate,
                // userId: userStr(data.packedByAdminUserId, data.packedByWorkerUserId),
                userId: data.packageUserName,
                location: data.packedUpdatedInLocation,
            });
        }


        if (data.shippedDate) {
            statusChangeLog.push({
                status: i18n.t('shipped'),
                datetime: data.shippedDate,
                // userId: userStr(data.shippedByAdminUserId, data.shippedByWorkerUserId),
                userId: data.shippedUserName,
                location: data.shippedUpdatedInLocation,
            });
        }


        if (data.completedDate) {
            statusChangeLog.push({
                status: i18n.t('completed'),
                datetime: data.completedDate,
                // userId: userStr(data.completedByAdminUserId, data.completedByWorkerUserId),
                userId: data.completeUserName,
                location: data.completedUpdatedInLocation,
            });
        }


        this.setState(prevState => ({
            modalStatusChangeLog: {
                ...prevState.modalStatusChangeLog,
                toggle: !prevState.modalStatusChangeLog.toggle,
                statusChangeLog: statusChangeLog,
            },
        }));
        this.removeBodyCss();
    }

    toggleModalBlockchain(data = {}) {
        let info = {
            currentBlockHeight: data.currentBlockHeight,
            blockDataHash: data.blockDataHash
        }
        this.setState(prevState => ({
            blockchainData: {
                ...prevState.blockchainData,
                toggle: !prevState.blockchainData.toggle,
                info: info,
            },
        }));
        this.removeBodyCss();
    }


    render() {
        return (
            <Translation>
                {
                    (t, {i18n}) =>
                        <React.Fragment>
                            {/*{this.props.loading ? <Loader/> : null}*/}

                            <div className="container-fluid">
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="page-title-box">
                                            {/* <h4 className="navi-heander-size">{t('inventory')}</h4> */}
                                            <ol className="breadcrumb mb-0">
                                                <li className="breadcrumb-item font-bold-size">{t('inventory_info_manager')}</li>
                                                <li className="breadcrumb-item sub-tile-color"><Link
                                                    >{t('inventory')} 
                                                    { this.state.productName && <span>({this.state.productName})</span>}</Link></li> 
                                            </ol>
                                            
                                            {/*<CSVLink data={csvData} filename={"my-file.csv"}>Download CVS</CSVLink>*/}
                                            {/*<CSVDownload data={csvData} target="_blank" />*/}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                        <div>
                                                            <Row>
                                                                <Col xs="6">
                                                                    <button
                                                                        onClick={this.handleFilterVisibility}
                                                                        className="btn btn-primary mo-mb-2 btn-primary-bg"
                                                                        type="button"
                                                                    >
                                                                        {this.state.filterVisibility === true ? t('hide_filter') : t('show_filter')}
                                                                    </button>
                                                                </Col>
                                                                <Col xs="6">
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end"
                                                                    }}>
                                                                        {this.state.displaySwitch && <ToggleSwitch
                                                                            checked={this.state.displayInGroups}
                                                                            onChange={this.handleToggleSwitch}/>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {this.state.displayInGroups ? <CSV path="inventory_grouped"
                                                                                           curfilename={t('inventory_download_page')+ (parseInt(this.state.pageNumber+1)) +t('inventory_download_page2')+ nowDate + '.csv'}
                                                                                           allfilename={t('inventory_download_all_page')+ nowDate + '.csv'}
                                                                                           handler1={CSVHandler.inventory.pageDownloadHandler}
                                                                                           parser={CSVHandler.inventory.parser_grouped}
                                                                                           handler2={this.handler3}/> :
                                                            <CSV path="inventory"
                                                                 curfilename={t('inventory_download_page')+ (parseInt(this.state.pageNumber+1)) +t('inventory_download_page2')+ nowDate + '.csv'}
                                                                 allfilename={t('inventory_download_all_page')+ nowDate + '.csv'}
                                                                 handler1={CSVHandler.inventory.pageDownloadHandler}
                                                                 parser={CSVHandler.inventory.parser}
                                                                 handler2={this.handler2}/>}
                                                    </Col>
                                                </Row>

                                                <Collapse isOpen={this.state.filterVisibility}>
                                                    <AvForm className="mb-3 InfoContainer"
                                                            onValidSubmit={this.handleFormSubmit}>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="productSerialNumber"
                                                                        label={t('serial_number')}
                                                                        value={this.state.filter.productSerialNumber}
                                                                        onChange={this.handleFilterInputChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="productId"
                                                                        label={t('product_id')}
                                                                        value={this.state.filter.productId}
                                                                        onChange={this.handleFilterInputChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="packageId"
                                                                        label={t('package_id')}
                                                                        value={this.state.filter.packageId}
                                                                        onChange={this.handleFilterInputChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup>
                                                                    <Label>{t('status')}</Label>
                                                                    <Select
                                                                        name="status"
                                                                        value={optionStatus.find(option => option.value === this.state.filter.status)}
                                                                        onChange={this.handleFilterStatusChange}
                                                                        options={optionStatus}
                                                                        isSearchable={false}
                                                                        menuPortalTarget={document.body}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                        {/*</Row>*/}


                                                        {/* inbound date picker */}
                                                        {/*<Row>*/}
                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('inbound_date_from')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                  value={this.state.filter.inboundDateFrom}
                                                                                 onChange={(moment,string) => this.handleFilterDateChange('inboundDateFrom', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('inbound_date_to')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                 value={this.state.filter.inboundDateTo}
                                                                                onChange={(moment,string) => this.handleFilterDateChange('inboundDateTo', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            {/* packed date datepicker */}
                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('packed_date_from')}</Label>
                                                                    <InputGroup>
                                                                    <ConfigProvider locale={this.state.lang.lang}>
                                                                        <DatePicker
                                                                            value={this.state.filter.packedDateFrom}
                                                                            onChange={(moment,string) => this.handleFilterDateChange('packedDateFrom', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            {/* shipped date datepicker */}
                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('packed_date_to')}</Label>
                                                                    <InputGroup>
                                                                    <ConfigProvider locale={this.state.lang.lang}>
                                                                         <DatePicker
                                                                            value={this.state.filter.packedDateTo}
                                                                            onChange={(moment,string) => this.handleFilterDateChange('packedDateTo', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        {/*</Row>*/}

                                                        {/* shipped date datepicker */}
                                                        {/*<Row>*/}
                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('shipped_date_from')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                value={this.state.filter.shippedDateFrom}
                                                                                onChange={(moment,string) => this.handleFilterDateChange('shippedDateFrom', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('shipped_date_to')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                value={this.state.filter.shippedDateTo}
                                                                                onChange={(moment,string) => this.handleFilterDateChange('shippedDateTo', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            {/* completed date datepicker */}
                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('completed_date_from')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                value={this.state.filter.completedDateFrom}
                                                                                onChange={(moment,string) => this.handleFilterDateChange('completedDateFrom', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <FormGroup className="forminput">
                                                                    <Label>{t('completed_date_to')}</Label>
                                                                    <InputGroup>
                                                                        <ConfigProvider locale={this.state.lang.lang}>
                                                                            <DatePicker
                                                                                value={this.state.filter.completedDateTo}
                                                                                onChange={(moment,string) => this.handleFilterDateChange('completedDateTo', moment)}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </InputGroup>

                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Button color="primary"
                                                                            className="mr-1 btn-primary-bg">{t('apply')}</Button>
                                                                    <Button color="secondary"
                                                                            onClick={this.handleClearFilter}>{t('clear')}</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                </Collapse>

                                                <Row>
                                                    <Col sm={12}>
                                                        <p className="text-muted m-b-30">
                                                            {this.state.displayInGroups ? entryStr(this.state.pageNumber, this.state.pageSize, this.props.filteredGroupedCount, this.props.totalGroupedProductInstanceCount) : entryStr(this.state.pageNumber, this.state.pageSize, this.props.filteredCount, this.props.totalProductInstanceCount)}
                                                        </p>
                                                    </Col>
                                                </Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            className="table thead-nowrap"
                                                        >
                                                            {this.state.displayInGroups ?
                                                                <>
                                                                    <thead className="thead-header">
                                                                        <tr>
                                                                        <th className="w-1p"><span
                                                                            className="float-right">#</span></th>
                                                                        <th className="w-1p"><span
                                                                            className="float-right">{t('package_id')}</span>
                                                                        </th>
                                                                        <th><span
                                                                            className='float-right'>{t('product_amount')}</span>
                                                                        </th>
                                                                        <th className='w-3p'>{t('status')}</th>
                                                                        {/*<th className='w-3p'>{t('blockchain')}</th>*/}
                                                                        <th>{t('packed_date')}</th>
                                                                        <th>{t('shipped_date')}</th>
                                                                        <th>{t('completed_date')}</th>
                                                                        <th className='w-3p'>{t('product_instances')}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <GroupedProductInstanceTableRow
                                                                        toggleModalBlockchain={this.toggleModalBlockchain}
                                                                        data={this.props.productInstanceGroupedList}
                                                                        pageNumber={this.state.pageNumber}
                                                                        pageSize={this.state.pageSize}/>
                                                                </> :
                                                                <>
                                                                    <thead className="thead-header">
                                                                    <tr>
                                                                        <th className="w-1p"><span
                                                                                className="float-right">#</span></th>
                                                                         {/* hide some sections */}
                                                                         {  
                                                                            !this.state.productName &&
                                                                            <>
                                                                            
                                                                            <th className="w-1p"
                                                                                onClick={() => this.handleSorting('sn')}>
                                                                                <span
                                                                                    className="float-right">{t('serial_number')} {getSortingComponent('sn', this.state.sorting)}</span>
                                                                            </th>
                                                                            <th className="w-1p"
                                                                                onClick={() => this.handleSorting('productId')}>
                                                                                <span
                                                                                    className="float-right">{t('product_id')} {getSortingComponent('productId', this.state.sorting)}</span>
                                                                            </th>
                                                                            <th onClick={() => this.handleSorting('productName')}>{t('product_name')} {getSortingComponent('productName', this.state.sorting)}</th>
                                                                            </>
                                                                        }
                                                                        <th className="w-1p"><span
                                                                            className="float-right">{t('package_id')}</span>
                                                                        </th>
                                                                        <th className='w-3p'>{t('status')}</th>
                                                                        {/*<th className='w-3p'>{t('blockchain')}</th>*/}
                                                                        <th>{t('inbound_date')}</th>
                                                                        <th>{t('packed_date')}</th>
                                                                        <th>{t('shipped_date')}</th>
                                                                        <th>{t('completed_date')}</th>
                                                                        <th className='w-3p'>{t('status_change_log')}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <ProductInstanceTableRow
                                                                        data={this.props.productInstanceList}
                                                                        productName={this.state.productName}
                                                                        toggleModalStatusChangeLog={this.toggleModalStatusChangeLog}
                                                                        pageNumber={this.state.pageNumber}
                                                                        pageSize={this.state.pageSize}/>
                                                                </>
                                                            }
                                                        </Table>
                                                    </div>
                                                </div>
                                                <Row className='antd-pagination'>
                                                    {
                                                        this.state.displayInGroups ? this.props.filteredGroupedCount > 0 ?
                                                            <Col>
                                                               <ConfigProvider locale={this.state.lang.lang}>
                                                                    <Pagination
                                                                        total={this.props.filteredGroupedCount}
                                                                        showSizeChanger
                                                                        showQuickJumper
                                                                        onChange={this.handleAntdPageChange}
                                                                        onShowSizeChange={this.handleAntdPageSizeChange}
                                                                        showTotal={total => t('table_nav_count', {total: total})}
                                                                    />
                                                                </ConfigProvider>
                                                            </Col> : null :
                                                            this.props.filteredCount > 0 ? <Col>
                                                                <ConfigProvider locale={this.state.lang.lang}>
                                                                    <Pagination
                                                                        total={this.props.filteredCount}
                                                                        showSizeChanger
                                                                        showQuickJumper
                                                                        onChange={this.handleAntdPageChange}
                                                                        onShowSizeChange={this.handleAntdPageSizeChange}
                                                                        showTotal={total => t('table_nav_count', {total: total})}
                                                                    />
                                                                </ConfigProvider>
                                                            </Col> : null
                                                    }

                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                            <Modal
                                className="modal-lg"
                                isOpen={this.state.modalStatusChangeLog.toggle}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleModalStatusChangeLog()}
                                >
                                    {t('status_change_log')}
                                </ModalHeader>
                                <ModalBody>
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                className="table table-striped table-bordered thead-nowrap"
                                            >
                                                <thead>
                                                <tr>
                                                    <th>{t('status')}</th>
                                                    <th>{t('status_updated_at')}</th>
                                                    <th>{t('operator_account_id')}</th>
                                                    <th>{t('location')}</th>
                                                </tr>
                                                </thead>
                                                <StatusChangeLogRow
                                                    data={this.state.modalStatusChangeLog.statusChangeLog}/>
                                            </Table>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModalStatusChangeLog()}
                                    >
                                        {t('close')}
                                    </Button>
                                </ModalFooter>
                            </Modal>


                            <Modal
                                className="modal-lg"
                                // isOpen={this.state.blockchainData.toggle}
                                isOpen={false}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleModalBlockchain()}
                                >
                                    上链信息
                                </ModalHeader>
                                <ModalBody>
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                className="table table-striped table-bordered thead-nowrap"
                                            >
                                                <thead>
                                                <tr>
                                                    <th>所在链</th>
                                                    <th>所在区块</th>
                                                    <th>唯一编码</th>
                                                </tr>
                                                </thead>
                                                <BlockLianChangeLogRow2 data={this.state.blockchainData.info}/>
                                            </Table>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModalBlockchain()}
                                    >
                                        {t('close')}
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal
                                style={{width: '400px'}}
                                className="modal-lg"
                                isOpen={this.state.blockchainData.toggle}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleModalBlockchain()}
                                >
                                    上链信息
                                </ModalHeader>
                                <ModalBody>
                                    <BlockLianChangeLogRow data={this.state.blockchainData.info}/>
                                </ModalBody>
                            </Modal>

                        </React.Fragment>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    console.log('mapStateToProps', state.ProductInstance)
    const {
        filteredGroupedCount,
        totalGroupedProductInstanceCount,
        productInstanceList,
        filteredCount,
        totalProductInstanceCount,
        alert,
        error,
        loading,
        productInstanceGroupedList,
    } = state.ProductInstance;

    return {
        filteredGroupedCount,
        totalGroupedProductInstanceCount,
        productInstanceList,
        filteredCount,
        totalProductInstanceCount,
        alert,
        error,
        loading,
        productInstanceGroupedList,
    };
};

export default withRouter(connect(mapStateToProps, {
    getProductInstanceGroupedList,
    getProductInstanceList,
    clearAlert,
    productInstanceApiError,
})(Inventory));
