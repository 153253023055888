export const GET_BATCH_LIST = 'GET_BATCH_LIST';
export const GET_BATCH_LIST_SUCCESS = 'GET_PRODUCT_INFO_SUCCESS';
export const UPDATE_BATCH_INFO = 'UPDATE_BATCH_INFO';
export const DOWNLOAD_CODE_BATCH = 'DOWNLOAD_CODE_BATCH';
export const DOWNLOAD_CODE_BATCH_SUCCESS = 'DOWNLOAD_CODE_BATCH_SUCCESS';
export const UPDATE_BATCH_INFO_SUCCESS = 'UPDATE_BATCH_INFO_SUCCESS';
export const CLEAR_PRODUCT_INSTANCE_CSV = 'CLEAR_PRODUCT_INSTANCE_CSV';


export const GET_PRODUCT_IMAGE = 'GET_PRODUCT_IMAGE';
export const GET_PRODUCT_IMAGE_SUCCESS = 'GET_PRODUCT_IMAGE_SUCCESS';
export const CLEAR_PRODUCT_IMAGE_ID_PROP = 'CLEAR_PRODUCT_IMAGE_ID_PROP';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const SHOW_BATCH_ALERT = 'SHOW_BATCH_ALERT';
export const CLEAR_BATCH_ALERT = 'CLEAR_BATCH_ALERT';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const UPDATE_PRODUCT_SYSTEM_STATUS = 'UPDATE_PRODUCT_SYSTEM_STATUS';


export const CLEAR_PRODUCT_PROPS = 'CLEAR_PRODUCT_PROPS';

export const API_ERROR = 'PRODUCT_API_ERROR';

