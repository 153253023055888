import React from 'react';
import axios from 'axios';
import {Upload, Modal} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import Base64 from 'base-64';

const imgUrl = 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/';
const dir = 'provenance/';
const todayKey = moment().format('YYYYMMDD');
const host = "https://provenance-sz.oss-cn-shenzhen.aliyuncs.com";
const accessKeyId = "LTAI5tM3sbNUsuy9Gfc4vMw3";
const accessSecret = "ZCVR4d9k57prTWyVMsxIFWdCzCwL4Y";
const policyText = {
    "expiration": "2028-01-01T12:00:00.000Z", // 设置该Policy的失效时间，
    "conditions": [
        ["content-length-range", 0, 1048576000] // 设置上传文件的大小限制
    ]
};
const policyBase64 = Base64.encode(JSON.stringify(policyText));
const bytes = CryptoJS.HmacSHA1(policyBase64, accessSecret, {asBytes: true});
const signature = bytes.toString(CryptoJS.enc.Base64);

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class PicturesGrid extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileName: '',
            fileList: this.props.filelist || [],
            fileNum: this.props.filenum || 1,
            fileListTest: [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/provenance/20210415/1618468590182.png',
                }
            ],
        };
        this.uploadRequest = this.uploadRequest.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleCancel = () => this.setState({previewVisible: false});

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = (file) => {
        console.log('删除图片>>>');
        console.log(file);
        console.log(this.state.fileList);
        let fileListArr = this.state.fileList;
        fileListArr.map((item, key) => {
            console.log(item)
            if (item.status === 'removed') {
                fileListArr.splice(key, 1)
            }
        });
        this.setState({
            fileList: [...fileListArr]
        }, () => {
            console.log(this.state.fileList)
            this.props.delFileName(this.state.fileList)
        })
    };

    beforeUpload = file => {
        console.log('上传oss前的文件===>>>', file);
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        let fileItem = {
            uid: file.uid,
            name: filename,
            status: 'done',
            url: imgUrl + dir + todayKey + '/' + filename,
        };

        this.setState({
            fileName: filename,
        }, () => {
            // console.log(fileItem)
            // this.props.uploadFileName(fileItem)
        });

    };

    uploadRequest(params) {
        console.log('自定义上传请求>>>');
        console.log(params);
        let that = this;
        let forms = new FormData();
        let configs = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        let key = dir + todayKey + '/' + this.state.fileName;
        forms.append('key', key);
        forms.append('policy', policyBase64);
        forms.append('OSSAccessKeyId', accessKeyId);
        forms.append('success_action_status', 200);
        forms.append('signature', signature);
        forms.append('file', params.file);

        axios.post(host, forms, configs).then(res => {
            console.log(res);
            if (res.status === 200) {
                let arr = {
                    uid: that.state.fileName,
                    name: this.state.fileName,
                    status: 'done', // removed done
                    url: 'https://provenance-sz.oss-cn-shenzhen.aliyuncs.com/' + key,
                };
                that.setState({
                    fileList: [...this.state.fileList, arr]
                }, () => {
                    console.log('图片上传成功>>>');
                    console.log(this.state.fileList)
                    that.props.uploadFileName(this.state.fileList)
                })
            }
        })
    }

    render() {
        // console.log('上传组件渲染>>>>');
        const {previewVisible, previewImage, fileList, previewTitle} = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined/>
                <div style={{marginTop: 8}}>上传图片</div>
            </div>
        );
        return (
            <>
                <Upload
                    action={host}
                    listType="picture-card"
                    fileList={fileList}
                    customRequest={this.uploadRequest}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    beforeUpload={this.beforeUpload}
                    data={{
                        key: dir + todayKey + '/' + this.state.fileName,
                        policy: policyBase64,
                        OSSAccessKeyId: accessKeyId,
                        success_action_status: 200,
                        signature,
                    }}
                >
                    {fileList.length >= this.state.fileNum ? null : uploadButton}
                </Upload>
                <Modal
                    visible={previewVisible}
                    // title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}>
                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                </Modal>
            </>
        );
    }
}

export default PicturesGrid;
