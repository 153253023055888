import { message } from 'antd';
import { init } from 'echarts';
import {
    GET_PRODUCT_PLAN,
    GET_PRODUCT_PLAN_SUCCESS,
    GET_HISTORY_DYNAMIC_SUCCESS,
    GET_HISTORY_DYNAMIC,
    GET_DYNAMIC_BY_PRODUCT,
    GET_DYNAMIC_BY_PRODUCT_SUCCESS,
    PUT_PRODUCT_PLAN,
    GET_PRODUCT_WITD_PLAN,
    GET_PRODUCT_WITD_PLAN_SUCCESS,

    CREATE_PRODUCT,
    UPDATE_PRODUCT_INFO,

    UPDATE_INPUT_VALUE,
    CLEAR_INPUT_ERROR,
    SHOW_ALERT,
    CLEAR_ALERT,
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,

    UPDATE_PRODUCT_SYSTEM_STATUS,
    UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS,

    CLEAR_PRODUCT_PROPS,

    API_ERROR,
} from './actionTypes';

const initialState = {

    planList: [],
    planNodes:[],
    productList:[],
    productImages: {},
    detailImages: {},
    filteredCount: 0,
    totalCount: 0,
    inputError: {
        productId: true,
        name: true,
        systemStatus: true,
        description: true,
        image: true,
    },
    // search: {
    //     productId: '',
    //     name: '',
    //     systemStatus: 'enabled',
    // },
    alert: {
        success: false,
        successMessage: '',
    },
    error: null,
    loading: false
}

const productPlanInfo = (state = initialState, action) => {
    let product = { ...state.product };
    let productImages = { ...state.productImages };
    let detailImages = { ...state.detailImages };
    let inputError = { ...state.inputError };
    let alert = { ...state.alert };

    switch(action.type) {
        case GET_PRODUCT_PLAN:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_PLAN_SUCCESS:
            console.log('reducer debug', action.payload.data.data);
            let ldata = action.payload.data.data;

            state = {
                ...state,
                planList: ldata.batchInfo,
                filteredCount: ldata.filteredCount,
                totalCount: ldata.total,
                loading: false,
              }

           
            break;
            case GET_HISTORY_DYNAMIC:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_HISTORY_DYNAMIC_SUCCESS:{
            console.log('reducer debug', action.payload.data.data);
            let ldata = action.payload.data.data;

            state = {
                ...state,
                planNodes: ldata.productPlan,
                filteredCount: ldata.filteredCount,
                totalCount: ldata.total,
                loading: false,
              }

            }
            break;

        case GET_DYNAMIC_BY_PRODUCT:
            state = {
                ...state,
                planNodes: [],
                loading: true
            }
            break;
       
        case GET_DYNAMIC_BY_PRODUCT_SUCCESS:{
            let ldata = action.payload.data.data;
            state = {
                ...state,
                planNodes: ldata,
                loading: false,
            }
            }
            break;
        case PUT_PRODUCT_PLAN:{
                state = {
                    ...state,
                    loading: true
                }

            }    
            break;
        case GET_PRODUCT_WITD_PLAN:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_PRODUCT_WITD_PLAN_SUCCESS:
           
            state = {
                ...state,
                productList: action.payload.data.data,
                loading: false,
            }
            break;
        case UPDATE_PRODUCT_INFO:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_PRODUCT_SYSTEM_STATUS:
            state = {
                ...state,
                loading: true
            }
            break;
        case UPDATE_PRODUCT_SYSTEM_STATUS_SUCCESS:
            state = {
                ...state,
                loading: false
            }
            break;
        case UPDATE_INPUT_VALUE:
            product[action.payload.inputName] = action.payload.value;

            state = {
                ...state,
                product
            }
            break;
        case CLEAR_INPUT_ERROR:
            console.log('clear input error', action);

            inputError[action.payload] = true;

            console.log('after clear input error', inputError)

            state = {
                ...state,
                inputError,
            }
            break;
        case SHOW_ALERT:
            console.log('show alert', action.payload);

            alert[action.payload] = true;
            state = {
                ...state,
                alert,
                loading: false
            }
            break;
        case CLEAR_ALERT:
            // console.log('clear alert', action.payload);

            alert[action.payload] = false;
            state = {
                ...state,
                alert,
            }
            break;
        case CLEAR_PRODUCT_PROPS:
            state = {
                ...state,
                productList: initialState.productList,
                product: initialState.product,
            }
            break;
        case API_ERROR:
            console.log('API Error', action.payload);

            if(action.payload !== undefined) {
                for(let tmp of action.payload.data.errs) {
                    console.log('loop error', tmp);
                    // message.warning({
                    //     duration: 0,
                    //     content: tmp.error
                    // });
                    message.warning(tmp.error);
                    if(tmp.field !== undefined) {
                        switch(tmp.field) {
                            case 'name':
                                inputError.name = tmp.error;
                                break;
                            case 'systemStatus':
                                inputError.systemStatus = tmp.error;
                                break;
                            case 'description':
                                inputError.description = tmp.error;
                                break;
                            case 'productionAddress':
                                inputError.productionAddress = tmp.error;
                                break;
                            case 'shopUrl':
                                inputError.shopUrl = tmp.error;
                                break;
                            case 'image':
                                inputError.image = tmp.error;
                                break;
                        }
                    }
                }
            }

            state = {
                ...state,
                inputError,
                error: action.payload,
                loading: false
            };
            break;
        default:
            // state = { ...state };
            break;
    }
    return state;
}

export default productPlanInfo;
