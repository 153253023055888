export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_CERTIFICATE_SUCCESS = 'GET_COMPANY_CERTIFICATE_SUCCESS';
export const CLEAR_COMPANY_CERTIFICATE_PROP = 'CLEAR_COMPANY_CERTIFICATE_PROP';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const CREATE_COMPANY_INFO = 'CREATE_COMPANY_INFO';

export const COMPANY_WORKER_LIST = 'COMPANY_WORKER_LIST';
export const COMPANY_WORKER_LIST_SUCCESS = 'COMPANY_WORKER_LIST_SUCCESS';

export const REGISTER_APP_USER = 'REGISTER_APP_USER';
export const DEL_APP_USER = 'DEL_APP_USER';

export const API_ERROR = 'COMPANY_API_ERROR';
